import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Redirect } from 'react-router-dom';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import { useUserState, useWindowDimensions } from '../../../hook/customerHook';
import NavList from '../NavList';
import { Copyright, SearchAddressBar } from '../..';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import logo from '../../../SaigeLogo.svg';
import NotificationDropdown from './NotificationDropdown';
import { getUserNotificationPreferences } from '../../../api/serverRequests';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		overflow: 'hidden',
		position: 'relative'
	},
	toolbar: {
		alignItems: 'center',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: 10,
			paddingRight: 10
		},
		[theme.breakpoints.up('sm')]: {
			paddingLeft: 50,
			paddingRight: 50
		}
	},
	toolbarIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		backgroundColor: '#fafafa',
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	menuButton: {
		// marginRight: '40%',
		display: 'inline-block'
	},
	menuButtonHidden: {
		display: 'none'
	},
	title: {
		display: 'inline-block',
		padding: 5,
		paddingTop: 8,
		paddingLeft: 0,
		[theme.breakpoints.down('sm')]: {
			fontSize: 12
		}
	},
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		width: theme.spacing(7)
	},
	appBarSpacer: theme.mixins.toolbar,

	content: {
		width: `calc(100% - ${theme.spacing(7)}px)`,
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto',
		[theme.breakpoints.up('xl')]: {
			width: 'fit-content',
			flexShrink: 1
		}
	},
	container: {
		padding: theme.spacing(4, 3),
		[theme.breakpoints.up('xs')]: {
			padding: theme.spacing(4, 1)
		},
		[theme.breakpoints.up('sm')]: {
			padding: theme.spacing(6, 4)
		},
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(4, 4)
		}
	},
	sideContainers: {
		display: 'flex',
		alignItems: 'center'
	},
	containerCover: {
		position: 'absolute',
		zIndex: theme.zIndex.drawer + 1,
		top: 0,
		left: 0,
		[theme.breakpoints.up('md')]: {
			height: '120vh',
			width: '100%',
			backgroundColor: 'rgba(0, 0, 0, 0.5)'
		}
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column'
	},
	fixedHeight: {
		height: 12
	},
	iconColor: {
		'& .MuiSvgIcon-root': {
			color: '#004F2B'
		}
	},
	saigeLogo: {
		[theme.breakpoints.down('sm')]: {
			width: 120,
			height: 30
		},
		[theme.breakpoints.up('sm')]: {
			width: 160,
			height: 60
		}
	}
}));

export default function BaseLayout({ children, ...props }) {
	const classes = useStyles();
	const [open, setOpen] = useState(true);
	const [openSearch, setOpenSearch] = useState(false);
	const [drawerVariant, setDrawerVariant] = useState('permanent');
	const [userState, dispatchUser] = useUserState();
	const dimensions = useWindowDimensions();
	const location = useLocation();
	const [auth, isAuth] = useState(false);
	const history = useHistory();

	const [isNotificationDropdownOpen, setIsNotificationDropdownOpen] = useState(false);
	const [userNotificationPreferences, setUserNotificationPreferences] = useState(null);
	const [isLoadingNotificationPreferences, setIsLoadingNotificationPreferences] = useState(false);
	const [reFetchPreferences, setReFetchPreferences] = useState(false);

	useEffect(() => {
		//bandaid fix to check if dev environment
		if (
			document.location.hostname.search('localhost') === 0 ||
			document.location.hostname.search('beta.realsaige.com') === 0 ||
			document.location.hostname.search('beta.realsaige.ca') === 0
		)
			return;
		ReactGA.set({ userId: userState.user.user_id });
		ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
	}, [location]);

	useEffect(() => {
		if (!userState.user) {
			<Redirect to="/login" />;
		}
	}, [userState]);

	useEffect(() => {
		if (dimensions.width < 960) {
			setOpen(false);
			setDrawerVariant('temporary');
		} else {
			setDrawerVariant('permanent');
		}
	}, [dimensions]);

	useEffect(() => {
		// here we fetch the user's notification preferences

		let unmounted = false;
		const fetchData = async () => {
			setIsLoadingNotificationPreferences(true);
			if (!unmounted) {
				getUserNotificationPreferences()
					.then((res) => {
						if (res.status === 200) {
							// if the user has not set up notifications
							if (res.data.preferences === 'None')
								setUserNotificationPreferences(false);
							// if the user has set up notifications already
							else setUserNotificationPreferences(res.data.preferences);
						}

						if (!unmounted) setIsLoadingNotificationPreferences(false);
					})
					.catch((err) => {
						if (err.response.status === 401) {
							dispatchUser({
								isLoggedIn: false
							});
							return;
						} else if (!unmounted) {
							setIsLoadingNotificationPreferences(false);
						}
						console.error(err.message);
					});
			}
		};

		fetchData();
		return () => {
			unmounted = true;
		};
	}, [userState.user_id, dispatchUser, reFetchPreferences]);

	const handleDrawerOpen = () => {
		setOpen(!open);
	};
	const handleDrawerClose = () => {
		setOpen(!open);
	};

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar
				position="absolute"
				className={clsx(classes.appBar, dimensions.width < 960 && open)}
			>
				<Toolbar className={classes.toolbar}>
					<Box className={classes.sideContainers}>
						<IconButton
							edge="start"
							color="inherit"
							aria-label="open drawer"
							onClick={handleDrawerOpen}
							className={clsx(classes.menuButton, open, classes.iconColor)}
						>
							<MenuIcon />
						</IconButton>
						<Typography
							component="h1"
							variant="h6"
							noWrap
							className={classes.title}
							style={{ color: '#004F2B' }}
						>
							{userState.user ? userState.user.organization : ''}
						</Typography>
					</Box>
					<img src={logo} alt="Saige Logo" className={classes.saigeLogo} />
					<Box className={classes.sideContainers}>
						<Box>
							<IconButton
								color="inherit"
								onClick={() =>
									setIsNotificationDropdownOpen(!isNotificationDropdownOpen)
								}
							>
								<Badge
									badgeContent={0}
									color="secondary"
									className={classes.iconColor}
								>
									<NotificationsIcon />
								</Badge>
							</IconButton>
							<NotificationDropdown
								isNotificationDropdownOpen={isNotificationDropdownOpen}
								userNotificationPreferences={userNotificationPreferences}
								isLoadingNotificationPreferences={isLoadingNotificationPreferences}
								reFetchPreferences={reFetchPreferences}
								setReFetchPreferences={setReFetchPreferences}
							/>
						</Box>
						<IconButton
							color="inherit"
							onClick={() => window.open('/user/callcenter', '_blank')}
						>
							<Badge badgeContent={0} color="secondary" className={classes.iconColor}>
								<HeadsetMicIcon />
							</Badge>
						</IconButton>
						<SearchAddressBar setOpenSearch={setOpenSearch} />
					</Box>
				</Toolbar>
			</AppBar>
			<Drawer
				variant={drawerVariant}
				classes={{
					paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
				}}
				open={open}
				ModalProps={{
					keepMounted: true
				}}
			>
				<div className={classes.toolbarIcon}>
					<IconButton onClick={handleDrawerClose}>
						<ChevronLeftIcon />
					</IconButton>
				</div>
				<NavList auth={auth} />
			</Drawer>
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				{open && (
					<Box
						className={clsx(dimensions.width < 960 && classes.containerCover)}
						onClick={handleDrawerClose}
					>
						&nbsp;
					</Box>
				)}
				<Container maxWidth="xl" className={classes.container}>
					{children}
					<Box pt={4}>
						<Copyright />
					</Box>
				</Container>
			</main>
		</div>
	);
}
