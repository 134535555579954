import React from 'react';

const FireRating = ({ rating }) => {
	const maxRating = 5;
	const coloredFires = Math.min(parseInt(rating), maxRating);
	const greyFires = maxRating - coloredFires;

	const fireEmojis = [];
	for (let i = 0; i < coloredFires; i++) {
		fireEmojis.push(
			<span
				key={i}
				role="img"
				aria-label="Fire"
				style={{ color: 'orange', fontSize: '1.4em' }}
			>
				💎
			</span>
		);
	}
	for (let i = 0; i < greyFires; i++) {
		fireEmojis.push(
			<span
				key={coloredFires + i}
				role="img"
				aria-label="Fire"
				style={{ filter: 'grayscale(100%)', fontSize: '1.4em' }}
			>
				💎
			</span>
		);
	}

	return <>{fireEmojis}</>;
};

export default FireRating;
