import React, { useState } from 'react';

import { Controller } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
	root: {
		'& > * + *': {
			marginTop: theme.spacing(1)
		}
	},
	valueContainer: {
		'& > :not(:last-child)': {
			marginRight: theme.spacing(1)
		},
		'& > *': {
			marginBottom: theme.spacing(1)
		}
	},
	hidden: { display: 'none' }
}));

function TagSelectField({
	itemGrid,
	name,
	label,
	options,
	control,
	fieldStyle,
	multiple,
	defaultValue,
	requiredValue,
	disabled,
	title
}) {
	const classes = useStyles();
	return (
		<Grid item xs={12} sm={itemGrid}>
			<Controller
				name={name}
				control={control}
				defaultValue={defaultValue}
				color="primary"
				render={({ onChange, value }) => {
					if (label === 'Agent') {
						return (
							<div className={classes.root}>
								<Autocomplete
									fullWidth
									className={classes.hidden}
									multiple={multiple}
									value={value}
									disableCloseOnSelect={multiple}
									options={options}
									disabled={disabled}
									disableClearable={true}
									getOptionLabel={(option) => (option ? option.label : option)}
									renderOption={(option) => <span>{option.label}</span>}
									renderInput={(params) => (
										<TextField
											{...params}
											label={label}
											required={requiredValue}
											title={title}
										/>
									)}
									onChange={(_, data) => {
										onChange(data);
									}}
								/>
							</div>
						);
					} else if (label === 'Tag Name') {
						return (
							<div className={classes.root}>
								<Autocomplete
									freesolo
									fullWidth
									filterSelectedOptions
									multiple={multiple}
									value={value}
									disableCloseOnSelect={multiple}
									options={options}
									disabled={disabled}
									disableClearable={true}
									getOptionLabel={(option) =>
										option.value ? option.value.tagName : ''
									}
									renderOption={(option) => <span>{option.value.tagName}</span>}
									renderInput={(params) => {
										return (
											<TextField {...params} label={label} title={title} />
										);
									}}
									onChange={(_, data) => {
										onChange(data);
									}}
								/>
							</div>
						);
					} else {
						return (
							<Autocomplete
								freesolo
								fullWidth
								multiple={multiple}
								value={value}
								limitTags={5}
								disableCloseOnSelect={multiple}
								options={options}
								disabled={disabled}
								getOptionLabel={(option) => (option ? option.label : option)}
								renderOption={(option) => <span>{option.label}</span>}
								renderInput={(params) => {
									return (
										<TextField
											{...params}
											label={label}
											required={requiredValue}
											title={title}
										/>
									);
								}}
								onChange={(_, data) => {
									onChange(data);
								}}
							/>
						);
					}
				}}
			/>
		</Grid>
	);
}

export default React.memo(TagSelectField);
