import React, { useState, useEffect } from 'react';
import { Typography, Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import PropertyCard from '../PropertyCard';
import PropertyMap from '../PropertyMap';

const useStyles = makeStyles((theme) => ({
	root: {
		//
	},
	firstTitle: {
		margin: '0.4rem 0',
		paddingBottom: 10
	},
	noActiveTitle: {
		color: theme.palette.grey['KN_light_grey'],
		textAlign: 'center',
		lineHeight: 2
	},
	centerItem: {
		marginTop: 40,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%'
	}
}));

export default function NearbyActiveTab({ listing, activeListings, isLoadingActiveListings }) {
	const classes = useStyles();

	const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
	const [isMobileGoogleMap, setIsMobileGoogleMap] = useState(window.innerWidth <= 960);
	const [selectedMapListing, setSelectedMapListing] = useState(null);

	useEffect(() => {
		function handleResize() {
			setIsMobile(window.innerWidth <= 600);
		}

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		function handleResize() {
			setIsMobileGoogleMap(window.innerWidth <= 960);
		}

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleSelectedMapListing = (selectedListingLocation) => {
		setSelectedMapListing(selectedListingLocation);
	};

	return isLoadingActiveListings ? (
		<div className={classes.centerItem}>
			<CircularProgress />
		</div>
	) : (
		<>
			{activeListings.length === 0 ? (
				<Typography
					variant="h4"
					component="h4"
					className={`${classes.centerItem} ${classes.noActiveTitle}`}
				>
					No nearby active listings! Don't worry, we'll keep our eyes peeled for you.
				</Typography>
			) : (
				<>
					<Typography variant="h4" component="h4" className={classes.firstTitle}>
						Nearby Active Listings
					</Typography>
					<Grid container spacing={2}>
						{isMobileGoogleMap ? (
							<>
								<Grid item xs={12} md={6}>
									<PropertyMap
										currentPropertyLocation={listing.location}
										otherListings={activeListings}
										handleSelectedMapListing={handleSelectedMapListing}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<PropertyCard
										listingCardInfo={activeListings}
										isMobile={isMobile}
										selectedMapListing={selectedMapListing}
									/>
								</Grid>
							</>
						) : (
							<>
								<Grid item xs={12} md={6}>
									<PropertyCard
										listingCardInfo={activeListings}
										isMobile={isMobile}
										selectedMapListing={selectedMapListing}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<PropertyMap
										currentPropertyLocation={listing.location}
										otherListings={activeListings}
										handleSelectedMapListing={handleSelectedMapListing}
									/>
								</Grid>
							</>
						)}
					</Grid>
				</>
			)}
		</>
	);
}
