import React, { useState, useEffect } from 'react';
import { BaseLayout } from '../../..';
//Dynamically load components on request for faster page rendering
import loadable from '@loadable/component';
import {
	updateStatus,
	deleteStatus,
	postStatus,
	getStatusByOrganization
} from '../../../../api/serverRequests';
import './tags.css';
import { Grid, CircularProgress } from '@material-ui/core';
const StatusTable = loadable(() => import('../../Status/StatusTable'));

const styles = {
	progress: { display: 'flex', justifyContent: 'center' }
};

export default function StatusTab() {
	const [status, setStatus] = useState([]);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		setLoading(true);
		fetch();
	}, []);

	const SORTERS = {
		NUMBER_ASCENDING: (mapper) => (a, b) => mapper(a) - mapper(b),
		NUMBER_DESCENDING: (mapper) => (a, b) => mapper(b) - mapper(a),
		STRING_ASCENDING: (mapper) => (a, b) => mapper(a).localeCompare(mapper(b)),
		STRING_DESCENDING: (mapper) => (a, b) => mapper(b).localeCompare(mapper(a))
	};

	const getSorter = (data) => {
		const mapper = (x) => x[data.field];
		let sorter = SORTERS.STRING_ASCENDING(mapper);

		if (data.field === 'id' || data.field === 'Id') {
			sorter =
				data.direction === 'ascending'
					? SORTERS.NUMBER_ASCENDING(mapper)
					: SORTERS.NUMBER_DESCENDING(mapper);
		} else if (data.field === 'Status') {
			sorter =
				data.direction === 'ascending'
					? SORTERS.STRING_ASCENDING(mapper)
					: SORTERS.STRING_DESCENDING(mapper);
		}

		return sorter;
	};

	const fetch = async () => {
		return getStatusByOrganization()
			.then((res) => {
				if (res.status === 200) {
					setStatus(res.data.statusArr);
					setLoading(false);
				}
			})
			.catch((err) => {
				console.log(err.message);
				setLoading(false);
			});
	};

	const addtoStatus = async (data) => {
		postStatus(data)
			.then((res) => {
				if (res.status === 200) {
					setLoading(true);
					fetch();
				}
			})
			.catch((err) => {
				console.log(err.message);
			});
	};

	const deleteTheStatus = async (data) => {
		deleteStatus(data)
			.then((res) => {
				if (res.status === 200) {
					setLoading(true);
					fetch();
				}
			})
			.catch((err) => {
				console.log(err.message);
			});
	};

	const UpdateTheStatus = async (id, head) => {
		updateStatus(id, head)
			.then((res) => {
				if (res.status === 200) {
					setLoading(true);
					fetch();
				}
			})
			.catch((err) => {
				console.log(JSON.stringify(id));
				console.log(JSON.stringify(head));
				console.log(err.message);
			});
	};

	const service = {
		fetchItems: async (payload) => {
			let result = Array.from(status);
			result = result.sort(getSorter(payload.sort));
			return Promise.resolve(result);
		},
		create: async (task) => {
			const tetst = { statusArr: [task.Status] };
			addtoStatus(tetst);
			return Promise.resolve(task);
		},
		update: async (data) => {
			const head = { status: [data.Status] };
			const id = data.Id;
			UpdateTheStatus(id, head);
			return Promise.resolve(data);
		},
		delete: async (data) => {
			const test = { statusArr: [data.Id] };
			deleteTheStatus(test);
			return Promise.resolve(data);
		}
	};

	return (
		<>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				{!loading ? (
					<StatusTable status={status} service={service} />
				) : (
					<div style={styles.progress}>
						<CircularProgress />
					</div>
				)}
			</Grid>
		</>
	);
}
