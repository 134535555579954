import React, { useState, useEffect } from 'react';
import { Avatar, Typography, Box, Modal, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Work from '@material-ui/icons/Work';
import HouseIcon from '@material-ui/icons/House';
import InfoIcon from '@material-ui/icons/Info';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import {
	formatAsPercentage,
	formatAddress,
	formatPrice,
	correctKnockabilityValue
} from '../../../helper/parser';
import DetailChips from './DetailChips';
import SideIcons from './SideIcons';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import TramIcon from '@material-ui/icons/Tram';
import TrainIcon from '@material-ui/icons/Train';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import FireRating from '../FireRating';
import InfoTable from '../InfoTable';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'column'
	},
	propertyProfile: {
		display: 'flex',
		flexWrap: 'nowrap',
		[theme.breakpoints.up('sm')]: {
			alignItems: 'center'
		}
	},
	propertyInfo: {
		display: 'flex',
		flexDirection: 'column'
	},
	propertyChips: {
		marginTop: 22,
		marginBottom: 22
	},
	info: {
		flexGrow: '1',
		marginLeft: '0',
		[theme.breakpoints.up('md')]: {
			marginLeft: '1.5rem'
		}
	},
	details: {
		marginRight: '1rem',
		flexShrink: '0',
		[theme.breakpoints.down('s')]: {
			width: '40%'
		}
	},
	avatar: {
		height: '4.5rem',
		width: '4.5rem',
		display: 'block',
		marginRight: 8
	},
	text: {
		display: 'inline-block'
	},
	icon: {
		transform: 'translateY(25%)',
		marginRight: '0.5rem',
		color: theme.palette.grey.KN_grey
	},
	commissionText: {
		marginLeft: 6
	},
	previousPrice: {
		textDecoration: 'line-through',
		display: 'inline',
		margin: '0 0.5rem 0 0'
	},
	noMaxWidth: {
		maxWidth: 'none'
	}
}));

export default function DetailsPageListTitle({
	listing,
	setIsLoading,
	checkListingHistory,
	setShouldRefetch,
	shouldRefetch,
	addCommentsVisible,
	tagsVisible,
	activeStep,
	setActiveStep,
	currentPropertyIndex,
	totalNumProperties,
	setCurrentPropertyIndex,
	resetPermissions,
	isLoadingSecondary,
	setIsLoadingSecondary,
	updateChipsForFollowUp,
	isUsingHomeId,
	chipData
}) {
	const classes = useStyles();
	let property_value;
	const {
		Pics: pic,
		Address,
		Knockability,
		reason,
		City,
		Municipality,
		Area,
		TotBR: Beds,
		TotBaths: Baths,
		TotFlArea: FloorArea,
		NumKitchens,
		assessment,
		currentAssessment,
		ListPrice,
		SoldDate,
		PrevPrice,
		Status,
		Commission,
		estimate_mid
	} = listing;

	function commission_calculator(comm) {
		let totCommission = 0;
		if (Status === 'A') property_value = ListPrice;
		else property_value = assessment;
		if (property_value === undefined) {
			property_value = currentAssessment;
		}
		try {
			comm = comm.replace('S-', '');
			comm = comm.replace(/"/g, '');
			comm = comm.replace('$', '');

			const regexPattern = /\d+(\.\d+)?%/g;
			const regexPatternTwo = /^(\d{1,6}(,\d{3})*(\.\d+)?).*$/;

			const percentage_commission_match = comm.match(regexPattern);
			const flat_commission_match = comm.match(regexPatternTwo);

			if (!percentage_commission_match && !flat_commission_match) {
				totCommission = 100000 * 0.035 + (property_value - 100000) * 0.0125;
				return Math.round(totCommission);
			}

			if (percentage_commission_match) {
				if (percentage_commission_match.length === 2) {
					percentage_commission_match[0] = percentage_commission_match[0].replace(
						'%',
						''
					);
					percentage_commission_match[1] = percentage_commission_match[1].replace(
						'%',
						''
					);
					const comm_first_hundred = percentage_commission_match[0] / 100; //commission for the first 100k
					const rest_of_comm = percentage_commission_match[1] / 100; //commission for the rest of price
					totCommission =
						100000 * comm_first_hundred + (property_value - 100000) * rest_of_comm;
				} else if (percentage_commission_match.length === 1) {
					percentage_commission_match[0] = percentage_commission_match[0].replace(
						'%',
						''
					);
					totCommission = property_value * (percentage_commission_match[0] / 100);
				}
			} else if (flat_commission_match != null) {
				totCommission = flat_commission_match[1].replace(',', '');
			}

			return Math.round(totCommission);
		} catch {
			return 100000 * 0.035 + (property_value - 100000) * 0.0125;
		}
	}

	const renderPrice = () => {
		return (
			<>
				{!isUsingHomeId && PrevPrice != null && PrevPrice > 0 && (
					<p className={classes.previousPrice}>${formatPrice(PrevPrice)}</p>
				)}
				${formatPrice(ListPrice)}
			</>
		);
	};
	function isOlderThanTwoYears(dateToCheck) {
		if (!dateToCheck || dateToCheck == '0000-00-00') {
			return true;
		}
		var currentDate = new Date();

		// Calculate the date 2 years ago
		var twoYearsAgo = new Date(currentDate);
		twoYearsAgo.setFullYear(currentDate.getFullYear() - 2);

		// Compare the given date with the date 2 years ago
		const out = new Date(dateToCheck) < twoYearsAgo;
		return out;
	}

	const [chips, setChips] = useState([]);

	useEffect(() => {
		setChips([]);

		const renderSkytrainStatusChip = (toaStatus) => {
			if (toaStatus.status) {
				let toaStatusChips = {
					id: 'toaStatus',
					label: `${toaStatus.status} (${toaStatus.distance}m from ${toaStatus.name})`,
					value: `${toaStatus.status} (${toaStatus.distance}m from ${toaStatus.name})`,
					color: '#E3FAE0'
				};
				if (toaStatus.type === 'skytrain station') {
					toaStatusChips.icon = <TramIcon />;
				} else if (toaStatus.type === 'west coast express') {
					toaStatusChips.icon = <TrainIcon />;
				} else if (toaStatus.type === 'major bus exchanges') {
					toaStatusChips.icon = <AirportShuttleIcon />;
				}
				setChips((prevState) => [...prevState, toaStatusChips]);
			}
		};

		if (chipData && Object.keys(chipData).length > 0) {
			for (let key in chipData) {
				if (chipData.hasOwnProperty(key)) {
					if (key === 'toa_status') {
						let toaStatus = chipData['toa_status']['object'];
						renderSkytrainStatusChip(toaStatus);
					} else {
						setChips((prevState) => [
							...prevState,
							{
								id: key,
								label: chipData[key]['default'],
								value: chipData[key]['default'],
								tooltip:
									chipData[key].hasOwnProperty('tooltip') &&
									typeof chipData[key]['tooltip'] === 'string'
										? chipData[key]['tooltip']
										: '',
								color:
									chipData[key].hasOwnProperty('color') &&
									typeof chipData[key]['color'] === 'string'
										? chipData[key]['color']
										: ''
							}
						]);
					}
				}
			}
		}
	}, [chipData]);

	useEffect(() => {
		updateChipsForFollowUp(chips);
	}, [chips]);

	const fullAddress = `${Address}, ${City || Municipality || Area}`;
	// for modal TODO: move to infotable
	const [openModal, setOpenModal] = React.useState(false);
	const handleOpenModal = () => setOpenModal(true);
	const handleCloseModal = () => setOpenModal(false);
	const modalStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4
	};
	return (
		<Box className={classes.root}>
			{/* Address and Avatar */}
			<Box className={classes.propertyProfile}>
				{!isUsingHomeId && <Avatar src={pic} alt={Address} className={classes.avatar} />}
				<Box className={classes.info}>
					<Typography component="h2" variant="h2">
						{formatAddress(fullAddress)}
					</Typography>
				</Box>
				<SideIcons
					listing={listing}
					setIsLoading={setIsLoading}
					setShouldRefetch={setShouldRefetch}
					shouldRefetch={shouldRefetch}
					addCommentsVisible={addCommentsVisible}
					tagsVisible={tagsVisible}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					currentPropertyIndex={currentPropertyIndex}
					totalNumProperties={totalNumProperties}
					setCurrentPropertyIndex={setCurrentPropertyIndex}
					resetPermissions={resetPermissions}
					isLoadingSecondary={isLoadingSecondary}
					setIsLoadingSecondary={setIsLoadingSecondary}
				/>
			</Box>
			{/* Chips */}
			<Box className={classes.propertyChips}>
				<DetailChips chips={chips} />
			</Box>
			{/* Property Info */}
			<Box className={classes.propertyInfo}>
				{Status !== 'A' && isOlderThanTwoYears(SoldDate) && (
					<Box className={classes.details}>
						<Typography
							variant="subtitle1"
							component="h5"
							className={classes.text}
							onClick={reason && handleOpenModal}
						>
							<FireRating rating={Knockability * 5} />
						</Typography>
						<Modal
							open={openModal}
							onClose={handleCloseModal}
							aria-labelledby="modal-modal-title"
							aria-describedby="modal-modal-description"
						>
							{reason && (
								<Box sx={modalStyle}>
									<Typography id="modal-modal-description" sx={{ mt: 2 }}>
										<InfoTable data={reason} />
									</Typography>
								</Box>
							)}
						</Modal>
					</Box>
				)}
				<Box className={classes.details}>
					<Tooltip title={formatAsPercentage(Knockability)}>
						<HouseIcon className={classes.icon} />
					</Tooltip>
					<Typography variant="subtitle1" component="h5" className={classes.text}>
						{renderPrice()}
					</Typography>
				</Box>
				{estimate_mid && (
					<Box className={classes.details}>
						<BubbleChartIcon className={classes.icon} />
						<Typography variant="subtitle1" component="h5" className={classes.text}>
							${estimate_mid}
							<Typography
								variant="subtitle2"
								component="h5"
								className={`${classes.text} ${classes.commissionText}`}
							>
								{'Saige Estimate'}
							</Typography>
						</Typography>
					</Box>
				)}
				<Box className={classes.propertyInfo}>
					<Box className={classes.details}>
						<InfoIcon className={classes.icon} />
						<Typography variant="subtitle1" component="h5" className={classes.text}>
							{isUsingHomeId
								? `${Beds} Bed • ${Baths} Bath • ${FloorArea} sqft`
								: `${Beds} Bed • ${Baths} Bath • ${NumKitchens} Kit • ${FloorArea} sqft`}
						</Typography>
					</Box>
				</Box>
				<Box className={classes.details}>
					<Work className={classes.icon} />
					{Commission ? (
						<Tooltip title={Commission} classes={{ tooltip: classes.noMaxWidth }}>
							<Typography variant="subtitle1" component="h5" className={classes.text}>
								{`$${commission_calculator(Commission).toLocaleString()}`}
								<Typography
									variant="subtitle2"
									component="h5"
									className={`${classes.text} ${classes.commissionText}`}
								>
									{'  Estimated Commission'}
								</Typography>
							</Typography>
						</Tooltip>
					) : (
						<Typography variant="subtitle1" component="h5" className={classes.text}>
							{`$${commission_calculator(Commission).toLocaleString()}`}
							<Typography
								variant="subtitle2"
								component="h5"
								className={`${classes.text} ${classes.commissionText}`}
							>
								{'  Estimated Commission'}
							</Typography>
						</Typography>
					)}
				</Box>
			</Box>
		</Box>
	);
}
