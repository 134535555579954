import React, { useState, useEffect } from 'react';
import MuiTableCell from '@material-ui/core/TableCell';
import { Typography, TableRow, Chip, Grid, Snackbar } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import Alert from '@material-ui/lab/Alert';

import ViewMoreModal from '../ViewMoreModal';

import { useUserState } from '../../../../../hook/customerHook';
import { deleteTagFromHome } from '../../../../../api/serverRequests';

const useStyles = makeStyles((theme) => ({
	viewMore: {
		display: 'inline-block',
		marginLeft: 10,
		textDecoration: 'underline',
		color: theme.palette.primary.main,
		transition: 'transform 0.07s linear',
		'&:hover': {
			cursor: 'pointer',
			transform: 'scale(0.95)'
		},
		'@media (max-width: 520px)': {
			marginLeft: 0
		}
	},
	chip: {
		display: 'inline-block',
		margin: 3
	}
}));
const TableCell = withStyles({
	root: {
		borderBottom: 'none',
		padding: '0.5rem 0'
	}
})(MuiTableCell);

export default function RenderTags({ homeId, tags, setTags }) {
	const classes = useStyles();
	const [userState, dispatchUser] = useUserState();

	// for tags to display
	const [displayedTags, setDisplayedTags] = useState([]);

	// for opening/closing view more modal
	const [viewMore, setViewMore] = useState(false);

	// // submit status updated after user has clicked save in edit modal
	const [submitSuccessStatus, setSubmitSuccessStatus] = useState(''); // keeps track of status when data is posted

	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message

	// useEffect to determine how many tags to display based on a given the users viewWidth
	useEffect(() => {
		function updateDisplayedTags() {
			const screenWidth = window.innerWidth;
			let numberOfTagsToDisplay;

			if (screenWidth < 520) numberOfTagsToDisplay = 0;
			else if (screenWidth < 650) numberOfTagsToDisplay = 2;
			else if (screenWidth < 750) numberOfTagsToDisplay = 5;
			else if (screenWidth < 900) numberOfTagsToDisplay = 7;
			else numberOfTagsToDisplay = 8;

			setDisplayedTags(tags.slice(0, numberOfTagsToDisplay));
		}

		updateDisplayedTags();
		window.addEventListener('resize', updateDisplayedTags);

		return () => {
			window.removeEventListener('resize', updateDisplayedTags);
		};
	}, [tags]);

	// rendering tags & handle deleting
	const handleDelete = (tagId) => {
		// Remove the tags from the UI to avoid lag, then if something goes wrong, warn user, and repopulate tags
		// No need to re-fetch the tags
		// We just remove it from the UI if the backend operation is successful
		const updatedTags = tags.filter((tag) => tag.TagId !== tagId);
		setTags(updatedTags);

		deleteTagFromHome({ homeId: homeId, tagId: tagId })
			.then((res) => {
				if (res.data.success) return;
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue'
					);
					dispatchUser({
						isLoggedIn: false
					});
					return;
				} else {
					// if tags aren't deleted correctly repopulate tags
					setNotificationState(true);
					setNotificationMessage('Tag was not properly deleted!');
					setTags(tags);
				}
			});
	};

	const renderTags = (tagsToRender) => {
		return tagsToRender.map((tag) => (
			<>
				<Grid key={`tagNohUsername_${tag.TagId}`} item className={classes.chip}>
					{tag.UserId === userState.user.user_id ? (
						<Chip
							label={`${tag.UserName}: ${tag.Tag}`}
							onDelete={() => handleDelete(tag.TagId)}
						/>
					) : (
						<Chip label={`${tag.UserName}: ${tag.Tag}`} />
					)}
				</Grid>
			</>
		));
	};
	const renderTagsWithUsername = (tagsToRender) => {
		// you need render with username seperate otherwise a bug occurs
		// where the text of the chips in the background change
		return tagsToRender.map((tag) => (
			<>
				<Grid key={`tagWithUsername_${tag.TagId}`} item className={classes.chip}>
					{tag.UserId === userState.user.user_id ? (
						<Chip
							label={`${tag.UserName}: ${tag.Tag}`}
							onDelete={() => handleDelete(tag.TagId)}
						/>
					) : (
						<Chip label={`${tag.UserName}: ${tag.Tag}`} />
					)}
				</Grid>
			</>
		));
	};
	const TagsHeading = `Tags(${tags.length})`;

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={notificationState}
				autoHideDuration={6000}
				onClose={() => setNotificationState(false)}
			>
				<Alert
					onClose={() => setNotificationState(false)}
					severity={submitSuccessStatus === 'success' ? 'success' : 'error'}
					variant="filled"
				>
					{notificationMessage}
				</Alert>
			</Snackbar>
			<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
				<TableCell variant="h5" component="th" scope="row">
					<Typography variant="subtitle1" component="h5">
						{TagsHeading}
					</Typography>
				</TableCell>
				<TableCell align="left">
					{renderTags(displayedTags)}
					{tags.length > displayedTags.length && tags.length !== 0 ? (
						<Typography
							variant="subtitle2"
							component="h6"
							className={classes.viewMore}
							onClick={() => setViewMore(true)}
						>
							View More
						</Typography>
					) : tags.length === 0 ? (
						<Typography variant="subtitle1" component="h5">
							-
						</Typography>
					) : null}
				</TableCell>
			</TableRow>
			{viewMore && (
				<ViewMoreModal
					renderComponents={renderTagsWithUsername}
					itemsToRender={tags}
					title={'Tags'}
					viewMore={viewMore} // for controlling opening and closing modal
					setViewMore={setViewMore} // for controlling opening and closing modal
				/>
			)}
		</>
	);
}
