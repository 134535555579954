import React, { useState, useEffect } from 'react';
import { Grid, TextField, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	root: {
		margin: '0',
		padding: '0'
	},
	label: {
		marginBottom: '1rem',
		fontWeight: '700'
	},
	spinner: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%'
	}
}));

export default function AssignTasks({
	setOwner,
	selectAgentOptions,
	defaultAssignAgentStatusVal,
	addDataToFormObject
}) {
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (selectAgentOptions.length === 0) {
			setIsLoading(true);
		} else {
			setIsLoading(false);
		}
	}, [selectAgentOptions]);
	const handleChange = (e, selectedOption) => {
		setOwner(selectedOption?.label || '', selectedOption?.value || '');
		addDataToFormObject('Owner', selectedOption?.value || '');
	};

	return isLoading ? (
		<div className={classes.spinner}>
			<CircularProgress />
		</div>
	) : (
		<Grid className={classes.root}>
			<Autocomplete
				disableClearable
				id="select-agent-field"
				defaultValue={{
					label: defaultAssignAgentStatusVal.name,
					value: defaultAssignAgentStatusVal.assignedTask
				}}
				options={selectAgentOptions}
				getOptionLabel={(option) => option.label}
				sx={{ width: 300 }}
				onChange={handleChange}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Select Agent"
						variant="outlined"
						id={'selectAgent'}
					/>
				)}
			/>
		</Grid>
	);
}
