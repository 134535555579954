import React, { useEffect, useState } from 'react';
//Dynamically load components on request for faster page rendering
import loadable from '@loadable/component';
import Alert from '@material-ui/lab/Alert';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { BaseLayout, AreaOptionsAPI } from '../..';
import { useUserState } from '../../../hook/customerHook';
import {
	saveSelection,
	searchActiveListing,
	searchPriceDropListing,
	searchActiveListingBelowAssessment,
	saveFilterSelection,
	searchActiveDeals,
	searchForeclosureDeals,
	deleteFromCollection,
	checkUserHasPermissionId
} from '../../../api/serverRequests';
import { NotAuthorized } from '../../../components';
import { PERMS } from '../../../helper/perms';
const Snackbar = loadable(() => import('@material-ui/core/Snackbar'));

const Grid = loadable(() => import('@material-ui/core/Grid'));
const Paper = loadable(() => import('@material-ui/core/Paper'));
const CircularProgress = loadable(() => import('@material-ui/core/CircularProgress'));
const ListCards = loadable(() => import('../..'), {
	resolveComponent: (components) => components.ListCards
});
const SearchForeclosure = loadable(() => import('../..'), {
	resolveComponent: (components) => components.SearchForeclosure
});

const Title = loadable(() => import('../..'), {
	resolveComponent: (components) => components.Title
});

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column'
	},
	fixedHeight: {
		height: 120
	},
	fixedSearchField: {
		height: '100%'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	}
}));

export default function SearchForeclosurePage() {
	const classes = useStyles();
	const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
	const fixedSearchPaper = clsx(classes.paper, classes.fixedSearchField);
	const [listings, setListings] = useState();
	const [showSearchResult, setShowSearchResult] = useState(false);
	const [userState, dispatchUser] = useUserState();
	const [refreshSearch, setRefreshSearch] = useState(false);
	const [areaOptionsQuery, setAreaOptionsQuery] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [viewForeclosurePageVisible, setViewForeclosurePageVisible] = useState(false);
	// // submit status updated after user has clicked save in edit modal
	const [submitSuccessStatus, setSubmitSuccessStatus] = useState(''); // keeps track of status when data is posted

	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message

	const titles = [
		{ label: 'Image', numeric: false, id: 'Pics' },
		{ label: 'ML #', numeric: false, id: 'MLNum' },
		{ label: 'Area', numeric: false, id: 'Area' },
		{ label: 'Address', numeric: false, id: 'Address' },
		{ label: 'List Date', numeric: true, id: 'ListDate' },
		{ label: 'Price', numeric: true, id: 'Price' },
		{ label: 'Last Sold Price', numeric: true, id: 'LastSoldPrice' },
		{ label: 'Last Sold Date', numeric: true, id: 'LastSoldDate' },
		{ label: '%', numeric: true, id: 'Percent' }
	];

	// const cells = [
	// 	'MLNum',
	// 	'Area',
	// 	'Address',
	// 	'ListDate',
	// 	'Price',
	// 	'LastSoldPrice',
	// 	'LastSoldDate',
	// 	'Percent'
	// ];

	const mainCells = [
		'Price',
		'assessment2021',
		'PrevPrice',
		'LastSoldPrice',
		'LastSoldDate',
		'Percent',
		'MLNum',
		'HomeId',
		'Area',
		'TotBR',
		'Address',
		'List Date'
	];

	const handleSearch = (result) => {
		// debugger
		setListings(result);
		setShowSearchResult(true);
		localStorage.setItem('foreclosureListings', JSON.stringify(result));
	};

	const extractMlNums = (arr) => {
		const mlNumArr = [];
		// splits the selected array into mlnum
		arr.map((select) => {
			const [mlNum] = select.split('__');
			mlNumArr.push(mlNum);
		});
		return mlNumArr;
	};

	const refreshListings = (filters) => {
		let validfilters = {};

		for (const key of Object.keys(filters)) {
			if (filters[key]) {
				validfilters[key] = filters[key]['value'];
			}
		}

		searchForeclosureDeals({ user: userState.user_id, filters: validfilters })
			.then((res) => {
				if (res.data.success) setListings(res.data.listings);
				// handleSearch(res.data.listings)
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue'
					);
					dispatchUser({
						isLoggedIn: false
					});
					return;
				}
				console.log(err.message);
			});
	};

	const handleSave = (listings) => {
		const data = {
			userId: userState.user.user_id,
			organizationId: userState.user.organization,
			listings: listings
		};
		console.log(JSON.stringify(data));
		saveSelection(data)
			.then((res) => {
				if (res.data.success) refreshListings(userState.activeFilters);
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue'
					);
					dispatchUser({
						isLoggedIn: false
					});
				}
				console.log(err.message);
			});
	};

	const handleSaveFilter = (listings) => {
		const data = {
			userId: userState.user.user_id,
			organizationId: userState.user.organization,
			listings: extractMlNums(listings)
		};
		console.log(JSON.stringify(data));
		saveFilterSelection(data)
			.then((res) => {
				if (res.data.success) {
					setSubmitSuccessStatus('success');
					setNotificationState(true);
					setNotificationMessage('Selected listings have been filtered!');
					refreshListings(userState.activeFilters);
				}
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue'
					);
					dispatchUser({
						isLoggedIn: false
					});
				}
				console.log(err.message);
			});
	};

	const handleDelete = (listings) => {
		debugger;
		deleteFromCollection({ listings: listings })
			.then((res) => {
				console.log(res.data);
				refreshListings(userState.activeFilters);
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue'
					);
					dispatchUser({
						isLoggedIn: false
					});
				}
				console.log(err.message);
			});
	};

	useEffect(() => {
		const storedListings = localStorage.getItem('foreclosureListings');
		if (storedListings) {
			const parsedListings = JSON.parse(storedListings);
			setListings(parsedListings);

			// If parsedListings array is empty, set showSearchResult to true - this is for maintaing search results message
			if (parsedListings.length === 0) {
				setShowSearchResult(true);
			}
		}
		if (localStorage.getItem('areaOptions') == null) {
			AreaOptionsAPI(setAreaOptionsQuery, 'all', 'areaOptions');
		} else {
			setAreaOptionsQuery(JSON.parse(localStorage.getItem('areaOptions')));
		}
	}, []);

	return (
		<BaseLayout>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={notificationState}
				autoHideDuration={6000}
				onClose={() => setNotificationState(false)}
			>
				<Alert
					onClose={() => setNotificationState(false)}
					severity={submitSuccessStatus === 'success' ? 'success' : 'error'}
					variant="filled"
				>
					{notificationMessage}
				</Alert>
			</Snackbar>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper className={fixedSearchPaper}>
						<Title> Search Foreclosure Listings </Title>
						<Paper className={fixedSearchPaper}>
							<SearchForeclosure
								areaOptionsQuery={areaOptionsQuery}
								handleSearch={handleSearch}
								handleRefreshSearch={setRefreshSearch}
							/>
						</Paper>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Paper className={classes.paper}>
						{refreshSearch ? (
							<Grid container className={classes.root}>
								<Grid item xs class>
									<CircularProgress color="inherit" />
								</Grid>
							</Grid>
						) : listings && listings.length > 0 ? (
							<ListCards
								isSearch={true}
								isCollection={!showSearchResult}
								listHeads={titles}
								listCells={mainCells}
								listings={listings}
								handleSave={handleSave}
								handleSaveFilter={handleSaveFilter}
								handleDelete={handleDelete}
								title={showSearchResult ? 'Search Result' : ''}
								initialOrderBy={'Percent'}
							/>
						) : (
							<Title>
								{showSearchResult
									? 'No properties found.'
									: 'Start by clicking the search icon.'}
							</Title>
						)}
					</Paper>
				</Grid>
			</Grid>
		</BaseLayout>
	);
}
