import { useState } from 'react';
import {
	Paper,
	Switch,
	Button,
	Typography,
	Grid,
	Menu,
	MenuItem,
	Tab,
	Tabs,
	Box
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import StarIcon from '@material-ui/icons/Star';
import { createCheckoutSession } from '../../../../api/serverRequests';
import { useStripe } from '@stripe/react-stripe-js';
import { PaymentElement } from '@stripe/react-stripe-js';
import { useUserState } from '../../../../hook/customerHook';

const IOSSwitch = withStyles((theme) => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		margin: theme.spacing(1)
	},
	switchBase: {
		padding: 1,
		'&$checked': {
			transform: 'translateX(16px)',
			color: theme.palette.common.white,
			'& + $track': {
				backgroundColor: '#52d869',
				opacity: 1,
				border: 'none'
			}
		},
		'&$focusVisible $thumb': {
			color: '#52d869',
			border: '6px solid #fff'
		}
	},
	thumb: {
		width: 24,
		height: 24
	},
	track: {
		borderRadius: 26 / 2,
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.grey[50],
		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border'])
	},
	checked: {},
	focusVisible: {}
}))(({ classes, ...props }) => {
	return (
		<Switch
			focusVisibleClassName={classes.focusVisible}
			disableRipple
			classes={{
				root: classes.root,
				switchBase: classes.switchBase,
				thumb: classes.thumb,
				track: classes.track,
				checked: classes.checked
			}}
			{...props}
		/>
	);
});

const useStyles = makeStyles((theme) => ({
	tabs: {
		border: '1px solid black',
		minHeight: '20px',
		borderRadius: '25px',
		'& .MuiButtonBase-root': {
			minWidth: '20%',
			minHeight: '20px',
			transition: 'background-color 0.3s, color 0.3s'
		},
		'& .Mui-selected': {
			color: 'white',
			background: 'black',
			borderRadius: '25px'
		}
	},
	tabPanel: {
		'& h3': {
			margin: theme.spacing(1),
			textAlign: 'center',
			fontSize: '1.5rem'
		}
	},
	bottomButtons: {
		padding: theme.spacing(1, 3),
		textTransform: 'none',
		fontSize: '16px',
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.green['KN_dark_mint'],
		borderRadius: '5px',
		borderWidth: '2px',
		borderColor: `${theme.palette.green['KN_dark_mint']} !important`
	},
	packageDesc: {
		padding: 0,
		'& h6': {
			color: '#939393'
		},
		'& ul': {
			margin: 0
		}
	},
	packageTitles: {
		padding: 0,
		textAlign: 'center',
		color: 'black',
		'& h2': {
			color: 'black',
			fontWeight: '500'
		}
	},
	subscriptionType: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: 0,
		'& p': {
			margin: 0,
			fontWeight: 600
		}
	}
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	};
}

const PricingTab = () => {
	const classes = useStyles();
	const [tabValue, setTabValue] = useState(0);
	const [userState, dispatchUser] = useUserState();
	const stripe = useStripe();

	const handleBuyToken = async (e, amount) => {
		e.preventDefault();
		createCheckoutSession(amount).then(async (res) => {
			if (res.data.success) {
				dispatchUser({
					settingPanel: 7
				});
				const result = await stripe.redirectToCheckout({
					sessionId: res.data.id
				});
			}
		});
	};
	const [monthlySub, setMonthlySub] = useState(true);
	const packValues = [2, 5, 30, 125, 250];
	return (
		<Grid container>
			<Grid item xs={6}>
				<Paper style={{ padding: '20px' }}>
					<div>
						<Box className={classes.packageTitles}>
							<Typography variant="h2">Packs</Typography>
							<h3>Ideals for not active user</h3>
							<h3 style={{ marginBottom: 0 }}>Pay as you go</h3>
							<Typography variant="body1">Credits total</Typography>
						</Box>
						<Tabs
							value={tabValue}
							onChange={(e, newValue) => setTabValue(newValue)}
							className={classes.tabs}
							aria-label="simple tabs example"
							TabIndicatorProps={{
								style: { display: 'none' }
							}}
						>
							<Tab label="2" {...a11yProps(0)} />
							<Tab label="5" {...a11yProps(1)} />
							<Tab label="30" {...a11yProps(2)} />
							<Tab label="125" {...a11yProps(3)} />
							<Tab label="250" {...a11yProps(4)} />
						</Tabs>
						{packValues.map((val, idx) => (
							<TabPanel value={tabValue} index={idx} className={classes.tabPanel}>
								<h3>CA${25 * val}</h3>
								<Button
									className={classes.bottomButtons}
									variant="outlined"
									onClick={(e) => handleBuyToken(e, 25 * val * 100)}
								>
									Buy Now
								</Button>
								<Box className={classes.packageDesc}>
									<Typography variant="subtitle1">Includes:</Typography>
									<Typography variant="subtitle1">
										<CheckBoxIcon /> Doorknock which includes:
										<ul>
											<li>Images</li>
											<li>Status of Building</li>
											<li>Conversation with owner</li>
										</ul>
									</Typography>
									<Typography variant="subtitle1">
										<CheckBoxIcon /> Letters which includes:
										<ul>
											<li>3 follow ups</li>
										</ul>
									</Typography>
									<Typography variant="subtitle1">
										<CheckBoxIcon /> Access to listings and details
									</Typography>
								</Box>
							</TabPanel>
						))}
					</div>
				</Paper>
			</Grid>
			<Grid item xs={6}>
				<Paper style={{ padding: '20px' }}>
					<div>
						<Box className={classes.packageTitles}>
							<Typography variant="h2">Subscription</Typography>
							<h3>For power users and growing teams.</h3>
							<h3 style={{ marginBottom: 0 }}>
								<StarIcon /> Best Value
							</h3>
							<Typography variant="body1">Credits total</Typography>
						</Box>
						<Box className={classes.subscriptionType}>
							<p style={{ color: monthlySub ? 'black' : '#ababab' }}>Monthly</p>
							<IOSSwitch
								checked={monthlySub}
								onChange={() => setMonthlySub(!monthlySub)}
								name="monthlySub"
							/>
							<p style={{ color: monthlySub ? '#ababab' : 'black' }}>Yearly</p>
						</Box>
						{monthlySub ? (
							<Box className={classes.tabPanel}>
								<h3>CA$125</h3>
								<Button
									className={classes.bottomButtons}
									variant="outlined"
									disabled
								>
									Buy Now (coming soon)
								</Button>
								<Box className={classes.packageDesc}>
									<Typography variant="subtitle1">
										Everything in basic, plus:
									</Typography>
									<Typography variant="subtitle1">
										<CheckBoxIcon /> Free 50 Doorknock every month
										<ul>
											<li>Images</li>
											<li>Status of Building</li>
											<li>Conversation with owner</li>
										</ul>
									</Typography>
									<Typography variant="subtitle1">
										<CheckBoxIcon /> Free 50 letters every month
										<ul>
											<li>Every letter includes 3 follow ups</li>
										</ul>
									</Typography>
									<Typography variant="subtitle1">
										<CheckBoxIcon /> Access to listings and details
									</Typography>
								</Box>
							</Box>
						) : (
							<>
								<h3>CA$1250</h3>
								<Button className={classes.bottomButtons} variant="outlined">
									Buy Now
								</Button>
								<Box className={classes.packageDesc}>
									<Typography variant="subtitle1">Includes:</Typography>
									<Typography variant="subtitle1">
										<CheckBoxIcon /> Doorknock which includes:
										<ul>
											<li>Images</li>
											<li>Status of Building</li>
											<li>Conversation with owner</li>
										</ul>
									</Typography>
									<Typography variant="subtitle1">
										<CheckBoxIcon /> Letters which includes:
										<ul>
											<li>3 follow ups</li>
										</ul>
									</Typography>
									<Typography variant="subtitle1">
										<CheckBoxIcon /> Access to listings and details
									</Typography>
								</Box>
							</>
						)}
					</div>
				</Paper>
			</Grid>
		</Grid>
	);
};

export default PricingTab;
