import React, { useState, useEffect } from 'react';
import MuiTableCell from '@material-ui/core/TableCell';
import { Divider, TableRow, Typography, Box, TextField, Snackbar } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Alert from '@material-ui/lab/Alert';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import ViewMoreModal from '../ViewMoreModal';

import { useUserState } from '../../../../../hook/customerHook';
import { deleteComment, updateComment } from '../../../../../api/serverRequests';
import { displayHumanReadableDate, displayHumanReadableClock } from '../../../../../helper/parser';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
	root: {
		//
	},
	commentsTitle: {
		marginTop: 10,
		display: 'flex',
		'@media (max-width: 550px)': {
			marginTop: 0
		}
	},
	commentUserName: {
		marginTop: 10,
		display: 'inline-block',
		paddingRight: 10,
		color: theme.palette.primary.main
	},
	commentTime: {
		display: 'inline-block'
	},
	commentRemark: {
		paddingBottom: 5
	},
	userEditActions: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	editingTextField: {
		width: '65%'
	},
	viewMore: {
		textDecoration: 'underline',
		color: theme.palette.primary.main,
		'&:hover': {
			cursor: 'pointer'
		}
	}
}));
const TableCell = withStyles({
	root: {
		borderBottom: 'none',
		padding: '0.5rem 0'
	}
})(MuiTableCell);

export default function RenderComments({ comments, setComments }) {
	const classes = useStyles();
	const [, dispatchUser] = useUserState();

	// for comments to be displayed
	// const displayedComments = comments.slice(0, 3);

	// for tags to display
	const [displayedComments, setDisplayedComments] = useState([]);

	// for editing comments and storing updated text
	const [editingCommentId, setEditingCommentId] = useState(null);
	const [newText, setNewText] = useState('');

	// for opening/closing view more modal
	const [viewMore, setViewMore] = useState(false);

	// for viewing exact time
	const [showmore, setShowmore] = useState(false);

	// // submit status updated after user has clicked save in edit modal
	const [submitSuccessStatus, setSubmitSuccessStatus] = useState(''); // keeps track of status when data is posted

	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message

	// useEffect to determine how many comments to display based on a given the users viewWidth
	useEffect(() => {
		function updateDisplayedComments() {
			const screenWidth = window.innerWidth;
			let numberOfCommentsToDisplay;

			if (screenWidth < 550) numberOfCommentsToDisplay = 0;
			else if (screenWidth < 750) numberOfCommentsToDisplay = 2;
			else numberOfCommentsToDisplay = 3;

			setDisplayedComments(comments.slice(0, numberOfCommentsToDisplay));
		}

		updateDisplayedComments();
		window.addEventListener('resize', updateDisplayedComments);

		return () => {
			window.removeEventListener('resize', updateDisplayedComments);
		};
	}, [comments]);

	const handleDelete = (commentId) => {
		// Remove the comments from the UI to avoid lag, then if something goes wrong, warn user, and repopulate comments
		// No need to re-fetch the comments
		// We just remove it from the UI if the backend operation is successful

		const updatedComments = comments.filter((comment) => comment.Id !== commentId);

		setComments(updatedComments);

		deleteComment(commentId)
			.then((res) => {
				if (res.data.success) return;
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue'
					);
					dispatchUser({
						isLoggedIn: false
					});
					return;
				} else {
					// if comments aren't deleted correctly repopulate tags
					setNotificationState(true);
					setNotificationMessage('Comments was not properly deleted!');
					setComments(displayedComments);
				}
			});
	};

	const handleUpdate = (commentId, newText) => {
		// check if text field is empty or unedited
		if (newText === '') return;

		// Update the comment from the UI to avoid lag, then if something goes wrong, warn user, and undo changes
		// No need to refresh/re-fetch the comments
		// We just remove it from the UI if the backend operation is successful
		const updatedComments = comments.map((comment) => {
			if (comment.Id === commentId) return { ...comment, Comment: newText };
			else return comment;
		});
		setComments(updatedComments);

		updateComment(commentId, { comment: newText })
			.then((res) => {
				if (res.data.success) return;
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue'
					);
					dispatchUser({
						isLoggedIn: false
					});
					return;
				} else {
					// if comment cannot be updated, repopulate comment text
					setNotificationState(true);
					setNotificationMessage('Comments wan not updated properly!');
					//setComments(comments);
					setComments(displayedComments);
				}
			});
	};

	// rendering the comments here
	const renderDisplayedComments = (commentsToRender) => {
		return commentsToRender.map((comment) => (
			<Box key={`comment_${comment.Id}`}>
				<Typography variant="subtitle1" component="h5" className={classes.commentUserName}>
					{comment.UserName}
				</Typography>
				<Typography
					variant="subtitle2"
					component="h6"
					className={classes.commentTime}
					onClick={() => setShowmore(!showmore)}
				>
					{displayHumanReadableDate(comment.CreatedAt) + ' '}
					{showmore &&
						`(${
							comment.CreatedAt.split(' ')[0] +
							' ' +
							displayHumanReadableClock(comment.CreatedAt)
						})`}
				</Typography>
				{editingCommentId !== comment.Id ? (
					<Box className={classes.userEditActions}>
						<Typography
							variant="subtitle1"
							component="h5"
							className={classes.commentRemark}
						>
							{comment.Comment.split('\n').map((line, index) => (
								<React.Fragment key={index}>
									{line}
									<br />
								</React.Fragment>
							))}
						</Typography>
						{comment.Editable && (
							<Box>
								<IconButton aria-label="edit comment" size="medium">
									<EditIcon
										fontSize="inherit"
										onClick={() => setEditingCommentId(comment.Id)}
									/>
								</IconButton>
								<IconButton
									aria-label="delete comment"
									size="medium"
									onClick={() => handleDelete(comment.Id)}
								>
									<DeleteIcon fontSize="inherit" />
								</IconButton>
							</Box>
						)}
					</Box>
				) : (
					<Box className={classes.userEditActions}>
						<TextField
							InputProps={{
								style: {
									color: '#545454',
									fontSize: 'clamp(0.9rem, 0.8vw + 0.3rem, 1.1rem)'
								}
							}}
							id={`edit-comment-field-${comment.Id}`}
							label="Edit comment"
							variant="standard"
							defaultValue={comment.Comment}
							multiline
							onChange={(e) => setNewText(e.target.value)}
							className={classes.editingTextField}
						/>
						<Box>
							<IconButton
								aria-label="save edit"
								size="medium"
								onClick={() => {
									setEditingCommentId(null);
									handleUpdate(comment.Id, newText);
								}}
							>
								<CheckCircleIcon fontSize="inherit" />
							</IconButton>
							<IconButton
								aria-label="cancel editing"
								size="medium"
								onClick={() => setEditingCommentId(null)}
							>
								<CancelIcon fontSize="inherit" />
							</IconButton>
						</Box>
					</Box>
				)}
				<Divider />
			</Box>
		));
	};

	const commentHeader = `Comments(${comments.length})`;

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={notificationState}
				autoHideDuration={6000}
				onClose={() => setNotificationState(false)}
			>
				<Alert
					onClose={() => setNotificationState(false)}
					severity={submitSuccessStatus === 'success' ? 'success' : 'error'}
					variant="filled"
				>
					{notificationMessage}
				</Alert>
			</Snackbar>
			<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
				<TableCell
					variant="h5"
					component="th"
					scope="row"
					className={classes.commentsTitle}
				>
					<Typography variant="subtitle1" component="h5">
						{commentHeader}
					</Typography>
				</TableCell>
				<TableCell align="left">
					{/* view more button for smaller view widths */}
					{displayedComments.length === 0 && comments.length !== 0 ? (
						<Typography
							variant="subtitle2"
							component="h6"
							className={classes.viewMore}
							onClick={() => setViewMore(true)}
						>
							View More
						</Typography>
					) : comments.length === 0 ? (
						<Typography variant="subtitle1" component="h5">
							-
						</Typography>
					) : (
						renderDisplayedComments(displayedComments)
					)}
				</TableCell>
			</TableRow>
			<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
				{/* view more button for larger view widths (we need to have two seperate for styling purposes)*/}
				{comments.length > displayedComments.length &&
					!viewMore &&
					displayedComments.length !== 0 && (
						<>
							<TableCell align="right" colSpan={3}>
								<Typography
									variant="subtitle2"
									component="h6"
									className={classes.viewMore}
									onClick={() => setViewMore(true)}
								>
									View More
								</Typography>
							</TableCell>
						</>
					)}
			</TableRow>
			{viewMore && (
				<ViewMoreModal
					renderComponents={renderDisplayedComments}
					itemsToRender={comments}
					title={'Comments'}
					viewMore={viewMore} // for controlling opening and closing modal
					setViewMore={setViewMore} // for controlling opening and closing modal
				/>
			)}
		</>
	);
}
