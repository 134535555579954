import { memo, useState, useEffect } from 'react';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import { useUserState } from '../../../hook/customerHook';

function CusSelectField({ id, statusOptions, getStatus, updateOnChange }) {
	const [statusValue, setStatusValue] = useState('');
	const [, dispatchUser] = useUserState();

	useEffect(() => {
		const fetchStatus = async () => {
			const response = await getStatus();
			try {
				if (response.status === 200) {
					const optionObj = statusOptions.find(
						(option) => option.label === response.data.status?.Status
					);
					if (optionObj) {
						setStatusValue(optionObj.value);
					}
				}
			} catch (err) {
				if (response.status === 401) {
					return dispatchUser({ isLoggedIn: false });
				}
				console.log(err.message);
			}
		};
		if (statusOptions) {
			fetchStatus();
		}
	}, [statusOptions]);

	const changeHandler = ({ target }) => {
		setStatusValue(target.value);
		updateOnChange(target.value);
	};
	return (
		<FormControl fullWidth variant="outlined">
			<Select id={id} value={statusValue} onChange={changeHandler}>
				{statusOptions?.map((option) => (
					<MenuItem key={option.value} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}

export default memo(CusSelectField);
