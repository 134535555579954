import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import Title from '../Title';
import Tag from '../Tag';
import { useUserState } from '../../../hook/customerHook';
import { getTagsPopularityByOrganization } from '../../../api/serverRequests';
import { Paper, Grid, GridList, GridListTile } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'flex-start',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(1)
		},
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: '#fff'
		},
		maxHeight: '7.5rem',
		overflowY: 'scroll',
		padding: theme.spacing(2, 0.5, 1)
	},
	horizontalTag: { width: '100%', overflowX: 'auto' }
}));

export default function TagSummary({ handleTagClick, handleLoading, tagId }) {
	const [tags, setTags] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedId, setSelectedId] = useState(null);
	const [selectedUserId, setSelectedUserId] = useState(null);
	const [, dispatchUser] = useUserState();

	const classes = useStyles();

	useEffect(() => {
		let unmounted = false;
		fetchData(unmounted);
		return () => {
			unmounted = true;
		};
	}, []);

	const fetchData = async (unmounted) => {
		getTagsPopularityByOrganization()
			.then((res) => {
				if (res.status === 200) {
					if (res.data.tags.length > 0) {
						setTags(res.data.tags);
						console.log(tags);
						setSelectedId(res.data.tags[0].tagId);
						setSelectedUserId(res.data.tags[0].userId);
						handleTagClick(res.data.tags[0].tagId, res.data.tags[0].userId);
					} else {
						setTags(null);
						setSelectedId(null);
						setSelectedUserId(null);
						handleTagClick(null, null);
					}
				}
				if (!unmounted) {
					setIsLoading(false);
					handleLoading(false);
				}
			})
			.catch((err) => {
				if (err.response.status === 401) {
					dispatchUser({
						isLoggedIn: false
					});
				} else if (!unmounted) {
					setIsLoading(false);
					handleLoading(false);
				}
				console.log(err.message);
			});
	};

	return (
		<React.Fragment>
			{isLoading ? (
				<Backdrop className={classes.backdrop} open={true}>
					<CircularProgress size={50} color="inherit" />
				</Backdrop>
			) : (
				<Grid container wrap="nowrap" spacing={3} className={classes.horizontalTag}>
					{tags &&
						tags.map((tag) => (
							<Grid item>
								<Tag
									key={tag.tag}
									tag={tag.tag}
									count={tag.popularity}
									handleClick={() => {
										handleTagClick(tag.tagId, tag.userId);
										setSelectedId(tag.tagId);
										setSelectedUserId(tag.userId);
									}}
									isSelected={
										tag.tagId === selectedId && tag.userId === selectedUserId
									}
								/>
							</Grid>
						))}
				</Grid>
			)}
		</React.Fragment>
	);
}
