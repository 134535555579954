import { Box, Grid, IconButton, makeStyles, Typography, Checkbox, Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import EmailIcon from '@material-ui/icons/Email';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import { grey } from '@material-ui/core/colors';
import {
	changeUserNotificationPreference,
	turnOffAllNotifications
} from '../../../../../api/serverRequests';
import { useUserState } from '../../../../../hook/customerHook';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		top: 0,
		right: 0,
		width: 340,
		height: '50vh',
		backgroundColor: theme.palette.background.paper,
		border: '1px solid #ABABAB',
		borderRadius: 10,
		padding: 15,
		paddingLeft: 25,
		zIndex: 100
	},
	settingsHeaderContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end'
	},
	title: {
		opacity: 0.6,
		marginTop: 25,
		marginBottom: 10
	},
	allOptionsContainer: {
		marginLeft: 15
	},
	optionContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	optionTitleContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	optionTitle: {
		color: '#000',
		fontWeight: 700,
		marginLeft: 7
	},
	turnOffNotificationsContainer: {
		display: 'flex',
		alignItems: 'center',
		marginTop: 40,
		marginBottom: 10
	},
	offNotificationsText: {
		color: '#000',
		fontWeight: 700,
		marginLeft: 7,
		opacity: 0.5,
		fontSize: 16
	}
}));
export default function NotificationSettings({
	setIsNotificationSettingsOpen,
	isNotificationSettingsOpen,
	userNotificationPreferences
}) {
	const classes = useStyles();
	const [dispatchUser] = useUserState();
	const defaultNotificationPreferences = {
		ReceiveEmail: userNotificationPreferences.ReceiveEmail,
		ReceivePhone: userNotificationPreferences.ReceivePhone,
		ReceivePush: userNotificationPreferences.ReceivePush
	};

	const [notificationPreferences, setNotificationPreferences] = useState(
		defaultNotificationPreferences
	);

	const changeNotificationPref = (notificationType, value) => {
		const formData = {
			notificationType: notificationType, // i.e the column in the notification table ex. ReceivePhone, ReceiveEmail, or ReceivePush
			value: value ? 1 : 0 // the value is either 0 or 1 (false or true)
		};

		changeUserNotificationPreference(formData)
			.then((res) => {
				if (res.status === 200) {
					// do nothing
				}
			})
			.catch((err) => {
				if (err.response.status === 401) {
					dispatchUser({
						isLoggedIn: false
					});
					return;
				} else {
				}
				console.error(err.message);
			});
	};

	const turnOffNotifications = () => {
		setNotificationPreferences((prevPreferences) => ({
			...prevPreferences,
			ReceiveEmail: false,
			ReceivePhone: false,
			ReceivePush: false
		}));

		turnOffAllNotifications()
			.then((res) => {
				if (res.status === 200) {
					// do nothing
				}
			})
			.catch((err) => {
				if (err.response.status === 401) {
					dispatchUser({
						isLoggedIn: false
					});
					return;
				} else {
				}
				console.error(err.message);
			});
	};

	const handleCheckboxChange = (event) => {
		const { name, checked } = event.target;
		setNotificationPreferences((prevPreferences) => ({
			...prevPreferences,
			[name]: checked
		}));
		changeNotificationPref(name, checked);
	};

	return (
		<>
			{isNotificationSettingsOpen && (
				<Box className={classes.root}>
					<Box className={classes.settingsHeaderContainer}>
						<IconButton
							aria-label="delete"
							size="small"
							onClick={() =>
								setIsNotificationSettingsOpen(!isNotificationSettingsOpen)
							}
						>
							<CloseIcon fontSize="medium" />
						</IconButton>
					</Box>
					<Typography component="p" variant="h5" className={classes.title}>
						How You Get Notifications
					</Typography>
					<Box className={classes.allOptionsContainer}>
						<Grid container spacing={0} className={classes.optionContainer}>
							<Grid item xs={7}>
								<Box className={classes.optionTitleContainer}>
									<WebAssetIcon size="small" style={{ color: grey[900] }} />
									<Typography
										component="p"
										variant="h5"
										className={classes.optionTitle}
									>
										Browser
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={5}>
								<Checkbox
									color="primary"
									checked={notificationPreferences.ReceivePush}
									name="ReceivePush"
									onChange={handleCheckboxChange}
								/>
							</Grid>
							<Grid item xs={7}>
								<Box className={classes.optionTitleContainer}>
									<EmailIcon size="small" style={{ color: grey[900] }} />
									<Typography
										component="p"
										variant="h5"
										className={classes.optionTitle}
									>
										Email
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={5}>
								<Checkbox
									color="primary"
									checked={notificationPreferences.ReceiveEmail}
									name="ReceiveEmail"
									onChange={handleCheckboxChange}
								/>
							</Grid>
						</Grid>
					</Box>

					<Box className={classes.turnOffNotificationsContainer}>
						<Button style={{ textTransform: 'none' }} onClick={turnOffNotifications}>
							<NotificationsOffIcon size="small" style={{ color: grey[900] }} />
							<Typography
								component="p"
								variant="h5"
								className={classes.offNotificationsText}
							>
								Turn off All Notifications
							</Typography>
						</Button>
					</Box>
				</Box>
			)}
		</>
	);
}
