import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	Grid,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
	CircularProgress
} from '@material-ui/core/';

import KnockingStatus from './FormFields/KnockingStatus';
import DatePicker from './FormFields/DatePicker';
import TagForm from './FormFields/TagForm';
import CommentForm from './FormFields/CommentForm';
import OwnerInfo from './FormFields/OwnerInfo';

import { useUserState } from '../../../../../hook/customerHook';
import { submitPropertyCustomData } from '../../../../../api/serverRequests';
import AssignTasks from './FormFields/AssignTasks';
import { currentTimeInUnix } from '../../../../../helper/parser';

const useStyles = makeStyles((theme) => ({
	form: {
		display: 'flex',
		flexDirection: 'column',
		margin: 10
	},
	formControl: {
		marginTop: theme.spacing(2),
		minWidth: 120
	},
	formControlLabel: {
		marginTop: theme.spacing(1)
	},
	formSection: {
		marginTop: 20
	},
	heading: {
		margin: '0 0 1rem'
	},
	subHeading: {
		margin: '0.1rem 0 1rem'
	},
	decreaseMarginBottom: {
		marginBottom: 0
	},
	spacingAbove: {
		marginTop: 13,
		marginBottom: 15
	},
	postDataSpinnerContainer: {
		width: '100%',
		height: '100%',
		position: 'absolute',
		zIndex: 10,
		backdropFilter: 'blur(2px)'
	},
	postDataSpinner: {
		position: 'absolute',
		top: '50%',
		left: '45%'
	}
}));

export default function EditPopUp({
	listing,
	handlePopUpClick,
	editPopUp,
	primaryInfo,
	assignedInfo,
	shouldRefetch,
	setShouldRefetch,
	submitSuccessStatus,
	setSubmitSuccessStatus,
	setNotificationState,
	setNotificationMessage,
	knockingStatusOptions,
	tags,
	tagsOptions,
	selectAgentOptions,
	knockingStatusVisible,
	addCommentsVisible,
	tagsVisible,
	editAssignedTaskVisible,
	owner,
	setOwner,
	resetPermissions,
	assignMyself,
	viewOwnerName,
	viewOwnerPhone
}) {
	const classes = useStyles();
	const [userState, dispatchUser] = useUserState();

	const [formSubmitObject, setFormSubmitObject] = useState({});

	const [warningOwnerName, setWarningOwnerName] = useState(false);
	const [warningOwnerPhoneNumber, setWarningOwnerPhoneNumber] = useState(false);

	const [warningMessageOwnerInfo, setWarningMessageOwnerInfo] = useState('');

	const [viewCustomTags, setViewCustomTags] = useState(false);

	const addDataToFormObject = (key, value) => {
		// every time user clicks save we save all changed data in the formSubmitObject state
		setFormSubmitObject((prevObject) => {
			return { ...prevObject, [key]: value };
		});
	};

	useEffect(() => {
		// add necessary info for post request on render
		addDataToFormObject('mlNum', listing.MLNum);
		addDataToFormObject('homeId', listing.HomeId);
		addDataToFormObject('homeAddress', listing.Address);
		addDataToFormObject('city', listing.City);
	}, [listing]);

	useEffect(() => {
		// set the state for the custom view grid when either permissions are true
		if (tagsVisible || addCommentsVisible) setViewCustomTags(true);
	}, [addCommentsVisible, tagsVisible]);

	const clearKeys = (obj) => {
		// this function clears the form field if the user closes or saves changes
		// the desired keys are kept, so that the edit modal still works
		const desiredKeysToKeep = ['mlNum', 'homeId', 'homeAddress', 'city'];

		return Object.fromEntries(
			Object.entries(obj).filter(([key, value]) => desiredKeysToKeep.includes(key))
		);
	};

	const handleSaveSubmit = () => {
		const expectedKeys = ['mlNum', 'homeId', 'homeAddress', 'city']; // array of keys in the formdata field by default

		// removes the TagsToRemove key if there are no tags to remove
		// doing this stops the EditPopUp from submitting if the user click's save but has made no changes
		if ('TagsToRemove' in formSubmitObject && formSubmitObject.TagsToRemove.length === 0) {
			delete formSubmitObject.TagsToRemove;
		}

		// check if no new keys are added (i.e. user has not made any changed to the form field)
		if (Object.keys(formSubmitObject).every((key) => expectedKeys.includes(key))) {
			handlePopUpClick(); //close modal
			return;
		}

		if ('OwnerPhoneNumber' in formSubmitObject) {
			const isValidPhoneNumber = validatePhoneNumber(formSubmitObject.OwnerPhoneNumber);
			if (!isValidPhoneNumber) return;
		}
		if ('OwnerName' in formSubmitObject) {
			const isValidName = validateName(formSubmitObject.OwnerName);
			if (!isValidName) return;
		}

		// if someone is assigned to the property or has just been assigned we must post more data to store in the notifications table
		if (owner || assignedInfo['name']) {
			formSubmitObject['assignedUser'] = owner || assignedInfo['name']; // attach the current assigned user to the submit object no matter what  <-- specifically for the notification system
			formSubmitObject['timeOfEdit'] = currentTimeInUnix; // attach the time the changes were made <-- specifically for the notification system
			formSubmitObject['editor'] = userState.user.name; // attach the name of the person who made the changes to the property info <-- specifically for the notification system
		}

		setSubmitSuccessStatus('waiting'); // status is set to waiting (to show loading icon)
		submitPropertyCustomData(formSubmitObject)
			.then((res) => {
				if (res.data.success) {
					setSubmitSuccessStatus('success');
					setShouldRefetch(!shouldRefetch); // just changing the value so that the useEffect can rerun and fetch newly updated data
					resetPermissions();
					setNotificationState(true); // display notification
					setNotificationMessage('Your changes have been updated!');
					handlePopUpClick(); //close modal
				}
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					dispatchUser({
						isLoggedIn: false
					});
					return;
				}
				setFormSubmitObject(clearKeys(formSubmitObject));
				setNotificationState(true); // display notification
				setNotificationMessage('Something went wrong, could not update changes.');
				handlePopUpClick(); // close modal
			});
	};

	const validatePhoneNumber = (value) => {
		if (value === '') return true; // if the user deletes the phone number

		const phoneNumber = value.replace(/\D/g, ''); // remove all non-numeric characters from the input
		const isPhoneNumberValid = /^\d{10}$|^\d{3}[- ]\d{3}[- ]\d{4}$/.test(phoneNumber); // check if the phone number matches any of the valid formats

		let formattedPhoneNumber = phoneNumber;

		if (phoneNumber.length === 10) {
			formattedPhoneNumber = phoneNumber.replace(/(\d{3})\s?(\d{3})\s?(\d{4})/, '$1-$2-$3');
		}

		setWarningOwnerPhoneNumber(!isPhoneNumberValid);
		setWarningMessageOwnerInfo('Incorrect phone number format!');
		formSubmitObject.OwnerPhoneNumber = formattedPhoneNumber;

		return isPhoneNumberValid;
	};

	const validateName = (fullName) => {
		if (fullName === '') return true; // if the user deletes the phone number

		const names = fullName.trim().split(' ');

		if (names.length !== 2) {
			setWarningOwnerName(true);
			setWarningMessageOwnerInfo('Owner Name must contain a first and last name!');
			return false; // both first and last names should be present
		}

		const [firstName, lastName] = names;
		if (!firstName || !lastName) {
			setWarningOwnerName(true);
			setWarningMessageOwnerInfo('Owner Name must contain a first and last name!');
			return false; // first or last name is missing
		}

		return true;
	};

	// to visualize object in console
	// console.log('formSubmitObject ' + JSON.stringify(formSubmitObject));

	return (
		<Dialog
			fullWidth={true}
			maxWidth={'xs'}
			open={editPopUp}
			onClose={() => {
				setFormSubmitObject(clearKeys(formSubmitObject));
				handlePopUpClick();
				setWarningOwnerPhoneNumber(false);
				setWarningOwnerName(false);
			}}
			aria-labelledby="max-width-dialog-title"
		>
			{submitSuccessStatus === 'waiting' && (
				<div className={classes.postDataSpinnerContainer}>
					<CircularProgress className={classes.postDataSpinner} />
				</div>
			)}
			<DialogContent>
				<form className={classes.form} noValidate>
					<Typography variant="h4" component="h4" className={classes.heading}>
						Edit Custom Info
					</Typography>
					{knockingStatusVisible && (
						<Grid className={classes.formSection}>
							<Typography variant="h5" component="h5" className={classes.subHeading}>
								Knocking Details
							</Typography>
							<Grid container spacing={2}>
								<KnockingStatus
									addDataToFormObject={addDataToFormObject}
									knockingStatusOptions={knockingStatusOptions}
									defaultKnockingStatusVal={primaryInfo.KnockingStatus}
								/>

								<DatePicker
									mlNum={listing.MLNum}
									address={listing.Address}
									addDataToFormObject={addDataToFormObject}
									defaultFollowUpScheduleVal={primaryInfo.FollowUpSchedule}
								/>
							</Grid>
						</Grid>
					)}
					{(editAssignedTaskVisible || assignMyself) && (
						<Grid className={classes.formSection}>
							<Typography variant="h5" component="h5" className={classes.subHeading}>
								Assign Task
							</Typography>
							<Grid item>
								<AssignTasks
									setOwner={setOwner}
									selectAgentOptions={selectAgentOptions}
									defaultAssignAgentStatusVal={assignedInfo}
									addDataToFormObject={addDataToFormObject}
								></AssignTasks>
							</Grid>
						</Grid>
					)}

					{viewCustomTags && (
						<Grid className={classes.formSection}>
							<Typography variant="h5" component="h5" className={classes.subHeading}>
								Custom Tags
							</Typography>

							{tagsVisible && (
								<Grid item>
									<TagForm
										formSubmitObject={formSubmitObject}
										addDataToFormObject={addDataToFormObject}
										tags={tags} // these are tags that are already assigned on the property
										tagsOptions={tagsOptions}
									/>
								</Grid>
							)}
							{addCommentsVisible && (
								<Grid item className={classes.spacingAbove}>
									<CommentForm addDataToFormObject={addDataToFormObject} />
								</Grid>
							)}
						</Grid>
					)}
					{(viewOwnerName || viewOwnerPhone) && (
						<Grid className={classes.formSection}>
							<Typography
								variant="h5"
								component="h5"
								mb={0}
								className={`${classes.subHeading} ${classes.decreaseMarginBottom}`}
							>
								Owner Info
							</Typography>
							<OwnerInfo
								addDataToFormObject={addDataToFormObject}
								defaultOwnerNameVal={primaryInfo.OwnerName}
								defaultOwnerPhoneNumberVal={primaryInfo.OwnerPhoneNumber}
								warningOwnerName={warningOwnerName}
								warningOwnerPhoneNumber={warningOwnerPhoneNumber}
								warningMessageOwnerInfo={warningMessageOwnerInfo}
								viewOwnerPhone={viewOwnerPhone}
								viewOwnerName={viewOwnerName}
							/>
						</Grid>
					)}
				</form>
				<DialogActions>
					<Button
						onClick={() => {
							setFormSubmitObject(clearKeys(formSubmitObject));
							handlePopUpClick();
							setWarningOwnerPhoneNumber(false);
							setWarningOwnerName(false);
						}}
						color="primary"
						size="large"
					>
						CANCEL
					</Button>
					<Button onClick={handleSaveSubmit} color="primary" size="large">
						SAVE
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
}
