import React, { useEffect } from 'react';
import NotificationAlert from './NotificationAlert';
import { requestForToken } from '../../../Firebase/firebase';
import { useUserState } from '../../../hook/customerHook';
import { storeClientFCMToken } from '../../../api/serverRequests';

export default function NotificationWrapper({ children }) {
	const [userState, dispatchUser] = useUserState();

	useEffect(() => {
		const storeToken = async () => {
			const FCMToken = await requestForToken();

			storeClientFCMToken(FCMToken)
				.then((res) => {
					if (res.status === 200) {
						// do nothing
					}
				})
				.catch((err) => {
					if (err.response.status === 401) {
						dispatchUser({
							isLoggedIn: false
						});
						return;
					}
					console.error(err.message);
				});
		};

		if (userState.isLoggedIn) {
			storeToken();
		}
	}, [userState.isLoggedIn, dispatchUser]);

	return (
		<>
			<NotificationAlert />
			{children}
		</>
	);
}
