import { useState } from 'react';
import axios from 'axios';
//Dynamically load components on request for faster page rendering
import loadable from '@loadable/component';

// mui //
import Alert from '@material-ui/lab/Alert';

import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useUserState } from '../../../../hook/customerHook';
import { saveClusterSelection } from '../../../../api/serverRequests';
import { useEffect } from 'react';
import { cusTheme } from '../../Lists/cusTheme';
import { currencyParser, numberParser } from '../../../../helper/parser';

const Typography = loadable(() => import('@material-ui/core/Typography'));
const Box = loadable(() => import('@material-ui/core/Box'));
const Collapse = loadable(() => import('@material-ui/core/Collapse'));
const IconButton = loadable(() => import('@material-ui/core/IconButton'));
const Table = loadable(() => import('@material-ui/core/Table'));
const TableBody = loadable(() => import('@material-ui/core/TableBody'));
const TableCell = loadable(() => import('@material-ui/core/TableCell'));
const TableContainer = loadable(() => import('@material-ui/core/TableContainer'));
const TableSortLabel = loadable(() => import('@material-ui/core/TableSortLabel'));
const TableHead = loadable(() => import('@material-ui/core/TableHead'));
const TableRow = loadable(() => import('@material-ui/core/TableRow'));
const Paper = loadable(() => import('@material-ui/core/Paper'));
const KeyboardArrowDownIcon = loadable(() => import('@material-ui/icons/KeyboardArrowDown'));
const KeyboardArrowUpIcon = loadable(() => import('@material-ui/icons/KeyboardArrowUp'));
const TablePagination = loadable(() => import('@material-ui/core/TablePagination'));
const Checkbox = loadable(() => import('@material-ui/core/Checkbox'));
const Button = loadable(() => import('@material-ui/core/Button'));
const Snackbar = loadable(() => import('@material-ui/core/Snackbar'));

const useRowStyles = makeStyles({
	root: {
		'& > *': {
			borderBottom: 'unset'
		}
	},
	collectionInUser: {
		backgroundColor: cusTheme.palette.primary.light
	},
	collectionInOrganization: {
		backgroundColor: cusTheme.palette.secondary.light
	},
	assignBtn: {
		fontWeight: 'bold',
		color: 'white',
		backgroundColor: '#808080',
		marginTop: '15px'
	},
	checkBoxClm: {
		width: '95px',
		display: 'flex'
	},

	link: {
		display: 'none',
		'@media (max-width: 780px)': {
			display: 'block'
		}
	},

	desktopLink: {
		display: 'none',
		'@media (min-width: 780px)': {
			display: 'block'
		}
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1
	}
});

function ClusterAssignButton({ clusterCount, selectedClusters, row }) {
	const classes = useRowStyles();
	const [userState, dispatchUser] = useUserState();
	// // submit status updated after user has clicked save in edit modal
	const [submitSuccessStatus, setSubmitSuccessStatus] = useState(''); // keeps track of status when data is posted

	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message

	const handleClusterAssign = (e) => {
		let listings = [];
		e.preventDefault();
		if (selectedClusters.length <= 0) {
			setSubmitSuccessStatus('success');
			setNotificationState(true);
			setNotificationMessage('Please select a cluster first.');
		}
		for (let i = 0; i < selectedClusters.length; i++) {
			row[i].clusters.forEach((item) => {
				listings.push(item.MLNum);
			});
		}
		const data = {
			userId: userState.user.user_id,
			organizationId: userState.user.organization,
			listings: listings
		};

		saveClusterSelection(data)
			.then((res) => {
				if (res.data.success) {
					setSubmitSuccessStatus('success');
					setNotificationState(true);
					setNotificationMessage('Selected listings are saved!');
				}
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					dispatchUser({
						isLoggedIn: false
					});
					return;
				}
				setNotificationState(true);
				setNotificationMessage(
					'Your connection is time out. Please log in again to continue'
				);
			});
	};

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={notificationState}
				autoHideDuration={4500}
				onClose={() => setNotificationState(false)}
			>
				<Alert
					onClose={() => setNotificationState(false)}
					severity={submitSuccessStatus === 'success' ? 'success' : 'error'}
					variant="filled"
				>
					{notificationMessage}
				</Alert>
			</Snackbar>
			<div style={{ float: 'right', margin: '3px' }}>
				<Button
					variant="contained"
					color="default"
					type="submit"
					onClick={handleClusterAssign}
					className={classes.assignBtn}
				>
					{clusterCount > 0 ? `Assign to My List (${clusterCount})` : `Assign to My List`}
				</Button>
			</div>
		</>
	);
}

function DownloadCSVButton({ url }) {
	const classes = useRowStyles();
	const handleDownload = (e) => {
		e.preventDefault();
	};

	return (
		<div style={{ float: 'right', margin: '3px' }}>
			<Button
				disabled={url ? false : true}
				variant="contained"
				color="default"
				type="submit"
				className={classes.assignBtn}
				href={url ? url : '#'}
				target="_blank"
			>
				Download Cluster CSV
			</Button>
		</div>
	);
}
//Render each clusterListing data as cells
function Row({ rowName, row, handleChange, ariaChecked, selectedRow }) {
	const [open, setOpen] = useState(false);
	const classes = useRowStyles();

	return (
		<>
			<TableRow hover selected={selectedRow} className={classes.root}>
				<TableCell className={classes.checkBoxClm}>
					<Checkbox
						inputProps={{ 'aria-labelledby': row.id }}
						value={row.id}
						className={classes.checkBox}
						checked={selectedRow}
						onClick={(event) => handleChange(event, row.id)}
					/>
					<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell>Cluster {row.id + 1}</TableCell>
				<TableCell>{row.Area}</TableCell>
				<TableCell>{row.clusters.length}</TableCell>
				<TableCell>
					<a
						className={classes.link}
						href={row.clusterRoute}
						target="_blank"
						rel="noreferrer"
					>
						Link
					</a>
					<a
						className={classes.desktopLink}
						href={row.clusterRoute}
						target="_blank"
						rel="noreferrer"
					>
						View Link
					</a>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							<Table size="small" aria-label="purchases">
								<TableHead>
									<TableRow>
										<TableCell>Address</TableCell>
										<TableCell>Status</TableCell>
										<TableCell>Price</TableCell>
										<TableCell>Assessment</TableCell>
										<TableCell>Expiry Date</TableCell>
										<TableCell>List Date</TableCell>
										<TableCell>Sold Date</TableCell>
										<TableCell>TotFlArea</TableCell>
										<TableCell>Lot Size</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{row.clusters.map((clusterItem, i) => {
										return (
											<TableRow
												key={i}
												className={
													clusterItem['ExistsWithinMyList']
														? classes.collectionInUser
														: clusterItem['ExistsWithinOrg']
														? classes.collectionInOrganization
														: ''
												}
											>
												<TableCell component="th" scope="row">
													<a
														href={'listing/' + clusterItem.MLNum}
														target="_blank"
														rel="noreferrer"
													>
														{clusterItem.Address}
													</a>
												</TableCell>
												<TableCell>{clusterItem.Status}</TableCell>
												<TableCell>
													{typeof clusterItem.Price === 'string'
														? clusterItem.Price
														: currencyParser(clusterItem.Price)}
												</TableCell>
												<TableCell>
													{clusterItem.assessment > 0
														? currencyParser(clusterItem.assessment)
														: '-'}
												</TableCell>
												<TableCell>
													{new Date(clusterItem['Expiry Date'])
														.toLocaleDateString('en-CA')
														.toString()}
												</TableCell>
												<TableCell>
													{new Date(clusterItem.date)
														.toLocaleDateString('en-CA')
														.toString()}
												</TableCell>
												<TableCell>
													{new Date(clusterItem['Sold Date'])
														.toLocaleDateString('en-CA')
														.toString()}
												</TableCell>
												<TableCell>
													{numberParser(clusterItem.TotFlArea) > 0
														? clusterItem.TotFlArea.toLocaleString()
														: '-'}
												</TableCell>
												<TableCell>
													{numberParser(clusterItem['Lot Sz (Sq.Ft.)']) >
													0
														? clusterItem[
																'Lot Sz (Sq.Ft.)'
														  ].toLocaleString()
														: '-'}
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
}

//Table headers
const headCells = [
	{
		id: 'id',
		numeric: true,
		disablePadding: true,
		label: 'Cluster ID'
	},
	{
		id: 'Area',
		numeric: false,
		disablePadding: false,
		label: 'Area'
	},
	{
		id: 'clusters',
		numeric: true,
		disablePadding: false,
		label: 'Properties'
	},
	{
		id: 'Cluster Route',
		numeric: false,
		disablePadding: false,
		label: 'Cluster Route'
	}
];

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired
};

//Sorting functions
function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
	const classes = useRowStyles();
	const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox">
					<Checkbox
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{ 'aria-label': 'select all clusters' }}
					/>
				</TableCell>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}
Row.propTypes = {
	row: PropTypes.shape({
		id: PropTypes.number.isRequired,
		clusterName: PropTypes.string.isRequired,
		Area: PropTypes.string.isRequired,
		clusters: PropTypes.arrayOf(
			PropTypes.shape({
				Address: PropTypes.string.isRequired,
				Status: PropTypes.string.isRequired,
				Price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
				'Expiry Date': PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
				ListDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
				SoldDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
				numOfBedrooms: PropTypes.number.isRequired,
				numOfBathrooms: PropTypes.number.isRequired,
				TotFlArea: PropTypes.number.isRequired,
				LotSize: PropTypes.number,
				MLNum: PropTypes.string.isRequired,
				ExistWithinOrg: PropTypes.bool
			})
		).isRequired,
		numOfProperties: PropTypes.number.isRequired,
		clusterRoute: PropTypes.string.isRequired
	}).isRequired
};

export default function ClustersTable({ clusters: rows, filePath }) {
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('Area');
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [selectedClusters, setSelectedClusters] = useState([]);
	const [clusterCount, setClusterCount] = useState(0);
	const classes = useRowStyles();

	useEffect(() => {
		setSelectedClusters([]);
		setPage(0);
	}, [rows]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = rows.map((n) => n.id);
			setSelectedClusters(newSelecteds);
			return;
		}
		setSelectedClusters([]);
	};

	const handleChange = (e, id) => {
		// const isChecked = e.target.checked;
		// if (isChecked) {
		// 	setClusterCount(clusterCount + 1);
		// 	setSelectedClusters([...selectedClusters, e.target.value]);
		// } else {
		// 	setClusterCount(clusterCount - 1);
		// 	const index = selectedClusters.indexOf(e.target.value);
		// 	const selectedClustersCopy = [...selectedClusters];
		// 	selectedClustersCopy.splice(index, 1);
		// 	setSelectedClusters(selectedClustersCopy);
		// }
		const selectedIndex = selectedClusters.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selectedClusters, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selectedClusters.slice(1));
		} else if (selectedIndex === selectedClusters.length - 1) {
			newSelected = newSelected.concat(selectedClusters.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selectedClusters.slice(0, selectedIndex),
				selectedClusters.slice(selectedIndex + 1)
			);
		}
		setSelectedClusters(newSelected);
	};

	const isSelected = (id) => selectedClusters.indexOf(id) !== -1;

	return (
		<>
			<TableContainer component={Paper}>
				<Table aria-label="collapsible table">
					<EnhancedTableHead
						order={order}
						orderBy={orderBy}
						numSelected={selectedClusters.length}
						onSelectAllClick={handleSelectAllClick}
						onRequestSort={handleRequestSort}
						rowCount={rows.length}
					/>
					<TableBody>
						{!rows.length && (
							<TableRow style={{ height: 68 * emptyRows }}>
								<TableCell
									colSpan={6}
									style={{ textAlign: 'center', height: '300px' }}
								>
									<h2>Please upload a file to view property clusters.</h2>
								</TableCell>
							</TableRow>
						)}
						{stableSort(rows, getComparator(order, orderBy))
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((row, i) => {
								const isItemSelected = isSelected(row.id);
								return (
									<Row
										key={i}
										row={row}
										clusterCount={clusterCount}
										setClusterCount={setClusterCount}
										value={row.clusterName}
										handleChange={handleChange}
										selectedRow={isItemSelected}
									/>
								);
							})}
						{emptyRows > 0 && (
							<TableRow style={{ height: 68 * emptyRows }}>
								<TableCell colSpan={6}></TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</TableContainer>
			{rows.length > 0 && (
				<Box display="flex" justifyContent="center" alignItems="center">
					<Box
						variant="outlined"
						width={30}
						height={15}
						m={2}
						className={classes.collectionInUser}
						elevation={2}
					/>
					<Typography> Favourites</Typography>
					<Box
						variant="outlined"
						width={30}
						height={15}
						m={2}
						className={classes.collectionInOrganization}
						elevation={2}
					/>
					<Typography> Organization's Collection</Typography>
				</Box>
			)}
			<ClusterAssignButton
				clusterCount={selectedClusters.length}
				selectedClusters={selectedClusters}
				row={rows}
			/>
			<DownloadCSVButton url={filePath} />
		</>
	);
}
