import { darken } from '@material-ui/core/styles';

const TaskBoardCardStyle = {
	assignedTagWrapper: {
		backgroundColor: '#cfcfcf',
		color: 'black',
		border: '0.8px solid #2dafaf',
		borderRadius: '12px',
		padding: '2px 6px',
		margin: '0px 4px',
		fontSize: '0.8rem'
	},
	wrapper: {
		position: 'relative'
	},
	cardProgress: {
		filter: 'brightness(25%)'
	},
	circleProgress: {
		position: 'absolute',
		margin: 'auto',
		width: '100%'
	},
	divProgress: {
		position: 'absolute',
		top: 50,
		left: 10,
		color: 'white',
		textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000'
	},
	enqueuedTagWrapper: {
		backgroundColor: 'white',
		color: 'black',
		border: '0.8px solid black',
		borderRadius: '12px',
		padding: '2px 6px',
		margin: '0px 4px',
		fontSize: '0.8rem'
	},
	addTagBtn: {
		borderRadius: '50%',
		border: '0.8px solid black',
		minWidth: '30px',
		bgcolor: '#2196f3',
		color: 'black',
		boxShadow: 'none',
		ml: '6px',
		'&:hover': {
			bgcolor: '#1565c0',
			boxShadow: 'none'
		}
	},
	tagFormModal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '400px',
		backgroundColor: 'white',
		padding: '16px',
		borderRadius: '4px'
	},
	assigneesWrapper: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: 'auto'
	},
	Card: {
		backgroundColor: 'white',
		border: '1px solid #2dafaf',
		boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
		borderRadius: '10px',
		color: '#222222',
		fontSize: '14px',
		minWidth: '250px',
		display: 'flex',
		flexDirection: 'column'
	},
	EnqueueCard: {
		backgroundColor: '#10403a',
		borderRadius: '10px',
		color: 'white',
		fontSize: '14px',
		overflow: 'visible',
		minWidth: '250px',
		display: 'flex',
		flexDirection: 'column'
	},
	Avatar: {
		width: '24px',
		height: '24px',
		ml: '-8px',
		border: '2px solid white',
		'&:first-of-type': {
			ml: '0px'
		}
	},
	Typography: {
		whiteSpace: 'nowrap'
	},
	LinkText: {
		color: 'white',
		'&:hover': {
			opacity: 0.5
		},
		'&:disabled': {
			color: 'white'
		}
	},
	CardContent: {
		minHeight: '120px',
		flexGrow: 1
	}
};

export default TaskBoardCardStyle;
