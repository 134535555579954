import React from 'react';
import { Grid, Chip, Tooltip, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { correctKnockabilityValue } from '../../../../helper/parser';

const checkChip = (chip) => {
	// THIS FUNCTION MAY NOT BE NEEDED
	// but what i was going for was to have it check  the values `chips` (chips is passed into this component)
	// And if there is a value that we don't want to show we return false

	if (
		chip.id === 'knockability' &&
		(chip.value === undefined || correctKnockabilityValue(chip.value) === 0)
	)
		return false;
	else return true;
};

export default function DetailChips({ chips }) {
	const theme = useTheme();

	// rendering each chip
	// note if the chip has no bg color the default gray will be displayed
	const renderChips = () => {
		// Rendering the chip. If there is no tooltip value, the tooltip won't appear (Build in to MaterialUI)
		return chips.map(
			(chip) =>
			<Grid key={chip.id} item>
			<Tooltip title={chip.tooltip || ''} placement="top">
			  <Chip
				label={chip.label}
				style={{ backgroundColor: chip.color }}
				avatar={chip.icon ? chip.icon : ''}
			  />
			</Tooltip>
		  </Grid>
			  );
	};

	return (
		<Grid container spacing={1}>
			{renderChips()}
		</Grid>
	);
}
