import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, CircularProgress, Paper, Box } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import { putRole } from '../../../../../api/serverRequests';

const useStyles = makeStyles((theme) => ({
	gridContainer: {
		display: 'flex',
		padding: '0% 6% 0% 6%',
		alignItems: 'center',
		overflow: 'auto',
		height: '100%'
	},
	gridItem: {
		alignItems: 'center'
	},
	inputField: {
		width: '45%',
		padding: '9px, 0px, 16px, 16px',
		gap: '10px',
		color: 'gray',
		fontSize: '15px',
		outline: 'None'
	},
	header: {
		fontWeight: '600',
		fontSize: '18px',
		color: '#000000'
	},
	subheader: {
		fontWeight: '400',
		color: '#000000',
		fontSize: '20px',
		marginBottom: '20px'
	},
	checklist: {
		display: 'flex',
		flexDirection: 'column',
		spaceBetween: '14px'
	},
	selectAllContainer: {
		padding: '2% 6% 2% 6%',
		float: 'right',
		display: 'inline-block',
		boxShadow: '0px 1px 16px rgba(0, 0, 0, 0.1)',
		borderRadius: '8px'
	},
	box: {
		marginRight: '5px'
	},
	subBox: {
		marginLeft: '15px'
	},
	subBoxLabel: {
		color: 'gray',
		marginLeft: '3px'
	},
	submit: {
		margin: theme.spacing(3, 2, 2),
		width: '13%'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	gridWrapper: {
		padding: theme.spacing(1, 0, 0, 1)
	},
	mybox: {
		margin: theme.spacing(0, 1),
		backgroundColor: '#F8F8F8',
		//height: "100%", //use this if we want all columns has same height, but will cause some overflow problem
		'& h3': {
			margin: theme.spacing(0, 0, 1)
		}
	}
}));

export default function PermissionsForm({ setIsLoadingUserAccessTab, setFormOpen }) {
	const [isLoading, setIsLoading] = useState(true);
	const [errMsg, setErrMsg] = useState('');
	const classes = useStyles();

	const [roleNameInput, setRoleNameInput] = useState('');
	const [propertyInfoChecklist, setPropertyInfoChecklist] = useState({});
	const [activeListingChecklist, setActiveListingChecklist] = useState({});
	const [appConfigChecklist, setAppConfigChecklist] = useState({});
	const [docManagementChecklist, setDocManagementChecklist] = useState({});
	const [taskManagementChecklist, setTaskManagementChecklist] = useState({});
	const [areaOptionsChecklist, setAreaOptionsChecklist] = useState({});
	const [updateCheckboxes, setUpdateCheckboxes] = useState(false); // toggle
	const PERMISSIONS = JSON.parse(localStorage.getItem('PERMISSIONS'));

	const setCheckBoxes = (rolePermissions) => {
		let propertyInfo = {};
		let appConfig = {};
		let docManagement = {};
		let activeListing = {};
		let taskManagement = {};
		let areaOptions = {};

		for (const [PERM_ID, PERM_INFO] of Object.entries(PERMISSIONS.main)) {
			const box = {
				permissionId: PERM_ID,
				label: PERM_INFO.label,
				checked: false,
				children: PERM_INFO.children,
				type: PERM_INFO.type
			};

			if (PERM_ID in rolePermissions) {
				box['checked'] = true;
			}

			if (PERM_INFO.type === 'activeListing') {
				activeListing[PERM_ID] = box;
			} else if (PERM_INFO.type === 'propertyInfo') {
				propertyInfo[PERM_ID] = box;
			} else if (PERM_INFO.type === 'appConfig') {
				appConfig[PERM_ID] = box;
			} else if (PERM_INFO.type === 'docManagement') {
				docManagement[PERM_ID] = box;
			} else if (PERM_INFO.type === 'taskManagement') {
				taskManagement[PERM_ID] = box;
			} else if (PERM_INFO.type === 'areaOptions') {
				areaOptions[PERM_ID] = box;
			}
		}
		setActiveListingChecklist(activeListing);
		setAppConfigChecklist(appConfig);
		setPropertyInfoChecklist(propertyInfo);
		setDocManagementChecklist(docManagement);
		setTaskManagementChecklist(taskManagement);
		setAreaOptionsChecklist(areaOptions);
	};

	const buildFormData = () => {
		const permissions = {};
		const checklist = Object.assign(
			{},
			propertyInfoChecklist,
			appConfigChecklist,
			docManagementChecklist,
			activeListingChecklist,
			taskManagementChecklist,
			areaOptionsChecklist
		);
		const checkedPerms = Object.values(checklist).filter(
			(checkbox) => checkbox.checked === true
		);

		for (let perm of checkedPerms) {
			const id = perm['permissionId'];
			var data = {};
			if (id in PERMISSIONS.main) {
				data = {
					permission_id: id,
					action: PERMISSIONS.main[id].action,
					resource: PERMISSIONS.main[id].resource,
					type: PERMISSIONS.main[id].type
				};
			}

			if (id in PERMISSIONS.children) {
				data = {
					permission_id: id,
					action: PERMISSIONS.children[id].action,
					resource: PERMISSIONS.children[id].resource
				};
			}
			permissions[id] = data;
		}

		const formData = {
			role_name: roleNameInput,
			permissions: permissions
		};
		return formData;
	};

	const handleSubmit = () => {
		if (!roleNameInput) {
			setErrMsg('Role name is required');
		} else {
			const formData = buildFormData();
			console.log({ formData });
			putRole(formData)
				.then((res) => {
					if (res.data.success) {
						setIsLoadingUserAccessTab(true);
						setFormOpen(false);
					}
				})
				.catch((err) => {
					setErrMsg(err.message);
					console.log(err.message);
				});
		}
	};

	const handleCheck = (e) => {
		const permId = e.target.value;

		if (permId in appConfigChecklist) {
			let updatedAppConfigChecklist = appConfigChecklist;
			updatedAppConfigChecklist[permId]['checked'] =
				!updatedAppConfigChecklist[permId]['checked'];
			setAppConfigChecklist(updatedAppConfigChecklist);
		}
		if (permId in propertyInfoChecklist) {
			const updatedPropertyInfoChecklist = propertyInfoChecklist;
			updatedPropertyInfoChecklist[permId]['checked'] =
				!updatedPropertyInfoChecklist[permId]['checked'];
			setPropertyInfoChecklist(updatedPropertyInfoChecklist);
		}

		if (permId in docManagementChecklist) {
			const updatedDocManagementSubChecklist = docManagementChecklist;
			updatedDocManagementSubChecklist[permId]['checked'] =
				!updatedDocManagementSubChecklist[permId]['checked'];
			setDocManagementChecklist(updatedDocManagementSubChecklist);
		}

		if (permId in activeListingChecklist) {
			const updatedActiveListingChecklist = activeListingChecklist;
			updatedActiveListingChecklist[permId]['checked'] =
				!updatedActiveListingChecklist[permId]['checked'];
			setActiveListingChecklist(updatedActiveListingChecklist);
		}

		if (permId in taskManagementChecklist) {
			const updatedTaskManagementChecklist = taskManagementChecklist;
			updatedTaskManagementChecklist[permId]['checked'] =
				!updatedTaskManagementChecklist[permId]['checked'];
			setTaskManagementChecklist(updatedTaskManagementChecklist);
		}

		if (permId in areaOptionsChecklist) {
			const updatedAreaOptionsChecklist = areaOptionsChecklist;
			updatedAreaOptionsChecklist[permId]['checked'] =
				!updatedAreaOptionsChecklist[permId]['checked'];
			setAreaOptionsChecklist(updatedAreaOptionsChecklist);
		}

		setUpdateCheckboxes(!updateCheckboxes);
	};

	const handleRoleNameInput = (e) => {
		setRoleNameInput(e.target.value);
	};

	const handleSelectAll = (e) => {
		const updatedActiveListingChecklist = activeListingChecklist;
		const updatedAppConfigChecklist = appConfigChecklist;
		const updatedDocManagementChecklist = docManagementChecklist;
		const updatedPropertyInfoChecklist = propertyInfoChecklist;
		const updatedTaskManagementChecklist = taskManagementChecklist;

		for (let key of Object.keys(updatedActiveListingChecklist)) {
			updatedActiveListingChecklist[key]['checked'] = e.target.checked;
		}
		for (let key of Object.keys(updatedAppConfigChecklist)) {
			updatedAppConfigChecklist[key]['checked'] = e.target.checked;
		}
		for (let key of Object.keys(updatedDocManagementChecklist)) {
			updatedDocManagementChecklist[key]['checked'] = e.target.checked;
		}
		for (let key of Object.keys(updatedPropertyInfoChecklist)) {
			updatedPropertyInfoChecklist[key]['checked'] = e.target.checked;
		}

		for (let key of Object.keys(updatedTaskManagementChecklist)) {
			updatedTaskManagementChecklist[key]['checked'] = e.target.checked;
		}

		setPropertyInfoChecklist(updatedPropertyInfoChecklist);
		setDocManagementChecklist(updatedDocManagementChecklist);
		setAppConfigChecklist(updatedAppConfigChecklist);
		setActiveListingChecklist(updatedActiveListingChecklist);
		setTaskManagementChecklist(updatedTaskManagementChecklist);
		setUpdateCheckboxes(!updateCheckboxes);
	};

	useEffect(() => {
		if (isLoading) {
			const form = JSON.parse(localStorage.getItem('permissionsForm'));
			if (form.roleId) {
				if (form.Permissions) {
					setCheckBoxes(form.Permissions);
				} else {
					setCheckBoxes({});
				}
			} else {
				setCheckBoxes({});
			}
			setRoleNameInput(form.roleName);
			setIsLoading(false);
		}
	}, [localStorage.getItem('permissionsForm')]);

	return isLoading ? (
		<CircularProgress size={50} color="inherit" />
	) : (
		<>
			<Grid
				container
				className={classes.gridContainer}
				justifyContent="center"
				alignItems="center"
				spacing={4}
			>
				<Grid item xs={12}>
					<TextField
						fullWidth
						id="outlined-basic"
						placeholder="Role Name"
						value={roleNameInput}
						onChange={handleRoleNameInput}
						color="primary"
						size="small"
						variant="outlined"
					/>
				</Grid>
				<Grid item xs={6}>
					<div className={classes.header}>Permissions</div>
				</Grid>
				<Grid item xs={6}>
					<div className={classes.selectAllContainer}>
						<input className={classes.box} type="checkbox" onChange={handleSelectAll} />
						<span className={'selectAll'}>{'Select All'}</span>
					</div>
				</Grid>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={4} className={classes.gridWrapper}>
						<Box p={2} className={classes.mybox}>
							<h3>Property Information</h3>
							<Grid container spacing={2} justify="center">
								<Grid className={classes.gridItem} item xs={12}>
									<Grid container spacing={2}>
										{Object.values(propertyInfoChecklist).map((item, index) => {
											return (
												<Grid item xs={12} key={item.permissionId}>
													<input
														value={item.permissionId}
														className={classes.box}
														type="checkbox"
														checked={item.checked}
														onChange={handleCheck}
													/>
													<span>{item.label}</span>
												</Grid>
											);
										})}
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<Grid container spacing={2} direction="column">
							<Grid item className={classes.gridWrapper}>
								<Box p={2} className={classes.mybox}>
									<h3>Task Management</h3>
									<Grid container spacing={2} justify="center">
										<Grid className={classes.gridItem} item xs={12}>
											<Grid container spacing={2}>
												{Object.values(taskManagementChecklist).map(
													(item, index) => {
														return (
															<Grid
																item
																xs={12}
																key={item.permissionId}
															>
																<input
																	value={item.permissionId}
																	className={classes.box}
																	type="checkbox"
																	checked={item.checked}
																	onChange={handleCheck}
																/>
																<span>{item.label}</span>
															</Grid>
														);
													}
												)}
											</Grid>
										</Grid>
									</Grid>
								</Box>
							</Grid>
							<Grid item className={classes.gridWrapper}>
								<Box p={2} className={classes.mybox}>
									<h3>App Configuration</h3>
									<Grid container spacing={2} justify="center">
										<Grid className={classes.gridItem} item xs={12}>
											<Grid container spacing={2}>
												{Object.values(appConfigChecklist).map(
													(item, index) => {
														return (
															<Grid
																item
																xs={12}
																key={item.permissionId}
															>
																<input
																	value={item.permissionId}
																	className={classes.box}
																	type="checkbox"
																	checked={item.checked}
																	onChange={handleCheck}
																/>
																<span>{item.label}</span>
															</Grid>
														);
													}
												)}
											</Grid>
										</Grid>
									</Grid>
								</Box>
							</Grid>
							<Grid item className={classes.gridWrapper}>
								<Box p={2} className={classes.mybox}>
									<h3>Active Listing</h3>
									<Grid container spacing={2} justify="center">
										<Grid className={classes.gridItem} item xs={12}>
											<Grid container spacing={2}>
												{Object.values(activeListingChecklist).map(
													(item, index) => {
														return (
															<Grid
																item
																xs={12}
																key={item.permissionId}
															>
																<input
																	value={item.permissionId}
																	className={classes.box}
																	type="checkbox"
																	checked={item.checked}
																	onChange={handleCheck}
																/>
																<span>{item.label}</span>
															</Grid>
														);
													}
												)}
											</Grid>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
						<Grid container spacing={2} direction="column">
							<Grid item className={classes.gridWrapper}>
								<Box p={2} className={classes.mybox}>
									<h3>Document Management</h3>
									<Grid container spacing={2} justify="center">
										<Grid className={classes.gridItem} item xs={12}>
											<Grid container spacing={2}>
												{Object.values(docManagementChecklist).map(
													(item, index) => {
														return (
															<Grid
																item
																xs={12}
																key={item.permissionId}
															>
																<input
																	value={item.permissionId}
																	className={classes.box}
																	type="checkbox"
																	checked={item.checked}
																	onChange={handleCheck}
																/>
																<span>{item.label}</span>
															</Grid>
														);
													}
												)}
											</Grid>
										</Grid>
									</Grid>
								</Box>
							</Grid>
							<Grid item className={classes.gridWrapper}>
								<Box p={2} className={classes.mybox}>
									<h3>Area Options</h3>
									<Grid container spacing={2} justify="center">
										<Grid className={classes.gridItem} item xs={12}>
											<Grid
												container
												spacing={2}
												style={{ overflowY: 'scroll', height: '280px' }}
											>
												{Object.values(areaOptionsChecklist).map(
													(item, index) => {
														return (
															<Grid
																item
																xs={12}
																key={item.permissionId}
															>
																<input
																	value={item.permissionId}
																	className={classes.box}
																	type="checkbox"
																	checked={item.checked}
																	onChange={handleCheck}
																/>
																<span>{item.label}</span>
															</Grid>
														);
													}
												)}
											</Grid>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Button
					type="button"
					variant="contained"
					color="primary"
					className={classes.submit}
					onClick={handleSubmit}
				>
					Save
				</Button>
			</Grid>
		</>
	);
}
