import React, { useEffect, useState } from 'react';
import { Typography, Paper, Divider, Grid, Tabs, Tab, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Title from '../../Title';
import { renderWebsiteFavicon } from '../../../../helper/listing-info';
import Loading from '../../Loading';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={2}>
					<Box>{children}</Box>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

function a11yProps(index) {
	return {
		id: `scrollable-force-tab-${index}`,
		'aria-controls': `scrollable-force-tabpanel-${index}`
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: 30,
		flexGrow: 1,
		width: '100%',
		backgroundColor: theme.palette.background.paper
	},
	tabs: {
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: '14px',
		'& .MuiTab-root.Mui-selected': {
			fontWeight: theme.typography.fontWeightBold,
			backgroundColor: theme.palette.green['KN_mint_10']
		}
	},
	tab: {
		borderBottom: '1.5px solid',
		borderBottomColor: theme.palette.grey['KN_light_grey'],
		fontSize: 'inherit'
	},
	descBox: {
		fontSize: '20px',
		padding: '20px 0 0 50px'
	},
	noActiveTitle: {
		color: theme.palette.grey['KN_light_grey'],
		textAlign: 'center',
		lineHeight: 2
	},
	centerItem: {
		marginTop: 40,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%'
	}
}));
const ExpandableText = ({ text }) => {
	const [isExpanded, setIsExpanded] = useState(false);

	const toggleExpansion = () => {
		setIsExpanded(!isExpanded);
	};

	return (
		<>
			{isExpanded ? (
				<>
					{text}
					<a
						href="#"
						onClick={(e) => {
							e.preventDefault();
							toggleExpansion();
						}}
						style={{
							color: 'blue',
							textDecoration: 'underline',
							cursor: 'pointer',
							marginLeft: '10px'
						}}
					>
						see less
					</a>
				</>
			) : (
				<>
					{text.substring(0, 100)}...
					<a
						href="#"
						onClick={(e) => {
							e.preventDefault();
							toggleExpansion();
						}}
						style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
					>
						see more
					</a>
				</>
			)}
		</>
	);
};

const OwnerInfoTab = ({ ownerDetailsInformation, ownerInfoLoading }) => {
	const [tabValue, setTabValue] = useState(0);
	const classes = useStyles();

	return (
		<>
			{!ownerDetailsInformation || ownerDetailsInformation.length <= 0 ? (
				<Typography
					variant="h4"
					component="h4"
					className={`${classes.centerItem} ${classes.noActiveTitle}`}
				>
					No additional owner information is available
				</Typography>
			) : (
				<>
					{ownerInfoLoading && <Loading />}
					<div>
						<Tabs
							value={tabValue}
							onChange={(e, newValue) => setTabValue(newValue)}
							indicatorColor="primary"
							textColor="primary"
							variant="scrollable"
							scrollButtons="on"
							className={classes.tabs}
							aria-label="scrollable tabs"
						>
							{ownerDetailsInformation.length > 0 &&
								ownerDetailsInformation.map((owner, index) => (
									<Tab
										className={classes.tab}
										label={owner.OwnerName}
										{...a11yProps(index)}
									/>
								))}
						</Tabs>
					</div>

					<div style={{ padding: '20px' }}>
						{ownerDetailsInformation.length > 0 &&
							ownerDetailsInformation.map((owner, index) => {
								return (
									<TabPanel key={index} value={tabValue} index={index}>
										{owner.SearchResults.length > 0 &&
											owner.SearchResults.map((result, resultIndex) => {
												return (
													<>
														<Paper
															key={resultIndex}
															elevation={0}
															style={{
																padding: '10px',
																marginBottom: '20px'
															}}
														>
															<Typography variant="h6">
																<a
																	href={result.link}
																	target="_blank"
																	rel="noopener noreferrer"
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																		textDecoration: 'none'
																	}}
																>
																	<span>
																		{renderWebsiteFavicon(
																			result.link
																		)}
																	</span>
																	{result.title}
																</a>
															</Typography>
															<Typography variant="body1">
																<ExpandableText
																	text={result.snippet}
																/>
															</Typography>
														</Paper>
													</>
												);
											})}
										<Typography variant="body1">
											Last updated: {owner.LastUpdated}
										</Typography>
									</TabPanel>
								);
							})}
					</div>
				</>
			)}
		</>
	);
};

export default OwnerInfoTab;
