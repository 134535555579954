import { createTheme } from '@material-ui/core/styles';

export const cusTheme = createTheme({
	palette: {
		primary: {
			main: '#8E9DF2'
		},
		secondary: {
			main: '#8797AF'
		}
	}
});
