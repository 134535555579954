import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Alert from '@material-ui/lab/Alert';

import { makeStyles } from '@material-ui/core/styles';
import { CardContent, Grid, Button, Switch, FormLabel, Snackbar } from '@material-ui/core';

import { Loading, ErrorMsg } from '../..';
import { useUserState } from '../../../hook/customerHook';
import {
	searchActiveListing,
	searchPriceDropListing,
	searchActiveListingBelowAssessment,
	searchActiveDeals,
	searchForeclosureDeals
} from '../../../api/serverRequests';
import { priceOptions, areaOptions, percentOptions } from './formOptions';
import CusSelectField from '../CusSelectField';

const useStyles = makeStyles((theme) => ({
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	formControl: {
		marginTop: theme.spacing(1),
		width: '100%'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	}
}));

const hiddenField = {
	name: 'hiddenFilter',
	label: 'Show hidden/ignored listings?',
	defaultValue: false
};

/* const HiddenFilterFieldComp = ({ field, defaultValue, control }) => (
	<Grid item xs={12} sm={12}>
		<FormLabel> {field.label} </FormLabel>
		<Controller
			name={field.name}
			defaultValue={defaultValue}
			control={control}
			render={(props) => (
				<>
					<Grid>
						<FormLabel>No</FormLabel>
						<Switch
							color="primary"
							checked={props.value}
							onChange={(e) => props.onChange(e.target.checked)}
						/>
						<FormLabel>Yes</FormLabel>
					</Grid>
				</>
			)}
		/>
	</Grid>
); */

export default function SearchForeclosureForm({
	handleSearch,
	handleRefreshSearch,
	handleClose,
	areaOptionsQuery
}) {
	const [userState, dispatchUser] = useUserState();
	const classes = useStyles();
	const { handleSubmit, control, watch, setValue } = useForm();
	const [errorMsg, setErrorMsg] = useState('');

	// // submit status updated after user has clicked save in edit modal
	const [submitSuccessStatus, setSubmitSuccessStatus] = useState(''); // keeps track of status when data is posted

	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message

	const fields = [
		{
			name: 'area',
			options: areaOptionsQuery,
			label: 'Area',
			multiple: true
		},
		{
			name: 'minPrice',
			options: priceOptions,
			label: 'Min Price'
		},
		{
			name: 'maxPrice',
			options: priceOptions,
			label: 'Max Price'
		}
		/* ,
		{
			name: 'percent',
			options: percentOptions,
			label: 'Percent (+/- %)'
		} */
	];

	const handleSearchSubmit = (formData) => {
		setErrorMsg('');

		handleRefreshSearch(true);
		let filters = {},
			filtersPersisted = {};

		for (const key of Object.keys(formData)) {
			if (key === 'area' && Array.isArray(formData[key])) {
				// If the field is 'area' and has multiple selections
				filtersPersisted[key] = formData[key];
				filters[key] = formData[key].map((area) => area['value']);
			} else {
				filtersPersisted[key] = formData[key];
				filters[key] = formData[key]['value'];
			}
		}

		dispatchUser({ activeFilters: filtersPersisted });
		handleClose();

		//combined API for searching active properties
		searchForeclosureDeals({ user: userState.user_id, filters: filters })
			.then((res) => {
				if (res.data.success) handleSearch(res.data.listings);
				handleRefreshSearch(false);
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue'
					);
					dispatchUser({
						isLoggedIn: false
					});
					return;
				}
				handleRefreshSearch(false);
				console.log(err.message);
			});

		/* 		// Price Drop query
		if (filtersPersisted.searchType.value === 1) {
			searchPriceDropListing({ user: userState.user_id, filters: filters })
				.then((res) => {
					if (res.data.success) handleSearch(res.data.listings);
					handleRefreshSearch(false);
				})
				.catch((err) => {
					if (err.response.status === 401) {
						alert('Your connection is time out. Please log in again to continue');
						dispatchUser({
							isLoggedIn: false
						});
						return;
					}
					handleRefreshSearch(false);
					console.log(err.message);
				});
			// Price Below Last Sold query
		} else if (filtersPersisted.searchType.value === 2) {
			searchActiveListing({ user: userState.user_id, filters: filters })
				.then((res) => {
					if (res.data.success) handleSearch(res.data.listings);
					handleRefreshSearch(false);
				})
				.catch((err) => {
					if (err.response.status === 401) {
						alert('Your connection is time out. Please log in again to continue');
						dispatchUser({
							isLoggedIn: false
						});
						return;
					}
					handleRefreshSearch(false);
					console.log(err.message);
				});
			/// Price Below Assessment query
		} else {
			searchActiveListingBelowAssessment({ user: userState.user_id, filters: filters })
				.then((res) => {
					if (res.data.success) handleSearch(res.data.listings);
					handleRefreshSearch(false);
				})
				.catch((err) => {
					if (err.response.status === 401) {
						alert('Your connection is time out. Please log in again to continue');
						dispatchUser({
							isLoggedIn: false
						});
						return;
					}
					handleRefreshSearch(false);
					console.log(err.message);
				});
		} */
	};

	return (
		<React.Fragment>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={notificationState}
				autoHideDuration={6000}
				onClose={() => setNotificationState(false)}
			>
				<Alert
					onClose={() => setNotificationState(false)}
					severity={submitSuccessStatus === 'success' ? 'success' : 'error'}
					variant="filled"
				>
					{notificationMessage}
				</Alert>
			</Snackbar>
			<CardContent>
				{errorMsg && <ErrorMsg text={errorMsg} />}
				<form className={classes.form} onSubmit={handleSubmit(handleSearchSubmit)}>
					<Grid container justifyContent="center" alignItems="center" spacing={2}>
						{fields.map((field) => (
							<CusSelectField
								defaultValue={
									userState.activeFilters && userState.activeFilters[field.name]
										? userState.activeFilters[field.name]
										: field.multiple
										? []
										: ''
								}
								key={field.name}
								itemGrid={6}
								name={field.name}
								label={field.label}
								options={field.options}
								control={control}
								requiredValue={field.required}
								fieldStyle={classes.formControl}
								multiple={field.multiple}
							/>
						))}
						{/* 						<HiddenFilterFieldComp
							field={hiddenField}
							defaultValue={
								userState.activeFilters && userState.activeFilters[hiddenField.name]
									? userState.activeFilters[hiddenField.name].value
									: hiddenField.defaultValue
							}
							control={control}
						/> */}
					</Grid>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
					>
						Search
					</Button>
				</form>
			</CardContent>
		</React.Fragment>
	);
}
