import React, { useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import {
	Card,
	CardContent,
	Typography,
	Button,
	Avatar,
	makeStyles,
	IconButton,
	Modal,
	Link,
	Tooltip,
	CircularProgress
} from '@material-ui/core';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import TagForm from '../../../TagForm';
import { deleteTask, getMLNumberByHomeId } from '../../../../../api/serverRequests';
import TaskBoardCardStyle from './TaskBoardCardStyle.js';
import { displayHumanReadableClock, displayHumanReadableDate } from '../../../../../helper/parser';
import moment from 'moment';

const useStyles = makeStyles(TaskBoardCardStyle);

export default function TaskBoardCardModified({ data, index, colEditMode, isEnqueued }) {
	const classes = useStyles();
	const history = useHistory();
	const homeId = data.homeId;
	// const mlnum = data.mlnum; // need this for tag form
	const createdAt =
		displayHumanReadableDate(data.createdAt) + ' ' + displayHumanReadableClock(data.createdAt);
	const updatedAt =
		displayHumanReadableDate(data.statusUpdatedAt) +
		' ' +
		displayHumanReadableClock(data.statusUpdatedAt);

	const tooltipNormal = moment(data.statusUpdatedAt).format('dddd MMM Do YYYY');

	const [tags] = useState(data.tags);
	const [owner] = useState(data.owner);

	const [statusId, setStatusId] = useState(data.statusId);

	const [anchorEl, setAnchorEl] = useState(null);
	const handleCloseMenu = () => setAnchorEl(null);
	const [openTagForm, setOpenTagForm] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleContextMenu = (event) => {
		event.preventDefault();
		setAnchorEl(event.currentTarget);
	};

	const handleTaskAddressClick = (e, homeId) => {
		setIsLoading(true);
		getMLNumberByHomeId(homeId)
			.then((res) => {
				if (res.status === 200) {
					let mlNum = res.data.mlnum;
					if (mlNum) {
						// history.push('/user/listing/' + mlNum);
						window.open('/user/listing/' + mlNum);
					}
				}
				setIsLoading(false);
			})
			.catch((err) => {
				console.error(err.message);
				setIsLoading(false);
			});
	};

	const handleAddTag = () => {
		setOpenTagForm(true);
	};

	const handleCloseTagModal = () => {
		setOpenTagForm(false);
	};

	const handleTagRefresh = () => {
		setOpenTagForm(false);
		window.location.reload(false);
	};

	const handleUnassign = () => {
		const formData = {
			homeId: homeId,
			owner: owner
		};
		deleteTask(formData);
		handleCloseMenu();
		window.location.reload(false);
	};

	return (
		<div className={classes.wrapper}>
			<Card
				onContextMenu={handleContextMenu}
				className={isEnqueued ? clsx(classes.EnqueueCard) : clsx(classes.Card)}
			>
				<CardContent className={classes.CardContent}>
					<Typography fontWeight="bold">
						<Button
							className={isEnqueued && classes.LinkText}
							key={homeId}
							disabled={isLoading}
							onClick={(e) => handleTaskAddressClick(e, homeId)}
						>
							{data.address}
						</Button>
					</Typography>
					<div>
						{tags.slice(0, 2).map((tag, index) => (
							<span
								key={index}
								className={
									isEnqueued
										? classes.enqueuedTagWrapper
										: classes.assignedTagWrapper
								}
							>
								{tag.Tag}
							</span>
						))}
						{/* 						<IconButton onClick={handleAddTag}>
							<AddCircleOutlineRoundedIcon
								className={isEnqueued && classes.LinkText}
							/>
						</IconButton> */}
					</div>
				</CardContent>
				<CardContent>
					<div className={classes.timestamp}>
						<Tooltip title={tooltipNormal}>
							<Typography variant="caption" fontWeight="bold">
								ASSIGNED: {updatedAt}
							</Typography>
						</Tooltip>
					</div>
					{data.ownerName && (
						<div className={classes.assigneesWrapper}>
							<Avatar
								key={0}
								alt={data.ownerName}
								sx={{ colorDefault: '#ffffff' }}
								className={classes.Avatar}
							>
								{data.ownerName.charAt(0)}
							</Avatar>
						</div>
					)}
				</CardContent>
			</Card>
			{/* 			
				<Modal open={openTagForm} onClose={handleCloseTagModal} aria-labelledby="tag-modal">
				<div className={classes.tagFormModal}>
					<h2 id="tag-form-modal-title">Edit tags</h2>
					<TagForm homeId={data.homeId} mlnum={mlnum} handleRefresh={handleTagRefresh} />
					<Button variant="contained" color="secondary" onClick={handleCloseTagModal}>
						Close
					</Button>
				</div>
			</Modal> */}
		</div>
	);
}
