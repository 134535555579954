import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useUserState } from '../hook/customerHook';
import { Loading } from '../components/index';

export default function PrivateRoute({ children, ...rest }) {
	const [userState, dispatchUser] = useUserState();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (localStorage.isLoggedIn && userState.isLoggedIn == null) {
			let item = JSON.parse(localStorage.getItem('userState'));
			dispatchUser(item);
			setIsLoading(false);
		} else {
			setIsLoading(false);
		}
	}, []);

	if (isLoading) {
		return <Loading />;
	} else {
		return (
			<Route
				{...rest}
				render={({ location }) =>
					userState.isLoggedIn || localStorage.isLoggedIn ? (
						children
					) : (
						<Redirect
							to={{
								pathname: '/login',
								state: {
									from: location
									// message:"Please sign in to contine"
								}
							}}
						/>
					)
				}
			/>
		);
	}
}
