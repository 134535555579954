import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LanguageIcon from '@material-ui/icons/Language';
export const LISTING_STATUS = {
	A: 'Active',
	U: 'Active Under Contract',
	P: 'Pending',
	FA: 'For Approval',
	S: 'Sold',
	F: 'Closed',
	Z: 'Collapsed',
	X: 'Expired',
	W: 'Hold All Action',
	T: 'Terminated',
	C: 'Cancel Protected'
};

export const renderWebsiteFavicon = (url) => {
	//TODO: add more icons for website or find a way to render favicon from google search result api
	if (url.includes('linkedin')) {
		return <LinkedInIcon />;
	} else if (url.includes('facebook')) {
		return <FacebookIcon />;
	} else if (url.includes('instagram')) {
		return <InstagramIcon />;
	} else {
		return <LanguageIcon />;
	}
};
