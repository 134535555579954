export const PERMS = {
	USER_ACCESS: 1,
	VIEW_ALL_BOARDS: 2,
	OWNER_LETTER: 3, //deprecated
	AGENT_LETTER: 4,
	KNOCKING_STATUS: 5,
	VIEW_COMMENTS: 6,
	TAGS: 7,
	EDIT_ASSIGN_TASKS: 8,
	ADD_COMMENTS: 9,
	VIEW_RECORDINGS: 10,
	VIEW_FORECLOSURE: 11,
	VIEW_DEALS: 12,
	VIEW_OTHER_TAGS: 13,
	VIEW_TOP_AGENTS: 14,
	VIEW_OTHER_USERS_DASHBOARD: 15,
	CUSTOM_OWNER_LETTER: 16,
	PLAN_MY_ROUTES: 17,
	ASSIGN_TASK_MYSELF: 18,
	VIEW_EDIT_HISTORY: 19,
	VIEW_GENERATE_YOUR_PDF: 20,
	SETTING_ACCESS: 21,
	VIEW_CRM_ACCESS: 22,
	VIEW_OWNER_PHONE: 23,
	VIEW_OWNER_NAME: 24,
	VIEW_OFFMARKET_SEARCH: 25,
	AI_LETTER: 96
};
