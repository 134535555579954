import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const InfoTable = ({ data }) => {
	// Define all the properties you want to potentially display
	const properties = [
		{ name: 'Total Number of Listings', key: 'PastActFreq', format: 'number' },
		{ name: 'Total Number of Sold Listings', key: 'PastActFreq_S', format: 'number' },
		{ name: 'Months Since Last Activity', key: 'timeSinceLast', format: 'number' },
		{ name: 'Months Since Last Terminated', key: 'timeSinceLast_T', format: 'number' },
		{ name: 'Months Since Last Expired', key: 'timeSinceLast_X', format: 'number' },
		{ name: 'Home Age (years)', key: 'homeAge', format: 'number' },
		{ name: 'Total Floor Area Band', key: 'prev_TotFlArea', format: 'text' },
		{ name: 'Months Since First Sold', key: 'Timesince_First_sold', format: 'number' },
		{ name: 'First Recent Sold Price', key: 'First_recent_soldprice', format: 'currency' },
		{ name: 'Months Since Second Sold', key: 'Timesince_Second_sold', format: 'number' },
		{ name: 'Second Recent Sold Price', key: 'Second_recent_soldprice', format: 'currency' },
		{
			name: 'Sub area Avg. List Price',
			key: 'SA_avgListPrice_last6months',
			format: 'currency'
		},
		{ name: 'Unemployment Rate', key: 'UnEmploymentRate', format: 'percent' },
		{ name: 'Interest Rate', key: 'InterestRate', format: 'percent' }
	];

	// Filter out properties with null values and prepare rows
	const rows = properties
		.filter((property) => data[property.key] !== null && data[property.key] !== undefined)
		.map((property) => {
			let value = data[property.key];
			// Format value based on its type
			switch (property.format) {
				case 'percent':
					value = `${value}%`;
					break;
				case 'currency':
					value = `${value.toLocaleString('en-US', {
						style: 'currency',
						currency: 'USD',
						minimumFractionDigits: 0, // Do not show any decimal places
						maximumFractionDigits: 0 // Do not show any decimal places
					})}`;
					break;
				default:
					// Assume number or string without special formatting
					value = value.toString();
			}
			return { name: property.name, value };
		});

	return (
		<TableContainer component={Paper}>
			<Table aria-label="selective data table">
				<TableBody>
					{rows.map((row, index) => (
						<TableRow key={index}>
							<TableCell component="th" scope="row">
								{row.name}
							</TableCell>
							<TableCell align="right">{row.value}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default InfoTable;
