import { useState } from 'react';

// mui //
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

// comps //
import { FileUploadRegion } from '../../..';

const useStyles = makeStyles({
	heading: {
		fontSize: '30px',
		marginBottom: '10px'
	},
	fileUploading: {
		display: 'flex',
		flexDirection: 'column'
	},
	desc: {
		fontSize: '15px',
		margin: '10px 0'
	}
});
const UploadUpdateListingTab = () => {
	const [filePath, setFilePath] = useState(null);
	const classes = useStyles();

	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<div>
						<div>
							<h1 className={classes.heading}>Upload Updated Listings</h1>
							<p className={classes.desc}>Upload updated listings to the database.</p>
							<p>
								<b>Step 1:</b> Upload your .csv file <br />
								<b>Step 2:</b> View listings updated below. <br />
							</p>
						</div>
						<div className={classes.fileUploading}>
							<FileUploadRegion setFilePath={setFilePath} />
						</div>
					</div>
				</Grid>
			</Grid>
		</>
	);
};

export default UploadUpdateListingTab;
