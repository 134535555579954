import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Alert from '@material-ui/lab/Alert';

import { useHistory, useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Paper, IconButton, Drawer, Box, Button, Grid, Snackbar } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';

import { Loading, CusSelectFieldAsync } from '../..';
import { useUserState, useWindowDimensions } from '../../../hook/customerHook';
import { getListingsByAddress } from '../../../api/serverRequests';

const useStyles = makeStyles((theme) => ({
	form: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around'
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	formControl: {
		marginTop: theme.spacing(1),
		width: '100%'
	},
	drawerPaper: {
		position: 'absolute',
		whiteSpace: 'nowrap',
		width: '100%',
		height: '100%',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	searchPaper: {
		'& .MuiAutocomplete-inputRoot': {
			flexWrap: 'nowrap'
		},
		'& .MuiInputBase-root': {
			marginLeft: '5px'
		},
		'& .MuiFormLabel-root': {
			marginLeft: '5px'
		}
	},
	button: {
		color: '#004F2B'
	}
}));

export default function SearchAddressBar({ setOpenSearch }) {
	const [userState, dispatchUser] = useUserState();
	const [open, setOpen] = useState(false);
	const [isSearching, setIsSearching] = useState(false);
	const classes = useStyles();
	const { handleSubmit, control, watch, setValue } = useForm();
	let history = useHistory();
	const dimensions = useWindowDimensions();
	const location = useLocation();

	// // submit status updated after user has clicked save in edit modal
	const [submitSuccessStatus, setSubmitSuccessStatus] = useState(''); // keeps track of status when data is posted

	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message

	useEffect(() => {
		if (dimensions.width < 960) {
			setOpenSearch(false);
		}
	}, [dimensions]);

	const handleDrawer = () => {
		setOpen(!open);
	};

	const field = {
		name: 'address',
		label: 'Search for a listing...',
		placeholder: 'Enter an address, city, MLNum or telephone number.'
	};

	//Do search to fetch listing
	const handleSearchSubmit = (formData) => {
		if (!formData) return;
		const input = formData.address.value ? formData.address.value : '';

		if (input.length <= 1) {
			setSubmitSuccessStatus('error');
			setNotificationState(true);
			setNotificationMessage('Wrong format, please input as Address, City.');
			return;
		}
		getListingsByAddress(formData.address.address, formData.address.city)
			.then((res) => {
				if (res.data.success) {
					if (res.data.listing) {
						const mlNum = res.data.listing.MLNum;
						const HomeId = res.data.listing.Id;
						if (mlNum) {
							history.push('/user/listing/' + mlNum);
						} else {
							history.push('/user/listing/' + HomeId);
						}
						if (location.pathname.includes('listing')) history.go(0);
					} else {
						setSubmitSuccessStatus('error');
						setNotificationState(true);
						setNotificationMessage('A listing was not found!');
					}
				}
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue'
					);
					dispatchUser({
						isLoggedIn: false
					});
					return;
				}
				console.log(err.message);
			});
	};

	return (
		<React.Fragment>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={notificationState}
				autoHideDuration={6000}
				onClose={() => setNotificationState(false)}
			>
				<Alert
					onClose={() => setNotificationState(false)}
					severity={submitSuccessStatus === 'success' ? 'success' : 'error'}
					variant="filled"
				>
					{notificationMessage}
				</Alert>
			</Snackbar>
			<Box
				className={classes.searchBox}
				display={{
					xs: 'none',
					sm: 'block'
				}}
			>
				<form className={classes.form} onSubmit={handleSubmit(handleSearchSubmit)}>
					<Paper className={classes.searchPaper}>
						<CusSelectFieldAsync
							defaultValue={''}
							key={field.name}
							itemGrid={12}
							name={field.name}
							label={field.label}
							options={field.options}
							control={control}
							placeholder={field.placeholder}
							requiredValue={field.required}
							fieldStyle={classes.searchBar}
							multiple={field.multiple}
						/>{' '}
					</Paper>{' '}
					<IconButton type="submit">
						<SearchIcon className={classes.button} />{' '}
					</IconButton>{' '}
				</form>{' '}
			</Box>{' '}
			<Box
				display={{
					xs: 'block',
					sm: 'none'
				}}
			>
				<Drawer
					variant="persistent"
					classes={{
						paper: classes.drawerPaper
					}}
					open={open}
					anchor="top"
				>
					<form className={classes.form} onSubmit={handleSubmit(handleSearchSubmit)}>
						<Box className={classes.paper}>
							<CusSelectFieldAsync
								defaultValue={''}
								key={field.name}
								itemGrid={12}
								name={field.name}
								label={field.label}
								options={field.options}
								control={control}
								placeholder={field.placeholder}
								requiredValue={field.required}
								fieldStyle={classes.searchBar}
								multiple={field.multiple}
							/>{' '}
						</Box>{' '}
						<Button variant="contained" color="primary" type="submit">
							Go{' '}
						</Button>{' '}
						<IconButton onClick={handleDrawer}>
							<CancelIcon />
						</IconButton>{' '}
					</form>{' '}
				</Drawer>{' '}
				<IconButton onClick={handleDrawer}>
					<SearchIcon className={classes.button} />{' '}
				</IconButton>{' '}
			</Box>{' '}
		</React.Fragment>
	);
}
