import React, { useState } from 'react';
import { Slide, Snackbar, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { onMessageListener } from '../../../../Firebase/firebase';
import { useUserState } from '../../../../hook/customerHook';

export default function NotificationAlert() {
	const [userState] = useUserState();
	const [notifications, setNotifications] = useState([]); // array of notifications, each item contains an object with body and title of the notificaiton
	const [showSnackbar, setShowSnackbar] = useState(false); // to handle showing/closing notification

	// handle user manually closing notifications
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') return;
		setShowSnackbar(false);
	};

	// listen to incoming notifications and update notifications state
	onMessageListener()
		.then((payload) => {
			console.log(`Firebase got notification: ${payload}`);
			setNotifications((prevNotifications) => [...prevNotifications, payload.data]);
			if (userState.isLoggedIn) setShowSnackbar(true); // open the snackbar when a new notification comes in and the user is logged in
		})
		.catch((err) => console.error(`Firebase Notification Listener Failed: ${err}`));

	return (
		<>
			{notifications.map((notification, index) => (
				<Snackbar
					key={index}
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					open={showSnackbar}
					autoHideDuration={6000}
					onClose={handleClose}
					TransitionComponent={Slide}
					TransitionProps={{
						direction: 'left'
					}}
				>
					<Alert onClose={handleClose} severity="info">
						<Typography
							component="p"
							variant="subtitle1"
							sx={{ color: 'rgb(13, 60, 97)' }}
						>
							{notification.title}
						</Typography>
						<Typography component="p" variant="p">
							{notification.body}
						</Typography>
					</Alert>
				</Snackbar>
			))}
		</>
	);
}
