import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { IconButton, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import blue from '@material-ui/core/colors/blue';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PersonIcon from '@material-ui/icons/Person';
import UserFiltersForm from './UserFiltersForm';
import { useUserState } from '../../../hook/customerHook';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	button: {
		flex: 1,
		color: blue[900],
		margin: 10,
		justifyContent: 'center',
		alignSelf: 'center'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},
	content: {
		display: 'flex',
		padding: theme.spacing(0),
		overflowX: 'visible'
	},
	filterBtn: {
		marginTop: '8px',
		textTransform: 'none',
		letterSpacing: 'normal',
		fontWeight: 'normal'
	}
}));

const DialogTitle = ({ children, onClose, ...other }) => {
	const classes = useStyles();
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
};

export default function UserFilters({
	refreshListings,
	restoredFilters,
	editAssignedTaskVisible,
	assignMyself,
	setHasArea
}) {
	const [open, setOpen] = useState(false);
	const classes = useStyles();
	const [userState, dispatchUser] = useUserState();

	const userFilters = userState.userFilters;
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

	const filtersLength = (filters) => {
		return Object.keys(filters).length;
	};

	return (
		<React.Fragment>
			<Grid item justifyContent="center" alignItems="center">
				<Button
					variant="outlined"
					startIcon={<PersonIcon />}
					onClick={handleClickOpen}
					className={classes.filterBtn}
					style={{
						color: userFilters && filtersLength(userFilters) ? '#02838D' : '#004F2B',
						borderColor:
							userFilters && filtersLength(userFilters) ? '#02838D' : '#004F2B'
					}}
				>
					User filters{' '}
					{userFilters && filtersLength(userFilters)
						? `(${filtersLength(userFilters)})`
						: ''}
				</Button>
				<Dialog
					open={open}
					onClose={handleClose}
					aria-labelledby="search"
					scroll="paper"
					fullScreen={fullScreen}
				>
					<DialogTitle id="search" onClose={handleClose} className={classes.root}>
						{'Filters'}
					</DialogTitle>
					<DialogContent dividers className={classes.content}>
						<UserFiltersForm
							refreshListings={refreshListings}
							restoredFilters={restoredFilters}
							editAssignedTaskVisible={editAssignedTaskVisible}
							assignMyself={assignMyself}
							handleClose={handleClose}
							setHasArea={setHasArea}
						/>
					</DialogContent>
				</Dialog>
			</Grid>
		</React.Fragment>
	);
}
