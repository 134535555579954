import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';

import Routes from './route/Routes';

import './App.css';
import './style.css';
import { ThemeProvider } from '@material-ui/core/styles';
import { WindowDimensionsProvider, UserStateProvider, theme } from './context/Provider';
import { NotificationWrapper } from './components';

const TRACKING_ID = 'G-N561FCDQ9Q';
ReactGA.initialize(TRACKING_ID, { debug: true });

//WindowDimensionProvider is prepared for responsiveness for future dev
export default function App() {
	return (
		<ThemeProvider theme={theme}>
			<UserStateProvider>
				<WindowDimensionsProvider>
					<NotificationWrapper>
						<Routes />
					</NotificationWrapper>
				</WindowDimensionsProvider>
			</UserStateProvider>
		</ThemeProvider>
	);
}
