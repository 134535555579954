import React, { useMemo, useEffect, useState } from 'react';
import {
	Typography,
	Box,
	Table,
	TableBody,
	TableRow,
	TableContainer,
	TableCell,
	TableHead,
	Paper
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import DetailTab from '../DetailTab';
import EmailIcon from '@material-ui/icons/Email';

const useStyles = makeStyles((theme) => ({
	root: {
		//
	},
	emailIcon: {
		color: 'inherit'
	},
	agentTableDiv: {
		marginTop: '20px'
	},
	tableHeading: {
		marginTop: '20px',
		marginBotton: '20px'
	},
	tableTitle: {
		color: theme.palette.black.main,
		fontWeight: theme.typography.fontWeightMedium
	}
}));

export default function ListingAgentsTab({ listing }) {
	const classes = useStyles();

	const [mobileView, setMobileView] = useState(false);

	const listAgentDetails = useMemo(() => [
		{
			Address: listing.ListDesigAgt1Address1,
			Name: listing.ListDesigAgt1AgentName,
			Office: listing.ListFirm1CodeOfficeCode,
			Email: listing.ListDesigAgt1Email,
			Phone: listing.ListDesigAgt1PhoneNumber
		},
		{
			Address: listing.ListDesigAgt2Address1,
			Name: listing.ListDesigAgt2AgentName,
			Office: listing.ListFirm2CodeOfficeCode,
			Email: listing.ListDesigAgt2Email,
			Phone: listing.ListDesigAgt2PhoneNumber
		},
		{
			Address: listing.ListDesigAgt3Address1,
			Name: listing.ListDesigAgt3AgentFullName,
			Office: listing.ListFirm3CodeOfficeCode,
			Email: listing.ListDesigAgt3Email,
			Phone: listing.ListDesigAgt3PhoneNumber
		}
	]);

	const sellAgentDetails = useMemo(() => [
		{
			Address: listing.SellSalesRep1Address1,
			Name: listing.SellSalesRep1AgentFullName,
			// Office: not in db?
			Email: listing.SellSalesRep1Email,
			Phone: listing.SellSalesRep1PhoneNumber
		},
		{
			Address: listing.SellSalesRep2Address1,
			Name: listing.SellSalesRep2AgentFullName,
			Email: listing.SellSalesRep2Email,
			Phone: listing.SellSalesRep2PhoneNumber
		},
		{
			Address: listing.SellSalesRep3Address1,
			Name: listing.SellSalesRep3AgentFullName,
			Email: listing.SellSalesRep3Email,
			Phone: listing.SellSalesRep3PhoneNumber
		}
	]);

	useEffect(() => {
		function displaySeeMoreButtons() {
			const screenWidth = window.innerWidth;

			screenWidth < 550 ? setMobileView(true) : setMobileView(false);
		}

		displaySeeMoreButtons();
		window.addEventListener('resize', displaySeeMoreButtons);

		return () => {
			window.removeEventListener('resize', displaySeeMoreButtons);
		};
	}, []);

	const renderListAgentDetails = () => {
		return (
			<TableContainer component={Paper}>
				<Table aria-label="simple table">
					<colgroup>
						<col width="35%" />
						<col width="15%" />
						<col width="20%" />
						<col width="30%" />
					</colgroup>
					<TableHead>
						<TableRow>
							<TableCell>
								<Typography
									variant="subtitle1"
									component="h5"
									className={classes.tableTitle}
								>
									Name
								</Typography>
							</TableCell>
							<TableCell>
								<Typography
									variant="subtitle1"
									component="h5"
									className={classes.tableTitle}
								>
									Office
								</Typography>
							</TableCell>
							<TableCell align="center">
								<Typography
									variant="subtitle1"
									component="h5"
									className={classes.tableTitle}
								>
									Email
								</Typography>
							</TableCell>
							<TableCell>
								<Typography
									variant="subtitle1"
									component="h5"
									className={classes.tableTitle}
								>
									Phone
								</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{listAgentDetails.map((row) => {
							return !row.Name ? null : (
								<TableRow
									key={row.Name}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<TableCell>
										<Typography variant="subtitle1" component="h6">
											{row.Name}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="subtitle1" component="h6">
											{row.Office}
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography variant="subtitle1" component="h6">
											<a
												href={`mailto:${row.Email}`}
												className={classes.emailIcon}
											>
												<EmailIcon />
											</a>
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="subtitle1" component="h6">
											{row.Phone.replace(/[^\d.-]/g, '')}
										</Typography>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		);
	};

	const renderSellAgentDetails = () => {
		return (
			<TableContainer component={Paper}>
				<Table aria-label="simple table">
					<colgroup>
						<col width="35%" />
						<col width="15%" />
						<col width="20%" />
						<col width="30%" />
					</colgroup>
					<TableHead>
						<TableRow>
							<TableCell>
								<Typography
									variant="subtitle1"
									component="h5"
									className={classes.tableTitle}
								>
									Name
								</Typography>
							</TableCell>
							<TableCell>
								<Typography
									variant="subtitle1"
									component="h5"
									className={classes.tableTitle}
								>
									Office
								</Typography>
							</TableCell>
							<TableCell align="center">
								<Typography
									variant="subtitle1"
									component="h5"
									className={classes.tableTitle}
								>
									Email
								</Typography>
							</TableCell>
							<TableCell>
								<Typography
									variant="subtitle1"
									component="h5"
									className={classes.tableTitle}
								>
									Phone
								</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{sellAgentDetails.map((row) => {
							return !row.Name ? null : (
								<TableRow
									key={row.Name}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<TableCell>
										<Typography variant="subtitle1" component="h6">
											{row.Name}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="subtitle1" component="h6">
											{row.Office}
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography variant="subtitle1" component="h6">
											<a
												href={`mailto:${row.Email}`}
												className={classes.emailIcon}
											>
												<EmailIcon />
											</a>
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="subtitle1" component="h6">
											{row.Phone.replace(/[^\d.-]/g, '')}
										</Typography>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		);
	};

	const noAgentMsg = () => {
		return (
			<Typography variant="subtitle1" component="h6">
				No Agents Found{' '}
			</Typography>
		);
	};

	return (
		<div>
			<Typography variant="h4" component="h4" className={classes.tableHeading}>
				List Agents
			</Typography>
			<div className={classes.agentTableDiv}>
				{listAgentDetails[0].Name ? renderListAgentDetails() : noAgentMsg()}
			</div>
			<Typography variant="h4" component="h4" className={classes.tableHeading}>
				Sell Agents
			</Typography>
			<div className={classes.agentTableDiv}>
				{sellAgentDetails[0].Name ? renderSellAgentDetails() : noAgentMsg()}
			</div>
		</div>
	);
}
