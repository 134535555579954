import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { IconButton, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import blue from '@material-ui/core/colors/blue';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useUserState } from '../../../hook/customerHook';
import RouteFilterForm from './RouteFilterForm';
import ExploreIcon from '@material-ui/icons/Explore';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	button: {
		flex: 1,
		color: blue[900],
		margin: 10,
		justifyContent: 'center',
		alignSelf: 'center'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},
	content: {
		display: 'flex',
		padding: theme.spacing(0),
		overflowX: 'visible'
	},
	filterBtn: {
		marginTop: '8px',
		textTransform: 'none',
		letterSpacing: 'normal',
		fontWeight: 'normal'
	},
	routeFilterModal: {
		width: '100vw',
		[theme.breakpoints.up('xl')]: {
			'& .MuiDialog-paper': {
				maxWidth: 'unset',
				width: '35%'
			}
		}
	}
}));

const DialogTitle = ({ children, onClose, ...other }) => {
	const classes = useStyles();
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
};

export default function RouteFilters({ refreshListings, restoredFilters }) {
	const [open, setOpen] = useState(false);
	const classes = useStyles();
	const [userState, dispatchUser] = useUserState();

	const routeFilters = userState.routeFilters;
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const theme = useTheme();
	const countRouteFilters = () => {
		let routeFiltersLength = Object.keys(routeFilters).length;
		for (const property in routeFilters) {
			if (property === 'startAddrLocation' || property === 'endAddrLocation') {
				routeFiltersLength -= 1;
			}
		}
		return routeFiltersLength;
	};

	return (
		<React.Fragment>
			<Grid item justifyContent="center" alignItems="center">
				<Button
					variant="outlined"
					startIcon={<ExploreIcon />}
					onClick={handleClickOpen}
					className={classes.filterBtn}
					style={{
						color: routeFilters && countRouteFilters() > 0 ? '#02838D' : '#004F2B',
						borderColor: routeFilters && countRouteFilters() > 0 ? '#02838D' : '#004F2B'
					}}
				>
					Route filters {routeFilters ? `(${routeFilters && countRouteFilters()})` : ''}
				</Button>
				<Dialog
					open={open}
					onClose={handleClose}
					aria-labelledby="search"
					className={classes.routeFilterModal}
				>
					<DialogTitle id="search" onClose={handleClose} className={classes.root}>
						{'Route Filters'}
					</DialogTitle>
					<DialogContent dividers className={classes.content}>
						<RouteFilterForm
							refreshListings={refreshListings}
							restoredFilters={restoredFilters}
							handleClose={handleClose}
						/>
					</DialogContent>
				</Dialog>
			</Grid>
		</React.Fragment>
	);
}
