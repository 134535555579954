import React from 'react';
import {
	Typography,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableCell,
	TableRow,
	CircularProgress,
	Link
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		//
	},
	firstTitle: {
		margin: '0.4rem 0',
		paddingBottom: 10
	},
	noActiveTitle: {
		color: theme.palette.grey['KN_light_grey'],
		textAlign: 'center',
		lineHeight: 2
	},
	centerItem: {
		marginTop: 40,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%'
	},
	tableTitle: {
		color: theme.palette.black.main,
		fontWeight: theme.typography.fontWeightMedium
	},
	agentEmail: {
		color: theme.palette.grey['KN_grey']
	}
}));
const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.grey['KN_back_ground'],
		color: theme.palette.black.main
	},
	body: {
		borderBottom: 'none'
	}
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(even)': {
			backgroundColor: theme.palette.grey['KN_back_ground_soft']
		}
	}
}))(TableRow);

export default function TopAgentsTab({ topAgents, isLoadingTopAgents }) {
	const classes = useStyles();

	return isLoadingTopAgents ? (
		<div className={classes.centerItem}>
			<CircularProgress />
		</div>
	) : (
		<>
			{topAgents.length === 0 ? (
				<Typography
					variant="h4"
					component="h4"
					className={`${classes.centerItem} ${classes.noActiveTitle}`}
				>
					Looks like the nearby top agents are hiding! We'll keep searching and give you a
					holler when we track them down.
				</Typography>
			) : (
				<>
					<Typography variant="h4" component="h4" className={classes.firstTitle}>
						Top Agents
					</Typography>

					<TableContainer>
						<Table className={classes.table} aria-label="customized table">
							<TableHead>
								<TableRow>
									<StyledTableCell align="center">
										<Typography
											variant="subtitle1"
											component="h5"
											className={classes.tableTitle}
										>
											Rank
										</Typography>
									</StyledTableCell>
									<StyledTableCell align="center">
										<Typography
											variant="subtitle1"
											component="h5"
											className={classes.tableTitle}
										>
											Name
										</Typography>
									</StyledTableCell>
									<StyledTableCell align="center">
										<Typography
											variant="subtitle1"
											component="h5"
											className={classes.tableTitle}
										>
											Phone No.
										</Typography>
									</StyledTableCell>
									<StyledTableCell align="center">
										<Typography
											variant="subtitle1"
											component="h5"
											className={classes.tableTitle}
										>
											Broker
										</Typography>
									</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{topAgents.map((agent) => (
									<StyledTableRow key={agent.Rank}>
										<StyledTableCell align="center">
											<Typography variant="subtitle1" component="h5">
												{agent.Rank ? agent.Rank : '-'}
											</Typography>
										</StyledTableCell>
										<StyledTableCell align="center">
											<Typography variant="subtitle1" component="h5">
												<Link
													href={`mailto:${agent.Email}`}
													underline="always"
													className={classes.agentEmail}
												>
													{agent.Name ? agent.Name : '-'}
												</Link>
											</Typography>
										</StyledTableCell>
										<StyledTableCell align="center">
											<Typography variant="subtitle1" component="h5">
												{agent.Phone ? agent.Phone : '-'}
											</Typography>
										</StyledTableCell>
										<StyledTableCell align="center">
											<Typography variant="subtitle1" component="h5">
												{agent.Broker ? agent.Broker : '-'}
											</Typography>
										</StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</>
			)}
		</>
	);
}
