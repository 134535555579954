import React from 'react';

import Typography from '@material-ui/core/Typography';

import { createTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

export default function Title({ children }) {
	return (
		<ThemeProvider theme={theme}>
			<Typography variant="h5" color="primary" gutterBottom>
				{children}
			</Typography>
		</ThemeProvider>
	);
}
