import React, { useState, useEffect } from 'react';
import {
	Card,
	FormControl,
	MenuItem,
	Select,
	InputLabel,
	CircularProgress,
	Snackbar,
	Grid,
	Backdrop
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import { getActiveAgents, checkUserHasPermissionId } from '../../../../api/serverRequests';
import { useUserState } from '../../../../hook/customerHook';
import Alert from '@material-ui/lab/Alert';
import AddItems from './AddItems';
import DateFilter from './DateFilter';
import { PERMS } from '../../../../helper/perms';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(1),
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between'
	},
	display: { display: 'none' },
	select: { minWidth: 150 },
	icons: {
		display: 'flex',
		padding: 0,
		margin: 0,
		alignItems: 'center'
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative'
	},
	selectProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	}
}));

export default function TopBar({
	toggleEdit,
	edit,
	onLayoutSave,
	agentValue,
	loggedInUser,
	handleDropdownChange,
	handleLoading,
	items,
	onRemoveItem,
	onAddItem,
	originalItems,
	startDate,
	endDate,
	setStartDate,
	setEndDate
}) {
	const classes = useStyles();
	const [agentsMap, setAgentsMap] = useState([]);
	const [agentsLoading, setAgentsLoading] = useState(true);
	const [viewUserDashboardPermission, setViewUserDashboardPermission] = useState(false);

	// permission ids
	const VIEW_OTHER_USERS_DASHBOARD = PERMS.VIEW_OTHER_USERS_DASHBOARD;

	// // submit status updated after user has clicked save in edit modal
	const [submitSuccessStatus, setSubmitSuccessStatus] = useState(''); // keeps track of status when data is posted

	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message

	useEffect(() => {
		if (!viewUserDashboardPermission) return;
		const fetchData = () => {
			return getActiveAgents()
				.then((res) => {
					if (res.status === 200) {
						setAgentsMap(res.data.agents);
						setAgentsLoading(false);
						handleLoading(false);
					} else {
						setSubmitSuccessStatus('error');
						setNotificationState(true);
						setNotificationMessage('Error fetching agents');
						handleLoading(false);
					}
				})
				.catch((err) => {
					setSubmitSuccessStatus('error');
					setNotificationState(true);
					setNotificationMessage('Error fetching agents');
					handleLoading(false);
				});
		};

		if (agentsLoading) {
			fetchData();
		}
	}, [viewUserDashboardPermission]);

	useEffect(() => {
		checkUserHasPermissionId(VIEW_OTHER_USERS_DASHBOARD, loggedInUser)
			.then((res) => {
				if (res.data.success) {
					setViewUserDashboardPermission(true);
				}
			})
			.catch((err) => {
				setViewUserDashboardPermission(false);
			});
	}, []);

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={notificationState}
				autoHideDuration={6000}
				onClose={() => setNotificationState(false)}
			>
				<Alert
					onClose={() => setNotificationState(false)}
					severity={submitSuccessStatus === 'success' ? 'success' : 'error'}
					variant="filled"
				>
					{notificationMessage}
				</Alert>
			</Snackbar>
			<Card className={classes.root}>
				<div className={classes.icons}>
					{viewUserDashboardPermission ? (
						<div className={classes.wrapper}>
							<FormControl variant="outlined">
								<Select
									onChange={handleDropdownChange}
									className={classes.select}
									disabled={agentsLoading}
									value={agentValue}
								>
									{agentsMap &&
										agentsMap.map((agent) => (
											<MenuItem key={agent.uuid} value={agent.uuid}>
												{agent.name}
											</MenuItem>
										))}
								</Select>
							</FormControl>
							{agentsLoading && (
								<CircularProgress size={24} className={classes.selectProgress} />
							)}
						</div>
					) : (
						''
					)}
					<DateFilter
						startDate={startDate}
						endDate={endDate}
						setStartDate={setStartDate}
						setEndDate={setEndDate}
					/>
				</div>
				<div className={classes.icons}>
					<div>
						<IconButton
							aria-label="edit"
							color={edit ? 'secondary' : ''}
							onClick={() => toggleEdit()}
						>
							<EditIcon />
						</IconButton>
					</div>
					<div className={!edit ? classes.display : ''}>
						<AddItems
							items={items}
							onRemoveItem={onRemoveItem}
							onAddItem={onAddItem}
							originalItems={originalItems}
						/>
						<IconButton aria-label="save" onClick={onLayoutSave}>
							<SaveIcon />
						</IconButton>
					</div>
				</div>
			</Card>
		</>
	);
}
