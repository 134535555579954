import React from 'react';
import MapIcon from '@material-ui/icons/Map';
import { IconButton, Button, Grid } from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import blue from '@material-ui/core/colors/blue';
import MapFilter from '../MapFilter';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	button: {
		flex: 1,
		color: blue[900],
		margin: 10,
		justifyContent: 'center',
		alignSelf: 'center',

		'&.MuiButton-root.active': {
			color: '#FF0000',
			borderColor: '#FF0000'
		}
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},
	filterBtn: {
		marginTop: '8px',
		textTransform: 'none',
		letterSpacing: 'normal',
		fontWeight: 'normal'
	}
}));

const DialogTitle = ({ children, onClose, ...other }) => {
	const classes = useStyles();
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
};

export default function MapSearch({
	handleSearch,
	refreshListings,
	rectFilter,
	circleFilter,
	open,
	listings,
	setRestoredFilters,
	restoredFilters,
	setRectFilter,
	setCircleFilter,
	clearMapFilter,
	handleMapSearchSubmit,
	handleClose,
	handleOpen,
	drawingControlEnabled,
	setDrawingControlEnabled
}) {
	const classes = useStyles();

	const filtersLength = () => {
		if (
			(rectFilter && Object.keys(rectFilter).length > 0) ||
			(circleFilter && Object.keys(circleFilter).length > 0)
		) {
			return 1;
		}
		return 0;
	};

	return (
		<React.Fragment>
			<Grid item>
				<Button
					variant="outlined"
					onClick={handleOpen}
					startIcon={<MapIcon />}
					id="mapFilter"
					className={classes.filterBtn}
					style={{
						color:
							(rectFilter || circleFilter) && filtersLength() ? '#02838D' : '#004F2B',
						borderColor:
							(rectFilter || circleFilter) && filtersLength() ? '#02838D' : '#004F2B'
					}}
				>
					Map{' '}
					{(rectFilter || circleFilter) && filtersLength() ? `(${filtersLength()})` : ''}
				</Button>
			</Grid>
			<Dialog
				maxWidth={'md'}
				fullWidth
				open={open}
				onClose={handleClose}
				aria-labelledby="search"
			>
				<DialogTitle id="map-search" onClose={handleClose}>
					{'Filter By Map Area'}
				</DialogTitle>
				<DialogContent dividers>
					<MapFilter
						circleFilter={circleFilter}
						rectFilter={rectFilter}
						onRectFilterUpdate={setRectFilter}
						onCircleFilterUpdate={setCircleFilter}
						restoredFilters={restoredFilters}
						setRestoredFilters={setRestoredFilters}
						listings={listings}
						drawingControlEnabled={drawingControlEnabled}
						setDrawingControlEnabled={setDrawingControlEnabled}
					/>
					<Grid xs={12} container spacing={1}>
						<Grid xs={6} item>
							<Button
								variant="contained"
								color="secondary"
								fullWidth
								onClick={clearMapFilter}
								disabled={rectFilter === null && circleFilter === null}
								style={{ marginTop: '8px' }}
							>
								Clear
							</Button>
						</Grid>
						<Grid xs={6} item>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								fullWidth
								onClick={handleMapSearchSubmit}
								disabled={rectFilter === null && circleFilter === null}
								style={{ marginTop: '8px' }}
							>
								Save
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
