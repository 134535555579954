import React, { useState, useEffect } from 'react';
//Dynamically load components on request for faster page rendering
import loadable from '@loadable/component';
import { makeStyles } from '@material-ui/core/styles';
import { Title, BaseLayout, Loading } from '../../';
import UserFavourites from '../../comps/UserFavourites';

import { searchOrganizationCollection, getFavouritesByUserId } from '../../../api/serverRequests';
import { useUserState } from '../../../hook/customerHook';
import { Redirect } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';

const Grid = loadable(() => import('@material-ui/core/Grid'));
const Box = loadable(() => import('@material-ui/core/Box'));
const Paper = loadable(() => import('@material-ui/core/Paper'));
const Snackbar = loadable(() => import('@material-ui/core/Snackbar'));
const Lists = loadable(() => import('../..'), {
	resolveComponent: (components) => components.Lists
});

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column'
	},
	fixedHeight: {
		height: 120
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	}
}));

export default function Organization() {
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(true);
	const [listings, setListings] = useState();
	const [showSearchResult] = useState(false);
	const [userState, dispatchUser] = useUserState();
	const [userId, setUserId] = useState(null);
	const [selectedId, setSelectedId] = useState(null);

	// // submit status updated after user has clicked save in edit modal
	const [submitSuccessStatus, setSubmitSuccessStatus] = useState(''); // keeps track of status when data is posted

	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message

	useEffect(() => {
		localStorage.setItem('userState', JSON.stringify(userState));
		localStorage.setItem('isLoggedIn', true);
	}, []);

	const handleLoading = (loading) => {
		setIsLoading(loading);
	};

	const handlePeelClick = (userId) => {
		setUserId(userId);
		getFavouritesByUserId(userId)
			.then((res) => {
				console.log(res);
				if (res.data.success) {
					const currListings = {};
					res.data.listings.forEach((listing) => {
						if (!(listing.MLNum in currListings)) currListings[listing.MLNum] = listing;
					});
					setListings(Object.values(currListings));
					console.log(currListings);
				}
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue'
					);
					dispatchUser({
						isLoggedIn: false
					});
					return;
				}
				console.log(err.message);
			});
	};

	useEffect(() => {
		let unmounted = false;
		fetchData(unmounted);
		return () => {
			unmounted = true;
		};
	}, []);

	const fetchData = async (unmounted) => {
		searchOrganizationCollection()
			.then((res) => {
				if (res.status === 200) {
					setListings(res.data.listings);
				}
				if (!unmounted) {
					setIsLoading(false);
				}
			})
			.catch((err) => {
				if (err.response.status === 401) {
					dispatchUser({
						isLoggedIn: false
					});
				} else if (!unmounted) {
					setIsLoading(false);
				} else if (err.response.status === 403) {
					<Redirect to="/403" />;
				}
				console.log(err.message);
			});
	};

	const titles = [
		{ label: 'Image', numeric: false, id: 'Pics' },
		{ label: 'ML #', numeric: false, id: 'ML #' },
		{ label: 'Address', numeric: false, id: 'Address' },
		{ label: 'Area', numeric: false, id: 'Area' },
		{ label: 'SubArea', numeric: false, id: 'S/A' },
		{ label: 'Price', numeric: true, id: 'Price' },
		{ label: 'Total Bedrooms', numeric: true, id: 'Tot BR' },
		{ label: 'Total Bathrooms', numeric: true, id: 'Tot Baths' },
		{ label: 'Kitchens', numeric: true, id: '#Kitchens' },
		{ label: 'Total Floor Area', numeric: true, id: 'TotFlArea' },
		{ label: 'Year Built', numeric: true, id: 'Yr Blt' },
		{ label: 'Frontage (Feet)', numeric: true, id: 'Frontage - Feet' },
		{ label: 'Lot Size', numeric: true, id: 'Lot Sz (Sq.Ft.)' }
	];

	const cells = [
		'MLNum',
		'Address',
		'Area',
		'SA',
		'Price',
		'TotBR',
		'TotBaths',
		'NumKitchens',
		'TotFlArea',
		'YrBlt',
		'FrontageFeet',
		'LotSzSqFt'
	];

	return (
		<BaseLayout>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={notificationState}
				autoHideDuration={6000}
				onClose={() => setNotificationState(false)}
			>
				<Alert
					onClose={() => setNotificationState(false)}
					severity={submitSuccessStatus === 'success' ? 'success' : 'error'}
					variant="filled"
				>
					{notificationMessage}
				</Alert>
			</Snackbar>
			<Paper className={classes.paper}>
				{isLoading ? (
					<Loading />
				) : (
					<>
						<Box item sx={{ mb: 3 }}>
							<UserFavourites
								handlePeelClick={handlePeelClick}
								handleLoading={handleLoading}
								userId={userId}
								selectedId={selectedId}
								setSelectedId={setSelectedId}
							/>
						</Box>

						<Grid container spacing={3}>
							<Grid item xs={12}>
								{listings && listings.length > 0 ? (
									<Lists
										isSearch={false}
										isCollection={!showSearchResult}
										listHeads={titles}
										listCells={cells}
										listings={listings}
										setIsLoading={setIsLoading}
										filters={null}
										title={
											showSearchResult
												? 'Search Result'
												: "My organization's recently added listings"
										}
									/>
								) : (
									<Title>
										{showSearchResult
											? 'No listings found'
											: "You don't have saved listings yet. "}
									</Title>
								)}
							</Grid>
						</Grid>
					</>
				)}
			</Paper>
		</BaseLayout>
	);
}
