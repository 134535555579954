import { useMemo } from 'react';
import { Typography, Box, Table, TableRow } from '@material-ui/core';
import MuiTableCell from '@material-ui/core/TableCell';
import { makeStyles, withStyles } from '@material-ui/styles';
import { currencyParser } from '../../../../helper/parser';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		overflowX: 'auto'
	},
	firstTitle: {
		margin: '0.4rem 0',
		paddingBottom: 0
	},
	title: {
		margin: '2.2rem 0 1rem',
		paddingBottom: 0
	},
	listingDetailsTable: {
		margin: '1rem 2rem 0 0',
		width: 'calc(32vw + 10rem)',
		'@media (max-width: 700px)': {
			width: '100%',
			margin: '0'
		}
	},
	propertyDetailsTable: {
		width: 'calc(32vw + 10rem)',
		margin: '0 2rem 1rem 0',
		'@media (max-width: 700px)': {
			width: '100%',
			margin: '0'
		}
	},
	tableCell: {
		fontSize: '1.1rem',
		fontWeight: 'bold',
		padding: '0.8rem 1rem'
	}
}));

const TableCell = withStyles({
	root: {
		borderBottom: 'none',
		padding: '0.5rem 0'
	}
})(MuiTableCell);

export default function LeadDetailTab({ listing }) {
	const classes = useStyles();

	const propertyDetails = useMemo(
		() => [
			{
				label: 'Assessment',
				value: listing.assessment ? currencyParser(listing.assessment) : '-'
			},
			{ label: 'Bedrooms', value: listing.TotBR },
			{ label: 'Bathrooms', value: listing.TotBaths },
			{ label: 'Kitchens', value: listing.NumKitchens },
			{ label: 'Year Built', value: listing.YrBlt },
			{ label: 'Size (Sqft)', value: listing.TotFlArea },
			{ label: 'Frontage (ft)', value: Math.round(listing.FrontageFeet) },
			{ label: 'LandSqft', value: Math.round(listing.LotSzSqFt) }
		],
		[listing]
	);

	const renderPropertyDetails = () => {
		return propertyDetails.map((detail) => (
			<TableRow key={detail.label}>
				<TableCell component="th" scope="row">
					<Typography className={classes.tableCell} component="h5">
						{detail.label}
					</Typography>
				</TableCell>
				<TableCell align="left">
					<Typography className={classes.tableCell} component="h5">
						{detail.value}
					</Typography>
				</TableCell>
			</TableRow>
		));
	};

	return (
		<Box className={classes.root}>
			<Box style={{ overflowX: 'auto' }}>
				<Table className={classes.propertyDetailsTable}>{renderPropertyDetails()}</Table>
			</Box>
		</Box>
	);
}
