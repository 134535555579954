import ReactGA from 'react-ga4';
import { useUserState } from './customerHook';

const useAnalyticsEventTracker = (category = 'Unspecified') => {
	const [userState, dispatchUser] = useUserState();

	const eventTracker = (action = 'test action', label = 'test label') => {
		if (userState.user) {
			ReactGA.set({ userId: userState.user.user_id });
		}
		ReactGA.event({
			category,
			action,
			label
		});
	};
	return eventTracker;
};
export default useAnalyticsEventTracker;
