import React, { useState, useMemo } from 'react';

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
	TableCell,
	TableRow,
	Table,
	TableHead,
	TableBody,
	useMediaQuery
} from '@material-ui/core/';
import { makeStyles, useTheme, styled } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		//
	},
	viewDimensions: {
		color: theme.palette.grey['KN_grey'],
		paddingTop: 5,
		marginLeft: 40,
		textDecoration: 'underline',

		'&:hover': {
			cursor: 'pointer'
		}
	}
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0
	}
}));

const StyledTableRowHead = styled(TableRow)(({ theme }) => ({
	backgroundColor: theme.palette.primary.main
}));
const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
	color: '#fff'
}));

function roomDimensions(listing) {
	let roomNumber = 1;
	const rooms = [];
	while (listing[`Room${roomNumber}Type`]) {
		const dim1 = listing[`Room${roomNumber}Dimension1`];
		const dim2 = listing[`Room${roomNumber}Dimension2`];
		rooms.push({
			room: roomNumber,
			type: listing[`Room${roomNumber}Type`],
			dimension: `${dim1} x ${dim2}`,
			level: listing[`Room${roomNumber}Level`]
		});
		roomNumber++;
	}
	return rooms;
}

export default function ViewDimensions({ listing }) {
	const theme = useTheme();
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

	const rooms = useMemo(() => roomDimensions(listing), [listing]);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<Typography
				variant="subtitle2"
				onClick={handleClickOpen}
				className={classes.viewDimensions}
			>
				View Dimensions
			</Typography>
			<Dialog
				fullWidth={true}
				maxWidth={'md'}
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				aria-labelledby="Dimensions"
			>
				<DialogTitle id="dimensions-title">Property Dimensions</DialogTitle>
				<DialogContent>
					<Table sx={{ minWidth: 650 }} aria-label="dimensions table">
						<TableHead>
							<StyledTableRowHead>
								<StyledTableCellHead align="center">Room</StyledTableCellHead>
								<StyledTableCellHead align="center">Type</StyledTableCellHead>
								<StyledTableCellHead align="center">Dimension</StyledTableCellHead>
								<StyledTableCellHead align="center">Level</StyledTableCellHead>
							</StyledTableRowHead>
						</TableHead>
						<TableBody>
							{rooms.map((room, idx) => (
								<StyledTableRow
									key={idx}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									{Object.values(room).map((value, idx) => (
										<TableCell align="center" key={idx}>
											{value}
										</TableCell>
									))}
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleClose} color="primary">
						CLOSE
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
