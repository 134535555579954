import 'amazon-connect-streams';
import { useHistory } from 'react-router-dom';
import { useUserState } from '../../../hook/customerHook';
import React, { memo, useRef, useEffect } from 'react';
import { genLogger } from './lib';
import { updateContactAttribute } from '../../../api/serverRequests';

const name = 'ConnectCCP';
const { log, error } = genLogger(name);
const connect = window.connect;
const ConnectCCP = (object) => {
	const [userState] = useUserState();
	const ref = useRef();
	const validNumber = object.phoneNumber.num;
	const homeId = object.phoneNumber.id ? object.phoneNumber.id : '';
	const history = useHistory();

	useEffect(() => {
		try {
			if (!userState.user.instanceAlias) throw new Error('missing alias');
			if (typeof window === 'undefined') throw new Error('window missing');
			if (typeof window.connect === 'undefined') throw new Error('global connect missing');
			log('init start');
			window.connect.core.initCCP(ref.current, {
				ccpUrl: `https://${userState.user.instanceAlias}.my.connect.aws/connect/ccp-v2`,
				loginPopup: true,
				loginPopupAutoClose: true,
				softphone: {
					allowFramedSoftphone: true
				},
				pageOptions: {
					//optional
					enableAudioDeviceSettings: true
				}
			});

			const eventBus = connect.core.getEventBus();
			eventBus.subscribe(connect.EventType.TERMINATED, () => {
				console.log('Logged out');
				// Do stuff...
			});

			initContact();

			log('init end');
			if (typeof window.connect !== 'undefined' && validNumber) dial_number(validNumber);
		} catch (e) {
			error(e);
			object.setNotificationMessage('There was an error initializing the panel.');
			object.setNotificationState(true);
			object.setStatusClass('error');
		}
	}, []);
	log('render');

	function dial_number(phone) {
		let endpoint = connect.Endpoint.byPhoneNumber(phone);
		connect.agent(function (agent) {
			agent.connect(endpoint, {
				success: function () {
					console.log('outbound call connected');
				},
				failure: function (err) {
					console.log('outbound call connection failed');
					console.log(err);
				}
			});
		});
	}

	function initContact() {
		connect.contact((contact) => {
			contact.onConnected((contact) => {
				const currentContactId = contact.contactId;
				const contactAttributes = contact.getAttributes();

				console.log('contact');
				console.log(contact);

				const update = async (agent) => {
					updateContactAttribute({
						agentName: agent.name,
						contactId: currentContactId,
						homeId: homeId,
						phoneNumber: validNumber
					})
						.then((res) => {
							if (res.status === 200) {
								console.log(res.data);
							}
						})
						.catch((err) => {
							console.log(err);
							console.log(err.message);
						});
				};
				if (homeId) {
					connect.agent(function (agent) {
						var config = agent.getConfiguration();
						update(config);
					});
				}
			});

			contact.onDestroy((contact) => {
				console.log('contact end');
				history.push('/user/callcenter');
			});
		});
	}

	return (
		<div
			id="container-div"
			ref={ref}
			style={{ width: '100%', height: '100%', minHeight: 600, minWidth: 400 }}
			// style={{ minWidth: 400, minHeight: 480 }}
		></div>
	);
};

export default memo(ConnectCCP);
