import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Lists, Title, BaseLayout, ErrorMsg, Loading } from '../../';
import { useUserState } from '../../../hook/customerHook';
import { searchMyCollection } from '../../../api/serverRequests';
import Alert from '@material-ui/lab/Alert';
import { Box, Snackbar, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column'
	},
	fixedHeight: {
		height: 120
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	containerNoHomeMsg: {
		display: 'flex',
		justifyContent: 'center',
		textAlign: 'center',
		margin: '3rem',
		height: '100%'
	},
	noHomes: {
		color: theme.palette.grey['KN_light_grey'],
		textAlign: 'center',
		lineHeight: 2
	}
}));

export default function MyCollection() {
	const classes = useStyles();
	const [listings, setListings] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [errMsg, setErrMsg] = useState('');
	const [, dispatchUser] = useUserState();
	// // submit status updated after user has clicked save in edit modal
	const [submitSuccessStatus, setSubmitSuccessStatus] = useState(''); // keeps track of status when data is posted

	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message

	const fetchData = async (unmounted) => {
		searchMyCollection()
			.then((res) => {
				if (res.status === 200) {
					setListings(res.data.listings);
					setIsLoading(false);
				}
				if (!unmounted) {
					setIsLoading(false);
				}
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue'
					);
					dispatchUser({
						isLoggedIn: false
					});
				} else if (!unmounted) {
					setIsLoading(false);
					setErrMsg('Error while loading listing!');
				}
				console.log(err.message);
			});
	};

	useEffect(() => {
		let unmounted = false;
		fetchData(unmounted);
		return () => {
			unmounted = true;
		};
	}, []);

	const titles = [
		{ label: 'Image', numeric: false, id: 'Pics' },
		{ label: 'ML #', numeric: false, id: 'ML #' },
		{ label: 'Address', numeric: false, id: 'Address' },
		{ label: 'Area', numeric: false, id: 'Area' },
		{ label: 'SubArea', numeric: false, id: 'S/A' },
		{ label: 'Price', numeric: true, id: 'Price' },
		{ label: 'Total Bedrooms', numeric: true, id: 'Tot BR' },
		{ label: 'Total Bathrooms', numeric: true, id: 'Tot Baths' },
		{ label: 'Kitchens', numeric: true, id: '#Kitchens' },
		{ label: 'Total Floor Area', numeric: true, id: 'TotFlArea' },
		{ label: 'Year Built', numeric: true, id: 'Yr Blt' },
		{ label: 'Frontage (Feet)', numeric: true, id: 'Frontage - Feet' },
		{ label: 'Lot Size', numeric: true, id: 'Lot Sz (Sq.Ft.)' }
	];

	const cells = [
		'MLNum',
		'Address',
		'Area',
		'SA',
		'Price',
		'TotBR',
		'TotBaths',
		'NumKitchens',
		'TotFlArea',
		'YrBlt',
		'FrontageFeet',
		'LotSzSqFt'
	];

	return (
		<BaseLayout>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={notificationState}
				autoHideDuration={6000}
				onClose={() => setNotificationState(false)}
			>
				<Alert
					onClose={() => setNotificationState(false)}
					severity={submitSuccessStatus === 'success' ? 'success' : 'error'}
					variant="filled"
				>
					{notificationMessage}
				</Alert>
			</Snackbar>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper className={classes.paper}>
						{isLoading ? (
							<Loading />
						) : errMsg ? (
							<ErrorMsg text={errMsg} />
						) : listings && listings.length > 0 ? (
							<Lists
								isSearch={false}
								listHeads={titles}
								listCells={cells}
								listings={listings}
								setIsLoading={setIsLoading}
								title="My Favourite Homes"
								filters={null}
							/>
						) : (
							<Box className={classes.containerNoHomeMsg}>
								<Typography variant="h4" component="h4" className={classes.noHomes}>
									Huh, your favorites page seems a bit empty. <br /> Why don't you
									go check out some properties!
								</Typography>
							</Box>
						)}
					</Paper>
				</Grid>
			</Grid>
		</BaseLayout>
	);
}
