import React, { useEffect, useState } from 'react';
import Alert from '@material-ui/lab/Alert';

import {
	Grid,
	Card,
	Box,
	CardContent,
	CircularProgress,
	Typography,
	makeStyles,
	Snackbar
} from '@material-ui/core';

import { getUserAssignedTasks } from '../../../../api/serverRequests';

import TaskBoardCardModified from './TaskBoardCardModified';

const useStyles = makeStyles((theme) => ({
	icon: { width: '100%', height: '100%' },
	container: {
		display: 'flex',
		height: '20vh'
	},
	spinner: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' },
	root: {
		minWidth: 275
	}
}));

export default function NewTasks(props) {
	const { curAgent, startDate, endDate } = props;
	const classes = useStyles();
	const [board, setBoardData] = useState(null);
	const [isLoading, setLoading] = useState(true);
	// // submit status updated after user has clicked save in edit modal
	const [submitSuccessStatus, setSubmitSuccessStatus] = useState(''); // keeps track of status when data is posted

	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message

	useEffect(() => {
		setLoading(true);
		getUserAssignedTasks(curAgent, startDate, endDate)
			.then((res) => {
				if (res.data.success) {
					setBoardData(res.data.task_data.cards);
					//console.log(res.data.task_data.cards);
					setLoading(false);
				}
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				setNotificationState(true);
				setNotificationMessage('Error loading tasks');
				setLoading(false);
			});
	}, [curAgent, startDate, endDate]);

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={notificationState}
				autoHideDuration={6000}
				onClose={() => setNotificationState(false)}
			>
				<Alert
					onClose={() => setNotificationState(false)}
					severity={submitSuccessStatus === 'success' ? 'success' : 'error'}
					variant="filled"
				>
					{notificationMessage}
				</Alert>
			</Snackbar>
			<Grid container spacing={1} className={classes.container}>
				<Grid item xs={12}>
					<Typography variant="h4">Newly Assigned Tasks</Typography>
				</Grid>
				{/* TODO Read off tasks table instead */}
				{isLoading ? (
					<Grid item xs={12} className={classes.spinner}>
						<CircularProgress />
					</Grid>
				) : board && board.length > 0 ? (
					board
						.sort((a, b) => a.statusUpdatedAt - b.statusUpdatedAt)
						.map((card, cardIndex) => {
							return (
								<Grid item xs={12}>
									<TaskBoardCardModified
										data={card}
										index={cardIndex}
										colEditMode={false}
										isEnqueued={true}
									/>
								</Grid>
							);
						})
				) : (
					<Grid item xs={12} className={classes.spinner}>
						<Typography variant="h4">No assigned tasks to show.</Typography>
					</Grid>
				)}
			</Grid>
		</>
	);
}
