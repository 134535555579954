import React, { useState, useEffect, useLayoutEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import {
	Box,
	IconButton,
	Typography,
	Checkbox,
	Dialog,
	DialogContent,
	Grid,
	DialogActions,
	Button,
	Snackbar,
	MenuItem,
	Menu,
	ListItemIcon,
	CircularProgress
} from '@material-ui/core';
import TagForm from '../../DetailsPageTabs/CustomTab/EditPopUp/FormFields/TagForm';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'; // outline heart icon
import FavoriteIcon from '@material-ui/icons/Favorite'; // filled heart icon
import MailOutlineIcon from '@material-ui/icons/MailOutline'; // outline mail icon
import MailIcon from '@material-ui/icons/Mail'; // filled mail icon
import EditIcon from '@material-ui/icons/Edit'; // filled mail icon
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import DirectionsIcon from '@material-ui/icons/Directions';
import CancelIcon from '@material-ui/icons/Cancel';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import blue from '@material-ui/core/colors/blue';
import CloseIcon from '@material-ui/icons/Close';
import CommentForm from '../../DetailsPageTabs/CustomTab/EditPopUp/FormFields/CommentForm';
import Alert from '@material-ui/lab/Alert';

import { useUserState } from '../../../../hook/customerHook';
import {
	saveSelection,
	deleteFromCollection,
	searchMyCollection,
	submitPropertyCustomData,
	getTagsByOrganization,
	getCommentsAndTags
} from '../../../../api/serverRequests';

const useStyles = makeStyles((theme) => ({
	iconConatiner: {},
	favouriteContainer: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 5,
		[theme.breakpoints.up('sm')]: {
			paddingRight: 30
		}
	},
	emailContainer: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 5,
		[theme.breakpoints.up('sm')]: {
			paddingRight: 30
		}
	},
	icon: {
		color: theme.palette.grey['KN_grey'],
		padding: 5
	},
	favouriteIconFilled: {
		color: theme.palette.red['KN_accent']
	},
	mailIconFilled: {
		color: theme.palette.green['KN_mint']
	},
	customDialog: {
		maxWidth: '600px',
		width: '100%'
	},
	dialogContent: {
		width: '100%',
		height: '165px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-end',
		marginBottom: '14px',
		justifyContent: 'center',
		alignItems: 'center'
	},
	spacingAbove: {
		marginTop: 13,
		marginBottom: 15
	}
}));

// styling for dialogTitle
const styles = (theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	button: {
		flex: 1,
		color: blue[900],
		margin: 10,
		justifyContent: 'center',
		alignSelf: 'center'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
});

const DialogTitle = withStyles(styles)(({ children, classes, onClose, ...other }) => {
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

export default function SideIcons({
	listing,
	setIsLoading,
	setShouldRefetch,
	shouldRefetch,
	addCommentsVisible,
	tagsVisible,
	activeStep,
	setActiveStep,
	currentPropertyIndex,
	totalNumProperties,
	setCurrentPropertyIndex,
	resetPermissions,
	isLoadingSecondary,
	setIsLoadingSecondary
}) {
	const classes = useStyles();
	const [userState, dispatchUser] = useUserState();
	const location = useLocation();
	const history = useHistory();
	const [favouriteIcon, setFavouriteIcon] = useState(false);
	const [notesPopup, setNotesPopup] = useState(false);
	const [tagsPopup, setTagsPopup] = useState(false);
	const [formSubmitObject, setFormSubmitObject] = useState({});

	const [showIconText, setShowIconText] = useState(true);
	// // submit status updated after user has clicked save in edit modal
	const [submitSuccessStatus, setSubmitSuccessStatus] = useState(''); // keeps track of status when data is posted

	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message
	const [secondaryInfo, setSecondaryInfo] = useState({});
	const [anchorEl, setAnchorEl] = useState(null);
	const [tagsOptions, setTagsOptions] = useState([]);
	const [tags, setTags] = useState([]);
	const { HomeId, MLNum } = listing;
	// because of the way it is in the backend, we have to add '__' between mlnum and homeid
	const listings = [MLNum + '__' + HomeId];
	const handleFavouriteIconClick = async () => {
		setFavouriteIcon(!favouriteIcon);
		handleSave(listings);
	};

	const fetchData = async (unmounted) => {
		searchMyCollection()
			.then((res) => {
				if (res.status === 200) {
					const filteredListing = res.data.listings.filter(
						(listing) => listing.HomeId === HomeId && listing.MLNum === MLNum
					);
					if (filteredListing.length > 0) setFavouriteIcon(!favouriteIcon);
					setIsLoading(false);
				}
				if (!unmounted) {
					setIsLoading(false);
				}
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue'
					);
					dispatchUser({
						isLoggedIn: false
					});
				} else if (!unmounted) {
					setIsLoading(false);
				}
				console.log(err.message);
			});
	};

	// fetch favorites from collection
	useEffect(() => {
		let unmounted = false;
		fetchData(unmounted);
		return () => {
			unmounted = true;
		};
	}, []);

	useEffect(() => {
		// add necessary info for post request on render
		addDataToFormObject('mlNum', MLNum);
		addDataToFormObject('homeId', HomeId);
	}, [HomeId, MLNum]);

	// useEffect to determine if we should display 'save' and 'email' beside the icons
	useEffect(() => {
		function shouldShowIconText() {
			const screenWidth = window.innerWidth;

			if (screenWidth < 550) setShowIconText(false);
			else setShowIconText(true);
		}

		shouldShowIconText();
		window.addEventListener('resize', shouldShowIconText);

		return () => {
			window.removeEventListener('resize', shouldShowIconText);
		};
	}, []);

	const handleSave = (listings) => {
		if (!favouriteIcon) {
			const data = {
				userId: userState.user.user_id,
				organizationId: userState.user.organization,
				listings: listings
			};
			console.log(JSON.stringify(data));
			saveSelection(data)
				.then((res) => {
					if (res.status == 200) {
					}
				})
				.catch((err) => {
					setSubmitSuccessStatus('error');
					if (err.response.status === 401) {
						setNotificationState(true);
						setNotificationMessage(
							'Your connection is time out. Please log in again to continue'
						);
						dispatchUser({
							isLoggedIn: false
						});
					}
					console.log(err.message);
				});
		} else {
			deleteFromCollection({ listings });
		}
	};

	const handleEditPopUpClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopupClose = () => {
		setFormSubmitObject(clearKeys(formSubmitObject));
		setNotesPopup(false);
		setTagsPopup(false);
	};

	const addDataToFormObject = (key, value) => {
		// everytime user clicks save we save all data
		// edit modal to state
		setFormSubmitObject((prevObject) => {
			return { ...prevObject, [key]: value };
		});
	};

	const clearKeys = (obj) => {
		// this function clears the form field if the user closes or saves changes
		// the desired keys are kept, so that the edit modal still works
		const desiredKeysToKeep = ['mlNum', 'homeId', 'homeAddress', 'city'];

		return Object.fromEntries(
			Object.entries(obj).filter(([key, value]) => desiredKeysToKeep.includes(key))
		);
	};

	const handleSaveSubmit = () => {
		const expectedKeys = ['mlNum', 'homeId', 'homeAddress', 'city']; // array of keys in the formdata field by default

		//check if no new keys are added (i.e. user has not made any changed to the form field)
		if (Object.keys(formSubmitObject).every((key) => expectedKeys.includes(key))) {
			handlePopupClose(); //close modal
			return;
		}

		submitPropertyCustomData(formSubmitObject)
			.then((res) => {
				if (res.data.success) {
					setShouldRefetch(!shouldRefetch);
					handlePopupClose(); //close modal
				}
			})
			.catch((err) => {
				if (err.response.status === 401) {
					dispatchUser({
						isLoggedIn: false
					});
					return;
				}
				setFormSubmitObject(clearKeys(formSubmitObject));
				handlePopupClose(); // close modal
			});
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		const { Tags = [] } = secondaryInfo || {};

		setTags(Tags);
	}, []);

	useEffect(() => {
		// we need to fetch the tag options

		let unmounted = false;
		const fetchData = async () => {
			getTagsByOrganization()
				.then((res) => {
					if (res.status === 200) {
						setTagsOptions(res.data.tags);
					}
				})
				.catch((err) => {
					if (err.response.status === 401) {
						dispatchUser({
							isLoggedIn: false
						});
						return;
					}
					console.log(err.message);
				});
		};
		fetchData();
		return () => {
			unmounted = true;
		};
	}, []);

	useLayoutEffect(() => {
		// Here we fetch the 'secondary info' (the tags and comments)
		// results are stored in secondaryInfo, then passed as a prop to <CustomTab />
		let unmounted = false;
		const fetchData = async () => {
			if (!unmounted) {
				getCommentsAndTags(listing.HomeId)
					.then((res) => {
						if (res.status === 200) {
							setSecondaryInfo(res.data.secondaryInfo);
							setIsLoadingSecondary(false);
						}
						if (!unmounted) {
							setIsLoadingSecondary(false);
							// setPermissionChecked(true);
						}
					})
					.catch((err) => {
						if (err.response.status === 401) {
							dispatchUser({
								isLoggedIn: false
							});
							return;
						} else if (!unmounted) {
							setIsLoadingSecondary(false);
						}
						console.error(err.message);
					});
			}
		};
		if (addCommentsVisible || tagsVisible) fetchData();

		return () => {
			unmounted = true;
		};
	}, [shouldRefetch, addCommentsVisible, tagsVisible]);

	const saveToLocalStorage = (key, data) => {
		localStorage.setItem(key, JSON.stringify(data));
	};

	const handleRouteCompletion = () => {
		setAnchorEl(null);
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		if (currentPropertyIndex < totalNumProperties - 1) {
			setCurrentPropertyIndex((prevPropertyIndex) => prevPropertyIndex + 1);
			resetPermissions();
		}
		saveToLocalStorage(location.pathname.split('/')[4], {
			activeStep: activeStep + 1,
			currentPropertyIndex: currentPropertyIndex + 1
		});
	};

	const handleRouteIncompletion = () => {
		setAnchorEl(null);
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
		if (currentPropertyIndex > 0) {
			setCurrentPropertyIndex((prevPropertyIndex) => prevPropertyIndex - 1);
			resetPermissions();
		}
		saveToLocalStorage(location.pathname.split('/')[4], {
			activeStep: activeStep - 1,
			currentPropertyIndex: currentPropertyIndex - 1
		});
	};

	const handleReturnToRoutes = () => {
		setAnchorEl(null);
		history.push('/user/routes');
	};

	const handleDirection = () => {
		setAnchorEl(null);
		const address =
			userState.routingList[userState.currentRouteId].listings[
				userState.routingList[userState.currentRouteId].listings.findIndex(
					(route) => route.MLNum === location.pathname.split('/')[3]
				)
			].Address;
		const city =
			userState.routingList[userState.currentRouteId].listings[
				userState.routingList[userState.currentRouteId].listings.findIndex(
					(route) => route.MLNum === location.pathname.split('/')[3]
				)
			].City;
		const destination = address + ', ' + city;

		const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
			destination
		)}`;

		window.open(url, '_blank');
	};

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={notificationState}
				autoHideDuration={6000}
				onClose={() => setNotificationState(false)}
			>
				<Alert
					onClose={() => setNotificationState(false)}
					severity={submitSuccessStatus === 'success' ? 'success' : 'error'}
					variant="filled"
				>
					{notificationMessage}
				</Alert>
			</Snackbar>
			<div>
				{anchorEl && (
					<Menu
						id="actions-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						{isLoadingSecondary ? (
							<MenuItem>
								<CircularProgress size={24} />
							</MenuItem>
						) : (
							<>
								{tagsVisible && (
									<MenuItem
										onClick={() => {
											setTagsPopup(true);
											setAnchorEl(null);
										}}
									>
										<ListItemIcon>
											<LocalOfferIcon />
										</ListItemIcon>
										Add Tags
									</MenuItem>
								)}
								{addCommentsVisible && (
									<MenuItem
										onClick={() => {
											setNotesPopup(true);
											setAnchorEl(null);
										}}
									>
										<ListItemIcon>
											<MailIcon />
										</ListItemIcon>
										Notes
									</MenuItem>
								)}
							</>
						)}
						{userState.routingList && (
							<MenuItem onClick={handleDirection}>
								<ListItemIcon>
									<DirectionsIcon />
								</ListItemIcon>
								Directions
							</MenuItem>
						)}
						{userState.routingList &&
							activeStep !==
								userState.routingList[userState.currentRouteId].listings.length -
									1 && (
								<MenuItem onClick={handleRouteCompletion}>
									<ListItemIcon>
										<CheckCircleIcon />
									</ListItemIcon>
									Mark as Completed
								</MenuItem>
							)}
						{userState.routingList &&
							activeStep ===
								userState.routingList[userState.currentRouteId].listings.length -
									1 && (
								<MenuItem onClick={handleReturnToRoutes}>
									<ListItemIcon>
										<CheckCircleIcon />
									</ListItemIcon>
									Return to Saved Routes
								</MenuItem>
							)}
						{userState.routingList && activeStep !== 0 && (
							<MenuItem onClick={handleRouteIncompletion}>
								<ListItemIcon>
									<CancelIcon />
								</ListItemIcon>
								Mark as Incomplete
							</MenuItem>
						)}
					</Menu>
				)}
				{notesPopup && (
					<Dialog
						open={true}
						onClose={handlePopupClose}
						PaperProps={{ classes: { root: classes.customDialog } }}
					>
						<DialogTitle>Add comment</DialogTitle>
						<DialogContent className={classes.dialogContent}>
							<Grid container spacing={2}>
								<Grid item xs={12} className={classes.spacingAbove}>
									<CommentForm addDataToFormObject={addDataToFormObject} />
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Grid container justifyContent="flex-end">
								<Grid item>
									<Button
										onClick={() => {
											handlePopupClose();
										}}
										color="primary"
										size="large"
									>
										CANCEL
									</Button>
								</Grid>
								<Grid item>
									<Button onClick={handleSaveSubmit} color="primary" size="large">
										SAVE
									</Button>
								</Grid>
							</Grid>
						</DialogActions>
					</Dialog>
				)}
				{tagsPopup && (
					<Dialog
						open={true}
						onClose={handlePopupClose}
						PaperProps={{ classes: { root: classes.customDialog } }}
					>
						<DialogTitle>Custom Tags</DialogTitle>
						<DialogContent className={classes.dialogContent}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TagForm
										formSubmitObject={formSubmitObject}
										addDataToFormObject={addDataToFormObject}
										tags={tags} // these are tags that are already assigned on the property
										tagsOptions={tagsOptions}
									/>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Grid container justifyContent="flex-end">
								<Grid item>
									<Button
										onClick={() => {
											handlePopupClose();
										}}
										color="primary"
										size="large"
									>
										CANCEL
									</Button>
								</Grid>
								<Grid item>
									<Button onClick={handleSaveSubmit} color="primary" size="large">
										SAVE
									</Button>
								</Grid>
							</Grid>
						</DialogActions>
					</Dialog>
				)}
				<Box className={classes.iconConatiner}>
					<Box className={classes.favouriteContainer}>
						<IconButton
							aria-label="Favourite"
							className={classes.icon}
							onClick={handleFavouriteIconClick}
						>
							{favouriteIcon ? (
								<FavoriteIcon className={classes.favouriteIconFilled} />
							) : (
								<FavoriteBorderIcon />
							)}
						</IconButton>
						{showIconText && (
							<Typography variant="subtitle1" component="h4">
								Save
							</Typography>
						)}
					</Box>
					{(tagsVisible || addCommentsVisible || userState.routingList) && (
						<Box className={classes.emailContainer}>
							<IconButton
								aria-label="email"
								className={classes.icon}
								onClick={handleEditPopUpClick}
							>
								<EditIcon />
							</IconButton>
							{showIconText && (
								<Typography variant="subtitle1" component="h4">
									Edit
								</Typography>
							)}
						</Box>
					)}
				</Box>
			</div>
		</>
	);
}
