import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { setFirebaseEnvironmentVariables } from './firebaseConfig';

// get env variables
const { firebaseConfig, vapidKey } = setFirebaseEnvironmentVariables();

// initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging();

// request the user's token to recieve pusn notifications
export const requestForToken = async () => {
	return getToken(messaging, {
		vapidKey: vapidKey
	})
		.then((currentToken) => {
			if (currentToken) {
				// console.log(`Firebase generated FCM token ${currentToken}`);
				return currentToken;
			} else {
				console.error('Firebase: an error occured while creating a FCM for this client');
			}
		})
		.catch((err) => {
			console.log('Firebase: An error occurred while retrieving token. ', err);
		});
};

// create a listener to listen for incoming messages
export const onMessageListener = () =>
	new Promise((resolve) => {
		onMessage(messaging, (payload) => {
			resolve(payload);
			console.log('FIREBASE: ' + payload);
		});
	});
