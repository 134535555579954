import React from 'react';
import { CircularProgress, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: '60vh'
	}
}));

export default function Loading() {
	const classes = useStyles();
	return (
		<Box id="temp" className={classes.container}>
			<CircularProgress size={50} color="primary" className={classes.spinner} />
		</Box>
	);
}
