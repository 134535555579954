import React, { useState, useEffect } from 'react';
//Dynamically load components on request for faster page rendering
import loadable from '@loadable/component';
import { BaseLayout } from '../../..';
import {
	updateTag,
	deleteTags,
	postTags,
	getTagsByOrganization
} from '../../../../api/serverRequests';
import { useUserState } from '../../../../hook/customerHook';
import { makeStyles } from '@material-ui/core/styles';
import './tags.css';

import { Grid, CircularProgress } from '@material-ui/core';
const TagTable = loadable(() => import('../../Tag/TagTable'));

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2, 0, 2)
	}
}));

const styles = {
	progress: { display: 'flex', justifyContent: 'center' }
};

const TagTab = () => {
	const classes = useStyles();
	const [tags, setTags] = useState([]);
	const [, dispatchUser] = useUserState();
	const [loading, setLoading] = useState(true);
	const [errMsg, setErrMsg] = useState('');
	useEffect(() => {
		setLoading(true);
		fetch();
	}, []);

	const SORTERS = {
		NUMBER_ASCENDING: (mapper) => (a, b) => mapper(a) - mapper(b),
		NUMBER_DESCENDING: (mapper) => (a, b) => mapper(b) - mapper(a),
		STRING_ASCENDING: (mapper) => (a, b) => mapper(a).localeCompare(mapper(b)),
		STRING_DESCENDING: (mapper) => (a, b) => mapper(b).localeCompare(mapper(a))
	};

	const getSorter = (data) => {
		const mapper = (x) => x[data.field];
		let sorter = SORTERS.STRING_ASCENDING(mapper);

		if (data.field === 'id' || data.field === 'Id') {
			sorter =
				data.direction === 'ascending'
					? SORTERS.NUMBER_ASCENDING(mapper)
					: SORTERS.NUMBER_DESCENDING(mapper);
		} else if (data.field === 'Tag') {
			sorter =
				data.direction === 'ascending'
					? SORTERS.STRING_ASCENDING(mapper)
					: SORTERS.STRING_DESCENDING(mapper);
		}

		return sorter;
	};

	const fetch = async () => {
		return getTagsByOrganization()
			.then((res) => {
				if (res.status === 200) {
					setTags(res.data.tags);
					setLoading(false);
				}
			})
			.catch((err) => {
				if (err.response.status === 401) {
					alert('Your connection is timed out. Please log in again to continue');
					dispatchUser({
						isLoggedIn: false
					});
					return;
				}
				setLoading(false);
			});
	};

	const addtoTag = async (data) => {
		postTags(data)
			.then((res) => {
				if (res.status === 200) {
					setErrMsg('');
					setLoading(true);
					fetch();
				}
			})
			.catch((err) => {
				console.log(err.message);
			});
	};

	const deleteTheTags = async (data) => {
		deleteTags(data)
			.then((res) => {
				if (res.status === 200) {
					setErrMsg('');
					setLoading(true);
					fetch();
				}
			})
			.catch((err) => {
				console.log(err.message);
				setErrMsg(
					'Can\'t delete the tag "' +
						data.tagName[0] +
						'" as it is assigned to one or more listings.'
				);
			});
	};

	const UpdateTheTags = async (id, head) => {
		updateTag(id, head)
			.then((res) => {
				if (res.status === 200) {
					setLoading(true);
					fetch();
				}
			})
			.catch((err) => {
				console.log(JSON.stringify(id));
				console.log(JSON.stringify(head));
				console.log(err.message);
			});
	};

	const service = {
		fetchItems: async (payload) => {
			let result = Array.from(tags);
			result = result.sort(getSorter(payload.sort));
			return Promise.resolve(result);
		},
		create: async (task) => {
			const tetst = { tags: [task.Tag] };
			addtoTag(tetst);
			return Promise.resolve(task);
		},
		update: async (data) => {
			const head = { tag: [data.Tag] };
			const id = data.Id;
			UpdateTheTags(id, head);
			return Promise.resolve(data);
		},
		delete: async (data) => {
			const test = { tags: [data.Id], tagName: [data.Tag] };
			deleteTheTags(test);
			return Promise.resolve(data);
		}
	};

	return (
		<>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				{!loading ? (
					<TagTable tags={tags} service={service} errMsg={errMsg} />
				) : (
					<div style={styles.progress}>
						<CircularProgress />
					</div>
				)}
			</Grid>
		</>
	);
};

export default TagTab;
