import { useEffect, useState, createRef } from 'react';
import PropTypes from 'prop-types';
import { getCallRecordings, getJSON } from '../../../api/serverRequests';
import TablePaginationActions from './TablePaginationActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// mui //
import { makeStyles } from '@material-ui/core/styles';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableFooter,
	Typography,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	CircularProgress,
	IconButton,
	Box,
	Grid,
	List,
	ListItem,
	ListItemText,
	Collapse
} from '@material-ui/core';
import { Title } from '../..';
import { displayHumanReadableDate, displayHumanReadableClock } from '../../../helper/parser';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import moment from 'moment';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useStyles = makeStyles((theme) => ({
	hyperLink: { color: 'rgba(54, 59, 66, 1)', fontWeight: 'bold' },
	otherListing: {
		padding: theme.spacing(3, 5),
		width: '100%',
		height: '100%'
	},
	root: {
		'& > *': {
			borderBottom: 'unset'
		}
	},
	transcript: { width: '50%', overflow: 'auto', maxHeight: 300 },
	customer: { padding: '5px', backgroundColor: '#f5f5f5', boxShadow: 'none', cursor: 'pointer' },
	agent: {
		padding: '5px',
		cursor: 'pointer'
	},
	inline: {
		display: 'inline'
	},
	spinner: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		paddingBottom: '10px'
	}
}));

const columns = [
	{ id: 'AgentName', label: 'Agent Caller', maxWidth: 70, align: 'center' },
	{ id: 'CreatedAt', label: 'Date', maxWidth: 70, align: 'center' },
	{ id: 'RecordingURL', label: 'Replay', maxWidth: 70, align: 'center' }
];

function timerFormat(ms) {
	const d = new Date(Date.UTC(0, 0, 0, 0, 0, 0, ms)),
		// Pull out parts of interest
		parts = [d.getUTCMinutes(), d.getUTCSeconds()];
	// Zero-pad
	return parts.map((s) => String(s).padStart(2, '0')).join(':');
}

function Row(props) {
	const { row } = props;
	const [open, setOpen] = useState(false);
	const classes = useStyles();
	const [transcript, setTranscript] = useState(null);
	const [showReadableDate, setShowReadableDate] = useState(false);
	const audio = createRef();

	useEffect(() => {
		if (open) {
			const getTranscript = async (url) => {
				try {
					getJSON(url).then((res) => {
						if (res.status === 200) {
							setTranscript(res.data.Transcript);
						}
					});
				} catch (err) {
					console.error(err);
				}
			};
			getTranscript(row.TranscriptURL);
		}
	}, [open]);

	const audioTimestamp = (time) => {
		try {
			let duration = moment.duration(time, 'milliseconds');
			audio.current.audio.current.currentTime = duration.asSeconds();
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<>
			<TableRow className={classes.root}>
				<TableCell>
					<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell align="center">{row.AgentName}</TableCell>
				<TableCell align="center">
					<Typography
						component="h6"
						className={classes.commentTime}
						onClick={() => setShowReadableDate(!showReadableDate)}
					>
						{displayHumanReadableDate(row.CreatedAt)}
						<br />
						{showReadableDate &&
							`(${
								row['CreatedAt'].split(' ')[0] +
								' ' +
								displayHumanReadableClock(row['CreatedAt'])
							})`}
					</Typography>
				</TableCell>
				<TableCell align="right">
					<AudioPlayer src={row.RecordingURL} />
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							<Typography variant="h6" gutterBottom component="div">
								Audio
							</Typography>
							<AudioPlayer
								style={{
									width: '500px'
								}}
								src={row.RecordingURL}
								ref={audio}
							/>
						</Box>
						<Box margin={1}>
							<Typography variant="h6" gutterBottom component="div">
								Transcript
							</Typography>
							<List className={classes.transcript}>
								{transcript ? (
									transcript.map((log, id) => {
										return (
											<ListItem
												key={id}
												onClick={() =>
													audioTimestamp(log.BeginOffsetMillis)
												}
												className={classes.chatlog}
											>
												<Grid container>
													<Grid item xs={12}>
														{log.ParticipantId === 'CUSTOMER' ? (
															<>
																<ListItemText
																	secondary={
																		<>
																			<Typography
																				component="span"
																				variant="body2"
																				color="textPrimary"
																			>
																				Caller
																			</Typography>
																			{'  ' +
																				timerFormat(
																					log.BeginOffsetMillis
																				)}
																		</>
																	}
																/>
																<Paper className={classes.customer}>
																	<ListItemText
																		align="left"
																		primary={log.Content}
																	/>
																</Paper>
															</>
														) : (
															<>
																<ListItemText
																	align="right"
																	secondary={
																		<>
																			<Typography
																				component="span"
																				variant="body2"
																				color="textPrimary"
																			>
																				{row.AgentName}
																			</Typography>
																			{'  ' +
																				timerFormat(
																					log.BeginOffsetMillis
																				)}
																		</>
																	}
																/>
																<Paper
																	className={classes.agent}
																	variant="outlined"
																>
																	<ListItemText
																		align="right"
																		primary={log.Content}
																	/>
																</Paper>
															</>
														)}
													</Grid>
												</Grid>
											</ListItem>
										);
									})
								) : (
									<Typography variant="body1" gutterBottom component="div">
										A transcript for this recording is not available yet.
									</Typography>
								)}
							</List>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
}

Row.propTypes = {
	row: PropTypes.shape({
		AgentName: PropTypes.string.isRequired,
		CreatedAt: PropTypes.string,
		RecordingURL: PropTypes.string,
		TranscriptURL: PropTypes.string
	}).isRequired
};

export default function CallRecordingTable(props) {
	const { homeId, title = false } = props;
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(6); // do we still need this? might have to refactor
	const [rows, setRows] = useState([]);
	const [expand, setExpand] = useState(false);
	const [loading, setLoading] = useState(false);
	const classes = useStyles();

	// for opening/closing view more modal
	const [viewMore, setViewMore] = useState(false);

	const handleChangePage = (newPage) => setPage(newPage);

	useEffect(() => {
		if (expand) {
			const fetchCallRecordings = async () => {
				try {
					getCallRecordings(homeId).then((res) => {
						if (res.status === 200) {
							let recordings = res.data.recordings;
							setRows(recordings);
							setLoading(false);
						}
					});
				} catch (err) {
					console.error(err);
					setLoading(false);
				}
			};
			fetchCallRecordings();
		}
	}, [expand]);

	return (
		<>
			<Accordion className={classes.accordian}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					onClick={() => {
						setExpand(!expand);
						setLoading(!loading);
					}}
				>
					{title ? (
						<Title>Recorded Calls</Title>
					) : (
						<Typography variant="h4" component="h4">
							Recorded Calls
						</Typography>
					)}
				</AccordionSummary>
				{loading ? (
					<div className={classes.spinner}>
						<CircularProgress />
					</div>
				) : rows.length > 0 ? (
					<AccordionDetails>
						<TableContainer sx={{ maxHeight: 440 }}>
							<Table stickyHeader aria-label="sticky table">
								{/* table header */}
								<TableHead>
									<TableRow>
										<TableCell />
										{columns.map((column) => (
											<TableCell
												key={column.id}
												align={column.align}
												style={{ minWidth: column.minWidth }}
											>
												<Typography variant="subtitle" component="h4">
													{column.label}
												</Typography>
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								{/* table body */}
								<TableBody>
									{rows
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row, id) => {
											return (
												<>
													<Row row={row} key={id} />
												</>
											);
										})}
								</TableBody>
							</Table>
							<Table>
								<TableBody>
									<TableRow>
										<TablePagination
											rowsPerPageOptions={[]}
											colSpan={3}
											count={rows.length}
											rowsPerPage={rowsPerPage}
											page={page}
											onPageChange={handleChangePage}
											ActionsComponent={() =>
												TablePaginationActions({
													onPageChange: handleChangePage,
													page: page,
													count: rows.length,
													rowsPerPage
												})
											}
										/>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</AccordionDetails>
				) : (
					<Typography align="center">No recordings found.</Typography>
				)}
			</Accordion>
		</>
	);
}
