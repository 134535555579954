import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CardContent from '@material-ui/core/CardContent';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import { Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import blue from '@material-ui/core/colors/blue';
import SearchIcon from '@material-ui/icons/Search';

import SearchActiveForm from '../SearchActiveForm';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	button: {
		flex: 1,
		color: blue[900],
		margin: 10,
		justifyContent: 'center',
		alignSelf: 'center'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},
	content: {
		display: 'flex',
		padding: theme.spacing(0),
		overflowX: 'visible'
	},
	actions: {
		padding: theme.spacing(2, 4),
		backgroundColor: '#f8f8f8'
	}
}));

const DialogTitle = ({ children, onClose, ...other }) => {
	const classes = useStyles();
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
};

export default function Search({ handleSearch, handleRefreshSearch, areaOptionsQuery }) {
	const [open, setOpen] = useState(false);
	const classes = useStyles();

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

	return (
		<React.Fragment>
			<CardContent className={classes.root}>
				<Grid container justifyContent="center" alignItems="center">
					<IconButton onClick={handleClickOpen}>
						<Fab component="span" className={classes.button}>
							<SearchIcon />
						</Fab>
					</IconButton>
					<Dialog
						open={open}
						scroll="paper"
						onClose={handleClose}
						aria-labelledby="search"
						fullScreen={fullScreen}
					>
						<DialogTitle id="search" onClose={handleClose}>
							{'Filters'}
						</DialogTitle>
						<DialogContent dividers={true} className={classes.content}>
							<SearchActiveForm
								areaOptionsQuery={areaOptionsQuery}
								handleSearch={handleSearch}
								handleClose={handleClose}
								handleRefreshSearch={handleRefreshSearch}
							/>
						</DialogContent>
					</Dialog>
				</Grid>
			</CardContent>
		</React.Fragment>
	);
}
