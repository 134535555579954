import React, { useState, useEffect, useRef } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { DateTimePicker } from '@material-ui/pickers';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { IconButton, InputAdornment, Grid, Typography } from '@material-ui/core';
import { useUserState } from '../../../../../../../hook/customerHook';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import googleLogo from './googleLogo.svg';
import appleLogo from './appleLogo.svg';
import { dateFormatterFormal } from '../../../../../../../helper/parser';

const { google, ics } = require('calendar-link');

const useStyles = makeStyles((theme) => ({
	form: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		gap: '0.625rem'
	},
	datePicker: {
		width: '100%'
	},
	calenderContainer: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	icons: {
		transform: 'scale(1.2)'
	},
	clearSchedule: {
		textDecoration: 'underline',
		marginTop: 10,
		color: theme.palette.grey['KN_light_grey'],
		transition: 'transform 0.07s linear',
		fontSize: 'clamp(0.95rem, 0.8vw + 0.2rem, 1.1rem)',
		'&:hover': {
			cursor: 'pointer',
			transform: 'scale(0.95)'
		}
	}
}));

const formatDefaultDate = (unixTime) => {
	// convert defaultFollowUpScheduleVal (which is in unix time)
	// to the format 'YYYY-MM-DD, h:mm a' so that it can be DateTimePicker's default value
	var formattedDate = moment.unix(unixTime).format('YYYY-MM-DDTHH:mm');
	return formattedDate;
};

export default function DatePicker({
	mlNum,
	address,
	addDataToFormObject,
	defaultFollowUpScheduleVal
}) {
	const classes = useStyles();
	const formRef = useRef();
	const [userState, dispatchUser] = useUserState();
	const [followUpSchedule, setFollowUpSchedule] = useState(
		!isNaN(defaultFollowUpScheduleVal) ? formatDefaultDate(defaultFollowUpScheduleVal) : null
	);
	const [calendarWarning, setcalendarWarning] = useState(false);
	useEffect(() => {
		// setFollowUpSchedule(formatDefaultDate(defaultFollowUpScheduleVal)); //set date to default date

		const formElement = formRef.current;
		const keyUpHandler = (e) => {
			if (e.key === 'Escape') {
				setFollowUpSchedule(null);
			}
		};
		formElement.addEventListener('keyup', keyUpHandler);

		return () => {
			formElement.removeEventListener('keyup', keyUpHandler);
		};
	}, []);

	const googleCalendarLink = (e) => {
		if (followUpSchedule != null) {
			const unixTime = moment(followUpSchedule).unix();

			const event = {
				title: address + ' scheduled by ' + userState.user.name,
				description: 'For listing http://app.realsaige.com/listing/' + mlNum,
				start: moment.unix(unixTime).format('yyyy-MM-DD, hh:mm:ss'),
				duration: [0.5, 'hour']
			};
			const newWindow = window.open(google(event), '_blank', 'noreferrer');
			return newWindow;
		} else {
			setcalendarWarning(true);
		}
	};

	const iCalCalendarLink = (e) => {
		if (followUpSchedule != null) {
			setcalendarWarning(false);
			const unixTime = moment(followUpSchedule).unix();
			const event = {
				title: address + ' scheduled by ' + userState.user.name,
				description: 'For listing http://app.realsaige.com/listing/' + mlNum,
				start: moment.unix(unixTime).format('yyyy-MM-DD, hh:mm:ss'),
				duration: [0.5, 'hour']
			};
			const newWindow = window.open(ics(event), '_blank', 'noreferrer');
			return newWindow;
		} else {
			setcalendarWarning(true);
		}
	};

	const handleDateTimePickerChange = (event) => {
		if (event.target.value !== '') {
			const selectedDateTime = moment(event.target.value, 'YYYY-MM-DDTHH:mm').toDate();

			console.log(event.target.value);
			setFollowUpSchedule(event.target.value);

			setcalendarWarning(false);
			// we store unix time in DB so we must convert before storing in the object
			const unixTime = moment(selectedDateTime).unix();
			addDataToFormObject('FollowUpSchedule', unixTime);
		} else {
			setFollowUpSchedule(null);
			addDataToFormObject('FollowUpSchedule', '');
		}
	};

	const clearSchedule = (e) => {
		e.preventDefault();
		setFollowUpSchedule(null);
		addDataToFormObject('FollowUpSchedule', '');
	};

	return (
		<>
			<Grid item xs={12} className="date-picker">
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<form ref={formRef} className={classes.form}>
						<TextField
							className={classes.datePicker}
							id="datetime-local"
							label="Follow up Schedule"
							type="datetime-local"
							inputVariant="outlined"
							variant="outlined"
							defaultDate={formatDefaultDate(defaultFollowUpScheduleVal)}
							value={followUpSchedule}
							onChange={handleDateTimePickerChange}
							onError={console.log}
							minutesStep={5}
							autoOK
							error={calendarWarning}
							helperText={calendarWarning ? 'Please pick a date' : ''}
							InputLabelProps={{
								shrink: true
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment
										position="end"
										style={{
											maxHeight: 'none',
											height: 'auto',
											marginTop: '-1px',
											marginRight: '-10px',
											marginBottom: '-1px'
										}}
									></InputAdornment>
								)
							}}
						/>
					</form>
				</MuiPickersUtilsProvider>
				<Grid className={classes.calenderContainer}>
					<Grid>
						<IconButton
							onClick={googleCalendarLink}
							aria-label="google calendar"
							size="large"
							color="grey"
						>
							<img src={googleLogo} alt="google calendar" className={classes.icons} />
						</IconButton>
						<IconButton
							onClick={iCalCalendarLink}
							aria-label="apple calendar"
							size="large"
							color="grey"
						>
							<img src={appleLogo} alt="apple calendar" className={classes.icons} />
						</IconButton>
					</Grid>

					{/* This is the clear schedule button */}
					<Typography
						variant="h6"
						component="h6"
						className={classes.clearSchedule}
						onClick={clearSchedule}
					>
						Clear Schedule
					</Typography>
				</Grid>
			</Grid>
		</>
	);
}
