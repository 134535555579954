import React from 'react';

import { Controller } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

function CusSelectField({
	itemGrid,
	name,
	label,
	options,
	control,
	fieldStyle,
	multiple,
	defaultValue,
	requiredValue,
	disabled,
	title
}) {
	return (
		<Grid item xs={12} sm={itemGrid}>
			<Controller
				name={name}
				control={control}
				defaultValue={defaultValue}
				color="primary"
				render={({ onChange, value }) => {
					if (label === 'Area') {
						return (
							<Autocomplete
								freesolo
								fullWidth
								multiple={multiple}
								value={value}
								limitTags={4}
								disableCloseOnSelect={multiple}
								options={options}
								disabled={disabled}
								getOptionLabel={(option) => (option ? option.label : option)}
								renderOption={(option) => <span>{option.label}</span>}
								renderInput={(params) => (
									<TextField
										{...params}
										label={label}
										required={requiredValue}
										title={title}
									/>
								)}
								onChange={(_, data, reason) => {
									onChange(data);
								}}
							/>
						);
					} else if (label === 'Subarea') {
						return (
							<Autocomplete
								freesolo
								fullWidth
								multiple={multiple}
								value={value}
								limitTags={4}
								disableCloseOnSelect={multiple}
								options={options}
								disabled={disabled}
								groupBy={(option) => option.label}
								getOptionLabel={(option) => (option ? option.value.full : '')}
								renderOption={(option) => <span>{option.value.full}</span>}
								renderInput={(params) => {
									return <TextField {...params} label={label} title={title} />;
								}}
								onChange={(_, data) => {
									onChange(data);
								}}
							/>
						);
					} else {
						return (
							<Autocomplete
								freesolo
								fullWidth
								multiple={multiple}
								value={value}
								limitTags={5}
								disableCloseOnSelect={multiple}
								options={options}
								disabled={disabled}
								getOptionLabel={(option) => (option ? option.label : option)}
								renderOption={(option) => <span>{option.label}</span>}
								renderInput={(params) => {
									return (
										<TextField
											{...params}
											label={label}
											required={requiredValue}
											title={title}
										/>
									);
								}}
								onChange={(_, data) => {
									onChange(data);
								}}
							/>
						);
					}
				}}
			/>
		</Grid>
	);
}

export default React.memo(CusSelectField);
