import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import { IconButton, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import blue from '@material-ui/core/colors/blue';
import SearchIcon from '@material-ui/icons/Search';

import SearchForm from '../SearchForm';
import TuneIcon from '@material-ui/icons/Tune';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useUserState } from '../../../hook/customerHook';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	button: {
		flex: 1,
		color: blue[900],
		margin: 10,
		justifyContent: 'center',
		alignSelf: 'center'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},
	content: {
		display: 'flex',
		padding: theme.spacing(0),
		overflowX: 'visible'
	},
	actions: {
		padding: theme.spacing(2, 4),
		backgroundColor: '#f8f8f8'
	},
	filterBtn: {
		marginTop: '8px',
		textTransform: 'none',
		letterSpacing: 'normal',
		fontWeight: 'normal'
	}
}));

const DialogTitle = ({ children, onClose, ...other }) => {
	const classes = useStyles();
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
};

export default function Search({
	setFields,
	handleSearch,
	handleRefreshSearch,
	refreshListings,
	areaOptionsQuery,
	subAreaOptionsQuery,
	setRestoredFilters,
	restoredFilters
}) {
	const [open, setOpen] = useState(false);
	const classes = useStyles();
	const [userState, dispatchUser] = useUserState();

	const advFilters = userState.advFilters;

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

	const filtersLength = (filters) => {
		const obj = Object.fromEntries(
			Object.entries(filters).filter(
				([key, value]) => key.toLowerCase().indexOf('date') === -1
			)
		);
		const dwellingTypeFilters = ['detached', 'attached', 'apartment'];
		let count = 0;
		let dwellingTypeCounted = false;
		for (const [key, value] of Object.entries(obj)) {
			if (dwellingTypeFilters.includes(key)) {
				if (value && !dwellingTypeCounted) {
					count += 1;
					dwellingTypeCounted = true;
				}
			} else {
				count += 1;
			}
		}
		return count;
	};

	return (
		<React.Fragment>
			<Grid item justifyContent="center" alignItems="center">
				<Button
					variant="outlined"
					startIcon={<TuneIcon />}
					onClick={handleClickOpen}
					className={classes.filterBtn}
					style={{
						color: advFilters && filtersLength(advFilters) ? '#02838D' : '#004F2B',
						borderColor: advFilters && filtersLength(advFilters) ? '#02838D' : '#004F2B'
					}}
				>
					Property filters{' '}
					{advFilters && filtersLength(advFilters)
						? `(${filtersLength(advFilters)})`
						: ''}
				</Button>
				<Dialog
					open={open}
					onClose={handleClose}
					aria-labelledby="search"
					scroll="paper"
					fullScreen={fullScreen}
				>
					<DialogTitle id="search" onClose={handleClose} className={classes.root}>
						{'Filters'}
					</DialogTitle>
					<DialogContent dividers className={classes.content}>
						<SearchForm
							setFields={setFields}
							areaOptionsQuery={areaOptionsQuery}
							subAreaOptionsQuery={subAreaOptionsQuery}
							handleSearch={handleSearch}
							handleRefreshSearch={handleRefreshSearch}
							handleClose={handleClose}
							setRestoredFilters={setRestoredFilters}
							restoredFilters={restoredFilters}
							refreshListings={refreshListings}
						/>
					</DialogContent>
				</Dialog>
			</Grid>
		</React.Fragment>
	);
}
