/*
The reason this component is in the directory of the DetailsPageTabs (when it is not one of the tabs)
is becuase it is used in many tab components (the active, sold, and off market tabs). So it makes
sense to have it at the same directory level.
*/

import React from 'react';
import { Typography, Box, Avatar, Grid, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { currencyParser, displayHumanReadableDate, formatAddress } from '../../../../helper/parser';

const useStyles = makeStyles((theme) => ({
	root: {
		//
	},

	// below are styles for view widths greater than 600px
	cardButton: {
		'&:hover': {
			cursor: 'pointer'
		}
	},
	cardButtonActive: {
		backgroundColor: theme.palette.green['KN_mint_10'],
		borderColor: `${theme.palette.green['KN_dark_mint']} !important`
	},
	cardContainer: {
		display: 'flex',
		alignItems: 'center',
		padding: 20,
		justifyContent: 'space-between',
		border: `solid 0.7px rgba(176, 176, 176, 0.5)`,
		borderRadius: 8,
		'&:hover': {
			borderWidth: '1px',
			borderColor: theme.palette.primary.main
		}
	},
	homeProfileContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	mainInfo: {
		marginLeft: 15
	},
	avatar: {
		width: 'clamp(2.5rem, 2.4vw + 1rem, 6rem)',
		height: 'clamp(2.5rem, 2.4vw + 1rem, 6rem)'
	},
	address: {
		color: theme.palette.black.main,
		textDecoration: 'none'
	},
	price: {
		display: 'inline-block',
		fontWeight: theme.typography.fontWeightBold,
		marginRight: 12
	},
	subInfo: {
		display: 'inline-block'
	},
	distanceText: {
		color: theme.palette.grey['KN_light_grey']
	},
	distanceTextActive: {
		color: theme.palette.green['KN_dark_mint']
	},
	// below are styles for view widths lower than 600px
	// (used for components that are only render on mobile)
	// (could use mui breakpoints but i found them to be buggy when shrinking the view width)
	cardContainerMobile: {
		flexDirection: 'column',
		alignItems: 'normal',
		padding: 14,
		justifyContent: 'space-between',
		border: `solid 0.7px rgba(176, 176, 176, 0.5)`,
		borderRadius: 8,
		'&:hover': {
			borderWidth: '1px',
			borderColor: theme.palette.primary.main
		}
	},
	homeProfileContainerMobile: {
		display: 'flex'
	},
	addressMobile: {
		color: theme.palette.grey['KN_grey'],
		textDecoration: 'none'
	},
	priceMobile: {
		display: 'inline-block',
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.black.main,
		marginRight: 12
	},
	distanceText: {
		color: theme.palette.grey['KN_light_grey'],
		marginTop: 10,
		textAlign: 'right'
	}
}));

export default function PropertyCard({
	listingCardInfo,
	isMobile,
	selectedMapListing,
	isNearBySold
}) {
	const classes = useStyles();

	function formatDistance(distance) {
		if (distance >= 1) return `${distance.toFixed(1)}km`;
		else {
			const meters = distance * 1000;
			return `${meters.toFixed(0)}m`;
		}
	}

	const checkIfSelected = (location) => {
		const [lat, lng] = location.replace('POINT(', '').replace(')', '').split(' ');
		const propertyLocation = { lat: parseFloat(lat), lng: parseFloat(lng) };

		try {
			if (
				propertyLocation['lat'] === selectedMapListing['lat'] &&
				propertyLocation['lng'] === selectedMapListing['lng']
			) {
				return true;
			}
		} catch {
			return false;
		}
	};

	const renderPropertyCards = () => {
		return listingCardInfo.map((listingCard) => (
			<Link
				key={listingCard.MLNum}
				style={{ textDecoration: 'none', paddingTop: 12 }}
				className={classes.cardButton}
				href={`/user/listing/${listingCard['MLNum']}`}
			>
				<Grid
					item
					className={`${classes.cardContainer} ${
						checkIfSelected(listingCard['Location']) && classes.cardButtonActive
					} `}
				>
					<Box className={classes.homeProfileContainer}>
						<Box>
							<Avatar
								alt={`Image of ${listingCard['Address']}`}
								className={classes.avatar}
								src={listingCard['Pic']}
							/>
						</Box>
						<Box className={classes.mainInfo}>
							<Typography
								variant="subtitle1"
								component="h4"
								className={classes.address}
							>
								{formatAddress(`${listingCard['Address']}, ${listingCard['City']}`)}
							</Typography>

							<Box>
								<Typography
									variant="subtitle1"
									component="h5"
									className={classes.price}
								>
									{currencyParser(listingCard['Price'])}
								</Typography>
								<Typography
									variant="subtitle1"
									component="h5"
									className={classes.subInfo}
								>
									{`${listingCard['TotBR']} Bed • ${
										listingCard['TotBaths']
									} Bath • ${listingCard['NumKitchens']} Kit • ${
										listingCard['TotFlArea']
									} Sqft • ${
										isNearBySold ? 'Sold' : 'Listed'
									} ${displayHumanReadableDate(listingCard['ListDate'])}`}
								</Typography>
							</Box>
						</Box>
					</Box>
					<Box>
						<Typography
							variant="subtitle1"
							component="h5"
							className={
								checkIfSelected(listingCard['Location'])
									? classes.distanceTextActive
									: classes.distanceText
							}
						>
							{formatDistance(listingCard['Distance'])}
						</Typography>
					</Box>
				</Grid>
			</Link>
		));
	};

	const renderPropertyCardsMobile = () => {
		/*
        The reason I made another function to render the comopnent for mobile
        is because the design demands a structure change in the code (not just re-styling).
        */
		return listingCardInfo.map((listingCard) => (
			<Link
				key={listingCard.MLNum}
				style={{ textDecoration: 'none', paddingTop: 12 }}
				clasName={classes.cardButton}
				href={`/user/listing/${listingCard['MLNum']}`}
			>
				<Grid className={classes.cardContainerMobile} item>
					<Box className={classes.homeProfileContainerMobile}>
						<Avatar
							alt={`Image of ${listingCard['Address']}`}
							className={classes.avatar}
							src={listingCard['Pic']}
						/>
						<Box className={classes.mainInfo}>
							<Typography
								variant="subtitle1"
								component="h5"
								className={classes.priceMobile}
							>
								{currencyParser(listingCard['Price'])}
							</Typography>

							<Box>
								<Typography
									variant="subtitle1"
									component="h4"
									className={classes.addressMobile}
								>
									{formatAddress(
										`${listingCard['Address']}, ${listingCard['City']}`
									)}
								</Typography>
								<Typography
									variant="subtitle1"
									component="h5"
									className={classes.subInfo}
								>
									{`${listingCard['TotBR']} Bed • ${listingCard['TotBaths']} Bath • ${listingCard['NumKitchens']} Kit • ${listingCard['TotFlArea']} Sqft`}
								</Typography>
							</Box>
						</Box>
					</Box>
					<Box>
						<Typography
							variant="subtitle1"
							component="h5"
							className={classes.distanceText}
						>
							{formatDistance(listingCard['Distance'])}
						</Typography>
					</Box>
				</Grid>
			</Link>
		));
	};

	return (
		<Grid container direction={'column'} spacing={24} className={classes.root}>
			{!isMobile ? renderPropertyCards() : renderPropertyCardsMobile()}
		</Grid>
	);
}
