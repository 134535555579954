import { ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

export default function ListButton({ navItem, handleClick, isExpanded }) {
	return (
		<ListItem button onClick={handleClick}>
			<Tooltip title={navItem.label}>
				<ListItemIcon>
					<navItem.iconComponent />
				</ListItemIcon>
			</Tooltip>
			<ListItemText primary={navItem.label} />
			{navItem.hasSubItems && (isExpanded ? <ExpandLess /> : <ExpandMore />)}
		</ListItem>
	);
}

ListButton.defaultProps = {
	hasSubItems: false,
	isExpanded: true
};
