import { useEffect, useState } from 'react';
import { getAllListings } from '../../../api/serverRequests';
import TablePaginationActions from '../ListingsTable/TablePaginationActions';

// mui //
import { makeStyles } from '@material-ui/core/styles';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableFooter,
	Typography
} from '@material-ui/core';

const useStyles = makeStyles({
	hyperLink: { color: 'rgba(54, 59, 66, 1)', fontWeight: 'bold' }
});

const columns = [
	{ id: 'mlNumber', label: 'ML No.', maxWidth: 70, align: 'center' },
	{ id: 'status', label: 'Status', maxWidth: 70, align: 'center' },
	{
		id: 'price',
		label: 'List Price',
		maxWidth: 70,
		align: 'center',
		format: (value) =>
			value.toLocaleString('en-US', {
				style: 'currency',
				currency: 'USD',
				minimumFractionDigits: 0,
				maximumFractionDigits: 0
			})
	},
	{
		id: 'date',
		label: 'Date',
		maxWidth: 70,
		align: 'center'
	}
];

function createData(date, price, status, mlNumber) {
	return { date, price, status, mlNumber };
}

export default function ListingsTable(props) {
	const { mlNum, address, city, homeId } = props;
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(6); // do we still need this? might have to refactor
	const [rows, setRows] = useState([]);
	const classes = useStyles();

	const handleChangePage = (newPage) => setPage(newPage);

	useEffect(() => {
		const fetchOtherListings = async () => {
			try {
				const {
					data: { listings }
				} = await getAllListings(homeId);

				const parsedListings = listings.reduce((acc, listing) => {
					const { ListDate, Price, MLNum, Status } = listing;
					if (MLNum === mlNum) return acc;
					acc.push(createData(ListDate, Price, Status, MLNum));
					return acc;
				}, []);
				setRows(parsedListings);
			} catch (err) {
				console.error(err);
			}
		};
		fetchOtherListings();
	}, [mlNum]);

	return (
		<>
			{rows.length > 0 ? (
				<Paper sx={{ width: '25%', overflow: 'hidden' }}>
					<TableContainer sx={{ maxHeight: 440 }}>
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow>
									{columns.map((column) => (
										<TableCell
											key={column.id}
											align={column.align}
											style={{ minWidth: column.minWidth }}
										>
											{column.label}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{rows
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row) => {
										return (
											<TableRow
												hover
												role="checkbox"
												tabIndex={-1}
												key={row.mlNumber}
											>
												{columns.map((column) => {
													const value = row[column.id];
													return (
														<TableCell
															key={column.id}
															align={column.align}
														>
															{column.label === 'ML No.' ? (
																<a
																	href={`/user/listing/${row.mlNumber}`}
																	className={classes.hyperLink}
																	key={column.id}
																>
																	{column.format &&
																	typeof value === 'number'
																		? column.format(value)
																		: value}
																</a>
															) : column.format &&
															  typeof value === 'number' ? (
																column.format(value)
															) : (
																value
															)}
														</TableCell>
													);
												})}
											</TableRow>
										);
									})}
							</TableBody>
						</Table>
					</TableContainer>
					<Table>
						<TableBody>
							<TableRow>
								<TablePagination
									rowsPerPageOptions={[]}
									colSpan={3}
									count={rows.length}
									rowsPerPage={rowsPerPage}
									page={page}
									onPageChange={handleChangePage}
									ActionsComponent={() =>
										TablePaginationActions({
											onPageChange: handleChangePage,
											page: page,
											count: rows.length,
											rowsPerPage
										})
									}
								/>
							</TableRow>
						</TableBody>
					</Table>
				</Paper>
			) : (
				<Typography>This property doesn't have any other listings.</Typography>
			)}
		</>
	);
}
