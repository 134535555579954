import React, { useState } from 'react';
import { Box, Button, Checkbox, Grid, Typography, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import EmailIcon from '@material-ui/icons/Email';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import { useUserState } from '../../../../../../hook/customerHook';
import { createUserNotificationPreferences } from '../../../../../../api/serverRequests';
import { requestForToken } from '../../../../../../Firebase/firebase';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		height: '100%'
	},
	setupContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		height: 350
	},
	headingText: {
		textAlign: 'center',
		fontSize: 28,
		color: theme.palette.primary.main
	},
	title: {
		opacity: 0.6,
		marginTop: 25,
		marginBottom: 10
	},
	allOptionsContainer: {
		marginLeft: 15
	},
	optionContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	optionTitleContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	optionTitle: {
		color: '#000',
		fontWeight: 700,
		marginLeft: 7
	}
}));

export default function SelectNotificationPreferences({
	isLoadingNotificationPreferences,
	reFetchPreferences,
	setReFetchPreferences
}) {
	const classes = useStyles();
	const [userState, dispatchUser] = useUserState();
	const [loading, setLoading] = useState(false);

	const [checkboxes, setCheckboxes] = useState({
		receiveEmail: false,
		receivePush: false,
		receivePhone: false
	});

	const handleCheckboxChange = (name) => (event) => {
		setCheckboxes((prevCheckboxes) => ({
			...prevCheckboxes,
			[name]: event.target.checked
		}));
	};

	const onSubmit = async () => {
		setLoading(true);

		const formData = {
			ReceivePhone: checkboxes.receivePhone || '',
			ReceiveEmail: checkboxes.receiveEmail || '',
			ReceivePush: checkboxes.receivePush || '',
			GeneratedFCMToken: await requestForToken()
		};

		createUserNotificationPreferences(formData)
			.then((res) => {
				if (res.status === 200) {
					setReFetchPreferences(!reFetchPreferences);
				}
				setLoading(false);
			})
			.catch((err) => {
				if (err.response.status === 401) {
					dispatchUser({
						isLoggedIn: false
					});
					return;
				} else {
					setLoading(false);
				}
				console.error(err.message);
			});

		return;
	};

	return (
		<Box className={classes.root}>
			<Box className={classes.setupContainer}>
				<Typography component="h2" variant="h2" className={classes.headingText}>
					Select Your Notifications
				</Typography>
				<Box>
					<Typography component="p" variant="h5" className={classes.title}>
						How You Get Notified
					</Typography>
					<Box className={classes.allOptionsContainer}>
						<Grid container spacing={0} className={classes.optionContainer}>
							<Grid item xs={7}>
								<Box className={classes.optionTitleContainer}>
									<WebAssetIcon size="small" style={{ color: grey[900] }} />
									<Typography
										component="p"
										variant="h5"
										className={classes.optionTitle}
									>
										Browser
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={5}>
								<Checkbox
									color="primary"
									checked={checkboxes.receivePush}
									onChange={handleCheckboxChange('receivePush')}
								/>
							</Grid>
							<Grid item xs={7}>
								<Box className={classes.optionTitleContainer}>
									<EmailIcon size="small" style={{ color: grey[900] }} />
									<Typography
										component="p"
										variant="h5"
										className={classes.optionTitle}
									>
										Email
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={5}>
								<Checkbox
									color="primary"
									checked={checkboxes.receiveEmail}
									onChange={handleCheckboxChange('receiveEmail')}
								/>
							</Grid>
						</Grid>
					</Box>
				</Box>
				<Button
					variant="contained"
					color="primary"
					style={{ marginTop: 40 }}
					disabled={loading || isLoadingNotificationPreferences}
					onClick={onSubmit}
				>
					{loading
						? 'Setting up...'
						: isLoadingNotificationPreferences
						? 'Almost done...'
						: 'Setup'}
				</Button>
			</Box>
		</Box>
	);
}
