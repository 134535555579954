import { useState } from 'react';
//Dynamically load components on request for faster page rendering
import loadable from '@loadable/component';

// mui //
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const FileUpload = loadable(() => import('../../..'), {
	resolveComponent: (components) => components.FileUpload
});
const ClustersTable = loadable(() => import('../../..'), {
	resolveComponent: (components) => components.ClustersTable
});

const useStyles = makeStyles({
	heading: {
		fontSize: '30px',
		marginBottom: '10px'
	},
	fileUploading: {
		display: 'flex',
		flexDirection: 'column'
	},
	desc: {
		fontSize: '15px',
		margin: '10px 0'
	}
});

const PropertyClusteringTab = () => {
	const [clusters, setClusters] = useState([]);
	const [filePath, setFilePath] = useState(null);
	const classes = useStyles();

	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<div>
						<div>
							<h1 className={classes.heading}>Door Knocking</h1>
							<p className={classes.desc}>
								Upload, view, and assign property clusters to your list.
							</p>
							<p>
								<b>Step 1:</b> Upload your .csv file <br />
								<b>Step 2:</b> Use the dropdown arrow to view properties within each
								cluster <br />
								<b>Step 3:</b> Assign cluster(s) to your list and/or view the
								cluster route by pressing the link.
							</p>
						</div>
						<div className={classes.fileUploading}>
							{/* <ProgressBar /> */}
							<FileUpload setClusters={setClusters} setFilePath={setFilePath} />
						</div>
					</div>
					<ClustersTable clusters={clusters} filePath={filePath} />
				</Grid>
			</Grid>
		</>
	);
};

export default PropertyClusteringTab;
