import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Title } from '../../..';
//Dynamically load components on request for faster page rendering
import loadable from '@loadable/component';
import { Typography } from '@material-ui/core';
import { useUserState } from '../../../../hook/customerHook';

const Grid = loadable(() => import('@material-ui/core/Grid'));
const Paper = loadable(() => import('@material-ui/core/Paper'));
const Button = loadable(() => import('@material-ui/core/Button'));
const UserInfo = loadable(() => import('../../..'), {
	resolveComponent: (components) => components.UserInfo
});

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column'
	},
	gridHead: {
		padding: theme.spacing(1),
		paddingBottom: theme.spacing(3)
	},
	fixedHeight: {
		height: 120
	}
}));

const ProfileTab = () => {
	const classes = useStyles();
	const [user, dispatchUser] = useUserState();
	const name = user['user'].name;
	const [changePassword, setChangePassword] = useState(false);

	return (
		<>
			<Typography component="h1" variant="h4">
				Hello, {name}
			</Typography>
			<Grid item xs={12}>
				<Paper className={classes.paper}>
					<Grid container className={classes.gridHead}>
						<Grid item xs={12} sm={9}>
							<Title>My Account</Title>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								onClick={() => {
									setChangePassword(!changePassword);
								}}
							>
								Change Password
							</Button>
						</Grid>
					</Grid>
					<Paper className={classes.paper}>
						<UserInfo
							userInfo={user}
							modifyUser={dispatchUser}
							changePassword={changePassword}
						/>
					</Paper>
				</Paper>
			</Grid>
		</>
	);
};

export default ProfileTab;
