import React, { useEffect, useState, useRef } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import {
	Button,
	CardActions,
	Box,
	CircularProgress,
	TextField,
	Paper,
	InputAdornment
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import blue from '@material-ui/core/colors/blue';
import { makeStyles } from '@material-ui/core/styles';
import { useUserState } from '../../../hook/customerHook';
import { useForm } from 'react-hook-form';
import {
	getRecentListingLocationByAddress,
	searchOnRecentListings
} from '../../../api/serverRequests';
import { Autocomplete } from '@material-ui/lab';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	button: {
		flex: 1,
		color: blue[900],
		margin: 10,
		justifyContent: 'center',
		alignSelf: 'center'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},
	actions: {
		padding: theme.spacing(2, 4),
		backgroundColor: '#f8f8f8',
		borderTop: '1px solid rgba(0, 0, 0, 0.12)'
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		display: 'flex',
		flexFlow: 'column nowrap',
		'& .MuiList-root': {
			overflowX: 'auto',
			scrollbarWidth: 'none',
			gap: '0.2rem'
		},
		'& .MuiList-root::-webkit-scrollbar': {
			display: 'none'
		},
		'& .MuiListItem-root': {
			padding: theme.spacing(0, 1, 0, 0),
			width: 'auto'
		},
		'& .MuiChip-root': {
			padding: theme.spacing(2, 1),
			transition: 'none'
		},
		'& .MuiChip-clickable:active': {
			boxShadow: 'none'
		}
	},
	cardContent: {
		padding: theme.spacing(4),
		paddingTop: theme.spacing(2),
		paddingBottom: '0 !important'
	},
	textFields: {
		position: 'relative'
	},
	autocomplete: {
		'& .MuiAutocomplete-inputRoot': {
			paddingRight: '30px !important'
		}
	}
}));

export default function RouteFilterForm({ refreshListings, restoredFilters, handleClose }) {
	const classes = useStyles();
	const [userState, dispatchUser] = useUserState();

	const [startAddress, setStartAddress] = useState('');
	const [endAddress, setEndAddress] = useState('');

	const [startAddrAutoCompltOpen, setStartAddrAutoCompltOpen] = useState(false);
	const [endAddrAutoCompltOpen, setEndAddrAutoCompltOpen] = useState(false);

	const [startAddrInputLoading, setStartAddrInputLoading] = useState(false);
	const [endAddrInputLoading, setEndAddrInputLoading] = useState(false);

	const [startAddroptions, setStartAddrOptions] = useState([]);
	const [endAddroptions, setEndAddrOptions] = useState([]);

	const [startAddrLocation, setStartAddrLocation] = useState('');
	const [endAddrLocation, setEndAddrLocation] = useState('');
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

	const [extraTime, setExtraTime] = useState();

	let defaultValuesEmpty = {
		startAddress: 'any',
		endAddress: 'any'
	};

	const defaultValues = Object.assign(
		{},
		defaultValuesEmpty,
		restoredFilters,
		userState.routeFilters
	);

	useEffect(() => {
		if (userState.routeFilters) {
			let routeFilters = userState.routeFilters;
			if (routeFilters.startAddress) {
				setStartAddress(routeFilters.startAddress);
			}
			if (routeFilters.endAddress) {
				setEndAddress(routeFilters.endAddress);
			}
			if (routeFilters.extraTime) {
				setExtraTime(routeFilters.extraTime);
			}
			setStartAddrLocation(
				routeFilters.startAddrLocation ? routeFilters.startAddrLocation : ''
			);
			setEndAddrLocation(routeFilters.endAddrLocation ? routeFilters.endAddrLocation : '');
		}
	}, []);

	useEffect(() => {
		setIsSubmitDisabled(
			startAddrInputLoading || endAddrInputLoading || !startAddrLocation || !endAddrLocation
		);
	}, [startAddrLocation, endAddrLocation, startAddrInputLoading, endAddrInputLoading]);

	const { handleSubmit, control, reset, watch, setValue } = useForm({
		defaultValues
	});

	const timeoutRef = useRef(undefined);
	const handleInputChange = (event, newInputValue, addressType) => {
		const value = newInputValue;
		if (addressType === 'startAddress') {
			setStartAddress(value);
		} else if (addressType === 'endAddress') {
			setEndAddress(value);
		}

		// clear any previously triggered timeouts
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}

		// wait 1 sec before calling the api to not spam it as the suser is typing
		timeoutRef.current = setTimeout(() => {
			setPredictionOption(newInputValue, addressType);
		}, 1000);
	};

	const setPredictionOption = (term, addressType) => {
		if (!term) return; // if the search field is empty, do not search

		if (addressType === 'startAddress') {
			setStartAddrInputLoading(true);
			searchOnRecentListings(term)
				.then((res) => {
					if (res.data.success) {
						setStartAddrOptions(res.data.homes);
						setStartAddrInputLoading(false);
					} else {
						setStartAddrInputLoading(false);
					}
				})
				.catch((err) => {
					setStartAddrOptions([]);
					setStartAddrInputLoading(false);
				});
		} else if (addressType === 'endAddress') {
			setEndAddrInputLoading(true);
			searchOnRecentListings(term)
				.then((res) => {
					if (res.data.success) {
						setEndAddrOptions(res.data.homes);
						setEndAddrInputLoading(false);
					} else {
						setEndAddrInputLoading(false);
					}
				})
				.catch((err) => {
					setEndAddrOptions([]);
					setEndAddrInputLoading(false);
				});
		}
	};

	const handleSearchSubmit = async () => {
		let filtersPersisted = {};
		if (startAddress) {
			filtersPersisted['startAddress'] = startAddress;
			if (restoredFilters) restoredFilters['startAddress'] = startAddress;
			filtersPersisted['startAddrLocation'] = startAddrLocation;
			if (restoredFilters) restoredFilters['startAddrLocation'] = startAddrLocation;
		}
		if (endAddress) {
			filtersPersisted['endAddress'] = endAddress;
			if (restoredFilters) restoredFilters['endAddress'] = endAddress;
			filtersPersisted['endAddrLocation'] = endAddrLocation;
			if (restoredFilters) restoredFilters['endAddrLocation'] = endAddrLocation;
		}
		if (extraTime) {
			filtersPersisted['extraTime'] = extraTime;
			if (restoredFilters) restoredFilters['extraTime'] = extraTime;
		}
		dispatchUser({ routeFilters: filtersPersisted });
		handleClose();
		refreshListings(filtersPersisted);
	};

	const handleReset = () => {
		reset(defaultValuesEmpty);
		if (restoredFilters && 'routeFilters' in restoredFilters) {
			delete restoredFilters['routeFilters'];
		}
		dispatchUser({ routeFilters: null });
		handleClose();
	};

	const handleChangeAutocomplete = async (e, newValue, reason, addressType) => {
		if (reason !== 'select-option') {
			return;
		}
		if (addressType === 'startAddress') {
			setStartAddress(newValue);
			setStartAddrInputLoading(true);
			setStartAddrAutoCompltOpen(false);
			try {
				const res = await getRecentListingLocationByAddress(
					newValue.address,
					newValue.city
				);
				if (res.data.success) {
					setStartAddrLocation(res.data.location);
				}
			} catch (error) {
				console.error('Error fetching listings for start address:', error);
			} finally {
				setStartAddrInputLoading(false);
			}
		} else if (addressType === 'endAddress') {
			setEndAddress(newValue);
			setEndAddrInputLoading(true);
			setEndAddrAutoCompltOpen(false);
			try {
				const res = await getRecentListingLocationByAddress(
					newValue.address,
					newValue.city
				);
				if (res.data.success) {
					setEndAddrLocation(res.data.location);
				}
			} catch (error) {
				console.error('Error fetching listings for end address:', error);
			} finally {
				setEndAddrInputLoading(false);
			}
		}
	};

	return (
		<React.Fragment>
			<form
				className={classes.form}
				id="userFilters"
				onSubmit={handleSubmit(handleSearchSubmit)}
			>
				<CardContent className={classes.cardContent}>
					<Grid container justifyContent="start" alignItems="center" spacing={2}>
						<Grid container style={{ gap: '0.7rem' }} item xs={12} direction="column">
							<Grid
								item
								container
								style={{
									gap: '0.7rem',
									marginTop: '1rem',
									paddingBottom: '1.5rem',
									marginBottom: '0.5rem'
								}}
							>
								<Grid item container xs={12} style={{ alignItems: 'center' }}>
									<Grid item xs={4}>
										<Typography>Departure Address</Typography>
									</Grid>
									<Grid item xs={8}>
										<Box className={classes.searchBox}>
											<Autocomplete
												className={classes.autocomplete}
												id="search-homes-start"
												open={
													startAddress !== '' && startAddrAutoCompltOpen
												}
												onOpen={() => {
													setStartAddrAutoCompltOpen(true);
												}}
												onClose={() => {
													setStartAddrInputLoading(false);
												}}
												clearOnBlur={false}
												clearOnEscape={false}
												getOptionSelected={(option, value) =>
													option.name === value.name
												}
												getOptionLabel={(option) =>
													option.value ? option.value : ''
												}
												options={startAddroptions}
												inputValue={startAddress}
												onInputChange={(event, inputValue) =>
													handleInputChange(
														event,
														inputValue,
														'startAddress'
													)
												}
												loading={startAddrInputLoading}
												fullWidth
												onChange={(event, newValue, reason) =>
													handleChangeAutocomplete(
														event,
														newValue,
														reason,
														'startAddress'
													)
												}
												renderOption={(option, { inputValue }) => {
													const matches = match(option.value, inputValue);
													const parts = parse(option.value, matches);
													return (
														<div>
															{parts.map((part, index) => (
																<span
																	key={index}
																	style={{
																		fontWeight: part.highlight
																			? 700
																			: 400
																	}}
																>
																	{part.text}
																</span>
															))}
														</div>
													);
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														variant="outlined"
														size="small"
														label={'Enter an address'}
														fullWidth
														InputProps={{
															...params.InputProps,
															endAdornment: (
																<InputAdornment position="end">
																	<Box
																		width={24}
																		display="flex"
																		justifyContent="center"
																	>
																		{startAddrInputLoading && (
																			<CircularProgress
																				color="primary"
																				size={20}
																			/>
																		)}
																	</Box>
																</InputAdornment>
															)
														}}
													/>
												)}
											/>
										</Box>
									</Grid>
								</Grid>
								<Grid item container xs={12} style={{ alignItems: 'center' }}>
									<Grid item xs={4}>
										<Typography>Destination Address</Typography>
									</Grid>
									<Grid item xs={8}>
										<Box className={classes.searchBox}>
											<Autocomplete
												className={classes.autocomplete}
												id="search-homes-end"
												open={endAddress !== '' && endAddrAutoCompltOpen}
												onOpen={() => {
													setEndAddrAutoCompltOpen(true);
												}}
												onClose={() => {
													setEndAddrInputLoading(false);
												}}
												clearOnBlur={false}
												clearOnEscape={false}
												getOptionSelected={(option, value) =>
													option.name === value.name
												}
												getOptionLabel={(option) =>
													option.value ? option.value : ''
												}
												options={endAddroptions}
												inputValue={endAddress}
												onInputChange={(event, inputValue) =>
													handleInputChange(
														event,
														inputValue,
														'endAddress'
													)
												}
												loading={endAddrInputLoading}
												fullWidth
												onChange={(event, newValue, reason) =>
													handleChangeAutocomplete(
														event,
														newValue,
														reason,
														'endAddress'
													)
												}
												renderOption={(option, { inputValue }) => {
													const matches = match(option.value, inputValue);
													const parts = parse(option.value, matches);
													return (
														<div>
															{parts.map((part, index) => (
																<span
																	key={index}
																	style={{
																		fontWeight: part.highlight
																			? 700
																			: 400
																	}}
																>
																	{part.text}
																</span>
															))}
														</div>
													);
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														variant="outlined"
														size="small"
														label={'Enter an address'}
														fullWidth
														InputProps={{
															...params.InputProps,
															endAdornment: (
																<InputAdornment position="end">
																	<Box
																		width={24}
																		display="flex"
																		justifyContent="center"
																	>
																		{endAddrInputLoading && (
																			<CircularProgress
																				color="primary"
																				size={20}
																			/>
																		)}
																	</Box>
																</InputAdornment>
															)
														}}
													/>
												)}
											/>
										</Box>
									</Grid>
								</Grid>
								<Grid item container xs={12} style={{ alignItems: 'center' }}>
									<Grid item xs={4}>
										<Typography>Extra Time</Typography>
									</Grid>
									<Grid item xs={8}>
										<TextField
											id="outlined-basic"
											variant="outlined"
											size="small"
											label={'Enter Extra Time'}
											fullWidth
											className={classes.textField}
											value={extraTime}
											type="number"
											onChange={(e) => setExtraTime(e.target.value)}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</CardContent>
				<CardActions className={classes.actions}>
					<Grid item container alignItems="center">
						<Grid item xs={6}>
							<Button
								onClick={() => {
									handleReset();
								}}
							>
								Clear All
							</Button>
						</Grid>
						<Grid item xs={6}>
							<Button
								// type="submit"
								onClick={handleSubmit(handleSearchSubmit)}
								id="advFilters"
								fullWidth
								size="large"
								variant="contained"
								color="primary"
								className={classes.submit}
								disabled={isSubmitDisabled}
							>
								Apply
							</Button>
						</Grid>
					</Grid>
				</CardActions>
			</form>
		</React.Fragment>
	);
}
