import React, { useState, useEffect } from 'react';
import { Grid, Typography, makeStyles, CircularProgress } from '@material-ui/core';
import { PieChart, Pie, Cell, Label, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import { getAllTaskStatusForUser } from '../../../../api/serverRequests';

const useStyles = makeStyles((theme) => ({
	calls: { display: 'flex', flexDirection: 'column' },
	spinner: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }
}));

function CustomLabel(props) {
	const { cx, cy } = props.viewBox;
	return (
		<>
			<text
				x={cx}
				y={cy - 5}
				fill="rgba(0, 0, 0, 0.87)"
				className="recharts-text recharts-label"
				textAnchor="middle"
				dominantBaseline="central"
			>
				<tspan alignmentBaseline="middle" fontSize="31.5px" fontFamily="Roboto">
					{props.value2}
				</tspan>
			</text>
			<text
				x={cx}
				y={cy + 20}
				fill="rgba(0, 0, 0, 0.54)"
				className="recharts-text recharts-label"
				textAnchor="middle"
				dominantBaseline="central"
			>
				<tspan fontSize="12.3px" fontFamily="Roboto">
					{props.value1}
				</tspan>
			</text>
		</>
	);
}
export default function KnockingPieChart(props) {
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(true);
	const { curAgent, startDate, endDate } = props;
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(null);
	const [activeIndex, setActiveIndex] = useState(null);

	useEffect(() => {
		setIsLoading(true);
		getAllTaskStatusForUser(curAgent, startDate, endDate)
			.then((res) => {
				if (res.status === 200) {
					const tasks = res.data.task_data;
					setTotal(res.data.total);

					const pieData = tasks
						.filter((status) => status['Total'] > 0)
						.map((v, i) => ({
							name: v['StatusName'],
							value: v['Total']
						}));

					setData(pieData);
				}
			})
			.then(() => {
				setIsLoading(false);
			})
			.catch((err) => {
				if (err.response.status === 401) {
				}
				console.log(err.message);
				setIsLoading(false);
			});
	}, [curAgent, startDate, endDate]);

	const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#F44336'];

	const RADIAN = Math.PI / 180;
	const renderCustomizedLabel = ({
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		percent,
		name,
		value,
		index
	}) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		return (
			<text
				x={x}
				y={y}
				fill="white"
				textAnchor={x > cx ? 'start' : 'end'}
				dominantBaseline="central"
			>
				{`${value}`}
			</text>
		);
	};

	const onPieEnter = (_, index) => {
		setActiveIndex(index);
		// console.log(index);
	};

	return (
		<>
			{isLoading ? (
				<div className={classes.spinner}>
					<CircularProgress />
				</div>
			) : (
				<>
					{data.length > 0 ? (
						<ResponsiveContainer debounce={300} width="100%" height="100%">
							<PieChart width={400} height={300}>
								<Pie
									data={data}
									cx="50%"
									cy="50%"
									labelLine={false}
									label={renderCustomizedLabel}
									innerRadius="60%"
									outerRadius="90%"
									fill="#8884d8"
									dataKey="value"
									onMouseEnter={onPieEnter}
								>
									<Label
										width={30}
										position="center"
										content={
											<CustomLabel value1={'total tasks'} value2={total} />
										}
									/>
									{data.map((entry, index) => (
										<Cell
											key={`cell-${index}`}
											fill={COLORS[index % COLORS.length]}
										/>
									))}
								</Pie>
								<Tooltip />
								<Legend layout="vertical" verticalAlign="top" align="left" />
							</PieChart>
						</ResponsiveContainer>
					) : (
						<div className={classes.spinner}>
							<Typography variant="h4">You have no tasks!</Typography>
						</div>
					)}
				</>
			)}
		</>
	);
}
