import React, { useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';
import Image from '../../../assets/images/Login_Page_Image.png';
import logo from '../../../SaigeLogo.svg';

import { forgetPassword, forgetPasswordConfirm } from '../../../api/serverRequests';

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100vh'
	},
	image: {
		backgroundImage: `url(${Image})`,

		backgroundRepeat: 'no-repeat',
		backgroundColor:
			theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
		backgroundSize: 'cover',
		backgroundPosition: 'center'
	},
	paper: {
		padding: theme.spacing(15, 10),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		height: '100%',
		backgroundColor: theme.palette.grey[0]
	},

	formControl: {
		marginTop: theme.spacing(1),
		width: '100%',
		'& label.Mui-focused': {
			color: '#004F2B',
			background: 'rgba(72, 193, 198, 0.06)'
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#004F2B'
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#004F2B'
			},
			'&:hover fieldset': {
				borderColor: '#004F2B'
			},
			'&.Mui-focused fieldset': {
				borderColor: '#004F2B'
			}
		}
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
		alignItems: 'center'
	},
	submit: {
		margin: theme.spacing(0, 0, 2),
		backgroundColor: '#004F2B',
		boxShadow: 'none'
	},
	textInput: {
		'& label.Mui-focused': {
			color: '#004F2B',
			background: 'rgba(72, 193, 198, 0.06)'
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#004F2B'
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#004F2B'
			},
			'&:hover fieldset': {
				borderColor: '#004F2B'
			},
			'&.Mui-focused fieldset': {
				borderColor: '#004F2B'
			}
		}
	},
	logoImg: {
		height: '80px',
		width: '100px',
		marginBottom: theme.spacing(2)
	}
}));

//ForgetPassword component - reset password request with registered email
const ForgetPassword = ({ classes, handleForgetPassword }) => {
	const { handleSubmit, control, errors, watch } = useForm();

	const { email } = watch(['email']);

	const isEmailFilled = email && email.trim() !== '';
	const isFormFilled = isEmailFilled;

	return (
		<form className={classes.form} onSubmit={handleSubmit(handleForgetPassword)}>
			<Controller
				name="email"
				defaultValue=""
				control={control}
				render={(props) => (
					<TextField
						className={classes.textInput}
						variant="outlined"
						margin="normal"
						fullWidth
						id="email"
						label="Email Address"
						name="email"
						autoComplete="email"
						autoFocus
						value={props.value}
						onChange={props.onChange}
						inputRef={props.ref}
					/>
				)}
				rules={{
					required: 'Email is required',
					pattern: {
						value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
						message: 'Invalid email address'
					}
				}}
			/>
			<ErrorMessage
				errors={errors}
				name="email"
				render={({ message }) => <p className="text-danger mx-2">{message}</p>}
			/>
			<Button
				type="submit"
				fullWidth
				variant="contained"
				color="primary"
				className={classes.submit}
				disabled={!isFormFilled}
			>
				Submit
			</Button>
		</form>
	);
};

//Forget password confirm component - confirm with verification code and set new password
const ForgetPasswordConfirm = ({ classes, handleForgetPasswordConfirm }) => {
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const { handleSubmit, control, errors, getValues } = useForm();

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleClickShowConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	//comparing equality of two values
	const sameAs = (field, getValues) => (value) => {
		const compareTo = getValues(field);
		return compareTo === value;
	};

	return (
		<form className={classes.form} onSubmit={handleSubmit(handleForgetPasswordConfirm)}>
			<Controller
				name="code"
				defaultValue=""
				control={control}
				render={(props) => (
					<TextField
						variant="outlined"
						margin="normal"
						fullWidth
						id="code"
						label="Verification code"
						name="code"
						autoFocus
						value={props.value}
						onChange={props.onChange}
						inputRef={props.ref}
					/>
				)}
				rules={{
					required: 'Code is required',
					pattern: {
						value: /^\d{6}$/i,
						message: 'Invalid code!'
					}
				}}
			/>
			<ErrorMessage
				errors={errors}
				name="code"
				render={({ message }) => <p className="text-danger mx-2">{message}</p>}
			/>

			<Controller
				name="password"
				defaultValue=""
				control={control}
				render={(props) => (
					<FormControl required variant="outlined" className={classes.formControl}>
						<InputLabel htmlFor="password">New Password</InputLabel>
						<OutlinedInput
							name="password"
							id="Password"
							label="Password"
							type={showPassword ? 'text' : 'password'}
							autoComplete="current-password"
							value={props.value}
							onChange={props.onChange}
							inputRef={props.ref}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										edge="end"
									>
										{showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
				)}
				rules={{
					required: 'Password is required',
					minLength: { value: 8, message: 'Minimum length of password is 8 characters!' },
					pattern: {
						value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.,)(:";'\+_=]).{8,}$/,
						message:
							"A password must contain at least eight characters, including at least one number, both lower and uppercase letters, and special characters of '#?!@$%^&*-'"
					}
				}}
			/>
			<ErrorMessage
				errors={errors}
				name="password"
				render={({ message }) => <p className="text-danger mx-2">{message}</p>}
			/>

			<Controller
				name="confirmPassword"
				defaultValue=""
				control={control}
				render={(props) => (
					<FormControl required variant="outlined" className={classes.formControl}>
						<InputLabel htmlFor="confirmPassword">Confirm New Password</InputLabel>
						<OutlinedInput
							name="confirmPassword"
							id="confirmPassword"
							label="Confirm New Password"
							type={showConfirmPassword ? 'text' : 'password'}
							autoComplete="current-password"
							value={props.value}
							onChange={props.onChange}
							inputRef={props.ref}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowConfirmPassword}
										edge="end"
									>
										{showConfirmPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
				)}
				rules={{
					required: 'Confirm password is required',
					minLength: { value: 8, message: 'Minimum length of password is 8 characters!' },
					validate: sameAs('password', getValues)
				}}
			/>
			<ErrorMessage
				errors={errors}
				name="confirmPassword"
				render={({ message }) => {
					return errors.confirmPassword?.type === 'validate' ? (
						<p className="text-danger mx-2">Password does not match!</p>
					) : (
						<p className="text-danger mx-2">{message}</p>
					);
				}}
			/>
			<Button
				type="submit"
				fullWidth
				variant="contained"
				color="primary"
				className={classes.submit}
			>
				Submit
			</Button>
		</form>
	);
};

export default function ResetPassword() {
	const history = useHistory();
	const [isConfirming, setIsConfirming] = useState(false);
	const [email, setEmail] = useState(null);
	const [errMsg, setErrMsg] = useState('');
	const classes = useStyles();

	const handleForgetPassword = (formData) => {
		forgetPassword(formData)
			.then((res) => {
				if (res.data.success) {
					setEmail(formData.email);
					setIsConfirming(true);
				} else {
					setErrMsg(res.data.err);
				}
			})
			.catch((err) => {
				if (err.response.status === 400) {
					setErrMsg('Invalid parameters');
				} else {
					setErrMsg('Oops, Internal Error');
				}
				console.log(err.message);
			});
	};

	const handleForgetPasswordConfirm = (formData) => {
		formData.email = email;
		forgetPasswordConfirm(formData)
			.then((res) => {
				if (res.data.success) {
					setIsConfirming(false);
					history.push('/login');
				} else {
					setErrMsg(res.data.err);
				}
			})
			.catch((err) => {
				if (err.response.status === 400) {
					setErrMsg('Invalid parameters');
				} else {
					setErrMsg('Oops, Internal Error');
				}
				console.log(err.message);
			});
	};
	return (
		<Grid container component="main" className={classes.root}>
			<CssBaseline />
			<Grid item xs={false} sm={4} md={7} className={classes.image} />
			<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
				<div className={classes.paper}>
					<img src={logo} alt="Logo" className={classes.logoImg} />

					<Typography component="h1" variant="h5">
						Reset Password
					</Typography>
					{errMsg && <p className="text-danger my-0">{errMsg}</p>}
					{isConfirming ? (
						<ForgetPasswordConfirm
							classes={classes}
							handleForgetPasswordConfirm={handleForgetPasswordConfirm}
						/>
					) : (
						<ForgetPassword
							classes={classes}
							handleForgetPassword={handleForgetPassword}
						/>
					)}
				</div>
			</Grid>
		</Grid>
	);
}
