import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Tabs, Tab, Box, Snackbar } from '@material-ui/core/';
import Alert from '@material-ui/lab/Alert';

import CustomTab from './CustomTab';
import DetailTab from './DetailTab';
import ListingAgentsTab from './ListingAgentsTab';
import NearbyActiveTab from './NearbyActiveTab';
import NearbySoldTab from './NearbySoldTab';
import NearbyOffMarketTab from './NearbyOffMarketTab';
import TopAgentsTab from './TopAgentsTab';
import ListingHistoryTab from './ListingHistoryTab';
import AssessmentTab from './AssessmentTab';

import { useUserState } from '../../../hook/customerHook';
import {
	getKnockingStatusAndFollowUp,
	getCommentsAndTags,
	getAllNearbyActiveListings,
	getAllNearbySoldListings,
	getAllNearbyOffMarketListings,
	getNearbyAgents,
	getAllListings,
	getAssignedTask,
	getAssessmentData
} from '../../../api/serverRequests';
import OwnerInfoTab from './OwnerInfoTab';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={2}>
					<Box>{children}</Box>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

function a11yProps(index) {
	return {
		id: `scrollable-force-tab-${index}`,
		'aria-controls': `scrollable-force-tabpanel-${index}`
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: 30,
		flexGrow: 1,
		width: '100%',
		backgroundColor: theme.palette.background.paper
	},
	tabs: {
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: 'clamp(0.9rem, 0.9vw + 0.3rem, 1rem) !important',
		'& .MuiTab-root.Mui-selected': {
			fontWeight: theme.typography.fontWeightBold,
			backgroundColor: theme.palette.green['KN_mint_10']
		}
	},
	tab: {
		borderBottom: '1.5px solid',
		borderBottomColor: theme.palette.grey['KN_light_grey'],
		fontSize: 'inherit',
		'&:hover': {
			backgroundColor: theme.palette.grey['KN_back_ground']
		}
	}
}));

export default function DetailsPageTabs({
	listing,
	userNavigatedToNewProperty,
	shouldRefetch,
	setShouldRefetch,
	assignedInfo,
	setAssignedInfo,
	knockingStatusVisible,
	editAssignedTaskVisible,
	viewCommentsVisible,
	tagsVisible,
	viewCallRecordingsVisible,
	addCommentsVisible,
	permissionChecked,
	setPermissionChecked,
	resetPermissions,
	viewTopAgentsVisible,
	setCheckListingHistory,
	assignMyself,
	viewEditHistoryVisible,
	viewOwnerName,
	viewOwnerPhone,
	ownerDetailsInformation,
	ownerInfoLoading,
	isUsingHomeId
}) {
	const classes = useStyles();
	const [userState, dispatchUser] = useUserState();
	const [value, setValue] = useState(isUsingHomeId ? 4 : 0);

	// THE BELOW STATES ARE MEANT FOR THE CUSTOM TAB
	const [primaryInfo, setPrimaryInfo] = useState({}); // object that holds knocking status and follow up time
	const [secondaryInfo, setSecondaryInfo] = useState({}); // object that holds comments and tags

	const [isLoadingPrimary, setIsLoadingPrimary] = useState(true);
	const [isLoadingSecondary, setIsLoadingSecondary] = useState(true);

	// const [shouldRefetch, setShouldRefetch] = useState(false); // after the user clicks save we update this state so the useEffect runs again

	// // submit status updated after user has clicked save in edit modal
	const [submitSuccessStatus, setSubmitSuccessStatus] = useState(''); // keeps track of status when data is posted

	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message

	// THE BELOW STATES ARE MEANT FOR TOP AGENTS TAB
	const [listingHistory, setListingHistory] = useState([]);

	const [isLoadingListingHistory, setIsLoadingListingHistory] = useState(true);

	// THE BELOW STATES ARE MEANT FOR ACTIVE LISTINGS TAB
	const [activeListings, setActiveListings] = useState([]);

	const [isLoadingActiveListings, setIsLoadingActiveListings] = useState(true);

	// THE BELOW STATES ARE MEANT FOR SOLD LISTINGS TAB
	const [soldListings, setSoldListings] = useState([]);

	const [isLoadingSoldListings, setIsLoadingSoldListings] = useState(true);

	// THE BELOW STATES ARE MEANT FOR OFF MARKET LISTINGS TAB
	const [offMarketListings, setOffMarketListings] = useState([]);

	const [isLoadingOffMarketListings, setIsLoadingOffMarketListings] = useState(true);

	// THE BELOW STATES ARE MEANT FOR TOP AGENTS TAB
	const [topAgents, setTopAgents] = useState([]);

	const [isLoadingTopAgents, setIsLoadingTopAgents] = useState(true);

	// THE BELOW STATES ARE MEANT FOR ASSESSMENT TAB
	const [assessmentDetails, setAssessmentDetails] = useState([]);

	const [isLoadingAssessment, setIsLoadingAssessment] = useState(true);

	useEffect(() => {
		// Here we fetch the 'primary info' (the knocking status and follow up time)
		// owner info is also stored here
		// results are stored in primaryInfo, then passed as a prop to <CustomTab />
		setIsLoadingPrimary(true);
		let unmounted = false;
		const fetchData = async () => {
			if (!unmounted) {
				getKnockingStatusAndFollowUp(listing.HomeId, listing.Address, listing.City)
					.then((res) => {
						if (res.status === 200) setPrimaryInfo(res.data.primaryInfo);
						if (!unmounted) setIsLoadingPrimary(false);
					})
					.catch((err) => {
						if (err.response.status === 401) {
							dispatchUser({
								isLoggedIn: false
							});
							return;
						} else if (!unmounted) {
							setIsLoadingPrimary(false);
						}
						console.error(err.message);
					});
			}
		};
		fetchData();
		return () => {
			unmounted = true;
		};
	}, [shouldRefetch, userNavigatedToNewProperty]);

	useEffect(() => {
		// Here we fetch the 'secondary info' (the tags and comments)
		// results are stored in secondaryInfo, then passed as a prop to <CustomTab />
		setIsLoadingSecondary(true);
		let unmounted = false;
		const fetchData = async () => {
			if (!unmounted) {
				getCommentsAndTags(listing.HomeId)
					.then((res) => {
						if (res.status === 200) setSecondaryInfo(res.data.secondaryInfo);
						if (!unmounted) {
							setIsLoadingSecondary(false);
							setPermissionChecked(true);
						}
					})
					.catch((err) => {
						if (err.response.status === 401) {
							dispatchUser({
								isLoggedIn: false
							});
							return;
						} else if (!unmounted) {
							setIsLoadingSecondary(false);
						}
						console.error(err.message);
					});
			}
		};
		if (viewCommentsVisible || tagsVisible) fetchData();
		else setIsLoadingSecondary(false);

		return () => {
			unmounted = true;
		};
	}, [shouldRefetch, userNavigatedToNewProperty, viewCommentsVisible, tagsVisible]);

	useEffect(() => {
		// Here we fetch the data for the listing history
		setIsLoadingListingHistory(true);
		let unmounted = false;
		const fetchData = async () => {
			if (!unmounted) {
				getAllListings(listing.HomeId)
					.then((res) => {
						if (res.status === 200) setListingHistory(res.data.listings);
						res.data.listings.length > 2
							? setCheckListingHistory(
									res.data.listings
										.slice(0, 3)
										.every(
											(listing) =>
												listing.Status === 'T' ||
												listing.Status === 'X' ||
												listing.Status === 'C'
										)
							  )
							: setCheckListingHistory(false);
						if (!unmounted) setIsLoadingListingHistory(false);
					})
					.catch((err) => {
						if (err.response.status === 401) {
							dispatchUser({
								isLoggedIn: false
							});
							return;
						} else if (!unmounted) {
							setIsLoadingListingHistory(false);
						}
						console.error(err.message);
					});
			}
		};
		fetchData();
		return () => {
			unmounted = true;
		};
	}, [userNavigatedToNewProperty]);

	useEffect(() => {
		let unmounted = false;
		const fetchData = async () => {
			setIsLoadingAssessment(true); // Set loading state when fetching data
			try {
				const res = await getAssessmentData(listing.HomeId);
				if (res.status === 200) {
					setAssessmentDetails(res.data.assessment_details);
				}
			} catch (err) {
				if (err.response && err.response.status === 401) {
					dispatchUser({ isLoggedIn: false });
				} else {
					console.error(err.message);
				}
			} finally {
				if (!unmounted) setIsLoadingAssessment(false); // Clear loading state regardless of success or failure
			}
		};

		// Reset assessment details when navigating away from a property
		setAssessmentDetails([]); // This line clears out the assessment details

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [listing.HomeId]);

	useEffect(() => {
		// Here we fetch the data for the listing history

		let unmounted = false;
		const fetchData = async () => {
			if (!unmounted) {
				getAllListings(listing.HomeId)
					.then((res) => {
						if (res.status === 200) setListingHistory(res.data.listings);
						if (!unmounted) setIsLoadingListingHistory(false);
					})
					.catch((err) => {
						if (err.response.status === 401) {
							dispatchUser({
								isLoggedIn: false
							});
							return;
						} else if (!unmounted) {
							setIsLoadingListingHistory(false);
						}
						console.error(err.message);
					});
			}
		};
		fetchData();
		return () => {
			unmounted = true;
		};
	}, []);

	useEffect(() => {
		// Here we fetch the data for all active listings
		setIsLoadingActiveListings(true);
		let unmounted = false;
		const fetchData = async () => {
			if (!unmounted) {
				getAllNearbyActiveListings(listing.location, listing.dwellingClassification)
					.then((res) => {
						if (res.status === 200) setActiveListings(res.data.active_listings);
						if (!unmounted) setIsLoadingActiveListings(false);
					})
					.catch((err) => {
						if (err.response.status === 401) {
							dispatchUser({
								isLoggedIn: false
							});
							return;
						} else if (!unmounted) {
							setIsLoadingActiveListings(false);
						}
						console.error(err.message);
					});
			}
		};
		fetchData();
		return () => {
			unmounted = true;
		};
	}, [userNavigatedToNewProperty]);

	useEffect(() => {
		let unmounted = false;
		const fetchData = async () => {
			if (!unmounted) {
				getAssignedTask(listing.HomeId)
					.then((res) => {
						if (res.status === 200) setAssignedInfo(res.data.assignedInfo);
					})
					.catch((err) => {
						if (err.response.status === 401) {
							dispatchUser({
								isLoggedIn: false
							});
							return;
						}
						console.error(err.message);
					});
			}
		};
		fetchData();
		return () => {
			unmounted = true;
		};
	}, [shouldRefetch, userNavigatedToNewProperty]);

	useEffect(() => {
		// Here we fetch the data for all sold listings
		setIsLoadingSoldListings(true);
		let unmounted = false;
		const fetchData = async () => {
			if (!unmounted) {
				getAllNearbySoldListings(listing.location, listing.dwellingClassification)
					.then((res) => {
						if (res.status === 200) setSoldListings(res.data.sold_listings);
						if (!unmounted) setIsLoadingSoldListings(false);
					})
					.catch((err) => {
						if (err.response.status === 401) {
							dispatchUser({
								isLoggedIn: false
							});
							return;
						} else if (!unmounted) {
							setIsLoadingSoldListings(false);
						}
						console.error(err.message);
					});
			}
		};
		fetchData();
		return () => {
			unmounted = true;
		};
	}, [userNavigatedToNewProperty]);

	useEffect(() => {
		// Here we fetch the data for all off market listings
		setIsLoadingOffMarketListings(true);
		let unmounted = false;
		const fetchData = async () => {
			if (!unmounted) {
				getAllNearbyOffMarketListings(listing.location, listing.dwellingClassification)
					.then((res) => {
						if (res.status === 200) setOffMarketListings(res.data.offmarket_listings);
						if (!unmounted) setIsLoadingOffMarketListings(false);
					})
					.catch((err) => {
						if (err.response.status === 401) {
							dispatchUser({
								isLoggedIn: false
							});
							return;
						} else if (!unmounted) {
							setIsLoadingOffMarketListings(false);
						}
						console.error(err.message);
					});
			}
		};
		fetchData();
		return () => {
			unmounted = true;
		};
	}, [userNavigatedToNewProperty]);

	useEffect(() => {
		// Here we fetch the data for all top agents
		setIsLoadingTopAgents(true);
		let unmounted = false;
		const fetchData = async () => {
			if (!unmounted) {
				getNearbyAgents(listing.Area, listing.SA, listing.HomeId)
					.then((res) => {
						if (res.status === 200) setTopAgents(res.data.nearby_agents);
						if (!unmounted) setIsLoadingTopAgents(false);
					})
					.catch((err) => {
						if (err.response.status === 401) {
							dispatchUser({
								isLoggedIn: false
							});
							return;
						} else if (!unmounted) {
							setIsLoadingTopAgents(false);
						}
						console.error(err.message);
					});
			}
		};
		fetchData();
	}, [userNavigatedToNewProperty]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<>
			{/* NOTIFICATION FOR SUBMITTING EDIT MODAL */}
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={notificationState}
				autoHideDuration={4500}
				onClose={() => setNotificationState(false)}
			>
				<Alert
					onClose={() => setNotificationState(false)}
					severity={submitSuccessStatus === 'success' ? 'success' : 'error'}
					variant="filled"
				>
					{notificationMessage}
				</Alert>
			</Snackbar>

			<div className={classes.root}>
				<Grid>
					<Tabs
						value={value}
						onChange={handleChange}
						indicatorColor="primary"
						textColor="primary"
						variant="scrollable"
						scrollButtons="on"
						className={classes.tabs}
						aria-label="scrollable tabs"
					>
						{!isUsingHomeId && (
							<Tab
								className={classes.tab}
								label="DETAIL"
								{...a11yProps(0)}
								value={0}
							/>
						)}
						<Tab className={classes.tab} label="CUSTOM" {...a11yProps(1)} value={1} />
						<Tab className={classes.tab} label="OWNER" {...a11yProps(2)} value={2} />
						<Tab
							className={classes.tab}
							label="LISTING HISTORY"
							{...a11yProps(3)}
							value={3}
						/>

						<Tab
							className={classes.tab}
							label="ASSESSMENT"
							{...a11yProps(4)}
							value={4}
						/>
						<Tab
							className={classes.tab}
							label="NEARBY ACTIVE"
							{...a11yProps(5)}
							value={5}
						/>
						<Tab
							className={classes.tab}
							label="NEARBY SOLD"
							{...a11yProps(6)}
							value={6}
						/>
						<Tab
							className={classes.tab}
							label="NEARBY OFF MARKET"
							{...a11yProps(7)}
							value={7}
						/>
						{viewTopAgentsVisible && (
							<Tab
								className={classes.tab}
								label="TOP AGENTS"
								{...a11yProps(8)}
								value={8}
							/>
						)}
						<Tab
							className={classes.tab}
							label="LIST/SELL AGENTS"
							{...a11yProps(9)}
							value={9}
						/>
					</Tabs>
				</Grid>
				{!isUsingHomeId && (
					<>
						<TabPanel value={value} index={0}>
							<DetailTab
								listing={listing}
								ownerDetailsInformation={ownerDetailsInformation}
							/>
						</TabPanel>
					</>
				)}
				<TabPanel value={value} index={1}>
					<CustomTab
						listing={listing}
						primaryInfo={primaryInfo}
						secondaryInfo={secondaryInfo}
						assignedInfo={assignedInfo}
						shouldRefetch={shouldRefetch}
						setShouldRefetch={setShouldRefetch}
						isLoadingPrimary={isLoadingPrimary}
						isLoadingSecondary={isLoadingSecondary}
						submitSuccessStatus={submitSuccessStatus}
						setSubmitSuccessStatus={setSubmitSuccessStatus}
						setNotificationState={setNotificationState}
						setNotificationMessage={setNotificationMessage}
						knockingStatusVisible={knockingStatusVisible}
						viewCommentsVisible={viewCommentsVisible}
						tagsVisible={tagsVisible}
						editAssignedTaskVisible={editAssignedTaskVisible}
						viewCallRecordingsVisible={viewCallRecordingsVisible}
						addCommentsVisible={addCommentsVisible}
						permissionChecked={permissionChecked}
						resetPermissions={resetPermissions}
						assignMyself={assignMyself}
						viewEditHistoryVisible={viewEditHistoryVisible}
						viewOwnerName={viewOwnerName}
						viewOwnerPhone={viewOwnerPhone}
					/>
				</TabPanel>
				<TabPanel value={value} index={2}>
					<OwnerInfoTab
						ownerDetailsInformation={ownerDetailsInformation}
						ownerInfoLoading={ownerInfoLoading}
					/>
				</TabPanel>
				<TabPanel value={value} index={3}>
					<ListingHistoryTab
						listingHistory={listingHistory}
						isLoadingListingHistory={isLoadingListingHistory}
					/>
				</TabPanel>
				<TabPanel value={value} index={4}>
					<AssessmentTab
						assessmentDetails={assessmentDetails}
						isLoadingAssessment={isLoadingAssessment}
					/>
				</TabPanel>
				<TabPanel value={value} index={5}>
					<NearbyActiveTab
						listing={listing}
						activeListings={activeListings}
						isLoadingActiveListings={isLoadingActiveListings}
					/>
				</TabPanel>
				<TabPanel value={value} index={6}>
					<NearbySoldTab
						listing={listing}
						soldListings={soldListings}
						isLoadingSoldListings={isLoadingSoldListings}
					/>
				</TabPanel>
				<TabPanel value={value} index={7}>
					<NearbyOffMarketTab
						listing={listing}
						offMarketListings={offMarketListings}
						isLoadingOffMarketListings={isLoadingOffMarketListings}
					/>
				</TabPanel>
				{viewTopAgentsVisible && (
					<TabPanel value={value} index={8}>
						<TopAgentsTab
							topAgents={topAgents}
							isLoadingTopAgents={isLoadingTopAgents}
						/>
					</TabPanel>
				)}
				{value === 9 && <ListingAgentsTab listing={listing} />}
			</div>
		</>
	);
}
