import React, { useState, useEffect } from 'react';
import {
	Popover,
	FormControl,
	FormLabel,
	FormGroup,
	FormControlLabel,
	Checkbox,
	IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const useStyles = makeStyles((theme) => ({
	popup: {
		padding: theme.spacing(2)
	}
}));

export default function AddItems({ items, onRemoveItem, onAddItem, originalItems }) {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleChange = (e) => {
		if (e.target.checked) {
			onAddItem(e.target.name);
		} else {
			onRemoveItem(e.target.name);
		}
	};

	const open = Boolean(anchorEl);

	const id = open ? 'simple-popover' : undefined;
	return (
		<>
			<IconButton aria-label="add" onClick={handleClick} aria-describedby={id}>
				<AddCircleOutlineIcon />
			</IconButton>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
			>
				<div className={classes.popup}>
					<FormControl component="fieldset">
						<FormLabel component="legend">Select Widgets</FormLabel>
						<FormGroup>
							{originalItems.map((i) => (
								<FormControlLabel
									control={
										<Checkbox
											checked={items.some((sub) => {
												return sub.widget === i.widget;
											})}
											onChange={handleChange}
											name={i.widget}
										/>
									}
									label={i.name}
									key={i.name}
								/>
							))}
						</FormGroup>
					</FormControl>
				</div>
			</Popover>
		</>
	);
}
