import React, { useState, useEffect } from 'react';
import { Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	root: {
		margin: '0',
		padding: '0'
	},
	label: {
		marginBottom: '1rem',
		fontWeight: '700'
	}
}));

const options = [
	{ label: 'Not interested', value: 'Not interested' },
	{ label: 'Called', value: 'Called' },
	{ label: 'Ongoing', value: 'Ongoing' }
];
export default function KnockingStatus({
	addDataToFormObject,
	knockingStatusOptions,
	defaultKnockingStatusVal
}) {
	const classes = useStyles();

	return (
		<Grid item xs={12} className={classes.root}>
			<Autocomplete
				disablePortal
				disableClearable
				id="knocking-status-field"
				defaultValue={{ label: defaultKnockingStatusVal, value: defaultKnockingStatusVal }}
				options={knockingStatusOptions}
				getOptionLabel={(option) => option.label}
				sx={{ width: 300 }}
				onChange={(e, selectedOption) =>
					addDataToFormObject('KnockingStatus', selectedOption?.value || '')
				}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Knocking Status"
						variant="outlined"
						id={'KnockingStatus'}
					/>
				)}
			/>
		</Grid>
	);
}
