import { getAreas } from '../../../api/serverRequests';

export default function AreaOptionsAPI(setAreaOptionsQuery, page, localKey, userId = null) {
	getAreas(page, userId).then((res) => {
		if (res.data.success) {
			let array = [];
			res.data.areas.map((items) =>
				array.push({
					label: items,
					value: items
				})
			);
			setAreaOptionsQuery(array);
			localStorage.setItem(localKey, JSON.stringify(array));
		}
	});
}
