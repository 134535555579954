import React, { useState, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		//
	}
}));

export default function CommentForm({ addDataToFormObject }) {
	const classes = useStyles();
	const id = 'CommentField';

	return (
		<form className={classes.root} noValidate autoComplete="off">
			<TextField
				fullWidth
				id={id}
				label="Add Comment"
				multiline
				rows={4}
				onChange={(e) => addDataToFormObject(id, e.target?.value)}
			/>
		</form>
	);
}
