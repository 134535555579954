import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	Container,
	Button,
	Grid,
	Chip,
	CircularProgress,
	Typography,
	TablePagination,
	TableContainer,
	Table,
	TableRow,
	TableCell,
	TableBody,
	Modal
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { getOrganizationRoles, deleteOrganizationRole } from '../../../../api/serverRequests';
import PermissionsForm from './PermissionsForm';
import ViewMoreModal from '../../DetailsPageTabs/CustomTab/ViewMoreModal';

const useStyles = makeStyles((theme) => ({
	chip: {
		display: 'inline-block',
		margin: 3
	},
	tableContainer: {
		marginTop: '20px'
	},
	header: {
		marginLeft: '10px',
		marginTop: '20px',
		fontSize: '20px',
		fontWeight: '400'
	},
	modalContainer: {
		width: '80%',
		height: '80%',
		backgroundColor: '#ffffff',
		border: '2px solid #000',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '8%'
	},
	viewMore: {
		display: 'inline-block',
		marginLeft: 10,
		textDecoration: 'underline',
		color: theme.palette.primary.main,
		transition: 'transform 0.07s linear',
		'&:hover': {
			cursor: 'pointer',
			transform: 'scale(0.95)'
		},
		'@media (max-width: 520px)': {
			marginLeft: 0
		}
	},
	btn: {
		float: 'right',
		marginLeft: '12px'
	},
	iconButton: {
		float: 'right'
	}
}));

export default function RolesList(tabValue, index) {
	const [isLoading, setIsLoading] = useState(true);
	const classes = useStyles();

	// for opening/closing view more modal
	const [viewMore, setViewMore] = useState(false);

	const [formOpen, setFormOpen] = useState(false);
	const [page, setPage] = useState(0);
	const [rolesCount, setRolesCount] = useState(10);
	const [rowsPerPage] = useState(10);
	const [roles, setRoles] = useState([]);
	const [displayedRoles, setDisplayedRoles] = useState(0);
	const [rolesToRender, setRolesToRender] = useState(null);
	const PERMS = JSON.parse(localStorage.getItem('PERMISSIONS'));
	const PERMISSIONS = Object.assign({}, PERMS.main, PERMS.children);

	// useEffect to determine how many Roles to display based on a given the users viewWidth
	useEffect(() => {
		function updateDisplayedRoles() {
			const screenWidth = window.innerWidth;
			let numberOfRolesToDisplay;

			if (screenWidth < 600) numberOfRolesToDisplay = 0;
			else numberOfRolesToDisplay = 100;

			setDisplayedRoles(numberOfRolesToDisplay);
		}

		updateDisplayedRoles();
		window.addEventListener('resize', updateDisplayedRoles);

		return () => {
			window.removeEventListener('resize', updateDisplayedRoles);
		};
	}, []);

	const handleChangePage = (e, newPage) => {
		setPage(newPage);
	};

	const handleEditBtnClick = (role) => {
		const form = {
			roleId: role.Id,
			roleName: role.Name,
			Permissions: role.Permissions
		};
		localStorage.setItem('permissionsForm', JSON.stringify(form));
		setFormOpen(true);
	};

	const handleCloseForm = () => {
		localStorage.removeItem('permissionsForm');
		setFormOpen(false);
	};

	const handleDeleteBtnClick = (role) => {
		deleteOrganizationRole(role.Id)
			.then((res) => {
				if (res.data.success) {
					setIsLoading(true);
				}
			})
			.catch((err) => {
				console.log(err.message);
			});
	};

	const renderRoles = (rolesToRender) => {
		return Object.keys(rolesToRender).map((role) => (
			<>
				{role in PERMISSIONS && (
					<Grid key={`${PERMISSIONS[role].label}`} item className={classes.chip}>
						<Chip label={`${PERMISSIONS[role].label}`} />
					</Grid>
				)}
			</>
		));
	};

	const handleNewRoleBtn = () => {
		localStorage.setItem('permissionsForm', JSON.stringify({}));
		setFormOpen(true);
	};

	const handleRefreshBtn = () => {
		setIsLoading(true);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await getOrganizationRoles();
				if (res.data.success) {
					const rolesData = res.data.roles;
					console.log(rolesData);
					setRoles(rolesData);
					setRolesCount(rolesData.length);
					setIsLoading(false);
				}
			} catch (e) {
				console.log(e);
			}
		};

		if (isLoading) {
			fetchData();
		}
	});

	return isLoading ? (
		<CircularProgress size={50} color="inherit" />
	) : (
		<React.Fragment>
			<div role="tabpanel" id={index}>
				<Modal open={formOpen} onClose={handleCloseForm}>
					<Container className={classes.modalContainer}>
						<PermissionsForm
							setIsLoadingUserAccessTab={setIsLoading}
							setFormOpen={setFormOpen}
						/>
					</Container>
				</Modal>
				<Container>
					<Container style={{ flexDirection: 'row' }}>
						<Button
							variant="contained"
							color="secondary"
							className={classes.btn}
							onClick={handleRefreshBtn}
						>
							Refresh
						</Button>
						<Button
							variant="contained"
							color="primary"
							className={classes.btn}
							onClick={handleNewRoleBtn}
						>
							New Role
						</Button>
					</Container>
					<Typography className={classes.header}>
						Roles in your account ({rolesCount})
					</Typography>
					<TableContainer className={classes.tableContainer}>
						<Table sx={{ minWidth: 650 }}>
							<TableBody>
								{roles
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((role) => (
										<TableRow key={role['Id']}>
											<TableCell
												style={{ color: '#192591' }}
												component="th"
												scope="row"
											>
												{role['Name']}
												{displayedRoles === 0 &&
													` (${Object.keys(role['Permissions']).length})`}
											</TableCell>
											<TableCell component="th" scope="row">
												{displayedRoles > 0 ? (
													renderRoles(role['Permissions'])
												) : (
													<Typography
														variant="subtitle2"
														component="h6"
														className={classes.viewMore}
														onClick={() => {
															setViewMore(true);
															setRolesToRender(role['Permissions']);
														}}
													>
														View All
													</Typography>
												)}
											</TableCell>
											<TableCell component="th" scope="row" float="right">
												<IconButton
													lassName={classes.iconButton}
													onClick={() => handleEditBtnClick(role)}
												>
													<EditIcon></EditIcon>
												</IconButton>
											</TableCell>
											<TableCell component="th" scope="row" float="right">
												<IconButton
													className={classes.iconButton}
													onClick={() => handleDeleteBtnClick(role)}
												>
													<DeleteIcon></DeleteIcon>
												</IconButton>
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						component="div"
						count={rolesCount}
						page={page}
						onPageChange={handleChangePage}
						rowsPerPage={rowsPerPage}
						rowsPerPageOptions={[10]}
					/>
				</Container>
			</div>
			{viewMore && (
				<ViewMoreModal
					renderComponents={renderRoles}
					itemsToRender={rolesToRender}
					title={'Roles'}
					viewMore={viewMore} // for controlling opening and closing modal
					setViewMore={setViewMore} // for controlling opening and closing modal
				/>
			)}
		</React.Fragment>
	);
}
