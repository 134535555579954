import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import Title from '../Title';
import Tag from '../Tag';
import { useUserState } from '../../../hook/customerHook';
import { getDetailsPopularityByUserFavourites } from '../../../api/serverRequests';
import { Paper, Grid, GridList, GridListTile } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'flex-start',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(1)
		},
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: '#fff'
		},
		maxHeight: '7.5rem',
		overflowY: 'scroll',
		padding: theme.spacing(2, 0.5, 1)
	},
	horizontalTag: { width: '100%', overflowX: 'auto' }
}));

export default function UserFavourites({
	handlePeelClick,
	handleLoading,
	userid,
	selectedId,
	setSelectedId
}) {
	const [peels, setPeels] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [, dispatchUser] = useUserState();

	const classes = useStyles();

	useEffect(() => {
		let unmounted = false;
		fetchData(unmounted);
		return () => {
			unmounted = true;
		};
	}, []);

	const fetchData = async (unmounted) => {
		getDetailsPopularityByUserFavourites()
			.then((res) => {
				console.log('belowthis');
				console.log(res);
				if (res.status === 200) {
					if (res.data.user_details.length > 0) {
						setPeels(res.data.user_details);
						setSelectedId(res.data.user_details[0].userid);
						handlePeelClick(res.data.user_details[0].userid);
					} else {
						setPeels(null);
						setSelectedId(null);
						handlePeelClick(null);
					}
				}
				if (!unmounted) {
					setIsLoading(false);
					handleLoading(false);
				}
			})
			.catch((err) => {
				if (err.response.status === 401) {
					dispatchUser({
						isLoggedIn: false
					});
				} else if (!unmounted) {
					setIsLoading(false);
					handleLoading(false);
				}
				console.log(err.message);
			});
	};

	return (
		<React.Fragment>
			{isLoading ? (
				<Backdrop className={classes.backdrop} open={true}>
					<CircularProgress size={50} color="inherit" />
				</Backdrop>
			) : (
				<Grid container wrap="nowrap" spacing={3} className={classes.horizontalTag}>
					{peels &&
						peels.map((peel) => (
							<Grid item>
								<Tag
									key={peel.username}
									tag={peel.username}
									count={peel.popularity}
									handleClick={() => {
										handlePeelClick(peel.userid);
										setSelectedId(peel.userid);
									}}
									isSelected={peel.userid === selectedId}
								/>
							</Grid>
						))}
				</Grid>
			)}
		</React.Fragment>
	);
}
