import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Alert from '@material-ui/lab/Alert';

import { makeStyles } from '@material-ui/core/styles';
import { CardContent, Grid, Button, Switch, FormLabel, Snackbar } from '@material-ui/core';

import { Loading, CusSelectFieldManual } from '../..';
import { useUserState } from '../../../hook/customerHook';
import { searchPriceDropListing } from '../../../api/serverRequests';
import { priceOptions, areaOptions, percentOptions } from './formOptions';
import CusSelectField from '../CusSelectField';

const useStyles = makeStyles((theme) => ({
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	formControl: {
		marginTop: theme.spacing(1),
		width: '100%'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	}
}));

export default function SearchPriceDroppedForm({ handleSearch, handleClose, areaOptionsQuery }) {
	const [userState, dispatchUser] = useUserState();
	const [isSearching, setIsSearching] = useState(false);
	const classes = useStyles();
	const { handleSubmit, control, watch, setValue } = useForm();
	// // submit status updated after user has clicked save in edit modal
	const [submitSuccessStatus, setSubmitSuccessStatus] = useState(''); // keeps track of status when data is posted

	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message
	const area = watch('area');

	const fields = [
		{
			name: 'area',
			options: areaOptionsQuery,
			label: 'Area'
		},
		{
			name: 'minPrice',
			options: priceOptions,
			label: 'Min Price'
		},
		{
			name: 'maxPrice',
			options: priceOptions,
			label: 'Max Price'
		},
		{
			name: 'minPriceDrop',
			options: priceOptions,
			label: 'Minimum Price Drop'
		}
	];

	const handleSearchSubmit = (formData) => {
		setIsSearching(true);
		let filters = {},
			filtersPersisted = {};

		for (const key of Object.keys(formData)) {
			if (formData[key]) {
				filtersPersisted[key] = formData[key];
				if (key === 'area') {
					filters[key] = formData[key]['value'];
				} else {
					filters[key] = formData[key];
				}
			}
		}
		dispatchUser({ priceListingFilters: filtersPersisted });
		handleClose();

		searchPriceDropListing({ user: userState.user_id, filters: filters })
			.then((res) => {
				if (res.data.success) handleSearch(res.data.listings);
				setIsSearching(false);
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue'
					);
					dispatchUser({
						isLoggedIn: false
					});
					return;
				}
				setIsSearching(false);
				console.log(err.message);
			});
	};

	return (
		<React.Fragment>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={notificationState}
				autoHideDuration={6000}
				onClose={() => setNotificationState(false)}
			>
				<Alert
					onClose={() => setNotificationState(false)}
					severity={submitSuccessStatus === 'success' ? 'success' : 'error'}
					variant="filled"
				>
					{notificationMessage}
				</Alert>
			</Snackbar>
			{isSearching ? (
				<Loading />
			) : (
				<CardContent>
					<form className={classes.form} onSubmit={handleSubmit(handleSearchSubmit)}>
						<Grid container justifyContent="center" alignItems="center" spacing={2}>
							{fields.map((field) =>
								field.label === 'Area' ? (
									<CusSelectField
										defaultValue={
											userState.priceListingFilters &&
											userState.priceListingFilters[field.name]
												? userState.priceListingFilters[field.name]
												: field.multiple
												? []
												: ''
										}
										key={field.name}
										itemGrid={6}
										name={field.name}
										label={field.label}
										options={field.options}
										control={control}
										fieldStyle={classes.formControl}
										multiple={field.multiple}
									/>
								) : (
									<CusSelectFieldManual
										defaultValue={
											userState.priceListingFilters &&
											userState.priceListingFilters[field.name]
												? userState.priceListingFilters[field.name]
												: field.multiple
												? []
												: ''
										}
										key={field.name}
										itemGrid={6}
										name={field.name}
										label={field.label}
										control={control}
										options={field.options}
										fieldStyle={classes.formControl}
										multiple={field.multiple}
										type={field.label === 'Area' ? 'text' : 'number'}
									/>
								)
							)}
						</Grid>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
						>
							Search
						</Button>
					</form>
				</CardContent>
			)}
		</React.Fragment>
	);
}
