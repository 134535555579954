import React, { useState, useEffect } from 'react';

import {
	Box,
	Button,
	List,
	ListItem,
	ListItemText,
	Typography,
	makeStyles,
	lighten,
	CircularProgress,
	ListItemSecondaryAction
} from '@material-ui/core';

import { getUpcomingUserFollowUps } from '../../../../api/serverRequests';

import moment from 'moment';
import { displayHumanReadableDate } from '../../../../helper/parser';
import Loading from '../../Loading';

const useStyles = makeStyles((theme) => ({
	calls: { display: 'flex', flexDirection: 'column' },
	container: {
		width: '100%',
		scrollbarWidth: 'none' /* Firefox */,
		'&::-webkit-scrollbar': {
			display: 'none'
		} /* Chrome */
	},
	list: {
		height: '20vh'
		/* hide scrollbar for IE, Edge and Firefox */
	},
	spinner: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' },
	item: {
		backgroundColor: lighten(theme.palette.primary.light, 0.85),
		margin: '10px'
	},
	missed: {
		backgroundColor: lighten(theme.palette.secondary.light, 0.6),
		margin: '10px'
	},
	noItems: {
		backgroundColor: lighten(theme.palette.primary.light, 0.85),
		padding: '10px'
	},
	itemText: { textAlign: 'right' }
}));

function ListItemLink(props) {
	const classes = useStyles();
	return <ListItem button classes={classes.listItems} component="a" {...props} />;
}

export default function FollowUpOverview(props) {
	const classes = useStyles();
	const { curAgent, curOrg } = props;
	const [isLoading, setIsLoading] = useState(true);
	const [events, setEvents] = useState([]);

	const VIEW_ALL_FOLLOW_UPS_ID = 2;

	useEffect(() => {
		setIsLoading(true);
		getUpcomingUserFollowUps(curAgent, VIEW_ALL_FOLLOW_UPS_ID, curOrg)
			.then((res) => {
				if (res.status === 200) {
					const thirtyMinsUnix = 30 * 60; // 30mins
					setEvents([]);
					let newArr = [];

					for (let followUp of res.data.user_follow_ups) {
						const manipulateObj = {
							...followUp,
							start: moment.unix(followUp.start).toDate(),
							end: moment.unix(followUp.end + thirtyMinsUnix).toDate(),
							title: followUp.title,
							missedFollowUp: moment.unix(followUp.start) <= moment()
						};
						newArr.push(manipulateObj);
					}
					setEvents((prevState) => [...prevState, ...newArr]);
				}
			})
			.then(() => {
				setIsLoading(false);
			})
			.catch((err) => {
				if (err.response.status === 401) {
				}
				console.log(err.message);
				setIsLoading(false);
			});
	}, [curAgent]);

	// console.log(events);

	return (
		<>
			{isLoading ? (
				<div className={classes.spinner}>
					<CircularProgress />
				</div>
			) : (
				<Box className={classes.container}>
					{/* TODO restrict to upcoming 30 days and add month label */}
					<Typography variant="h4">Upcoming Follow-ups</Typography>
					<List className={classes.list}>
						{events.length > 0 ? (
							events
								.sort((a, b) => b.start - a.start)
								.map((item, id) => {
									return (
										<ListItemLink
											className={
												item.missedFollowUp ? classes.missed : classes.item
											}
											href={'/user/listing/' + item.mlnum}
											target="_blank"
											rel="noopener noreferrer"
										>
											<ListItemText
												primary={
													<Typography textAlign={'left'}>
														{item.title + ' - '}
														{item.user_name}
													</Typography>
												}
												secondary={
													moment
														.utc(item.start)
														.format('ddd MMMM Do h:mm A') +
													' - ' +
													moment.utc(item.end).format('h:mm A')
												}
											></ListItemText>
											<ListItemText>
												<Typography className={classes.itemText}>
													{displayHumanReadableDate(item.start)}
												</Typography>
											</ListItemText>
										</ListItemLink>
									);
								})
						) : (
							<ListItemText
								className={classes.noItems}
								primary={'There are currently 0 upcoming meetings.'}
							></ListItemText>
						)}
					</List>
				</Box>
			)}
		</>
	);
}
