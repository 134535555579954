import { getSubAreas } from '../../../api/serverRequests';

export default function SubAreaOptionsAPI(setSubAreaOptionsQuery, localKey) {
	getSubAreas().then((res) => {
		if (res.data.success) {
			let array = [];

			// Object.entries(res.data.subareas).map(([key, value]) => {
			// 	array.push({ label: key, value: value });
			// });

			res.data.subareas.map((items) =>
				array.push({
					label: items.Area,
					value: {
						full: items.subareafull,
						short: items.subareashort
					}
				})
			);

			setSubAreaOptionsQuery(array);
			localStorage.setItem(localKey, JSON.stringify(array));
		}
	});
}
