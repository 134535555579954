import React, { useState, useEffect } from 'react';
import { Grid, Typography, makeStyles, CircularProgress } from '@material-ui/core';
import { useUserState, useWindowDimensions } from '../../../../hook/customerHook';
import moment from 'moment';
import { getTotalTaskCountPerUser } from '../../../../api/serverRequests';
import {
	BarChart,
	Bar,
	Label,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer
} from 'recharts';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center'
	},
	chart: { width: '100%', height: '100%' },
	spinner: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }
}));

export default function MonthlyAgentRank(props) {
	const classes = useStyles();
	const { curOrg, startDate, endDate } = props;
	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setIsLoading(true);
		getTotalTaskCountPerUser(startDate, endDate)
			.then((res) => {
				if (res.status === 200) {
					setData(res.data.task_data);
					setIsLoading(false);
				}
			})
			.catch((err) => {
				setIsLoading(false);
				console.log(err.message);
			});
	}, [startDate, endDate]);

	const tickFormatter = (value, index) => {
		const limit = 10; // put your maximum character
		if (value.length < limit) return value;
		return `${value.substring(0, limit)}...`;
	};

	return (
		<>
			{isLoading ? (
				<div className={classes.spinner}>
					<CircularProgress />
				</div>
			) : (
				<>
					{data && data.length > 0 ? (
						<ResponsiveContainer debounce={300}>
							<BarChart
								width={500}
								height={500}
								data={data}
								margin={{
									top: 0,
									right: 0,
									left: 20,
									bottom: 3
								}}
								layout="vertical"
							>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis type="number">
									<Label
										value="Total tasks among users"
										offset={0}
										position="insideBottom"
									/>
								</XAxis>
								<YAxis
									type="category"
									dataKey="UserName"
									width={90}
									tickFormatter={tickFormatter}
								/>
								<Tooltip />
								<Bar dataKey="Count" fill="#8884d8" />
							</BarChart>
						</ResponsiveContainer>
					) : (
						<div className={classes.spinner}>
							<Typography variant="h4">No information available.</Typography>
						</div>
					)}
				</>
			)}
		</>
	);
}
