import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import Geocode from 'react-geocode';
import { MAP } from '../../../helper/api';

import { Loading } from '../..';

const useStyles = makeStyles(() => ({
	root: {
		width: '100%',
		height: '100%',
		minHeight: '21rem'
	}
}));

Geocode.setApiKey(MAP);
Geocode.setLanguage('en');

const LIBRARIES = ['drawing'];

export default function Map({ address, point }) {
	const [coords, setCoords] = useState(null);
	const classes = useStyles();

	const containerStyle = {
		width: '100%',
		height: '100%'
	};

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: MAP,
		libraries: LIBRARIES
	});

	useEffect(() => {
		if (point) {
			let result = point.match(/-?(?:\.\d+|\d+(?:\.\d*)?)/gm);
			setCoords({ lat: Number(result[0]), lng: Number(result[1]) });
		} else {
			Geocode.fromAddress(address).then(
				(response) => {
					const { lat, lng } = response.results[0].geometry.location;
					setCoords({ lat: lat, lng: lng });
				},
				(error) => {
					console.error(error);
				}
			);
		}
	}, [address, point]);

	return isLoaded ? (
		<Box className={`${classes.root}`}>
			<GoogleMap
				mapContainerStyle={containerStyle}
				center={coords}
				zoom={18}
				options={{ gestureHandling: 'cooperative' }}
			>
				<Marker position={coords} />
			</GoogleMap>
		</Box>
	) : (
		<Loading />
	);
}
