import { useMemo } from 'react';
import { Grid, TextField, IconButton } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
	root: {
		//
	}
}));

export default function OwnerInfo({
	addDataToFormObject,
	defaultOwnerNameVal,
	defaultOwnerPhoneNumberVal,
	warningOwnerName,
	warningOwnerPhoneNumber,
	warningMessageOwnerInfo,
	viewOwnerPhone,
	viewOwnerName
}) {
	const classes = useStyles();

	const info = useMemo(
		() => [
			{
				id: 'OwnerName',
				label: 'Owner Name',
				value: defaultOwnerNameVal ? defaultOwnerNameVal : ''
			},
			{
				id: 'OwnerPhoneNumber',
				label: 'Owner Phone Number',
				value: defaultOwnerPhoneNumberVal ? defaultOwnerPhoneNumberVal : ''
			}
		],
		[defaultOwnerNameVal, defaultOwnerPhoneNumberVal]
	);

	return (
		<>
			{viewOwnerName && (
				<Grid item>
					<TextField
						fullWidth
						margin="normal"
						id={'OwnerName'}
						label={'Owner Name'}
						variant="standard"
						defaultValue={defaultOwnerNameVal ? defaultOwnerNameVal : ''}
						onChange={(e) => addDataToFormObject(e.target.id, e.target?.value || '')}
						error={warningOwnerName}
						helperText={warningOwnerName ? warningMessageOwnerInfo : ''}
					/>
				</Grid>
			)}
			{viewOwnerPhone && (
				<Grid item>
					<TextField
						fullWidth
						margin="normal"
						id={'OwnerPhoneNumber'}
						label={'Owner Phone Number'}
						variant="standard"
						defaultValue={defaultOwnerPhoneNumberVal ? defaultOwnerPhoneNumberVal : ''}
						onChange={(e) => addDataToFormObject(e.target.id, e.target?.value || '')}
						error={warningOwnerPhoneNumber}
						helperText={warningOwnerPhoneNumber ? warningMessageOwnerInfo : ''}
					/>
				</Grid>
			)}
		</>
	);
}
