import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		flexShrink: 0,

		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(2.5)
		}
	}
}));

export default function CusPaginationActions(props) {
	const classes = useStyles();
	const theme = useTheme();
	const { count, page, rowsPerPage, onChangePage } = props;

	const handleFirstPageButtonClick = (event) => {
		onChangePage(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onChangePage(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onChangePage(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};
	const totalPages = Math.ceil(count / rowsPerPage);
	const cusButtons = [
		{
			handleClick: handleFirstPageButtonClick,
			disabled: page === 0,
			label: 'first page',
			component: theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />
		},
		{
			handleClick: handleBackButtonClick,
			disabled: page === 0,
			label: 'previous page',
			component: theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />
		},
		{
			handleClick: handleNextButtonClick,
			disabled: false,
			label: 'next page',
			component: theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />
		},
		{
			handleClick: handleLastPageButtonClick,
			disabled: page >= totalPages - 1,
			label: 'last page',
			component: theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />
		}
	];

	return (
		<Box className={classes.root}>
			{cusButtons.map((cus, idx) => (
				<IconButton
					key={idx}
					onClick={cus.handleClick}
					disabled={cus.disabled}
					aria-label={cus.label}
				>
					{cus.component}
				</IconButton>
			))}
		</Box>
	);
}
