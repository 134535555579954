import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ConnectCCP from '../../comps/Call/ConnectCCP';
import { Grid, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const CallCenter = () => {
	// Avoid re-rendering ConnectCCP
	const CCP = useCallback(
		(num, id) => (
			<ConnectCCP
				phoneNumber={num}
				homeId={id}
				setNotificationState={setNotificationState}
				setNotificationMessage={setNotificationMessage}
				setStatusClass={setStatusClass}
			/>
		),
		[]
	);
	const location = useLocation();

	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message

	// // submit status updated after user has clicked save in edit modal
	const [statusClass, setStatusClass] = useState(''); // keeps track of status when data is posted

	let number = location.pathname.split('/')[3];
	let homeId = location.pathname.split('/')[4];
	if (number && !isNaN(+number)) {
		number = '+1' + number;
	}
	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={notificationState}
				autoHideDuration={4500}
				onClose={() => setNotificationState(false)}
			>
				<Alert
					onClose={() => setNotificationState(false)}
					severity={statusClass}
					variant="filled"
				>
					{notificationMessage}
				</Alert>
			</Snackbar>
			<Grid container>
				<Grid item xs={2}>
					<CCP num={number} id={homeId} />
				</Grid>
			</Grid>
		</>
	);
};

export default CallCenter;
