import * as React from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { MAP } from '../../../helper/api';
import _ from 'lodash';

const render = (status) => {
	return <h1>{status}</h1>;
};

function isLatLngLiteral(obj) {
	obj === window.google.maps.LatLngLiteral &&
		obj != null &&
		typeof obj === 'object' &&
		Number.isFinite(obj.lat) &&
		Number.isFinite(obj.lng);
}

const LIBRARIES = ['drawing'];

const MapFilter = ({
	onRectFilterUpdate,
	onCircleFilterUpdate,
	setRestoredFilters,
	restoredFilters,
	circleFilter,
	rectFilter,
	listings,
	drawingControlEnabled,
	setDrawingControlEnabled
}) => {
	const [zoom, setZoom] = React.useState(10);
	const [center, setCenter] = React.useState({ lat: 49.25, lng: -123.1 });
	const [mapInstance, setMapInstance] = React.useState(null);
	const { isLoaded, loadError } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: MAP,
		libraries: LIBRARIES
	});

	const onIdle = (m) => {
		if (m) {
			setZoom(m.getZoom());
			setCenter(m.getCenter().toJSON());
		}
	};

	if (loadError) {
		return <div>Error loading Google Maps API</div>;
	}

	const updateCircleFilter = (circle) => {
		const newObj = {
			lat: circle.getCenter().lat(),
			lng: circle.getCenter().lng(),
			radius: circle.getRadius()
		};
		if (onCircleFilterUpdate) {
			onCircleFilterUpdate(newObj);
		}
		let newRestoredFilters = {
			...restoredFilters,
			circleFilter: newObj
		};
		setRestoredFilters(newRestoredFilters);
	};

	const updateRectFilter = (rectangle) => {
		const bounds = rectangle.getBounds();
		const newRectFilter = {
			north: bounds.getNorthEast().lat(),
			east: bounds.getNorthEast().lng(),
			south: bounds.getSouthWest().lat(),
			west: bounds.getSouthWest().lng()
		};
		if (onRectFilterUpdate) {
			onRectFilterUpdate(newRectFilter);
		}
		let newRestoredFilters = {
			...restoredFilters,
			rectFilter: newRectFilter
		};
		setRestoredFilters(newRestoredFilters);
	};

	return (
		<MapContent
			isLoaded={isLoaded}
			mapInstance={mapInstance}
			setMapInstance={setMapInstance}
			zoom={zoom}
			setZoom={setZoom}
			center={center}
			setCenter={setCenter}
			circle={circleFilter}
			rect={rectFilter}
			onRectFilterUpdate={onRectFilterUpdate}
			onCircleFilterUpdate={onCircleFilterUpdate}
			setRestoredFilters={setRestoredFilters}
			listings={listings}
			restoredFilters={restoredFilters}
			updateCircleFilter={updateCircleFilter}
			updateRectFilter={updateRectFilter}
			drawingControlEnabled={drawingControlEnabled}
			setDrawingControlEnabled={setDrawingControlEnabled}
		/>
	);
};

const MapContent = ({
	isLoaded,
	mapInstance,
	setMapInstance,
	zoom,
	setZoom,
	center,
	setCenter,
	circle,
	rect,
	onRectFilterUpdate,
	onCircleFilterUpdate,
	setRestoredFilters,
	listings,
	restoredFilters,
	updateCircleFilter,
	updateRectFilter,
	drawingControlEnabled,
	setDrawingControlEnabled
}) => {
	const debouncedUpdateCircleFilter = React.useMemo(
		() => _.debounce(updateCircleFilter, 500),
		[]
	);
	const debouncedUpdateRectFilter = React.useMemo(() => _.debounce(updateRectFilter, 500), []);

	const onIdle = (m) => {
		if (m) {
			setZoom(m.getZoom());
			setCenter(m.getCenter().toJSON());
		}
	};

	return (
		<div className="map-filter" style={{ margin: '0', width: '100%', height: '400px' }}>
			{isLoaded ? (
				<GoogleMap
					mapContainerStyle={{ width: '100%', height: '100%' }}
					center={center}
					zoom={zoom}
					options={{
						mapTypeId: 'terrain',
						streetViewControl: false,
						fullscreenControl: false
					}}
					onIdle={onIdle}
					onLoad={(map) => setMapInstance(map)}
				>
					{mapInstance && (
						<MapCircleWrapper
							googleMap={mapInstance}
							circle={circle}
							rect={rect}
							onRectFilterUpdate={onRectFilterUpdate}
							onCircleFilterUpdate={onCircleFilterUpdate}
							setRestoredFilters={setRestoredFilters}
							debouncedUpdateRectFilter={debouncedUpdateRectFilter}
							debouncedUpdateCircleFilter={debouncedUpdateCircleFilter}
							drawingControlEnabled={drawingControlEnabled}
							setDrawingControlEnabled={setDrawingControlEnabled}
						/>
					)}
				</GoogleMap>
			) : (
				<div>Loading...</div>
			)}
		</div>
	);
};

const MapCircleWrapper = ({
	googleMap,
	onRectFilterUpdate,
	onCircleFilterUpdate,
	circle,
	rect,
	setRestoredFilters,
	debouncedUpdateRectFilter,
	debouncedUpdateCircleFilter,
	drawingControlEnabled,
	setDrawingControlEnabled,
	setRestoreFdFilters
}) => {
	if (!googleMap) {
		return null;
	}

	return (
		<MapCircle
			googleMap={googleMap}
			onRectFilterUpdate={onRectFilterUpdate}
			onCircleFilterUpdate={onCircleFilterUpdate}
			circle={circle}
			rect={rect}
			setRestoredFilters={setRestoreFdFilters}
			debouncedUpdateRectFilter={debouncedUpdateRectFilter}
			debouncedUpdateCircleFilter={debouncedUpdateCircleFilter}
			drawingControlEnabled={drawingControlEnabled}
			setDrawingControlEnabled={setDrawingControlEnabled}
		/>
	);
};

const MapCircle = ({
	googleMap,
	circle,
	rect,
	onRectFilterUpdate,
	onCircleFilterUpdate,
	setRestoredFilters,
	debouncedUpdateRectFilter,
	debouncedUpdateCircleFilter,
	drawingControlEnabled,
	setDrawingControlEnabled
}) => {
	const circleOptions = {
		fillColor: '#ff0000',
		fillOpacity: 0.35,
		strokeColor: '#ff0000',
		strokeWeight: 2,
		clickable: true,
		editable: true,
		zIndex: 1
	};
	const drawingManagerRef = React.useRef(null);
	const circleRef = React.useRef(null);
	const rectRef = React.useRef(null);
	const [circleObj, setCircleObj] = React.useState(null);
	const [rectObj, setRectObj] = React.useState(null);

	React.useEffect(() => {
		if (googleMap) {
			if (!drawingManagerRef.current) {
				const drawingManager = new window.google.maps.drawing.DrawingManager({
					drawingMode: null,
					drawingControl: drawingControlEnabled,
					drawingControlOptions: {
						position: window.google.maps.ControlPosition.TOP_CENTER,
						drawingModes: [
							window.google.maps.drawing.OverlayType.CIRCLE,
							window.google.maps.drawing.OverlayType.RECTANGLE
							//window.google.maps.drawing.OverlayType.MARKER
						]
					},
					circleOptions
				});

				drawingManager.setMap(googleMap);
				drawingManagerRef.current = drawingManager;

				window.google.maps.event.addListener(drawingManager, 'circlecomplete', (circle) => {
					drawingManager.setDrawingMode(null);
					debouncedUpdateCircleFilter(circle);
					circleRef.current = circle;
					circle.setMap(null);
					setDrawingControlEnabled(false);
				});

				window.google.maps.event.addListener(
					drawingManager,
					'rectanglecomplete',
					(rect) => {
						drawingManager.setDrawingMode(null);
						debouncedUpdateRectFilter(rect);
						rectRef.current = rect;
						rect.setMap(null);
						setDrawingControlEnabled(false);
					}
				);
			} else {
				// Update drawing control options when drawingControlEnabled changes
				drawingManagerRef.current.setOptions({
					drawingControl: drawingControlEnabled
				});
			}
		}
	}, [googleMap, debouncedUpdateCircleFilter, debouncedUpdateRectFilter, drawingControlEnabled]);

	React.useEffect(() => {
		if (circle) {
			if (!circleObj) {
				const restoredCircle = new window.google.maps.Circle({
					map: googleMap,
					center: { lat: circle.lat, lng: circle.lng },
					radius: circle.radius,
					...circleOptions
				});

				setCircleObj(restoredCircle);
				drawingManagerRef.current.setDrawingMode(null);
			} else {
				circleObj.setOptions({
					center: { lat: circle.lat, lng: circle.lng },
					radius: circle.radius
				});
			}
		} else if (circleObj) {
			circleObj.setMap(null);
			setCircleObj(null);
		}
	}, [circle, googleMap, circleObj]);

	React.useEffect(() => {
		if (rect) {
			if (!rectObj) {
				const restoredRect = new window.google.maps.Rectangle({
					map: googleMap,
					bounds: {
						north: rect.north,
						south: rect.south,
						east: rect.east,
						west: rect.west
					},
					...circleOptions
				});

				setRectObj(restoredRect);
				drawingManagerRef.current.setDrawingMode(null);
			} else {
				rectObj.setBounds({
					north: rect.north,
					south: rect.south,
					east: rect.east,
					west: rect.west
				});
			}
		} else if (rectObj) {
			rectObj.setMap(null);
			setRectObj(null);
		}
	}, [rect, googleMap, rectObj]);

	React.useEffect(() => {
		if (circleObj) {
			const centerChangedListener = window.google.maps.event.addListener(
				circleObj,
				'center_changed',
				() => {
					debouncedUpdateCircleFilter(circleObj);
				}
			);

			const radiusChangedListener = window.google.maps.event.addListener(
				circleObj,
				'radius_changed',
				() => {
					debouncedUpdateCircleFilter(circleObj);
				}
			);

			return () => {
				centerChangedListener.remove();
				radiusChangedListener.remove();
			};
		}
	}, [circleObj, debouncedUpdateCircleFilter]);

	React.useEffect(() => {
		if (rectObj) {
			const boundsChangedListener = window.google.maps.event.addListener(
				rectObj,
				'bounds_changed',
				() => {
					debouncedUpdateRectFilter(rectObj);
				}
			);

			return () => {
				boundsChangedListener.remove();
			};
		}
	}, [rectObj, debouncedUpdateRectFilter]);

	return null;
};

export default MapFilter;
