import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, useTheme, responsiveFontSizes } from '@material-ui/core/styles';
import clsx from 'clsx';
//Dynamically load components on request for faster page rendering
import loadable from '@loadable/component';
import CusPaginationActions from '../Lists/CusPaginationActions';

import { cusTheme } from '../Lists/cusTheme';
import { CardActions } from '@material-ui/core';
import { Favorite, FavoriteBorder } from '@material-ui/icons';
const CusTableToolbar = loadable(() => import('../Lists/CusTableToolbar'));
const CusTableCardHead = loadable(() => import('./CusTableCardHead'));
const CardActionArea = loadable(() => import('@material-ui/core/CardActionArea'));
const TablePagination = loadable(() => import('@material-ui/core/TablePagination'));
const PriceDropCardContent = loadable(() => import('./PriceDropPropCardContent'));
const ActivePropCardContent = loadable(() => import('./ActivePropCardContent'));
const PriceAssessmentCardContent = loadable(() => import('./PriceAssessmentCardContent'));
const Card = loadable(() => import('@material-ui/core/Card'));
const CardContent = loadable(() => import('@material-ui/core/CardContent'));
const VisibilityOffIcon = loadable(() => import('@material-ui/icons/VisibilityOff'));

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		marginBottom: 10,
		padding: 0,
		border: '1px solid lightgray',
		flexDirection: 'column',
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
			padding: 10
		}
	},
	rootAction: {
		display: 'flex',
		flex: '0',
		margin: 0,
		flexDirection: 'row',
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
			margin: 10
		}
	},
	cardActions: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	filterHorizontal: { width: '100%', overflowX: 'auto' },

	collectionInUser: {
		backgroundColor: cusTheme.palette.primary.light
	},
	collectionInOrganization: {
		backgroundColor: cusTheme.palette.secondary.light
	},
	collectionInHidden: {
		backgroundColor: 'rgba(0, 0, 0, 0.33)'
	},
	collectionIsSelected: {
		backgroundColor: 'rgba(245, 0, 87, 0.08)'
	},
	collectionIsFiltered: {
		backgroundColor: 'rgba(0, 0, 0, 0.25)'
	},
	pic: {
		backgroundColor: theme.palette.text.secondary
	},
	tableContainer: {
		height: '80vh',
		overflowY: 'auto'
	},
	pagination: {
		'&.MuiTableCell-root': {
			paddingTop: theme.spacing(2),
			border: 'none'
		},
		'& .MuiToolbar-root': {
			flexWrap: 'wrap',
			justifyContent: 'flex-end'
		},
		'& .MuiTablePagination-selectRoot': {
			marginRight: 0
		},
		'& .MuiTablePagination-caption': {
			marginLeft: theme.spacing(1),
			[theme.breakpoints.up('sm')]: {
				marginLeft: theme.spacing(4)
			}
		}
	}
}));

export default function ListCards({
	listings,
	title,
	listHeads,
	listCells,
	listType,
	handleSave,
	handleSaveFilter,
	handleDelete,
	isSearch,
	initialOrderBy
}) {
	let theme = useTheme();
	const classes = useStyles();
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState(initialOrderBy);
	const [selected, setSelected] = useState([]);
	const [filtered, setFiltered] = useState([]);
	const [rows, setRows] = useState(listings);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	function descendingComparator(a, b, orderBy) {
		if (
			orderBy === 'Percent' ||
			orderBy === 'TotFlArea' ||
			orderBy === 'LotSzSqFt' ||
			orderBy === 'FrontageFeet'
		) {
			b[orderBy] = parseFloat(b[orderBy]);
			a[orderBy] = parseFloat(a[orderBy]);
		}
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}

	function getComparator(order, orderBy) {
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = listings.map((row) => row['MLNum'] + '__' + row['HomeId']);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;
	const isFiltered = (name) => filtered.indexOf(name) !== -1;

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const handleHide = (event, name) => {
		const filteredIndex = filtered.indexOf(name);
		let newFiltered = [];

		if (filteredIndex === -1) {
			newFiltered = newFiltered.concat(filtered, name);
		} else if (filteredIndex === 0) {
			newFiltered = newFiltered.concat(filtered.slice(1));
		} else if (filteredIndex === filtered.length - 1) {
			newFiltered = newFiltered.concat(filtered.slice(0, -1));
		} else if (filteredIndex > 0) {
			newFiltered = newFiltered.concat(
				filtered.slice(0, filteredIndex),
				filtered.slice(filteredIndex + 1)
			);
		}
		setFiltered(newFiltered);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const onClickDelete = () => {
		if (handleDelete) handleDelete(selected);
		setSelected([]);
		// alert('Delete all selected listings');
	};

	const onClickSave = () => {
		if (handleSave) handleSave(selected);
		setSelected([]);
		// alert('Save all selected listings');
	};

	const onClickSaveFilter = () => {
		if (handleSave) handleSaveFilter(filtered);
		setFiltered([]);
		// alert('Save all selected listings');
	};

	const handleChangeRowsPerPage = (event) => {
		const currPage = page;
		const currRowPerPage = rowsPerPage;
		const startingItem = currPage * currRowPerPage;

		const nextRowPerPage = parseInt(event.target.value, 10);
		const nextPage = Math.floor(startingItem / nextRowPerPage);
		setRowsPerPage(nextRowPerPage);
		setPage(nextPage);
	};

	return (
		<>
			<CusTableToolbar
				isSearch={isSearch}
				title={title}
				styles={classes}
				numSelected={selected.length}
				numFiltered={filtered.length}
				handleSave={onClickSave}
				handleSaveFilter={onClickSaveFilter}
				handleDelete={onClickDelete}
			/>
			<CusTableCardHead
				className={classes.filterHorizontal}
				isSearch={isSearch}
				numSelected={selected.length}
				order={order}
				orderBy={orderBy}
				headCells={listHeads}
				onSelectAllClick={handleSelectAllClick}
				onRequestSort={handleRequestSort}
				rowCount={listings.length}
			/>
			{(rowsPerPage > 0
				? stableSort(listings, getComparator(order, orderBy)).slice(
						page * rowsPerPage,
						page * rowsPerPage + rowsPerPage
				  )
				: stableSort(listings, getComparator(order, orderBy))
			).map((row) => {
				const isItemSelected = isSelected(row['MLNum'] + '__' + row['HomeId']);
				const isItemFiltered =
					isFiltered(row['MLNum'] + '__' + row['HomeId']) || row['savedTo'] === 'hidden';
				return (
					<Card
						className={clsx(
							classes.root,
							{ [classes.collectionInUser]: row['savedTo'] === 'user' },
							{
								[classes.collectionInOrganization]:
									row['savedTo'] === 'organization'
							},
							{
								[classes.collectionInHidden]: row['savedTo'] === 'hidden'
							},
							{
								[classes.collectionIsSelected]: isSelected(
									row['MLNum'] + '__' + row['HomeId']
								)
							},
							{
								[classes.collectionIsFiltered]: isFiltered(
									row['MLNum'] + '__' + row['HomeId']
								)
							}
						)}
					>
						<CardActionArea>
							{row['DealType'] === 'price_dropped' ? (
								<PriceDropCardContent row={row} listMainCells={listCells} />
							) : row['DealType'] === 'active' ? (
								<ActivePropCardContent row={row} listMainCells={listCells} />
							) : (
								<PriceAssessmentCardContent row={row} listMainCells={listCells} />
							)}
						</CardActionArea>

						<CardActions className={classes.rootAction}>
							<CardActionArea
								onClick={(event) => {
									if (row['savedTo'] !== 'user') {
										handleSave([row['MLNum'] + '__' + row['HomeId']]);
									} else {
										handleDelete([row['MLNum'] + '__' + row['HomeId']]);
									}
								}}
							>
								<CardContent className={classes.cardActions}>
									<Checkbox
										icon={<FavoriteBorder />}
										checkedIcon={<Favorite />}
										inputProps={{
											'aria-labelledby': row['MLNum'] + '__' + row['HomeId']
										}}
										checked={isItemSelected || row['savedTo'] === 'user'}
									/>
								</CardContent>
							</CardActionArea>
							<CardActionArea
								disabled={row['savedTo'] === 'hidden'}
								onClick={(event) => {
									if (row['savedTo'] !== 'user')
										handleHide(event, row['MLNum'] + '__' + row['HomeId']);
								}}
							>
								<CardContent className={classes.cardActions}>
									<Checkbox
										color="default"
										inputProps={{
											'aria-labelledby': row['MLNum'] + '__' + row['HomeId']
										}}
										checked={isItemFiltered}
									/>
									<VisibilityOffIcon />
								</CardContent>
							</CardActionArea>
						</CardActions>
					</Card>
				);
			})}
			<TablePagination
				className={classes.pagination}
				rowsPerPageOptions={[10, 20, 30, { label: 'All', value: -1 }]}
				count={rows.length}
				rowsPerPage={rowsPerPage}
				page={page}
				SelectProps={{
					inputProps: { 'aria-label': 'rows per page' },
					native: true
				}}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				ActionsComponent={() =>
					CusPaginationActions({
						count: rows.length,
						page,
						rowsPerPage,
						onChangePage: handleChangePage
					})
				}
			/>
		</>
	);
}
