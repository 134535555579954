import * as React from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import { createCustomEqual } from 'fast-equals';
import { MAP } from '../../../helper/api';

import { MarkerClusterer } from '@googlemaps/markerclusterer';

import { interpolateRgb } from 'd3-interpolate';

const render = (status) => {
	return <h1>{status}</h1>;
};

function isLatLngLiteral(obj) {
	obj === window.google.maps.LatLngLiteral &&
		obj != null &&
		typeof obj === 'object' &&
		Number.isFinite(obj.lat) &&
		Number.isFinite(obj.lng);
}

const MapResults = ({ onFilterUpdate, circle, listings }) => {
	const [zoom, setZoom] = React.useState(10);
	const [center, setCenter] = React.useState({
		lat: 49.25,
		lng: -123.1
	});

	const onIdle = (m) => {
		setZoom(m.getZoom());
		setCenter(m.getCenter().toJSON());
	};

	return (
		<div className="map-results" style={{ margin: '0', width: '100%', height: '100%' }}>
			<Wrapper apiKey={MAP} render={render} libraries={['drawing']}>
				<Map
					circle={circle}
					center={center}
					onIdle={onIdle}
					onFilterUpdate={onFilterUpdate}
					zoom={zoom}
					streetViewControl={false}
					fullscreenControl={false}
					listings={listings}
					style={{ width: '100%', height: '100%' }}
				></Map>
			</Wrapper>
		</div>
	);
};

const interpolatedRenderer = {
	palette: interpolateRgb('#FF9900', '#004F2B'),
	render: function ({ count, position }, stats) {
		// use d3-interpolateRgb to interpolate between red and blue
		const color = this.palette(count / stats.clusters.markers.max);

		// create svg url with fill color
		const svg = window.btoa(`
  <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
    <circle cx="120" cy="120" opacity=".8" r="70" />
  </svg>`);

		// create marker using svg icon
		return new window.google.maps.Marker({
			position,
			icon: {
				url: `data:image/svg+xml;base64,${svg}`,
				scaledSize: new window.google.maps.Size(75, 75)
			},
			label: {
				text: String(count),
				color: 'rgba(255,255,255,0.9)',
				fontSize: '12px'
			},
			// adjust zIndex to be above other markers
			zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count
		});
	}
};

const Map = ({ onIdle, children, style, listings, ...options }) => {
	const ref = React.useRef(null);
	const [map, setMap] = React.useState();

	const textMargin = 4;
	const infowindow = new window.google.maps.InfoWindow({
		content: '',
		ariaLabel: 'Property Marker'
	});

	function openInfoWindow(marker, lat, lng, address, price, lotSize, listingNumber) {
		const priceDisplay = '$' + price.toLocaleString();
		const lotSizeDisplay = Math.round(lotSize).toLocaleString() + ' sq. ft';
		infowindow.setContent(`<p style="font-weight: bold; margin: ${textMargin}px; min-width: 170px">${address}</p>
		<p style="margin: ${textMargin}px;">${priceDisplay}</p>
		<p style="margin: ${textMargin}px;">${lotSizeDisplay}</p>
		<a href="listing/${listingNumber}" target="_blank">
			<p style="margin: ${textMargin}px;">View Details</p>
		</a>`);
		infowindow.open({
			anchor: marker,
			map
		});
	}

	React.useEffect(() => {
		if (ref.current && !map) {
			const createdMap = new window.google.maps.Map(ref.current, {});
			setMap(createdMap);

			['click', 'idle'].forEach((eventName) =>
				window.google.maps.event.clearListeners(createdMap, eventName)
			);

			if (onIdle) {
				createdMap.addListener('idle', () => onIdle(createdMap));
			}
		}
	}, []);

	React.useEffect(() => {
		if (!listings.length || !map) {
			return;
		}

		const bounds = new window.google.maps.LatLngBounds();

		const markers = listings.map((listing) => {
			if (!listing.location) {
				return null; // Skip this listing if location is null
			}
			let location = listing.location.substring(6, listing.location.length - 1);
			const [lat, lng] = location.split(' ').map((el) => parseFloat(el, 10));

			const marker = new window.google.maps.Marker({
				position: {
					lat,
					lng
				},
				map
			});

			marker.addListener('click', () => {
				openInfoWindow(
					marker,
					lat,
					lng,
					listing.Address,
					listing.Price,
					listing.LotSzSqFt,
					listing.MLNum
				);
			});

			bounds.extend(marker.position);
			return marker;
		});

		new MarkerClusterer({
			renderer: interpolatedRenderer,
			markers,
			map
		});

		map.fitBounds(bounds);
	}, [listings, map]);

	// because React does not do deep comparisons, a custom hook is used
	// see discussion in https://github.com/googlemaps/js-samples/issues/946
	useDeepCompareEffectForMaps(() => {
		if (map) {
			map.setOptions(options);
		}
	}, [map, options]);

	return (
		<>
			<div ref={ref} style={style} />{' '}
			{React.Children.map(children, (child) => {
				if (React.isValidElement(child)) {
					// set the map prop on the child component
					return React.cloneElement(child, { map });
				}
			})}
		</>
	);
};

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
	if (
		isLatLngLiteral(a) ||
		a instanceof window.google.maps.LatLng ||
		isLatLngLiteral(b) ||
		b instanceof window.google.maps.LatLng
	) {
		return new window.google.maps.LatLng(a).equals(new window.google.maps.LatLng(b));
	}

	return deepEqual(a, b);
});

function useDeepCompareMemoize(value) {
	const ref = React.useRef();

	if (!deepCompareEqualsForMaps(value, ref.current)) {
		ref.current = value;
	}
	return ref.current;
}

function useDeepCompareEffectForMaps(callback, dependencies) {
	React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

export default MapResults;
