import React, { useState } from 'react';
import {
	Typography,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableCell,
	TableRow,
	CircularProgress,
	Link,
	TablePagination
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import { currencyParser } from '../../../../helper/parser';

const useStyles = makeStyles((theme) => ({
	root: {
		//
	},
	firstTitle: {
		margin: '0.4rem 0',
		paddingBottom: 10
	},
	noActiveTitle: {
		color: theme.palette.grey['KN_light_grey'],
		textAlign: 'center',
		lineHeight: 2
	},
	centerItem: {
		marginTop: 40,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%'
	},
	tableTitle: {
		color: theme.palette.black.main,
		fontWeight: theme.typography.fontWeightMedium
	},
	listingMLNum: {
		color: theme.palette.grey['KN_grey']
	}
}));
const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.grey['KN_back_ground'],
		color: theme.palette.black.main
	},
	body: {
		borderBottom: 'none'
	}
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(even)': {
			backgroundColor: theme.palette.grey['KN_back_ground_soft']
		}
	}
}))(TableRow);

const statusDisplay = (status) => {
	if (status === 'A') return 'Active';
	else if (status === 'S' || status === 'F') return 'Closed';
	else if (status === 'P') return 'Pending';
	else if (status === 'X') return 'Expired';
	else if (status === 'T') return 'Terminated';
	else if (status === 'C') return 'Cancel Protected';
};

export default function ListingHistoryTab({ listingHistory, isLoadingListingHistory }) {
	const classes = useStyles();

	// To handle table pagination
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};
	///////

	return isLoadingListingHistory ? (
		<div className={classes.centerItem}>
			<CircularProgress />
		</div>
	) : (
		<>
			{listingHistory.length === 0 ? (
				<Typography
					variant="h4"
					component="h4"
					className={`${classes.centerItem} ${classes.noActiveTitle}`}
				>
					This property is so new, it doesn't have a history yet. Let's give it some time
					to make some memorable moments!
				</Typography>
			) : (
				<>
					<Typography variant="h4" component="h4" className={classes.firstTitle}>
						Listing History
					</Typography>

					<TableContainer>
						<Table className={classes.table} aria-label="customized table">
							<TableHead>
								<TableRow>
									<StyledTableCell align="center">
										<Typography
											variant="subtitle1"
											component="h5"
											className={classes.tableTitle}
										>
											MLS No.
										</Typography>
									</StyledTableCell>
									<StyledTableCell align="center">
										<Typography
											variant="subtitle1"
											component="h5"
											className={classes.tableTitle}
										>
											Status
										</Typography>
									</StyledTableCell>
									<StyledTableCell align="center">
										<Typography
											variant="subtitle1"
											component="h5"
											className={classes.tableTitle}
										>
											List Price
										</Typography>
									</StyledTableCell>
									<StyledTableCell align="center">
										<Typography
											variant="subtitle1"
											component="h5"
											className={classes.tableTitle}
										>
											Sold Price
										</Typography>
									</StyledTableCell>
									<StyledTableCell align="center">
										<Typography
											variant="subtitle1"
											component="h5"
											className={classes.tableTitle}
										>
											Date
										</Typography>
									</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{(rowsPerPage > 0
									? listingHistory.slice(
											page * rowsPerPage,
											page * rowsPerPage + rowsPerPage
									  )
									: listingHistory
								).map((listing, index) => (
									<StyledTableRow key={index}>
										<StyledTableCell align="center">
											<Typography variant="subtitle1" component="h5">
												<Link
													href={`/user/listing/${listing.MLNum}`}
													underline="always"
													className={classes.listingMLNum}
												>
													{listing.MLNum ? listing.MLNum : '-'}
												</Link>
											</Typography>
										</StyledTableCell>
										<StyledTableCell align="center">
											<Typography variant="subtitle1" component="h5">
												{listing.Status
													? statusDisplay(listing.Status)
													: '-'}
											</Typography>
										</StyledTableCell>
										<StyledTableCell align="center">
											<Typography variant="subtitle1" component="h5">
												{listing.ListPrice
													? currencyParser(listing.ListPrice)
													: '-'}
											</Typography>
										</StyledTableCell>
										<StyledTableCell align="center">
											<Typography variant="subtitle1" component="h5">
												{listing.SoldPrice
													? currencyParser(listing.SoldPrice)
													: '-'}
											</Typography>
										</StyledTableCell>
										<StyledTableCell align="center">
											<Typography variant="subtitle1" component="h5">
												{listing.ListDate ? listing.ListDate : '-'}
											</Typography>
										</StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>

						{/* TABLE PAGINATION */}
						<TablePagination
							rowsPerPageOptions={[]}
							component="div"
							count={listingHistory.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</TableContainer>
				</>
			)}
		</>
	);
}
