import React, { useEffect, useState } from 'react';
//Dynamically load components on request for faster page rendering
import loadable from '@loadable/component';

import { makeStyles } from '@material-ui/core/styles';
import { getUserActivities, getUserActivitiesSingleListing } from '../../../api/serverRequests';

import CusPaginationActions from './CusPaginationActions';
import { cusTheme } from './cusTheme';
import {
	Select,
	MenuItem,
	FormControl,
	TableHead,
	Typography,
	Box,
	InputLabel,
	CircularProgress,
	Grid
} from '@material-ui/core';

import { displayHumanReadableClock, displayHumanReadableDate } from '../../../helper/parser';

const Avatar = loadable(() => import('@material-ui/core/Avatar'));
const Table = loadable(() => import('@material-ui/core/Table'));
const TableBody = loadable(() => import('@material-ui/core/TableBody'));
const TableCell = loadable(() => import('@material-ui/core/TableCell'));
const TableContainer = loadable(() => import('@material-ui/core/TableContainer'));
const TablePagination = loadable(() => import('@material-ui/core/TablePagination'));
const TableRow = loadable(() => import('@material-ui/core/TableRow'));
const Paper = loadable(() => import('@material-ui/core/Paper'));

const useStyles = makeStyles((theme) => ({
	collectionInUser: {
		backgroundColor: cusTheme.palette.primary.light
	},
	collectionInOrganization: {
		backgroundColor: cusTheme.palette.secondary.light
	},
	pic: {
		backgroundColor: theme.palette.text.secondary
	},
	tableContainer: {
		height: '80vh',
		overflowY: 'auto'
	},
	printIcon: {
		'&:hover, &.Mui-focusVisible': { backgroundColor: 'white' }
	},
	printIconContainer: {
		display: 'flex',
		width: '100%',
		justifyContent: 'flex-end'
	},
	mapButton: {
		margin: theme.spacing(1)
	},
	pagination: {
		'&.MuiTableCell-root': {
			paddingTop: theme.spacing(2),
			border: 'none'
		},
		'& .MuiToolbar-root': {
			flexWrap: 'wrap',
			justifyContent: 'flex-end'
		},
		'& .MuiTablePagination-selectRoot': {
			marginRight: 0
		},
		'& .MuiTablePagination-caption': {
			marginLeft: theme.spacing(1),
			[theme.breakpoints.up('sm')]: {
				marginLeft: theme.spacing(4)
			}
		}
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},
	summaryButton: {
		right: theme.spacing(2)
	},
	dialogContent: {
		width: '400px',
		height: '90px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-end',
		marginBottom: '14px'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	hoverElement: {
		position: 'relative',
		'&:hover': {
			'& $hoverBox': {
				display: 'block'
			}
		}
	},
	hoverBox: {
		zIndex: 100,
		display: 'none',
		background: 'white',
		border: '1px solid gray',
		position: 'absolute',
		padding: '0 1rem',
		whiteSpace: 'nowrap'
	},
	hoverBox_tags: {
		color: '#3f51b5'
	},
	commentBox: {
		margin: '10px',
		'& h6': {
			whiteSpace: 'nowrap'
		},
		'& span': {
			fontSize: '1rem'
		}
	},
	spinner: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		margin: '2rem'
	},
	username_wrapper: {
		marginLeft: '12px'
	},
	username: {
		fontSize: '14px',
		lineHeight: '21px',
		fontWeight: 400
	},
	email: {
		fontSize: '12px',
		lineHeight: '18px',
		fontWeight: 300
	},
	audit_detail: {
		'& p': {
			whiteSpace: 'nowrap'
		}
	},
	audit_table_head: {
		background: '#F8F8F8',
		'& .MuiTableCell-head': {
			fontWeight: 700
		}
	},
	title: {
		flex: '1 1 100%',
		color: theme.palette.primary.main
	},
	commentTime: {
		display: 'inline-block',
		whiteSpace: 'nowrap'
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative',
		width: 'fit-content'
	},
	select: { minWidth: 150 },
	selectProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	}
}));

export default function AuditTable({
	listings,
	setListings,
	listHeads,
	listCells,
	title,
	homeId,
	agentsLoading,
	agents,
	curAgent,
	setCurAgent,
	setCurAgentName,
	boardLoading,
	setBoardLoading
}) {
	const classes = useStyles();

	const [rows, setRows] = useState(listings);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [offset, setOffset] = useState(35);
	const [showReadableDate, setShowReadableDate] = useState(false);

	const handleChangePage = async (event, newPage) => {
		const totalPages = Math.ceil(rows.length / rowsPerPage);
		if (newPage >= totalPages - 1) {
			let res;
			try {
				if (homeId) {
					//single listing
					res = await getUserActivitiesSingleListing(homeId, 35, offset);
				} else {
					res = await getUserActivities(35, offset);
				}
				if (res.data.success) {
					console.log('client side response', res.data.userActivities);
					setListings((prevListings) => [...prevListings, ...res.data.userActivities]);
					setOffset((prevOffset) => prevOffset + 35);
					setPage(newPage);
				}
			} catch (err) {
				console.log(err.message);
			}
		} else {
			setPage(newPage);
		}
	};

	const handleChangeRowsPerPage = (event) => {
		const currPage = page;
		const currRowPerPage = rowsPerPage;
		const startingItem = currPage * currRowPerPage;

		const nextRowPerPage = parseInt(event.target.value, 10);
		const nextPage = Math.floor(startingItem / nextRowPerPage);
		setRowsPerPage(nextRowPerPage);
		setPage(nextPage);
	};

	useEffect(() => {
		setRows(listings);
	}, [listings]);

	const parseJSON = (obj) => {
		var parsedData = '';
		for (var i in obj) {
			if (Array.isArray(obj[i])) {
				parsedData += i;
				parsedData += '<ul>';
				for (let ele in obj[i]) {
					parsedData += '<li>' + obj[i][ele] + '</li>';
				}
				parsedData += '</ul>';
			} else {
				if (typeof obj[i] === 'object') {
					if (Object.keys(obj[i]).length !== 0) {
						parsedData += i + ': ';
						parsedData += '<br/>';
						parsedData += parseJSON(obj[i]);
					} else {
						parsedData += i + ': {}';
					}
				} else {
					parsedData += '<p>' + i + ': ' + obj[i] + '</p>';
				}
			}
		}
		return parsedData;
	};

	const handleDropdownChange = (event) => {
		const text = event.target.innerText;
		setCurAgent(event.target.value);
		setCurAgentName(text);
		setBoardLoading(true);
		setListings([]);
	};

	class AuditTableComponent extends React.Component {
		render() {
			return (
				<Table>
					<TableHead className={classes.audit_table_head}>
						<TableRow>
							{listHeads.map((column) => (
								<TableCell key={column.id} align={column.align}>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((row) => {
								return (
									<TableRow key={row['UserName']}>
										{listCells &&
											listCells.map((cell) => {
												if (cell === 'User Name') {
													return (
														<TableCell key={cell}>
															<div style={{ display: 'flex' }}>
																<Avatar />
																<div
																	className={
																		classes.username_wrapper
																	}
																>
																	<div
																		className={classes.username}
																	>
																		{row['UserName']}
																	</div>
																	<div className={classes.email}>
																		{row['Email']}
																	</div>
																</div>
															</div>
														</TableCell>
													);
												} else if (cell === 'Date & Time') {
													return (
														<TableCell>
															<Typography
																component="h6"
																className={classes.commentTime}
																onClick={() =>
																	setShowReadableDate(
																		!showReadableDate
																	)
																}
															>
																{displayHumanReadableDate(
																	row['CreatedAt']
																) + ' '}
																<br />
																{showReadableDate &&
																	`(${
																		row['CreatedAt'].split(
																			' '
																		)[0] +
																		' ' +
																		displayHumanReadableClock(
																			row['CreatedAt']
																		)
																	})`}
															</Typography>
														</TableCell>
													);
												} else if (cell === 'Response time') {
													return (
														<TableCell>{row['Latency']} ms</TableCell>
													);
												} else if (cell === 'Action') {
													return (
														<TableCell>
															<p
																style={{
																	whiteSpace: 'nowrap',
																	textTransform: 'capitalize'
																}}
															>
																{row['Action']}
															</p>
															{row['Path']}
														</TableCell>
													);
												} else if (cell === 'Detail') {
													let detail = row['Payload'];
													try {
														detail = JSON.parse(detail);
														detail = parseJSON(detail);
													} catch (e) {
														console.log(`Error parsing JSON: ${e}`);
													}
													return (
														<TableCell>
															<div
																className={classes.audit_detail}
																dangerouslySetInnerHTML={{
																	__html: detail
																}}
															/>
														</TableCell>
													);
												} else {
													return (
														<TableCell key={cell} value={row[cell]}>
															{row[cell]}
														</TableCell>
													);
												}
											})}
									</TableRow>
								);
							})}
					</TableBody>
				</Table>
			);
		}
	}

	return (
		<React.Fragment>
			{agents && (
				<Box className={classes.wrapper}>
					<FormControl variant="outlined">
						<InputLabel htmlFor="agent-dropdown">Select Agent</InputLabel>
						<Select
							onChange={handleDropdownChange}
							label="Select Agent"
							className={classes.select}
							value={curAgent}
						>
							{agents.map((agent) => (
								<MenuItem key={agent.uuid} value={agent.uuid}>
									{agent.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					{agentsLoading && (
						<CircularProgress size={24} className={classes.selectProgress} />
					)}
				</Box>
			)}

			<TableContainer className={classes.tableContainer} component={Paper}>
				<AuditTableComponent />
			</TableContainer>
			<Table>
				<TableBody>
					<TableRow>
						<TablePagination
							className={classes.pagination}
							rowsPerPageOptions={[10, 20, 30, { label: 'All', value: -1 }]}
							colSpan={9}
							count={rows.length}
							rowsPerPage={rowsPerPage}
							page={page}
							SelectProps={{
								inputProps: { 'aria-label': 'rows per page' },
								native: true
							}}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							ActionsComponent={() =>
								CusPaginationActions({
									count: rows.length,
									page,
									rowsPerPage,
									onChangePage: handleChangePage
								})
							}
						/>
					</TableRow>
				</TableBody>
			</Table>
		</React.Fragment>
	);
}
