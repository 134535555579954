import React, { useEffect, useState, useRef } from 'react';
import { GoogleMap, Marker, Circle } from '@react-google-maps/api';

export default function PropertyMap({
	currentPropertyLocation,
	otherListings,
	handleSelectedMapListing
}) {
	const [map, setMap] = useState(null);
	const [shouldRender, setShouldRender] = useState(false);
	const [currentCircle, setCurrentCircle] = useState(null);
	const [bounds, setBounds] = useState(new window.google.maps.LatLngBounds());

	const handleLoad = (map) => {
		setMap(map);
		handleSelectedMapListing(null);
	};

	const findMaxDistance = (arr) => {
		let maxDist = -Infinity;
		for (let i = 0; i < arr.length; i++) {
			const distance = arr[i].Distance;
			if (distance > maxDist) {
				maxDist = distance;
			}
		}
		return maxDist;
	};

	const handleCircleClick = (center) => {
		handleSelectedMapListing(center);
		if (map) {
			const circleClicked = {
				path: window.google.maps.SymbolPath.CIRCLE,
				fillColor: '#FF9900',
				fillOpacity: 1,
				fillOpacity: 0.6,
				strokeWeight: 0,
				scale: 26,
				clickable: false, // disable click on this circle to prevent adding more circles on top of each other
				zIndex: -1
			};

			const circleClickedMarker = new window.google.maps.Marker({
				position: center,
				map,
				icon: circleClicked
			});

			if (currentCircle) currentCircle.setMap(null); // remove the previously generated circle
			setCurrentCircle(circleClickedMarker); // update the state to current circle
			setShouldRender(!shouldRender); // force a rerender, so that the zIndex of the new circle is updated
		}
	};

	useEffect(() => {
		if (map) {
			otherListings.forEach((point) => {
				// placing all the other listings
				const [lat, lng] = point.Location.replace('POINT(', '').replace(')', '').split(' ');
				const center = { lat: parseFloat(lat), lng: parseFloat(lng) };
				setBounds((prevBounds) => prevBounds.extend(center));

				const circle = {
					path: window.google.maps.SymbolPath.CIRCLE,
					fillColor: '#004F2B',
					fillOpacity: 1,
					strokeWeight: 0,
					scale: 10
				};

				const circleMarker = new window.google.maps.Marker({
					position: center,
					map,
					icon: circle
				});

				circleMarker.addListener('click', () => handleCircleClick(center));
			});
		}
	}, [otherListings, shouldRender, map]);

	useEffect(() => {
		if (map) {
			// placing the red google maps marker (that represents the current property)
			const [lat, lng] = currentPropertyLocation
				.replace('POINT(', '')
				.replace(')', '')
				.split(' ');
			const center = { lat: parseFloat(lat), lng: parseFloat(lng) };
			setBounds((prevBounds) => prevBounds.extend(center));

			new window.google.maps.Marker({
				position: center,
				map,
				zIndex: 101
			});

			map.setCenter(center);
			map.fitBounds(bounds);
		}
	}, [currentPropertyLocation, map]);

	useEffect(() => {
		if (map) {
			// placing the large circle that encompasses all properties
			const [lat, lng] = currentPropertyLocation
				.replace('POINT(', '')
				.replace(')', '')
				.split(' ');
			const center = { lat: parseFloat(lat), lng: parseFloat(lng) };

			// if theres a problem with findMaxDistance set radius to 2000
			// (bc its thehard coded value that is set on backend)
			const radius = findMaxDistance(otherListings) * 1000 + 10 || 2000;

			new window.google.maps.Circle({
				center,
				radius,
				map,
				fillColor: '#FF9900',
				fillOpacity: 0.15,
				strokeWeight: 0
			});
			map.setCenter(center);
			map.fitBounds(bounds);
		}
	}, [map]);

	return (
		<GoogleMap
			onLoad={handleLoad}
			zoom={8}
			mapContainerStyle={{ width: '100%', height: '400px' }}
		/>
	);
}
