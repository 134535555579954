import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import blue from '@material-ui/core/colors/blue';
import SearchIcon from '@material-ui/icons/Search';

import SearchPriceDroppedForm from '../SearchPriceDroppedForm';

const styles = (theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	button: {
		flex: 1,
		color: blue[900],
		margin: 10,
		justifyContent: 'center',
		alignSelf: 'center'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
});

const DialogTitle = withStyles(styles)(({ children, classes, onClose, ...other }) => {
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

export default function Search({ handleSearch, areaOptionsQuery }) {
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<React.Fragment>
			<CardContent className={styles.root}>
				<Grid container justifyContent="center" alignItems="center">
					<IconButton onClick={handleClickOpen}>
						<Fab component="span" className={styles.button}>
							<SearchIcon />
						</Fab>
					</IconButton>
					<Dialog open={open} onClose={handleClose} aria-labelledby="search">
						<DialogTitle id="search" onClose={handleClose}>
							{'Search Price Dropped Properties'}
						</DialogTitle>
						<DialogContent dividers>
							<SearchPriceDroppedForm
								areaOptionsQuery={areaOptionsQuery}
								handleSearch={handleSearch}
								handleClose={handleClose}
							/>
						</DialogContent>
					</Dialog>
				</Grid>
			</CardContent>
		</React.Fragment>
	);
}
