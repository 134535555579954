import React, { useState, useEffect } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
//Dynamically load components on request for faster page rendering
import loadable from '@loadable/component';

import { makeStyles } from '@material-ui/core/styles';

import { signupUser, signupConfirm, getOrganization } from '../../../api/serverRequests';
import { Copyright, SingleInputForm } from '../../';
import Image from '../../../assets/images/signup_page_image.png';
import logo from '../../../SaigeLogo.svg';

const Button = loadable(() => import('@material-ui/core/Button'));
const CssBaseline = loadable(() => import('@material-ui/core/CssBaseline'));
const TextField = loadable(() => import('@material-ui/core/TextField'));
const FormControlLabel = loadable(() => import('@material-ui/core/FormControlLabel'));
const Checkbox = loadable(() => import('@material-ui/core/Checkbox'));
const Link = loadable(() => import('@material-ui/core/Link'));
const Paper = loadable(() => import('@material-ui/core/Paper'));
const Box = loadable(() => import('@material-ui/core/Box'));
const Grid = loadable(() => import('@material-ui/core/Grid'));
const OutlinedInput = loadable(() => import('@material-ui/core/OutlinedInput'));
const InputLabel = loadable(() => import('@material-ui/core/InputLabel'));
const IconButton = loadable(() => import('@material-ui/core/IconButton'));
const InputAdornment = loadable(() => import('@material-ui/core/InputAdornment'));
const MenuItem = loadable(() => import('@material-ui/core/MenuItem'));
const FormControl = loadable(() => import('@material-ui/core/FormControl'));
const Select = loadable(() => import('@material-ui/core/Select'));
const Visibility = loadable(() => import('@material-ui/icons/Visibility'));
const VisibilityOff = loadable(() => import('@material-ui/icons/VisibilityOff'));

const LockOutlinedIcon = loadable(() => import('@material-ui/icons/LockOutlined'));
const Typography = loadable(() => import('@material-ui/core/Typography'));

const Avatar = loadable(() => import('@material-ui/core/Avatar'));

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100vh'
	},
	image: {
		backgroundImage: `url(${Image})`,

		backgroundRepeat: 'no-repeat',
		backgroundColor:
			theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
		backgroundSize: 'cover',
		backgroundPosition: 'center'
	},
	paper: {
		padding: theme.spacing(5, 10),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		height: '100%',
		width: '100%',

		backgroundColor: theme.palette.grey[0]
	},
	centerBtm: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column'
	},
	formControl: {
		width: '100%',
		'& label.Mui-focused': {
			color: '#004F2B',
			background: 'rgba(72, 193, 198, 0.06)'
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#004F2B'
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#004F2B'
			},
			'&:hover fieldset': {
				borderColor: '#004F2B'
			},
			'&.Mui-focused fieldset': {
				borderColor: '#004F2B'
			}
		}
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
		alignItems: 'center'
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		backgroundColor: '#004F2B',
		boxShadow: 'none'
	},
	linkContent: {
		display: 'flex',
		flexDirection: 'column'
	},

	signUpText: {
		color: 'black'
	},
	termsAndPrivacy: {
		marginLeft: '2%',
		marginRight: '2%',
		textAlign: 'center',
		fontSize: '15',
		color: 'black',
		marginTop: theme.spacing(6)
	},
	signUpLink: {
		color: 'blue'
	},
	termsAndPrivacyLink: {
		textDecoration: 'underline',
		color: 'black'
	},
	textInput: {
		'& label.Mui-focused': {
			color: '#004F2B',
			background: 'rgba(72, 193, 198, 0.06)'
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#004F2B'
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#004F2B'
			},
			'&:hover fieldset': {
				borderColor: '#004F2B'
			},
			'&.Mui-focused fieldset': {
				borderColor: '#004F2B'
			}
		}
	},

	copyRight: {
		alignSelf: 'end',
		color: 'blue'
	},
	logoImg: {
		height: '92px',
		width: '115px',
		marginBottom: theme.spacing(2)
	},
	title: {
		position: 'absolute',
		[theme.breakpoints.down(960)]: {
			display: 'none'
		},
		color: '#FFFFFF',
		marginTop: theme.spacing(6),
		marginLeft: theme.spacing(7),
		textTransform: 'uppercase',
		fontStyle: 'normal',
		fontWeight: '600',
		fontSize: '28px',
		lineHeight: '110%',
		width: '350px',
		height: '84px',
		transform: 'scale(1.05, 1)'
	},
	subTitle: {
		position: 'absolute',
		[theme.breakpoints.down(960)]: {
			display: 'none'
		},
		width: '350px',
		height: '84px',
		marginTop: theme.spacing(14.25),
		marginLeft: theme.spacing(7),
		fontStyle: 'normal',
		fontWeight: '500',
		fontSize: '13px',
		lineHeight: '125%',
		color: '#FFFFFF',
		transform: 'scale(1.05, 1)'
	}
}));

export default function Signup() {
	const classes = useStyles();

	const history = useHistory();
	const methods = useForm();
	const { handleSubmit, control, errors, getValues } = methods;
	const [agencycode, setAgencyCode] = useState();
	const [email, setEmail] = useState();
	const [password, setPassword] = useState();
	const [confirming, setConfirming] = useState(false);
	const [errMessage, setErrMessage] = useState();
	const [organizationArr, setOrganizationArr] = useState();
	const [openDropdown, setOpenDropdown] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const handleClose = () => {
		setOpenDropdown(false);
	};

	const handleOpen = () => {
		setOpenDropdown(true);
	};

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleClickShowConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	//comparing equality of two values
	const sameAs = (field, getValues) => (value) => {
		const compareTo = getValues(field);
		return compareTo === value;
	};

	const onSignupSubmit = async (formData) => {
		// const organization = organizationArr.filter((o) => o.Id === formData.organizationId)[0]
		// 	.Title;
		// formData.organization = organization;

		signupUser(formData)
			.then((res) => {
				if (res.data.success) {
					setConfirming(true);
					setEmail(formData.email);
					setPassword(formData.password);
					setAgencyCode(formData.agencycode);
				} else {
					setErrMessage(res.data.err);
				}
			})
			.catch((err) => {
				setErrMessage('Oops! Internal Error');
				console.log(err.message);
			});
	};

	const onConfirmSubmit = async (formData) => {
		formData.email = email;
		formData.password = password;
		formData.agencycode = agencycode;

		signupConfirm(formData)
			.then((res) => {
				if (res.data.success) {
					setConfirming(false);
					history.push({
						pathname: '/login',
						state: false
					});
				} else {
					setErrMessage('res.data.err');
				}
			})
			.catch((err) => {
				setErrMessage('Oops, Internal Error');
				console.log(err.message);
			});
	};

	// useEffect(() => {
	// 	const fetchData = async () => {
	// 		getOrganization()
	// 			.then((res) => {
	// 				// console.log(res.data);
	// 				if (res.data.success) setOrganizationArr(res.data.organizations);
	// 			})
	// 			.catch((err) => console.log(err));
	// 	};
	// 	fetchData();
	// }, []);

	// const emailConfirm = (
	//    <>
	//       <Avatar className={classes.avatar}>
	//          <LockOutlinedIcon />
	//        </Avatar>
	//        <Typography component="h1" variant="h5">
	//          Confirm Your Email
	//        </Typography>
	//        <form className={classes.form} onSubmit={handleSubmit(onConfirmSubmit)}>
	//          <Grid container spacing={2}>
	//             <Grid item xs={12}>
	//             <Controller
	//                name="code"
	//                control={control}
	//                render={ props =>
	//                   <TextField
	//                      name="code"
	//                      variant="outlined"
	//                      fullWidth
	//                      id="code"
	//                      label="Code"
	//                      autoFocus
	//                      value={props.value || ''}
	//                      onChange={props.onChange}
	//                      inputRef={props.ref}
	//                   />}
	//                rules= {{
	//                   required: "Code is required",
	//                   minLength: {
	//                      value: 6,
	//                      message: "Invalid code"
	//                   }
	//                }}
	//                />
	//             <ErrorMessage
	//                errors={errors}
	//                name="code"
	//                render= {({ message })=> <p className="text-danger mx-2">{message}</p>}
	//             />
	//          </Grid>
	//          </Grid>
	//          <Button
	//             type="submit"
	//             fullWidth
	//             variant="contained"
	//             color="primary"
	//             className={classes.submit}
	//          >
	//             Confirm
	//          </Button>
	//       </form>
	//    </>
	// );

	const signupFragment = (
		<>
			<img src={logo} alt="Logo" className={classes.logoImg} />

			<form className={classes.form} onSubmit={handleSubmit(onSignupSubmit)}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<Controller
							name="firstName"
							defaultValue=""
							control={control}
							render={(props) => (
								<TextField
									className={classes.textInput}
									required
									autoComplete="fname"
									name="firstName"
									variant="outlined"
									fullWidth
									id="firstName"
									label="First Name"
									autoFocus
									value={props.value}
									onChange={props.onChange}
									inputRef={props.ref}
								/>
							)}
							rules={{
								required: 'First name is required',
								minLength: {
									value: 2,
									message: 'Please enter a first name'
								}
							}}
						/>
						<ErrorMessage
							errors={errors}
							defaultValue=""
							name="firstName"
							render={({ message }) => <p className="text-danger mx-2">{message}</p>}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Controller
							name="lastName"
							defaultValue=""
							control={control}
							render={(props) => (
								<TextField
									className={classes.textInput}
									required
									variant="outlined"
									fullWidth
									id="lastName"
									label="Last Name"
									name="lastName"
									autoComplete="lname"
									value={props.value}
									onChange={props.onChange}
									inputRef={props.ref}
								/>
							)}
							rules={{
								required: 'Last name is required',
								minLength: {
									value: 2,
									message: 'Please enter a last name'
								}
							}}
						/>
						<ErrorMessage
							errors={errors}
							name="lastName"
							render={({ message }) => <p className="text-danger mx-2">{message}</p>}
						/>
					</Grid>
					<Grid item xs={12}>
						{/* {errMessage && <p className="text-danger my-0">{errMessage}</p> } */}
						{/* <Controller
							name="organizationId"
							defaultValue=""
							control={control}
							render={(props) => (
								<FormControl
									required
									variant="outlined"
									className={classes.formControl}
								>
									<InputLabel id="label">Organization</InputLabel>
									<Select
										labelId="label"
										label="Organization"
										id="select"
										fullWidth
										value={props.value}
										open={openDropdown}
										onClose={handleClose}
										onOpen={handleOpen}
										onChange={props.onChange}
									>
										{organizationArr &&
											organizationArr.map((org) => (
												<MenuItem value={org.Id}>{org.Title}</MenuItem>
											))}
									</Select>
								</FormControl>
							)}
							rules={{
								required: 'Organization is required'
							}}
						/>
						<ErrorMessage
							errors={errors}
							name="organizationId"
							render={({ message }) => <p className="text-danger mx-2">{message}</p>}
						/> */}

						<Grid item xs={12}>
							<Controller
								name="agencycode"
								defaultValue=""
								control={control}
								render={(props) => (
									<TextField
										className={classes.textInput}
										required
										variant="outlined"
										margin="normal"
										fullWidth
										id="agencycode"
										label="Agency Code"
										name="agencycode"
										autoComplete="agencycode"
										value={props.value}
										onChange={props.onChange}
										inputRef={props.ref}
									/>
								)}
								rules={{
									required: 'Agency Code is required'
								}}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						{errMessage && <p className="text-danger my-0">{errMessage}</p>}
						<Controller
							name="email"
							defaultValue=""
							control={control}
							render={(props) => (
								<TextField
									className={classes.textInput}
									required
									variant="outlined"
									margin="normal"
									fullWidth
									id="email"
									label="Email Address"
									name="email"
									autoComplete="email"
									value={props.value}
									onChange={props.onChange}
									inputRef={props.ref}
								/>
							)}
							rules={{
								required: 'Email is required',
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
									message: 'Invalid email address'
								}
							}}
						/>
						<ErrorMessage
							errors={errors}
							name="email"
							render={({ message }) => <p className="text-danger mx-2">{message}</p>}
						/>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="password"
							defaultValue=""
							control={control}
							render={(props) => (
								<FormControl
									required
									variant="outlined"
									className={classes.formControl}
								>
									<InputLabel htmlFor="password">Password</InputLabel>
									<OutlinedInput
										name="password"
										id="Password"
										label="Password"
										type={showPassword ? 'text' : 'password'}
										autoComplete="current-password"
										value={props.value}
										onChange={props.onChange}
										inputRef={props.ref}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													edge="end"
												>
													{showPassword ? (
														<Visibility />
													) : (
														<VisibilityOff />
													)}
												</IconButton>
											</InputAdornment>
										}
									/>
								</FormControl>
							)}
							rules={{
								required: 'Password is required',
								minLength: {
									value: 8,
									message: 'Minimum length of password is 8 characters!'
								},
								pattern: {
									value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.,)(:";'\+_=]).{8,}$/,
									message:
										"A password must contain at least eight characters, including at least one number, both lower and uppercase letters, and special characters of '#?!@$%^&*-'"
								}
							}}
						/>
						<ErrorMessage
							errors={errors}
							name="password"
							render={({ message }) => <p className="text-danger mx-2">{message}</p>}
						/>
					</Grid>
					<Grid item xs={12}>
						{/* <Controller
							name="confirmPassword"
							defaultValue=""
							control={control}
							render={(props) => (
								<FormControl
									required
									variant="outlined"
									className={classes.formControl}
								>
									<InputLabel htmlFor="confirmPassword">
										Confirm Password
									</InputLabel>
									<OutlinedInput
										name="confirmPassword"
										id="confirmPassword"
										label="Confirm Password"
										type={showConfirmPassword ? 'text' : 'password'}
										autoComplete="current-password"
										value={props.value}
										onChange={props.onChange}
										inputRef={props.ref}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowConfirmPassword}
													edge="end"
												>
													{showConfirmPassword ? (
														<Visibility />
													) : (
														<VisibilityOff />
													)}
												</IconButton>
											</InputAdornment>
										}
									/>
								</FormControl>
							)}
							rules={{
								required: 'Confirm password is required',
								minLength: {
									value: 8,
									message: 'Minimum length of password is 8 characters!'
								},
								validate: sameAs('password', getValues)
							}}
						/>
						<ErrorMessage
							errors={errors}
							name="confirmPassword"
							render={({ message }) => {
								return errors.confirmPassword?.type === 'validate' ? (
									<p className="text-danger mx-2">Password does not match!</p>
								) : (
									<p className="text-danger mx-2">{message}</p>
								);
							}}
						/> */}
					</Grid>
					<FormControlLabel
						control={<Checkbox value="allowExtraEmails" color="" />}
						label={
							<Box component="div" fontSize={14} color="black">
								Send me emails about inspiration, marketing promotions and updates.
							</Box>
						}
					/>

					<p className={classes.termsAndPrivacy}>
						By creating an account, you acknowledge you have read and agreed to out{' '}
						<Link className={classes.termsAndPrivacyLink} href="login" variant="body2">
							Terms of Use
						</Link>{' '}
						and{' '}
						<Link className={classes.termsAndPrivacyLink} href="login" variant="body2">
							{' '}
							Privacy Policy
						</Link>
						.
					</p>
				</Grid>
				<Button
					type="submit"
					fullWidth
					variant="contained"
					color="primary"
					className={classes.submit}
				>
					Sign Up
				</Button>
				<div className={classes.centerBtm}>
					<p className={classes.signUpText}>
						Have an account?
						<Link className={classes.signUpLink} href="/" variant="body2">
							{' '}
							Login here!
						</Link>
					</p>
				</div>

				<Box mt={5}>
					<Copyright />
				</Box>
			</form>
		</>
	);

	return (
		<Grid container component="main" className={classes.root}>
			<CssBaseline />
			<Grid item xs={false} sm={4} md={6} className={classes.image} />
			<Typography variant="h3" className={classes.title}>
				Discover available properties
			</Typography>
			<Typography variant="p" className={classes.subTitle}>
				Find an extensive list of potential residential properties across the Lower Mainland
				generated by our Machine Learning algorithms.
			</Typography>
			<Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
				<div className={classes.paper}>
					{confirming ? (
						<SingleInputForm
							title="Confirm your email"
							handleSubmit={handleSubmit}
							errors={errors}
							onConfirmSubmit={onConfirmSubmit}
							control={control}
						/>
					) : (
						//emailConfirm
						signupFragment
					)}
				</div>
			</Grid>
		</Grid>
	);
}
