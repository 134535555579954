import React, { useEffect, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';
import { CardContent, Grid, Button, CircularProgress, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { useUserState } from '../../../hook/customerHook';
import { getHomeTags, getListingsByAllTags, getListingsByTag } from '../../../api/serverRequests';
import Search from '../Search';
import Loading from '../Loading';
import TagSelectField from '../TagSelectField';

const useStyles = makeStyles((theme) => ({
	submit: {
		margin: theme.spacing(3, 0, 2),
		background: '#B6B6B6',
		'&:hover': {
			backgroundColor: '#004F2B',
			boxShadow: 'none'
		}
	},
	formControl: {
		marginTop: theme.spacing(1),
		width: '100%'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	card: {
		margin: theme.spacing(0, 0, 8)
	}
}));

export default function TagDropdown({
	handleLoading,
	handleListings,
	agentValue,
	canViewOtherAgentTags,
	setIsLoading
}) {
	const [userState, dispatchUser] = useUserState();
	const [agentQuery, setAgentQuery] = useState([]);
	const [tagNameOptionsQuery, setTagNameOptionsQuery] = useState([]);
	const [tagNameOptions, setTagNameOptions] = useState([]);
	const classes = useStyles();
	const { handleSubmit, control, watch, setValue } = useForm();

	// // submit status updated after user has clicked save in edit modal
	const [submitSuccessStatus, setSubmitSuccessStatus] = useState(''); // keeps track of status when data is posted

	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message

	const agentField = watch('agent');
	const tagName = watch('tag');

	const fields = [
		{
			name: 'agent',
			options: agentQuery,
			label: 'Agent',
			multiple: false,
			disabled: !canViewOtherAgentTags
		},
		{
			name: 'tag',
			options: tagNameOptions,
			label: 'Tag Name',
			multiple: false
		}
	];

	const handleSearchSubmit = (formData) => {
		if (formData.agent.length <= 0) return;

		let filtersPersisted = {};

		for (const key of Object.keys(formData)) {
			if (formData[key] && formData[key] !== 'any') {
				filtersPersisted[key] = formData[key];
			}
		}

		dispatchUser({ agentTags: filtersPersisted });

		setIsLoading(true);
		getListingsByTag(formData['tag']['value'].tagId, formData['agent'].value)
			.then((res) => {
				if (res.data.success) {
					const currListings = {};
					res.data.listings.forEach((listing) => {
						if (!(listing.MLNum in currListings)) currListings[listing.MLNum] = listing;
					});
					handleListings(Object.values(currListings));
				}
				setIsLoading(false);
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue'
					);
					setIsLoading(false);
					dispatchUser({
						isLoggedIn: false
					});
					return;
				}
				console.log(err.message);
				setIsLoading(false);
			});
	};

	const fetchData = async (unmounted) => {
		getHomeTags()
			.then((res) => {
				if (res.status === 200) {
					if (res.data.tags) {
						handleAgentData(res.data.tags);
					}
					setIsLoading(false);
				}
				if (!unmounted) {
					setIsLoading(false);
					handleLoading(false);
				}
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue'
					);
					dispatchUser({
						isLoggedIn: false
					});
				} else if (!unmounted) {
					setIsLoading(false);
					handleLoading(false);
				}
				console.log(err.message);
			});
	};

	// build agent and tag data
	const handleAgentData = (data) => {
		let array = [];
		let tags = [];
		let value;

		Object.keys(data).map((k) => {
			array.push({
				label: k,
				value: data[k]['user_id']
			});

			if (data[k]['user_id'] === agentValue) value = { label: k, value: agentValue };

			data[k]['tags'].map((n) => {
				tags.push({
					label: k,
					value: { tagName: n.tag_name, tagId: n.tag_id }
				});
			});
		});

		setAgentQuery(array);
		setTagNameOptionsQuery(tags);
		const filter = tags.filter((item) => {
			return value.label === item.label;
		});
		if (filter.length > 0) {
			setValue('agent', value);
			setTagNameOptions(filter);
			setValue('tag', filter[0]);
			setIsLoading(false);
		}
		if (sessionStorage.getItem('agentTags')) {
			setValue('agent', JSON.parse(sessionStorage.getItem('agentTags'))['agent']);
			setValue('tag', JSON.parse(sessionStorage.getItem('agentTags'))['tag']);
		} else setValue('agent', value);
	};

	useEffect(() => {
		let unmounted = false;
		fetchData(unmounted);
		return () => {
			unmounted = true;
		};
	}, []);

	//Dynamically adjust tag options depending on agent values selected
	useEffect(() => {
		const agent = agentQuery.filter((item) => {
			return agentValue === item.value;
		});

		if (agent.length > 0) {
			const filter = tagNameOptionsQuery.filter((item) => {
				return agent[0].label === item.label;
			});
			setValue('agent', agent[0]);
			setTagNameOptions(filter);
			setValue('tag', filter[0]);
		} else {
			setValue('agent', []);
			setTagNameOptions([]);
			setValue('tag', []);
			handleListings([]);
		}
	}, [agentValue]);

	//change filters if tag is changed
	useEffect(() => {
		if (tagName) handleSubmit(handleSearchSubmit)();
	}, [tagName]);

	return (
		<React.Fragment>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={notificationState}
				autoHideDuration={6000}
				onClose={() => setNotificationState(false)}
			>
				<Alert
					onClose={() => setNotificationState(false)}
					severity={submitSuccessStatus === 'success' ? 'success' : 'error'}
					variant="filled"
				>
					{notificationMessage}
				</Alert>
			</Snackbar>
			<CardContent className={classes.card}>
				<form className={classes.form}>
					<Grid container justifyContent="center" alignItems="center" spacing={2}>
						{fields.map((field) => {
							return (
								<TagSelectField
									defaultValue={[]}
									key={field.name}
									itemGrid={12}
									name={field.name}
									label={field.label}
									options={field.options}
									control={control}
									requiredValue={field.required}
									fieldStyle={classes.formControl}
									multiple={field.multiple}
									disabled={field.disabled}
								/>
							);
						})}
					</Grid>

					<Grid
						container
						justifyContent="flex-end"
						alignItems="center"
						spacing={2}
					></Grid>
				</form>
			</CardContent>
		</React.Fragment>
	);
}
