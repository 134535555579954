import { useMemo, useState, useEffect } from 'react';
import { Typography, Box, Table, TableRow } from '@material-ui/core';
import MuiTableCell from '@material-ui/core/TableCell';
import { makeStyles, withStyles } from '@material-ui/styles';
import { LISTING_STATUS } from '../../../../helper/listing-info';
import {
	currencyParser,
	dateFormatter,
	dateFormatterFormal,
	formatPrice
} from '../../../../helper/parser';
import moment from 'moment';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ViewDimensions from './ViewDimensions';

const useStyles = makeStyles((theme) => ({
	root: {
		//
	},
	firstTitle: {
		margin: '0.4rem 0',
		paddingBottom: 0
	},
	title: {
		margin: '2.2rem 0 1rem',
		paddingBottom: 0
	},
	listingDetailsTable: {
		margin: '1rem 2rem 1rem 0',
		width: 'calc(20vw + 10rem)',

		'@media (max-width: 700px)': {
			width: 'calc(60vw + 10rem)'
		}
	},
	longMLSOwnerName: {
		maxWidth: '30vw'
	},
	propertyDetailsTitleContainer: {
		margin: '2.2rem 0 1rem',
		paddingBottom: 0,
		display: 'flex',
		alignItems: 'center'
	},
	propertyDetailsTable: {
		width: 'calc(32vw + 10rem)',
		margin: '1rem 2rem 1rem 0',

		'@media (min-width: 700px)': {
			display: 'flex'
		}
	},
	spacer: {
		marginLeft: '2rem',
		'@media (max-width: 699px)': {
			marginLeft: 0
		}
	},
	remarks: {
		marginTop: '0.2rem'
	},
	lastModified: {
		margin: '1.5rem 0'
	},
	seeMoreContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	seeMoreIcon: {
		display: 'inline-block',
		color: theme.palette.primary.main
	},
	seeMore: {
		display: 'inline-block',
		textDecoration: 'underline',

		color: theme.palette.primary.main,
		fontWeight: 700,
		'&:hover': {
			cursor: 'pointer'
		}
	}
}));

const TableCell = withStyles({
	root: {
		borderBottom: 'none',
		padding: '0.5rem 0'
	}
})(MuiTableCell);

export default function DetailTab({ listing }) {
	const classes = useStyles();

	const [showSeeMore, setShowSeeMore] = useState(false); // determined base on viewWidth

	// see more button for LISTING details
	const [listingDetailsCollapsed, setListingDetailsCollapsed] = useState(true);

	// see more button for PROPERTY details
	const [propertyDetailsCollapsed, setPropertyDetailsCollapsed] = useState(true);

	// useEffect to determine if we need to display the See More option (for mobile)
	useEffect(() => {
		function displaySeeMoreButtons() {
			const screenWidth = window.innerWidth;

			screenWidth < 550 ? setShowSeeMore(true) : setShowSeeMore(false);
		}

		displaySeeMoreButtons();
		window.addEventListener('resize', displaySeeMoreButtons);

		return () => {
			window.removeEventListener('resize', displaySeeMoreButtons);
		};
	}, []);

	const dateShow = (status, listing) => {
		if (status === 'A') {
			return '';
		} else if (status === 'S' || status === 'F') {
			return `(${dateFormatterFormal(listing.SoldDate)})`;
		} else if (status === 'X') {
			return `(${dateFormatterFormal(listing.ExpiryDate)})`;
		} else if (status === 'T') {
			return `Expiry Date: ${listing.ExpiryDate}`;
		} else if (status === 'C' && listing.ExpiryDate != null) {
			return `(Expiry Date: ${dateFormatterFormal(listing.ExpiryDate)})`;
		} else {
			return '';
		}
	};

	const listingDetails = useMemo(
		() => [
			{
				label: 'Assessment',
				value: listing.assessment ? currencyParser(listing.assessment) : '-'
			},
			{
				label: 'MLS No.',
				value: listing.MLNum
			},
			{
				label: 'Status',
				value: `${LISTING_STATUS[listing.Status.toUpperCase()]} ${dateShow(
					listing.Status,
					listing
				)}`
			},
			{
				label: 'List Date',
				value: dateFormatter(listing.ListDate)
			},
			{
				label: 'MLS Owner',
				value: listing.OwnerName ? listing.OwnerName : '-'
			},
			{
				label: 'Occupancy',
				value: listing.Occupancy ? listing.Occupancy : '-'
			}
		],
		[listing]
	);

	const propertyDetailsLeft = useMemo(
		() => [
			{
				label: 'Bedrooms',
				value: listing.TotBR
			},
			{
				label: 'Bathrooms',
				value: listing.TotBaths
			},
			{
				label: 'Kitchens',
				value: listing.NumKitchens
			},
			{
				label: 'Year Built',
				value: listing.YrBlt
			},
			{
				label: 'Type',
				value: listing.TypeDwel
			},
			{
				label: 'Sqft',
				value: listing.TotFlArea
			},
			{
				label: 'Frontage(ft)',
				value: listing.FrontageFeet
			},
			{
				label: 'LandSqft',
				value: listing.LotSzSqFt
			},
			{
				label: 'No. of Floors',
				value: listing.NoFloorLevels
			}
		],
		[listing]
	);

	const propertyDetailsRight = useMemo(
		() => [
			{
				label: 'Style of Home',
				value: listing.StyleofHome
			},
			{
				label: 'Direction',
				value: listing.direction !== null ? listing.direction : '-'
			},
			{
				label: 'Zoning',
				value: listing.Zoning
			},
			{
				label: 'Future Zoning',
				value: listing.FutureZoning !== null ? listing.FutureZoning : '-'
			},
			{
				label: 'Dom',
				value: listing.DOM
			},
			{
				label: 'Depth',
				value: listing.Depth !== null ? listing.Depth : '-'
			},
			{
				label: 'Distance to highway',
				value: '-' // We don't have data for this
			},
			{
				label: 'Main road',
				value: listing.mainRoad !== null ? listing.mainRoad : '-'
			},
			{
				label: 'Sub Area',
				value: listing.SA
			}
		],
		[listing]
	);

	const combinedPropertyDetails = [...propertyDetailsLeft, ...propertyDetailsRight]; // this is used for mobile

	const renderListingDetails = () => {
		// determine which details should be visible
		const visibleMobileListingDetails =
			showSeeMore && listingDetailsCollapsed ? listingDetails.slice(0, 2) : listingDetails;

		return visibleMobileListingDetails.map((detail) => (
			<>
				{listingDetailRow(detail.label, detail.value)}
				{detail.label === 'Status' &&
					listing.Status === 'S' &&
					listingDetailRow('Sold Price', `$${formatPrice(listing.SoldPrice)}`)}
			</>
		));
	};

	const listingDetailRow = (label, value) => {
		return (
			<TableRow key={label} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
				<TableCell component="th" scope="row">
					<Typography variant="subtitle1" component="h5">
						{label}
					</Typography>
				</TableCell>
				<TableCell align="left">
					<Typography
						className={value.length > 28 && classes.longMLSOwnerName} // need to set special styling if MLSOwner name is long
						variant="subtitle1"
						component="h5"
					>
						{value}
					</Typography>
				</TableCell>
			</TableRow>
		);
	};

	const renderPropertyDetails = (propertyDetails) => {
		// determine which details should be visible
		const visibleMobilePropertyDetails =
			showSeeMore && propertyDetailsCollapsed ? propertyDetails.slice(0, 3) : propertyDetails;

		return visibleMobilePropertyDetails.map((detail) => (
			<TableRow key={detail.label} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
				<TableCell component="th" scope="row">
					<Typography variant="subtitle1" component="h5">
						{detail.label}
					</Typography>
				</TableCell>
				<TableCell align="left">
					<Typography variant="subtitle1" component="h5">
						{detail.value}
					</Typography>
				</TableCell>
			</TableRow>
		));
	};

	return (
		<>
			<Box className={classes.root}>
				<Typography variant="h4" component="h4" className={classes.firstTitle}>
					Listing Details
				</Typography>

				<Table
					className={
						listingDetails[4].value
							? listingDetails[4].value.length < 28 && classes.listingDetailsTable
							: ''
					} // need to set different styling if MLSOwner name is short
				>
					{renderListingDetails()}
				</Table>

				{/* the see more button for LISTING details (for mobile) */}
				{showSeeMore && (
					<Box
						className={classes.seeMoreContainer}
						onClick={() => setListingDetailsCollapsed(!listingDetailsCollapsed)}
					>
						<Typography variant="subtitle2" component="h6" className={classes.seeMore}>
							{listingDetailsCollapsed ? 'See more' : 'See less'}
						</Typography>
						{listingDetailsCollapsed ? (
							<ExpandMoreIcon className={classes.seeMoreIcon} />
						) : (
							<ExpandLessIcon className={classes.seeMoreIcon} />
						)}
					</Box>
				)}

				<Typography
					variant="h4"
					component="h4"
					className={classes.propertyDetailsTitleContainer}
				>
					Property Details
					<ViewDimensions listing={listing} />
				</Typography>

				{/* the see more button for PROPERTY details (for mobile) */}
				{!showSeeMore ? (
					<Box className={classes.propertyDetailsTable}>
						<Table>{renderPropertyDetails(propertyDetailsLeft)}</Table>
						<Table className={classes.spacer}>
							{renderPropertyDetails(propertyDetailsRight)}
						</Table>
					</Box>
				) : (
					<>
						<Box className={classes.propertyDetailsTable}>
							<Table>{renderPropertyDetails(combinedPropertyDetails)}</Table>
						</Box>
						<Box
							className={classes.seeMoreContainer}
							onClick={() => setPropertyDetailsCollapsed(!propertyDetailsCollapsed)}
						>
							<Typography
								variant="subtitle2"
								component="h6"
								className={classes.seeMore}
							>
								{propertyDetailsCollapsed ? 'See more' : 'See less'}
							</Typography>
							{propertyDetailsCollapsed ? (
								<ExpandMoreIcon className={classes.seeMoreIcon} />
							) : (
								<ExpandLessIcon className={classes.seeMoreIcon} />
							)}
						</Box>
					</>
				)}

				<Typography variant="h4" component="h4" className={classes.title}>
					Public Remarks
				</Typography>
				<Typography variant="subtitle1" component="h5">
					{listing.PublicRemarks}
				</Typography>
				<Typography variant="h4" component="h4" className={classes.title}>
					Realtor Remarks
				</Typography>
				<Typography variant="subtitle1" component="h5">
					{listing.RealtorRemarks}
				</Typography>
				<Typography variant="subtitle2" component="h5" className={classes.lastModified}>
					{`Last Modified: ${moment.utc(listing.LastModified).local().fromNow()}`}
				</Typography>
			</Box>
		</>
	);
}
