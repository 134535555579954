import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Title } from '../..';

const useStyles = makeStyles((theme) => ({
	container: {
		height: '100%',
		width: '100%',
		padding: theme.spacing(3, 5)
	}
}));

export default function ListingRemarks({ listing }) {
	const classes = useStyles();

	const remarks = [
		{
			title: 'Public Remarks',
			content: listing.PublicRemarks
		},
		{
			title: 'Realtor Remarks',
			content: listing.RealtorRemarks
		}
	];
	return (
		<>
			{remarks.map((remark, idx) => (
				<Grid key={idx} item xs={12} md={6} lg={4}>
					<Paper className={classes.container}>
						<Title>{remark.title}</Title>
						<Typography>{remark.content}</Typography>
					</Paper>
				</Grid>
			))}
		</>
	);
}
