import moment from 'moment'; // for display human readable function

export const sessionStorageParser = (value) => {
	//if value is null, retrieving from sessionStorage
	//else store value to sessionStorage

	if (value) {
		for (const key of Object.keys(value)) {
			if (key === 'isLoggedIn' && !value[key]) {
				sessionStorage.clear();
				return;
			}
			if (typeof value[key] == 'object')
				sessionStorage.setItem(key, JSON.stringify(value[key]));
			else sessionStorage.setItem(key, value[key]);
		}
	} else {
		const storage = {
			...sessionStorage
		};
		for (const key of Object.keys(storage)) {
			try {
				storage[key] = JSON.parse(storage[key]);
			} catch (error) {
				sessionStorage.removeItem(key);
			}
		}
		return storage;
	}
};

export const currencyParser = (num) => {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 0
	});
	return formatter.format(num);
};

export const numberParser = (num) => {
	return parseFloat(num.toString().replace('/,/g', ''));
};

export const getLastYear = () => {
	const currentYear = new Date();
	return currentYear.getFullYear() - 1;
};

export const dateFormatter = (inputDate) => {
	if (!inputDate) return '-';
	let raw = new Date(inputDate.replace(/-/g, '/'));
	let options = {
		month: 'short',
		day: 'numeric',
		year: 'numeric'
	};
	return raw.toLocaleString('en-CA', options);
};

export const dateFormatterFormal = (inputDate) => {
	let raw = new Date(inputDate.replace(/-/g, '/'));
	let options = {
		month: 'numeric',
		day: 'numeric',
		year: 'numeric'
	};
	return raw.toLocaleString('en-CA', options);
};

export const formatAsPercentage = (num) => `${Math.round(num * 100)}%`;

export const formatAddress = (address) => {
	return address
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		.join(' ');
};

export const formatPrice = (price) => price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const correctKnockabilityValue = (value) => Math.floor(value * 100);

export const displayHumanReadableDate = (date) => {
	const time = moment.utc(date).local();
	return moment(time).fromNow();
};

export const displayHumanReadableClock = (date) => {
	const time = moment.utc(date).local();
	return moment(time).format('hh:mm:ss A');
};

export const currentTimeInUnix = moment().valueOf();

export const saveUserLocation = () => {
	let options = {
		enableHighAccuracy: true,
		timeout: 5000,
		maximumAge: 0
	};

	if (navigator.permissions && navigator.permissions.query) {
		navigator.permissions
			.query({
				name: 'geolocation'
			})
			.then(function (result) {
				if (result.state === 'granted') {
					navigator.geolocation.getCurrentPosition(successGetCurrentPosition);
				} else if (result.state === 'prompt') {
					navigator.geolocation.getCurrentPosition(
						successGetCurrentPosition,
						errorGetCurrentPosition,
						options
					);
				} else if (result.state === 'denied') {
				}

				result.onchange = function () {
					console.log(result.state);
				};
			})
			.catch(function (error) {
				console.error('Error querying geolocation permission:', error);
			});
	} else if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(
			successGetCurrentPosition,
			errorGetCurrentPosition,
			options
		);
	} else {
		console.log('Geolocation is not supported in this browser.');
	}
};

// save user's location to sessionStorage
function successGetCurrentPosition(pos) {
	let coords = pos.coords;

	sessionStorage.setItem(
		'location',
		JSON.stringify({
			lat: coords.latitude,
			long: coords.longitude
		})
	);
}

function errorGetCurrentPosition(err) {
	console.warn(`ERROR(${err.code}): ${err.message}`);
}

export function isLetter(char) {
	return /^[a-zA-Z]$/.test(char);
}
