//Dynamically load components on request for faster page rendering
import loadable from '@loadable/component';
import BaseLayout from '../../comps/BaseLayout';
import { useEffect, useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import { getAllUserFollowUps } from '../../../api/serverRequests';
import { useUserState } from '../../../hook/customerHook';
import Loading from '../../comps/Loading';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { Snackbar, Paper } from '@material-ui/core';
import { PERMS } from '../../../helper/perms';

const ErrorMsg = loadable(() => import('../../comps/ErrorMsg'));
const ReactCalendar = loadable(() => import('../../comps/ReactCalendar'));

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column'
	},
	desc: {
		fontSize: '15px',
		margin: '10px 0'
	}
}));

const FollowUps = () => {
	const history = useHistory();
	const now = new Date();
	const [, dispatchUser] = useUserState();
	const [isLoading, setIsLoading] = useState(true);
	const [errMsg, setErrMsg] = useState('');
	const [events, setEvents] = useState([]);
	const classes = useStyles();
	// // submit status updated after user has clicked save in edit modal
	const [submitSuccessStatus, setSubmitSuccessStatus] = useState(''); // keeps track of status when data is posted

	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message
	const exampleEvent = [
		{
			id: 1,
			title: '3340 River Ranch Rd',
			start: now,
			end: now
		},
		{
			id: 2,
			title: 'Open House week',
			start: new Date(2023, 0, 7, 0, 0, 0), // March 7 2022
			end: new Date(2023, 0, 11, 0, 0, 0) // March 10 2022
		},
		{
			id: 3,
			title: 'Random Event',
			start: moment.unix(1647572077).toDate(), // March 17 2022 10:54 pm
			end: moment.unix(1647572077).toDate()
		}
	];

	useEffect(() => {
		getAllUserFollowUps(PERMS.KNOCKING_STATUS)
			.then((res) => {
				if (res.status === 200) {
					const thirtyMinsUnix = 30 * 60; // 30mins
					let newArr = [];

					for (let followUp of res.data.user_follow_ups) {
						const manipulateObj = {
							...followUp,
							start: moment.unix(followUp.start).toDate(),
							end: moment.unix(followUp.end + thirtyMinsUnix).toDate(),
							title: followUp.user_name
								? followUp.title + '  (' + followUp.user_name + ')'
								: followUp.title,
							mlNum: followUp.mlnum
						};
						newArr.push(manipulateObj);
					}
					setEvents((prevState) => [...prevState, ...newArr]);
				}
			})
			.then(() => {
				setIsLoading(false);
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is timed out. Please log in again to continue'
					);
					dispatchUser({
						isLoggedIn: false
					});
					return;
				}
				setErrMsg('Error while fetching follow up date!');
				console.log(err.message);
			});
	}, [dispatchUser]);

	// Print button variables
	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current
	});

	return (
		<BaseLayout>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={notificationState}
				autoHideDuration={6000}
				onClose={() => setNotificationState(false)}
			>
				<Alert
					onClose={() => setNotificationState(false)}
					severity={submitSuccessStatus === 'success' ? 'success' : 'error'}
					variant="filled"
				>
					{notificationMessage}
				</Alert>
			</Snackbar>

			<Paper className={classes.paper}>
				{isLoading ? (
					<Loading />
				) : (
					<>
						<div
							style={{
								display: 'flex',
								alignItems: 'start',
								flexWrap: 'wrap',
								gap: '1%',
								cursor: 'pointer',
								marginBottom: '2rem'
							}}
							onClick={handlePrint}
						>
							<PrintOutlinedIcon />
							<span> Print Follow Up Schedule </span>
						</div>
						<p className={classes.desc}>Click on a event to view its listing page.</p>
						<ReactCalendar events={events} ref={componentRef} history={history} />
					</>
				)}
				{errMsg && <ErrorMsg text={errMsg} />}
			</Paper>
		</BaseLayout>
	);
};

export default FollowUps;
