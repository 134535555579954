import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { TextField, Checkbox } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { useUserState } from '../../../hook/customerHook';
import { Loading, CusSelectField, ErrorMsg, SubmitButton } from '../../';
import { getTagsByOrganization, postTagToHome } from '../../../api/serverRequests';

const useStyles = makeStyles((theme) => ({
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
		'@media print': {
			display: 'none'
		}
	},

	formControl: {
		marginTop: theme.spacing(1),
		width: '100%'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	}
}));

export default function TagForm({ mlnum, homeId, handleRefresh }) {
	const [tags, setTags] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [errMsg, setErrMsg] = useState('');
	const classes = useStyles();
	const { handleSubmit, control, reset } = useForm();
	const [, dispatchUser] = useUserState();

	// formData format is {Tag:[{label:'', value:[id]}]}
	// only id is needed for API call
	const extraTagIds = (formData) => {
		return formData.tag.map((tag) => tag.value[0]);
	};

	const handlePostTags = (formData) => {
		if (formData.tag.length === 0) {
			setErrMsg('Tag is required');
			return;
		}
		if (homeId.length > 1) {
			for (let i = 0; i < homeId.length; i++) {
				postTagToHome({ homeId: homeId[i], mlnum: mlnum[i], tags: extraTagIds(formData) })
					.then((res) => {
						setErrMsg('');
						if (res.data.success) {
							handleRefresh();
							reset({ tag: [] });
						}
					})
					.catch((err) => {
						setErrMsg(err.message);
						console.log(err.message);
					});
			}
		} else {
			postTagToHome({ homeId: homeId, mlnum: mlnum, tags: extraTagIds(formData) })
				.then((res) => {
					setErrMsg('');
					if (res.data.success) {
						handleRefresh();
						reset({ tag: [] });
					}
				})
				.catch((err) => {
					setErrMsg(err.message);
					console.log(err.message);
				});
		}
	};

	const mapTagToOptions = (tags) => {
		return tags.map((tag) => {
			return { label: tag.Tag, value: [tag.Id] };
		});
	};

	useEffect(() => {
		let unmounted = false;
		const fetchData = async () => {
			getTagsByOrganization()
				.then((res) => {
					if (res.status === 200) {
						setTags(mapTagToOptions(res.data.tags));
					}
					if (!unmounted) {
						setIsLoading(false);
					}
				})
				.catch((err) => {
					if (err.response.status === 401) {
						dispatchUser({
							isLoggedIn: false
						});
						return;
					} else if (!unmounted) {
						setIsLoading(false);
					}
					setErrMsg(err.message);
					console.log(err.message);
				});
		};
		fetchData();
		return () => {
			unmounted = true;
		};
	}, []);
	return isLoading ? (
		<Loading />
	) : (
		<React.Fragment>
			<form className={classes.form} onSubmit={handleSubmit(handlePostTags)}>
				<Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
					<CusSelectField
						defaultValue={[]}
						itemGrid={12}
						name="tag"
						label="Tag"
						options={tags}
						control={control}
						fieldStyle={classes.formControl}
						multiple={true}
					/>
					{errMsg && <ErrorMsg text={errMsg} />}
					<Grid item>
						<SubmitButton />
					</Grid>
				</Grid>
			</form>
		</React.Fragment>
	);
}
