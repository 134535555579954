import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { makeStyles } from '@material-ui/core/styles';

import { Lists, ListTagTable, TagSummary, Title, TagDropdown, BaseLayout, Loading } from '../../';
import { useUserState } from '../../../hook/customerHook';
import {
	getListingsByTag,
	deleteFromTagCollection,
	checkUserHasPermissionId
} from '../../../api/serverRequests';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(3),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column'
	},
	fixedHeight: {
		height: 120
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	}
}));

export default function Dashboard() {
	const classes = useStyles();
	// const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
	const [listings, setListings] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [userState, dispatchUser] = useUserState();
	const [tagId, setTagId] = useState(null);
	const [userId, setUserId] = useState(null);
	const [agentValue, setAgentValue] = useState(null);
	const [viewTagsPermission, setViewTagsPermission] = useState(false);

	// permission ids
	const VIEW_OTHER_TAGS_PERM_ID = 13;

	useEffect(() => {
		localStorage.setItem('userState', JSON.stringify(userState));
		localStorage.setItem('isLoggedIn', true);

		const checkTagViewingStatus = async () => {
			checkUserHasPermissionId(VIEW_OTHER_TAGS_PERM_ID, userState.user.user_id)
				.then((res) => {
					if (res.data.success) {
						setViewTagsPermission(true);
					}
				})
				.catch((err) => {
					if (err.response && err.response.status === 401) {
						dispatchUser({
							isLoggedIn: false
						});
					}
					console.error(err.message);
				});
		};
		checkTagViewingStatus();
	}, []);

	useEffect(() => {
		let unmounted = false;
		return () => {
			unmounted = true;
		};
	}, []);

	const titles = [
		{ label: 'Image', numeric: false, id: 'Pics' },
		{ label: 'Address', numeric: false, id: 'Address' },
		{ label: 'Area', numeric: false, id: 'Area' },
		{ label: 'SubArea', numeric: false, id: 'S/A' },
		{ label: 'Price', numeric: true, id: 'Price' },
		{ label: 'Assessment', numeric: true, id: 'assessment' },
		{ label: 'Total Bedrooms', numeric: true, id: 'Tot BR' },
		{ label: 'Total Bathrooms', numeric: true, id: 'Tot Baths' },
		{ label: 'Kitchens', numeric: true, id: '#Kitchens' },
		{ label: 'Total Floor Area', numeric: true, id: 'TotFlArea' },
		{ label: 'Year Built', numeric: true, id: 'Yr Blt' },
		{ label: 'Frontage (Feet)', numeric: true, id: 'Frontage - Feet' },
		{ label: 'Lot Size', numeric: true, id: 'Lot Sz (Sq.Ft.)' }
		//{label: 'Direction',numeric: false, id:''},
	];

	const cells = [
		'Address',
		'Area',
		'SA',
		'Price',
		'assessment',
		'TotBR',
		'TotBaths',
		'NumKitchens',
		'TotFlArea',
		'YrBlt',
		'FrontageFeet',
		'LotSzSqFt'
	];

	const handleLoading = (loading) => {
		setIsLoading(loading);
	};

	// TagSummary
	/* 	const handleTagClick = (tagId, userId) => {
		setTagId(tagId);
		setUserId(userId);
		getListingsByTag(tagId, userId)
			.then((res) => {
				if (res.data.success) {
					const currListings = {};
					res.data.listings.forEach((listing) => {
						if (!(listing.MLNum in currListings)) currListings[listing.MLNum] = listing;
					});
					setListings(Object.values(currListings));
				}
			})
			.catch((err) => {
				if (err.response.status === 401) {
					alert('Your connection is time out. Please log in again to continue');
					dispatchUser({
						isLoggedIn: false
					});
					return;
				}
				console.log(err.message);
			});
	};
 */
	//TagDropdown
	/* 	const handleDelete = (list) => {
		deleteFromTagCollection(list)
			.then((res) => {
				if (res.data.success) alert('Selected listings are deleted!');
				let item = listings;
				const arr = item.filter((i) => {
					return !list.some((j) => j.listing === i.MLNum);
				});
				setListings(arr);
			})
			.catch((err) => {
				if (err.response.status === 401) {
					alert('Your connection is time out. Please log in again to continue');
					dispatchUser({
						isLoggedIn: false
					});
				}
				console.log(err.message);
			});
	}; */

	const handleDelete = (listings) => {
		deleteFromTagCollection({ listings: listings, tagId: tagId })
			.then((res) => {
				console.log(res.data);
				if (res.data.success) alert('Selected listings are deleted!');
				setTagId(null);
			})
			.catch((err) => {
				if (err.response.status === 401) {
					alert('Your connection is time out. Please log in again to continue');
					dispatchUser({
						isLoggedIn: false
					});
				}
				console.log(err.message);
			});
	};

	return (
		<BaseLayout>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper className={classes.paper}>
						<Title>Tags</Title>
						<TagDropdown
							handleLoading={handleLoading}
							handleAgentValue={setAgentValue}
							handleListings={setListings}
							canViewOtherAgentTags={viewTagsPermission}
							defaultAgentValue={userState.user.user_id}
						/>
						{/* 						<TagSummary
							handleTagClick={handleTagClick}
							handleLoading={handleLoading}
							tagId={tagId}
						/> */}
					</Paper>
				</Grid>

				<Grid item xs={12}>
					{isLoading && <Loading />}
					<Paper className={classes.paper}>
						{listings && listings.length > 0 ? (
							<Lists
								isSearch={false}
								listHeads={titles}
								listCells={cells}
								listings={listings}
								handleDelete={handleDelete}
								title={`Listing with tags`}
							/>
						) : (
							<Title>No listings found</Title>
						)}
					</Paper>
				</Grid>
			</Grid>
		</BaseLayout>
	);
}
