import React, { useEffect, useState } from 'react';
import { updateUserPayment, getUserBalance } from '../../../../api/serverRequests';
import {
	Paper,
	Switch,
	Button,
	Typography,
	Grid,
	Menu,
	MenuItem,
	Tab,
	Tabs,
	CircularProgress
} from '@material-ui/core';
import { useUserState } from '../../../../hook/customerHook';

const YourPlanTab = ({ paymentSessionId }) => {
	const [userState, dispatchUser] = useUserState();
	const [token, setToken] = useState(0);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (paymentSessionId) {
			console.log({ paymentSessionId });
			updateUserPayment(paymentSessionId)
				.then((res) => {
					if (res.data.success) {
						setToken(res.data.updated_balance / 100 / 25);
						setIsLoading(false);
					}
				})
				.catch((err) => {
					console.error(err.message);
				});
		} else {
			getUserBalance(userState.user.user_id)
				.then((res) => {
					if (res.data.success) {
						setToken(res.data.updated_balance / 100 / 25);
						setIsLoading(false);
					}
				})
				.catch((err) => {
					console.error(err.message);
				});
		}
	}, []);
	return (
		<Grid container>
			<Grid item xs={6}>
				{isLoading ? (
					<>
						<CircularProgress />
					</>
				) : (
					<>
						<Paper style={{ padding: '20px' }}>
							<Typography variant="h3">Best Value Subscription, Monthly</Typography>
							<Typography variant="subtitle1">
								Standard Monthly Subscription
							</Typography>
							<Typography variant="subtitle1">
								<b>Plan expires</b>
							</Typography>
							<Typography variant="subtitle1">December 19, 2024 at 3:40</Typography>
							<Typography variant="subtitle1">
								<b>Credits {token}</b>
							</Typography>
						</Paper>
					</>
				)}
			</Grid>
		</Grid>
	);
};

export default YourPlanTab;
