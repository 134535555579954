import React from 'react';

import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		margin: '0.5rem',
		backgroundColor: theme.palette.primary.main,
		color: '#fff',
		'& .MuiChip-deleteIcon': {
			color: '#e0e0e0'
		}
	}
}));

export default function ListingTag({ tag, handleDelete, username }) {
	const classes = useStyles();
	return <Chip label={`${username}:${tag}`} onDelete={handleDelete} className={classes.root} />;
}
