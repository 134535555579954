import React, { useState, useEffect } from 'react';
import {
	Select,
	FormControl,
	Menu,
	MenuItem,
	InputAdornment,
	IconButton,
	InputLabel,
	Typography,
	Popover
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { makeStyles } from '@material-ui/core/styles';
import { useWindowDimensions } from '../../../../../hook/customerHook';
import { AccessTime } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(1),
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between'
	},
	display: { display: 'none' },
	icons: {
		display: 'flex',
		padding: 0,
		margin: 0,
		alignItems: 'center'
	},
	labels: {
		display: 'flex',
		alignItems: 'center'
	},
	fields: {
		marginTop: 0,
		display: 'flex',
		alignItems: 'center'
	},
	popup: {
		padding: theme.spacing(1)
	},
	select: {
		minWidth: 150
	},
	datePicker: {
		marginRight: '10px',
		maxWidth: 150
	},
	form: {
		padding: theme.spacing(1)
	}
}));

function DateFields({ startDate, endDate, setStartDate, setEndDate }) {
	const classes = useStyles();
	const [selectValue, setSelectValue] = useState(
		localStorage.getItem('timeframeFilters') ? localStorage.getItem('timeframeFilters') : ''
	);

	const timeFilter = [
		{ label: 'All', value: '' },
		{ label: 'Last 7 days', value: 7 },
		{ label: 'Last 30 days', value: 30 },
		{ label: 'Last 60 days', value: 60 },
		{ label: 'Last 90 days', value: 90 },
		{ label: 'Specify range', value: 'range' }
	];

	const handleDropdownChange = (event) => {
		let time = event.target.value;
		if (time > 0) {
			setSelectValue(time);
			setStartDate(moment().subtract(time, 'days').toISOString());
			setEndDate(moment().toISOString());
		} else if (time === '') {
			setSelectValue('');
			setStartDate('');
			setEndDate(moment().toISOString());
		} else if (time === 'range') {
			setSelectValue(time);
		}
		localStorage.setItem('timeframeFilters', time);
	};

	const handleStartDateChange = (event) => {
		setStartDate(event.toISOString());
		localStorage.setItem('startTime', event.toISOString());
	};

	const handleEndDateChange = (event) => {
		setEndDate(event.toISOString());
		localStorage.setItem('endTime', event.toISOString());
	};

	return (
		<>
			<div className={classes.popup}>
				<FormControl component="fieldset" variant="outlined" className={classes.timeFrame}>
					<InputLabel>Timeframe</InputLabel>
					<Select
						onChange={handleDropdownChange}
						className={classes.select}
						value={
							localStorage.getItem('timeframeFilters')
								? localStorage.getItem('timeframeFilters')
								: selectValue
						}
						label="Timeframe"
					>
						{timeFilter.map((options, id) => (
							<MenuItem key={id} value={options.value}>
								{options.label}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
			{selectValue === 'range' && (
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<form className={classes.form}>
						<DatePicker
							className={classes.datePicker}
							id="date-picker-dialog"
							label="From"
							value={startDate}
							onChange={handleStartDateChange}
							autoOk
							InputProps={{
								endAdornment: (
									<InputAdornment
										position="end"
										style={{
											maxHeight: 'none',
											height: 'auto',
											marginTop: '-1px',
											marginRight: '-10px',
											marginBottom: '-1px'
										}}
									>
										<IconButton size="small">
											<ArrowDropDownIcon />
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
						<DatePicker
							id="date-picker-dialog"
							className={classes.datePicker}
							label="To"
							value={endDate}
							minDate={startDate ? startDate : endDate}
							onChange={handleEndDateChange}
							autoOk
							InputProps={{
								endAdornment: (
									<InputAdornment
										position="end"
										style={{
											maxHeight: 'none',
											height: 'auto',
											marginTop: '-1px',
											marginRight: '-10px',
											marginBottom: '-1px'
										}}
									>
										<IconButton size="small">
											<ArrowDropDownIcon />
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
					</form>
				</MuiPickersUtilsProvider>
			)}
		</>
	);
}

export default function DateFilter({ startDate, endDate, setStartDate, setEndDate }) {
	const [anchorEl, setAnchorEl] = useState(null);
	const dimensions = useWindowDimensions();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const id = open ? 'simple-popover' : undefined;

	useEffect(() => {
		if (
			localStorage.getItem('timeframeFilters') &&
			localStorage.getItem('timeframeFilters') == 'range'
		) {
			const start = localStorage.getItem('startTime');
			const end = localStorage.getItem('endTime');
			if (end) setEndDate(end);
			if (start) setStartDate(start);
		}
	}, []);

	return (
		<>
			{dimensions.width < 960 ? (
				<>
					<IconButton
						aria-label="add"
						color={startDate ? 'secondary' : ''}
						onClick={handleClick}
						aria-describedby={id}
					>
						<AccessTime />
					</IconButton>
					<Popover
						id={id}
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center'
						}}
					>
						<DateFields
							startDate={startDate}
							endDate={endDate}
							setStartDate={setStartDate}
							setEndDate={setEndDate}
						/>
					</Popover>
				</>
			) : (
				<DateFields
					startDate={startDate}
					endDate={endDate}
					setStartDate={setStartDate}
					setEndDate={setEndDate}
				/>
			)}
		</>
	);
}
