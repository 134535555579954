import React, { useEffect, useState, useRef } from 'react';
//Dynamically load components on request for faster page rendering
import loadable from '@loadable/component';

import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';

import { imageResolver } from '../../../helper/image-resolver';
import CusPaginationActions from './CusPaginationActions';
import { cusTheme } from './cusTheme';
import {
	currencyParser,
	formatAsPercentage,
	correctKnockabilityValue
} from '../../../helper/parser';
import ReactToPrint from 'react-to-print';
import { IconButton, Button, Tooltip, CircularProgress, Snackbar } from '@material-ui/core';
import { Print } from '@material-ui/icons';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Chip from '@material-ui/core/Chip';

import MapResults from '../MapResults';
import { withStyles } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import ListingTag from '../ListingTag';
import {
	getHomeCommentsByOrganization,
	getHomeTagsByOrganization
} from '../../../api/serverRequests';
import moment from 'moment';

const Avatar = loadable(() => import('@material-ui/core/Avatar'));
const Checkbox = loadable(() => import('@material-ui/core/Checkbox'));
const Table = loadable(() => import('@material-ui/core/Table'));
const TableBody = loadable(() => import('@material-ui/core/TableBody'));
const TableCell = loadable(() => import('@material-ui/core/TableCell'));
const TableContainer = loadable(() => import('@material-ui/core/TableContainer'));
const TablePagination = loadable(() => import('@material-ui/core/TablePagination'));
const TableRow = loadable(() => import('@material-ui/core/TableRow'));
const Paper = loadable(() => import('@material-ui/core/Paper'));
const CusTableHead = loadable(() => import('./CusTableHead'));
const CusTableToolbarOffMarket = loadable(() => import('./CusTableToolbarOffMarket'));
const VisibilityIcon = loadable(() => import('@material-ui/icons/Visibility'));

const styles = (theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	button: {
		flex: 1,
		color: blue[900],
		margin: 10,
		justifyContent: 'center',
		alignSelf: 'center',

		'&.MuiButton-root.active': {
			color: '#FF0000',
			borderColor: '#FF0000'
		}
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
});
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const useStyles = makeStyles((theme) => ({
	collectionInUser: {
		backgroundColor: cusTheme.palette.primary.light
	},
	collectionInOrganization: {
		backgroundColor: cusTheme.palette.secondary.light
	},
	pic: {
		backgroundColor: theme.palette.text.secondary
	},
	tableContainer: {
		height: '80vh',
		overflowY: 'auto'
	},
	printIcon: {
		'&:hover, &.Mui-focusVisible': { backgroundColor: 'white' }
	},
	printIconContainer: {
		display: 'flex',
		width: '100%',
		justifyContent: 'flex-end'
	},
	mapButton: {
		margin: theme.spacing(1)
	},
	pagination: {
		'&.MuiTableCell-root': {
			paddingTop: theme.spacing(2),
			border: 'none'
		},
		'& .MuiToolbar-root': {
			flexWrap: 'wrap',
			justifyContent: 'flex-end'
		},
		'& .MuiTablePagination-selectRoot': {
			marginRight: 0
		},
		'& .MuiTablePagination-caption': {
			marginLeft: theme.spacing(1),
			[theme.breakpoints.up('sm')]: {
				marginLeft: theme.spacing(4)
			}
		}
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},
	summaryButton: {
		height: '10px',
		width: '10px',
		fontSize: '10px',
		color: '#004F2B'
	},
	printButtons: {
		marginTop: '30px',
		marginLeft: '98px',
		display: 'flex',
		alignItems: 'space-between'
	},
	dialogContent: {
		width: '250px',
		height: '130px',
		display: 'flex',
		flexDirection: 'column'
	},
	popupLabel: {
		marginTop: '20px'
	},
	checkBox: {
		backgroundColor: 'blue',
		cursor: 'pointer',
		accentColor: '#004F2B'
	},
	printListings: {
		fontSize: '15px',
		marginLeft: '25px',
		fontWeight: 'bold'
	},
	printScaleText: {
		fontSize: '10px',
		marginLeft: '25px',
		marginTop: '-14px',
		color: 'grey'
	},
	comingSoon: {
		fontSize: '10px',
		fontStyle: 'italic',
		color: 'grey'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	hoverElement: {
		position: 'relative',
		'&:hover': {
			'& $hoverBox': {
				display: 'block'
			}
		}
	},
	hoverBox: {
		zIndex: 100,
		display: 'none',
		background: 'white',
		border: '1px solid gray',
		position: 'absolute',
		padding: '0 1rem',
		whiteSpace: 'nowrap'
	},
	hoverBox_tags: {
		color: '#3f51b5'
	},
	commentBox: {
		margin: '10px',
		'& h6': {
			whiteSpace: 'nowrap'
		},
		'& span': {
			fontSize: '1rem'
		}
	},
	spinner: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		margin: '2rem'
	}
}));

const DialogTitle = withStyles(styles)(({ children, classes, onClose, ...other }) => {
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

export default function Lists({
	listings,
	title,
	listHeads,
	listCells,
	handleSelect,
	handleSave,
	handleRoutes,
	handleDelete,
	isSearch,
	initialOrderBy,
	initialSortOrder,
	isDetailsTable
}) {
	const classes = useStyles();
	const [order, setOrder] = useState(initialSortOrder || 'desc');
	const [orderBy, setOrderBy] = useState(initialOrderBy);
	const [selected, setSelected] = useState([]);
	const [rows, setRows] = useState(listings);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [on, setOn] = useState(false);
	const [showPrint, setShowPrint] = useState(false);
	const [mapResultsOpen, setMapResultsOpen] = useState(false);
	const [newListings, setNewListings] = useState([]);
	const [oldRows, setOldRows] = useState([0]);
	const [printType, setPrintType] = useState();

	function descendingComparator(a, b, orderBy) {
		if (orderBy === 'Percent') {
			b[orderBy] = parseFloat(b[orderBy]);
			a[orderBy] = parseFloat(a[orderBy]);
		}
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}

	function filterListings() {
		let temp = [];

		for (var i = 0; i < listings.length; i++) {
			if (selected.includes(String(listings[i].MLNum) + '__' + String(listings[i].HomeId))) {
				temp.push(listings[i]);
			}
		}

		setNewListings(temp);
	}
	async function handleBeforePrint() {
		filterListings();
		await setOldRows(rowsPerPage);
		await setRowsPerPage(-1);
		await setShowPrint(true);
		await delay(5);
	}
	function onAfterPrint() {
		setShowPrint(false);
		setRowsPerPage(oldRows);
	}
	function getComparator(order, orderBy) {
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}
	const handlePopupClose = () => {
		setPopup(false);
	};
	const popupClick = () => {
		setPopup(true);
	};
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = listings.map((row) => row['MLNum'] + '__' + row['HomeId']);
			setSelected(newSelecteds);
			return;
		}
		if (!on) {
			setSelected([]);
		}
	};
	let componentRef = useRef();

	const handleClick = (event, name) => {
		const selectedIndex = selected.findIndex((obj) => obj.listing === name.listing);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		const currPage = page;
		const currRowPerPage = rowsPerPage;
		const startingItem = currPage * currRowPerPage;

		const nextRowPerPage = parseInt(event.target.value, 10);
		const nextPage = Math.floor(startingItem / nextRowPerPage);
		setRowsPerPage(nextRowPerPage);
		setPage(nextPage);
	};

	const isSelected = (name) => selected.findIndex((obj) => obj.listing === name.listing) !== -1;

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

	const onClickDelete = () => {
		if (handleDelete) handleDelete(selected);
		setSelected([]);
		// alert('Delete all selected listings');
	};

	const onClickSave = () => {
		if (handleSave) handleSave(selected);
		setSelected([]);
		// alert('Save all selected listings');
	};

	const onClickRoutes = () => {
		if (handleRoutes) handleRoutes(selected);
	};

	const onClickPrint = () => {
		console.log('Print');
	};
	const onClickSelect = () => {
		const oldListings = listings;
		if (handleSelect && on === false) {
			handleSelect(selected, on);
		} else {
			handleSelect(oldListings, on);
			// listings = oldListings;
			setSelected(selected);
		}
		//('Select all selected listings');
		setOn(!on);
	};

	const [errMsg, setErrMsg] = useState('');
	const [homeTagsAndComms, setHomeTagsAndComms] = useState({});

	// // submit status updated after user has clicked save in edit modal
	const [submitSuccessStatus, setSubmitSuccessStatus] = useState(''); // keeps track of status when data is posted

	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message

	const onHoverAPI = async (e, homeId) => {
		e.preventDefault();
		if (homeTagsAndComms.hasOwnProperty(homeId)) {
			return;
		}
		try {
			const [commentsResp, tagsResp] = await Promise.all([
				getHomeCommentsByOrganization(homeId, 5),
				getHomeTagsByOrganization(homeId)
			]);
			setHomeTagsAndComms((prevState) => ({
				...prevState,
				[homeId]: {
					comments: commentsResp.data.comments,
					tags: tagsResp.data.tags.slice(0, 2),
					loading: false
				}
			}));
		} catch (error) {
			setSubmitSuccessStatus('error');
			if (error.response.status === 401) {
				setNotificationState(true);
				setNotificationMessage(
					'Your connection is time out. Please log in again to continue'
				);
				return;
			}
			setErrMsg('Error while loading tags!');
			console.log(error.message);
		}
	};
	useEffect(() => {
		setRows(listings);
	}, [listings]);

	const displayHumanReadableDate = (createdAt) => {
		console.log('test');
		const commentDate = moment.utc(createdAt).local();
		return moment(commentDate).fromNow();
	};
	const [popup, setPopup] = useState(false);

	const OffMarketTable = ({ listings, newListings }) => {
		return (
			<>
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					open={notificationState}
					autoHideDuration={6000}
					onClose={() => setNotificationState(false)}
				>
					<Alert
						onClose={() => setNotificationState(false)}
						severity={submitSuccessStatus === 'success' ? 'success' : 'error'}
						variant="filled"
					>
						{notificationMessage}
					</Alert>
				</Snackbar>
				<Table>
					<CusTableHead
						isSearch={isSearch}
						numSelected={selected.length}
						order={order}
						orderBy={orderBy}
						headCells={listHeads}
						onSelectAllClick={handleSelectAllClick}
						onRequestSort={handleRequestSort}
						rowCount={listings.length}
					/>
					<TableBody>
						{(rowsPerPage > 0
							? stableSort(newListings, getComparator(order, orderBy)).slice(
									page * rowsPerPage,
									page * rowsPerPage + rowsPerPage
							  )
							: stableSort(newListings, getComparator(order, orderBy))
						).map((row) => {
							const isItemSelected = isSelected({
								listing: row['MLNum'],
								TagId: row['TagId'],
								HomeId: row['HomeId']
							});
							// const labelId = `enhanced-table-checkbox-${id}`;
							return (
								<TableRow
									className={
										row['savedTo'] === 'user'
											? classes.collectionInUser
											: row['savedTo'] === 'organization'
											? classes.collectionInOrganization
											: ''
									}
									hover={!(row['savedTo'] === 'user')}
									onClick={(event) => {
										if (row['savedTo'] !== 'user')
											handleClick(event, {
												listing: row['MLNum'],
												TagId: row['TagId'],
												HomeId: row['HomeId']
											});
									}}
									role="checkbox"
									aria-checked={isItemSelected}
									tabIndex={-1}
									key={row['MLNum'] + '__' + row['HomeId']}
									selected={isItemSelected}
								>
									{!isDetailsTable ? (
										<TableCell>
											<Checkbox
												inputProps={{
													'aria-labelledby':
														row['MLNum'] + '__' + row['HomeId']
												}}
												checked={isItemSelected}
											/>
										</TableCell>
									) : (
										<TableCell>
											{/* // this is addes as a placeholder to preserve format*/}
										</TableCell>
									)}
									<TableCell>
										<Avatar
											className={classes.pic}
											variant="square"
											alt=""
											src={imageResolver(row.Pics)}
										/>
									</TableCell>
									{listCells &&
										listCells.map((cell) => {
											if (cell === 'MLNum' || cell.id === 'Address') {
												return (
													<TableCell key={cell}>
														<a
															href={`/user/listing/${
																row[cell] || row[cell.value]
															}`}
															rel="noopener noreferrer"
														>
															{row[cell] || row[cell.id]}
														</a>
													</TableCell>
												);
											} else if (cell === 'Knockability') {
												let homeId = row['HomeId'];
												let loading =
													homeTagsAndComms[homeId] === undefined
														? true
														: homeTagsAndComms[homeId].loading;
												return (
													<>
														<TableCell
															key={cell}
															className={classes.hoverElement}
															onMouseEnter={(e) =>
																onHoverAPI(e, homeId, row)
															}
															onTouchStart={(e) =>
																onHoverAPI(e, homeId, row)
															}
														>
															<div
																style={{
																	display: 'flex',
																	justifyContent: 'space-between'
																}}
															>
																<div>
																	{correctKnockabilityValue(
																		row[cell]
																	) != 0 && row[cell] != null
																		? `${formatAsPercentage(
																				row[cell]
																		  )}`
																		: '-'}
																</div>
																<div>
																	<VisibilityIcon />
																</div>
															</div>
															<div className={classes.hoverBox}>
																{loading ? (
																	<>
																		<div
																			className={
																				classes.spinner
																			}
																		>
																			<CircularProgress />
																		</div>
																	</>
																) : (
																	<>
																		<div>
																			<div
																				style={{
																					display: 'flex'
																				}}
																			>
																				{homeTagsAndComms[
																					homeId
																				].tags.length !==
																				0 ? (
																					homeTagsAndComms[
																						homeId
																					].tags.map(
																						(tag) => (
																							<ListingTag
																								tag={
																									tag.Tag
																								}
																								username={
																									tag.UserName
																								}
																							/>
																						)
																					)
																				) : (
																					<p>
																						There is no
																						tag for this
																						property
																					</p>
																				)}
																			</div>
																		</div>
																		<div>
																			<div>
																				{homeTagsAndComms[
																					homeId
																				].comments
																					.length !==
																				0 ? (
																					homeTagsAndComms[
																						homeId
																					].comments.map(
																						(
																							comment
																						) => (
																							<div
																								key={`comment_${comment.Id}`}
																								className={
																									classes.commentBox
																								}
																							>
																								<Typography
																									variant="h6"
																									component="h6"
																								>
																									{`${comment.UserName}: `}
																									<span
																										style={{
																											display:
																												'inline'
																										}}
																									>
																										{displayHumanReadableDate(
																											comment.CreatedAt
																										)}
																									</span>
																								</Typography>
																								<p
																									style={{
																										margin: '0 1rem'
																									}}
																								>
																									{
																										comment.Comment
																									}
																								</p>
																							</div>
																						)
																					)
																				) : (
																					<p>
																						There is no
																						comment for
																						this
																						property
																					</p>
																				)}
																			</div>
																		</div>
																	</>
																)}
															</div>
														</TableCell>
													</>
												);
											} else if (cell === 'Distance (km)') {
												return <TableCell>{row[cell]}</TableCell>;
											} else if (
												cell === 'Price' ||
												cell === 'LastSoldPrice' ||
												cell === 'PrevPrice' ||
												cell === 'assessment'
											) {
												return (
													<TableCell>
														{currencyParser(row[cell])}
													</TableCell>
												);
											} else if (
												cell === 'ListDate' ||
												cell === 'LastSoldDate'
											) {
												return (
													<TableCell>
														{new Date(row[cell])
															.toLocaleDateString('en-CA')
															.toString()}
													</TableCell>
												);
											} else if (cell === 'Address') {
												return (
													<TableCell>
														<a
															href={`/user/listing/${row['MLNum']}`}
															rel="noopener noreferrer"
														>
															{row[cell]}
														</a>
													</TableCell>
												);
											} else {
												return (
													<TableCell key={cell} value={row[cell]}>
														{row[cell]}
													</TableCell>
												);
											}
										})}
								</TableRow>
							);
						})}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={6} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</>
		);
	};
	class ComponentToPrint extends React.Component {
		render() {
			return <OffMarketTable listings={listings} newListings={newListings} />;
		}
	}

	return (
		<React.Fragment>
			<div className={classes.printIconContainer}></div>
			{!isDetailsTable && (
				<CusTableToolbarOffMarket
					isSearch={isSearch}
					title={title}
					styles={classes}
					selectedListings={selected}
					numSelected={selected.length}
					handleSelect={onClickSelect}
					handleSave={onClickSave}
					handleRoutes={onClickRoutes}
					handleDelete={onClickDelete}
					handlePrint={onClickPrint}
					popupClick={popupClick}
				/>
			)}

			{isSearch && (
				<React.Fragment>
					<Grid className={classes.mapButton}>
						<Button color="secondary" onClick={() => setMapResultsOpen(true)}>
							View listings on Map
						</Button>
					</Grid>

					<Dialog
						maxWidth={'md'}
						fullWidth
						open={mapResultsOpen}
						onClose={() => setMapResultsOpen(false)}
						aria-labelledby="search"
					>
						<DialogTitle id="map-search" onClose={() => setMapResultsOpen(false)}>
							{'Search Results'}
						</DialogTitle>
						<DialogContent dividers>
							<MapResults listings={listings} />
						</DialogContent>
					</Dialog>
				</React.Fragment>
			)}

			<TableContainer className={classes.tableContainer} component={Paper}>
				<OffMarketTable listings={listings} newListings={listings} />
			</TableContainer>
			<Table>
				<TableBody>
					<TableRow>
						<TablePagination
							className={classes.pagination}
							rowsPerPageOptions={[10, 20, 30, { label: 'All', value: -1 }]}
							colSpan={9}
							count={rows.length}
							rowsPerPage={rowsPerPage}
							page={page}
							SelectProps={{
								inputProps: { 'aria-label': 'rows per page' },
								native: true
							}}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							ActionsComponent={() =>
								CusPaginationActions({
									count: rows.length,
									page,
									rowsPerPage,
									onChangePage: handleChangePage
								})
							}
						/>
					</TableRow>
				</TableBody>
			</Table>
			{popup && (
				<Dialog open={true} onClose={handlePopupClose} className={classes.dialogBox}>
					<p className={classes.printListings}> Print Listings</p>
					<p className={classes.printScaleText}>
						Set print scale to 70 to fit everything
					</p>

					<DialogContent className={classes.dialogContent}>
						<label>
							<input
								className={classes.checkBox}
								type="radio"
								checked={printType === 'summary'}
								onChange={() => setPrintType('summary')}
							/>
							<span>Print Summary</span>
						</label>
						<label className={classes.popupLabel}>
							<input
								className={classes.checkBox}
								type="radio"
								disabled="disabled"
								checked={printType === 'details'}
								onChange={() => setPrintType('details')}
							/>
							<span>Print Details </span>
							<span className={classes.comingSoon}>Coming Soon</span>
						</label>
						<div className={classes.printButtons}>
							<Button className={classes.summaryButton} onClick={handlePopupClose}>
								Cancel
							</Button>
							<ReactToPrint
								onBeforeGetContent={handleBeforePrint}
								onAfterPrint={onAfterPrint}
								trigger={() => (
									<Button className={classes.summaryButton}>Print</Button>
								)}
								content={() => componentRef}
							/>
						</div>
					</DialogContent>
				</Dialog>
			)}
			{showPrint && <ComponentToPrint ref={(el) => (componentRef = el)} />}
		</React.Fragment>
	);
}
