import React from 'react';

export default function Home() {
	return (
		<div>
			<header>
				<h1>Home</h1>
			</header>
		</div>
	);
}
