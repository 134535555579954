import React, { useEffect, useState } from 'react';
//Dynamically load components on request for faster page rendering
import loadable from '@loadable/component';
import Alert from '@material-ui/lab/Alert';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { BaseLayout, AreaOptionsAPI } from '../../';
import { useUserState } from '../../../hook/customerHook';
import {
	saveSelection,
	searchPriceDropListing,
	saveFilterSelection
} from '../../../api/serverRequests';
const Snackbar = loadable(() => import('@material-ui/core/Snackbar'));

const ListCards = loadable(() => import('../..'), {
	resolveComponent: (components) => components.ListCards
});
const SearchPriceDrop = loadable(() => import('../..'), {
	resolveComponent: (components) => components.SearchPriceDrop
});

const Title = loadable(() => import('../..'), {
	resolveComponent: (components) => components.Title
});

const Grid = loadable(() => import('@material-ui/core/Grid'));
const Paper = loadable(() => import('@material-ui/core/Paper'));
const CircularProgress = loadable(() => import('@material-ui/core/CircularProgress'));
const Backdrop = loadable(() => import('@material-ui/core/Backdrop'));

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column'
	},
	fixedHeight: {
		height: 120
	},
	fixedSearchField: {
		height: '100%'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	}
}));

export default function SearchPriceDropPage() {
	const classes = useStyles();
	const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
	const fixedSearchPaper = clsx(classes.paper, classes.fixedSearchField);
	const [listings, setListings] = useState();
	const [showSearchResult, setShowSearchResult] = useState(false);
	const [userState, dispatchUser] = useUserState();
	const [refreshSearch, setRefreshSearch] = useState(false);
	const [areaOptionsQuery, setAreaOptionsQuery] = useState([]);

	// // submit status updated after user has clicked save in edit modal
	const [submitSuccessStatus, setSubmitSuccessStatus] = useState(''); // keeps track of status when data is posted

	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message

	/* 	const titles = [
		{ label: 'Image', numeric: false, id: 'Pics' },
		{ label: 'ML #', numeric: false, id: 'MLNum' },
		{ label: 'Area', numeric: false, id: 'Area' },
		{ label: 'Address', numeric: false, id: 'Address' },
		{ label: 'List Date', numeric: true, id: 'ListDate' },
		{ label: 'Price', numeric: true, id: 'Price' },
		{ label: 'Previous Price', numeric: true, id: 'PrevPrice' },
		{ label: 'Percent', numeric: true, id: 'Percent' }
	];

	 const cells = ['MLNum', 'Area', 'Address', 'ListDate', 'Price', 'PrevPrice', 'Percent']; */

	const titles = [
		{ label: 'Area', numeric: false, id: 'Area' },
		{ label: 'Address', numeric: false, id: 'Address' },
		{ label: 'List Date', numeric: true, id: 'ListDate' },
		{ label: 'Price', numeric: true, id: 'Price' },
		{ label: 'Previous Price', numeric: true, id: 'PrevPrice' },
		{ label: 'Percent', numeric: true, id: 'Percent' }
	];

	const mainCells = ['Price', 'PrevPrice', 'Percent', 'TotBR', 'Address', 'MLNum', 'List Date'];

	const secondaryCells = ['TotBR', 'TotFlArea'];

	const tertiaryCells = ['List Date'];

	const handleSearch = (result) => {
		// debugger
		setListings(result);
		setShowSearchResult(true);
	};

	const refreshListings = (filters) => {
		setRefreshSearch(true);
		let validfilters = {};

		for (const key of Object.keys(filters)) {
			if (filters[key]) {
				validfilters[key] = key === 'area' ? filters[key]['value'] : filters[key];
			}
		}
		searchPriceDropListing({ user: userState.user_id, filters: validfilters })
			.then((res) => {
				if (res.data.success) setListings(res.data.listings);
				// handleSearch(res.data.listings)
				setRefreshSearch(false);
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue'
					);
					dispatchUser({
						isLoggedIn: false
					});
					return;
				}
				console.log(err.message);
			});
	};
	// const handleSearchParameter =(params)=>{
	//    setSearchParameter(params)
	// }

	const handleSave = (listings) => {
		const data = {
			userId: userState.user.user_id,
			organizationId: userState.user.organization,
			listings: listings
		};
		console.log('in handle save: ' + JSON.stringify(listings));
		saveSelection(data)
			.then((res) => {
				if (res.data.success) {
					setSubmitSuccessStatus('success');
					setNotificationState(true);
					setNotificationMessage('Selected listings have been filtered!');
				}
				refreshListings(userState.priceListingFilters);
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue'
					);
					dispatchUser({
						isLoggedIn: false
					});
				}
				console.log(err.message);
			});
	};

	const handleSaveFilter = (listings) => {
		const data = {
			userId: userState.user.user_id,
			organizationId: userState.user.organization,
			listings: listings
		};
		saveFilterSelection(data)
			.then((res) => {
				if (res.data.success) {
					setSubmitSuccessStatus('success');
					setNotificationState(true);
					setNotificationMessage('Selected listings have been filtered!');
				}
				refreshListings(userState.priceListingFilters);
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue'
					);
					dispatchUser({
						isLoggedIn: false
					});
				}
				console.log(err.message);
			});
	};

	useEffect(() => {
		if (userState.priceListingFilters) refreshListings(userState.priceListingFilters);
		if (localStorage.getItem('priceAreaOptions') == null) {
			AreaOptionsAPI(setAreaOptionsQuery, 'priceDropped', 'priceAreaOptions');
		} else {
			setAreaOptionsQuery(JSON.parse(localStorage.getItem('priceAreaOptions')));
		}
	}, []);

	return (
		<BaseLayout>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={notificationState}
				autoHideDuration={6000}
				onClose={() => setNotificationState(false)}
			>
				<Alert
					onClose={() => setNotificationState(false)}
					severity={submitSuccessStatus === 'success' ? 'success' : 'error'}
					variant="filled"
				>
					{notificationMessage}
				</Alert>
			</Snackbar>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper className={fixedSearchPaper}>
						<Title> Search Price Dropped Properties </Title>
						<Paper className={fixedSearchPaper}>
							<SearchPriceDrop
								areaOptionsQuery={areaOptionsQuery}
								handleSearch={handleSearch}
							/>
						</Paper>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					{refreshSearch && (
						<Backdrop className={classes.backdrop} open={true}>
							<CircularProgress color="inherit" />
						</Backdrop>
					)}
					<Paper className={classes.paper}>
						{listings && listings.length > 0 ? (
							/* 							<Lists
								isSearch={true}
								isCollection={!showSearchResult}
								listHeads={titles}
								listCells={cells}
								listings={listings}
								handleSave={handleSave}
								title={
									showSearchResult ? 'Search Result' : 'Price Dropped Properties'
								}
								initialOrderBy={'Percent'}
							/> */
							<ListCards
								isSearch={true}
								isCollection={!showSearchResult}
								listHeads={titles}
								listCells={mainCells}
								listType={'priceDrop'}
								listings={listings}
								handleSave={handleSave}
								handleSaveFilter={handleSaveFilter}
								initialOrderBy={'Percent'}
								title={
									showSearchResult ? 'Search Result' : 'My Recent Added Listings'
								}
							/>
						) : (
							<Title>
								{showSearchResult
									? 'No properties found.'
									: 'Please use the search menu to start.'}
							</Title>
						)}
					</Paper>
				</Grid>
			</Grid>
		</BaseLayout>
	);
}
