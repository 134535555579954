import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';
import { CardContent, Grid, Button } from '@material-ui/core';
import { useUserState } from '../../../hook/customerHook';
import CusSelectField from '../CusSelectField';
import Search from '../Search';
import MapSearch from '../MapSearch';
import UserFilters from './UserFilters';
import RouteFilter from './RouteFilter';

const useStyles = makeStyles((theme) => ({
	submit: {
		margin: theme.spacing(3, 0, 2),
		backgroundColor: '#004F2B',
		boxShadow: 'none'
	},
	formControl: {
		marginTop: theme.spacing(1),
		width: '100%'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	}
}));

export default function MainForm({
	handleSearch,
	handleRefreshSearch,
	handleClose,
	areaOptionsQuery,
	subAreaOptionsQuery,
	refreshListings,
	setRestoredFilters,
	restoredFilters,
	setCurrentFilters,
	currentFilters,
	listings,
	editAssignedTaskVisible,
	assignMyself
}) {
	const [userState, dispatchUser] = useUserState();
	const [hasArea, setHasArea] = useState(false);
	const [subAreaOptions, setSubAreaOptions] = useState([]);
	const classes = useStyles();
	const { handleSubmit, control, watch, setValue } = useForm();

	const [circleFilter, setCircleFilter] = useState(
		sessionStorage.getItem('circleFilter')
			? JSON.parse(sessionStorage.getItem('circleFilter'))
			: null
	);
	const [rectFilter, setRectFilter] = useState(
		sessionStorage.getItem('rectFilter')
			? JSON.parse(sessionStorage.getItem('rectFilter'))
			: null
	);

	const [mapOpen, setMapOpen] = useState(false);
	const [drawingControlEnabled, setDrawingControlEnabled] = useState(true);

	const area = watch('area');
	const subarea = watch('subarea');

	const fields = [
		{
			name: 'area',
			options: areaOptionsQuery,
			label: 'Area',
			multiple: true
		},
		{
			name: 'subarea',
			options: subAreaOptions,
			label: 'Subarea',
			multiple: true
		}
	];

	const handleSearchSubmit = (formData) => {
		let filtersPersisted = {};

		for (const key of Object.keys(formData)) {
			if (formData[key]) {
				filtersPersisted[key] = formData[key];
			}
		}

		dispatchUser({ filters: filtersPersisted });
		refreshListings(filtersPersisted);
	};

	const handleMapOpen = () => {
		setMapOpen(true);
	};
	const handleMapClose = () => {
		setMapOpen(false);
	};

	const handleMapSearchSubmit = () => {
		handleMapClose();
		if (circleFilter) {
			dispatchUser({ circleFilter });
			refreshListings({ circleFilter });
		}
		if (rectFilter) {
			dispatchUser({ rectFilter });
			refreshListings({ rectFilter });
		}
	};

	const clearMapFilter = () => {
		setRectFilter(null);
		setCircleFilter(null);
		dispatchUser({ circleFilter: {} });
		dispatchUser({ rectFilter: {} });
		if (restoredFilters && 'rectFilter' in restoredFilters) {
			delete restoredFilters['rectFilter'];
		}
		if (restoredFilters && 'circleFilter' in restoredFilters) {
			delete restoredFilters['circleFilter'];
		}
		sessionStorage.removeItem('circleFilter');
		sessionStorage.removeItem('rectFilter');
		setDrawingControlEnabled(true);
	};

	//Dynamically adjust subarea options depending on area values selected
	useEffect(() => {
		let filters = currentFilters;
		if (area !== undefined && area.length <= 0) {
			dispatchUser({ filters: null });
			sessionStorage.removeItem('filters');
		}
		if (area) {
			const array = area.map((value) => {
				return value.label;
			});
			setHasArea(array.length > 0);
			const filterSA = subAreaOptionsQuery.filter((item) => {
				return array.includes(item.label);
			});
			setSubAreaOptions(filterSA);

			if (subarea) {
				const subArray = subarea.map((value) => {
					return value;
				});
				const validate = subArray.filter((item) => {
					return array.includes(item.label);
				});

				setValue('subarea', validate);
			}
		}
		filters['area'] = area;
		setCurrentFilters(filters);
	}, [area]);

	useEffect(() => {
		if (subarea) {
			let newFilters = currentFilters;
			newFilters['subarea'] = subarea;
			setCurrentFilters(newFilters);
		}
	}, [subarea]);

	useEffect(() => {
		if (restoredFilters) {
			if ('rectFilter' in restoredFilters) {
				setRectFilter(restoredFilters.rectFilter);
				dispatchUser({ rectFilter });
				setValue('area', []);
				setValue('subarea', []);
			} else if ('circleFilter' in restoredFilters) {
				setCircleFilter(restoredFilters.circleFilter);
				dispatchUser({ circleFilter });
				setValue('area', []);
				setValue('subarea', []);
			} else {
				const filteredArea = restoredFilters['area']
					? areaOptionsQuery.filter((item) => {
							return restoredFilters['area'].includes(item.label);
					  })
					: [];

				const filteredSA = restoredFilters['subarea']
					? subAreaOptionsQuery.filter((item) => {
							return restoredFilters['subarea'].includes(item.value['short']);
					  })
					: [];

				setValue('area', filteredArea);
				setValue('subarea', filteredSA);
				clearMapFilter();
			}
		}
	}, [restoredFilters]);

	return (
		<React.Fragment>
			<CardContent>
				<form className={classes.form} onSubmit={handleSubmit(handleSearchSubmit)}>
					<Grid container justifyContent="center" alignItems="center" spacing={2}>
						{fields.map((field) => {
							return (
								<CusSelectField
									defaultValue={
										userState.filters && userState.filters[field.name]
											? userState.filters[field.name]
											: field.multiple
											? []
											: ''
									}
									key={field.name}
									itemGrid={6}
									name={field.name}
									label={field.label}
									options={field.options}
									control={control}
									requiredValue={field.required}
									fieldStyle={classes.formControl}
									multiple={field.multiple}
									disabled={
										(circleFilter !== null &&
											Object.keys(circleFilter).length > 0) ||
										(rectFilter !== null && Object.keys(rectFilter).length > 0)
									}
									title={
										rectFilter || circleFilter
											? 'Clear the map filter before searching by area'
											: ''
									}
								/>
							);
						})}
					</Grid>

					<Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
						<RouteFilter
							refreshListings={refreshListings}
							setRestoredFilters={setRestoredFilters}
						/>
						<MapSearch
							open={mapOpen}
							handleOpen={handleMapOpen}
							handleClose={handleMapClose}
							clearMapFilter={clearMapFilter}
							setCircleFilter={(filter) => {
								setCircleFilter(filter);
							}}
							setRectFilter={(filter) => {
								setRectFilter(filter);
							}}
							setRestoredFilters={setRestoredFilters}
							restoredFilters={restoredFilters}
							rectFilter={rectFilter}
							circleFilter={circleFilter}
							drawingControlEnabled={drawingControlEnabled}
							setDrawingControlEnabled={setDrawingControlEnabled}
							handleMapSearchSubmit={handleMapSearchSubmit}
							listings={listings}
						/>
						<Search
							refreshListings={refreshListings}
							setRestoredFilters={setRestoredFilters}
							restoredFilters={restoredFilters}
						/>
						<UserFilters
							refreshListings={refreshListings}
							setRestoredFilters={setRestoredFilters}
							restoredFilters={restoredFilters}
							editAssignedTaskVisible={editAssignedTaskVisible}
							assignMyself={assignMyself}
							setHasArea={setHasArea}
						/>
					</Grid>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						disabled={!hasArea && !(rectFilter || circleFilter)}
						className={classes.submit}
					>
						Search
					</Button>
				</form>
			</CardContent>
		</React.Fragment>
	);
}
