import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import {
	IconButton,
	Button,
	Typography,
	Dialog,
	DialogContent,
	TextField,
	List,
	ListItem,
	Grid,
	ListItemIcon,
	CircularProgress,
	ListItemText,
	ListItemSecondaryAction,
	Checkbox,
	lighten
} from '@material-ui/core';
import moment from 'moment';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
//Dynamically load components on request for faster page rendering
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import { green } from '@material-ui/core/colors';
import { displayHumanReadableClock, displayHumanReadableDate } from '../../../../helper/parser';
import {
	deleteSearchHistoryByIndex,
	deleteMyBookmarksByIndex,
	saveToMyBookmarks,
	updateMyBookmarks
} from '../../../../api/serverRequests';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.paper
	},
	listOfItems: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
		maxHeight: '50vh',
		overflowY: 'auto'
	},
	title: {
		margin: 0,
		padding: theme.spacing(2),
		minWidth: 400,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	closeButton: {
		color: theme.palette.grey[500]
	},
	item: {
		backgroundColor: theme.palette.grey[300],
		margin: '10px 5px 10px 0',
		'&.Mui-selected': {
			backgroundColor: lighten(theme.palette.primary.light, 0.7)
		},
		'&:hover': {
			backgroundColor: lighten(theme.palette.primary.light, 0.7),
			'&.Mui-selected': {
				backgroundColor: lighten(theme.palette.primary.light, 0.7)
			}
		}
	},
	checkedItem: {
		backgroundColor: lighten(theme.palette.primary.light, 0.7)
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative'
	},
	buttonProgress: {
		color: green[500],
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	},
	searchListText: { display: 'flex', alignItems: 'center', wordWrap: 'break-word' },
	aliasText: { padding: '50px' },
	spinner: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }
}));

const DialogTitle = (props) => {
	const {
		children,
		onClose,
		handleHistoryDelete,
		handleBookmarkDelete,
		saveChecked,
		bookmarkChecked,
		selectOption,
		handleEditCancel,
		editMode,
		...other
	} = props;
	const classes = useStyles();
	return (
		<MuiDialogTitle disableTypography className={classes.title} {...other}>
			{editMode ? (
				<Button onClick={(e) => handleEditCancel(e)} aria-label="cancel">
					Cancel
				</Button>
			) : selectOption === 'bookmark' ? (
				<Button
					disabled={bookmarkChecked && bookmarkChecked.length === 0}
					onClick={(e) => handleBookmarkDelete(e)}
					aria-label="delete"
				>
					Delete
				</Button>
			) : (
				<></>
			)}
			<Typography variant="h6">
				{children === 'bookmark' ? 'My Saved Searches' : 'My Search History'}
			</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
};

function SearchHistoryList({
	mySearchHistory,
	handleSearchHistory,
	saveBookmarks,
	handleSave,
	bookmarks,
	selectOption,
	setOpen,
	checked,
	setChecked,
	disabled,
	setEdit,
	editMode,
	isLoading
}) {
	const classes = useStyles();
	const [alias, setAlias] = useState(null);
	const [selected, setSelected] = useState(-1);

	const handleToggle = (event, name, index) => {
		const selectedIndex = mySearchHistory.indexOf(name);
		setSelected(index);
	};

	const handleCheckItem = (event, name) => {
		const selectedIndex = checked.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(checked, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(checked.slice(1));
		} else if (selectedIndex === checked.length - 1) {
			newSelected = newSelected.concat(checked.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				checked.slice(0, selectedIndex),
				checked.slice(selectedIndex + 1)
			);
		}
		setChecked(newSelected);
	};

	const isSelected = (item) => mySearchHistory.indexOf(item) !== -1;
	const isPicked = (item) => checked.indexOf(item) !== -1;

	const handleClick = (e, value) => {
		handleSearchHistory(value);
		setOpen(false);
	};

	const handleEdit = (e, name, index) => {
		setEdit(true);
		console.log(index);
		setSelected(index);
	};

	const handleAliasChange = (e) => {
		let alias = e.target.value;
		setAlias(alias);
	};

	const handleDisplay = (value, alias) => {
		let numFilters = Object.keys(value).length - 2;
		let minorFilters = Object.keys(value).length - 1;

		let delimitedFilters = numFilters > 2 ? ` and ${numFilters} other filters.` : '';

		let advFilters =
			minorFilters > 0
				? `A search with ${minorFilters} filter${minorFilters > 1 ? 's' : ''}.`
				: '';

		let date = moment(value['searchHistory']).format('YYYY-MM-DD');

		let string =
			'mapFilter' in value
				? 'Map filter search'
				: value
				? value['area']
					? value['area'].join(', ') + delimitedFilters
					: advFilters
				: advFilters;

		return alias && alias !== '' ? alias : string;
	};

	if (selectOption === 'history' && mySearchHistory && mySearchHistory.length > 0) {
		if (editMode) {
			return (
				<div className={classes.root}>
					<Grid container className={classes.aliasText}>
						<Grid item xs={12}>
							<Typography variant="h5">Name this search query</Typography>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="aliasText"
								type="text"
								fullWidth
								variant="outlined"
								placeholder="50 characters allowed."
								inputProps={{
									maxLength: 50
								}}
								onChange={(e) => handleAliasChange(e)}
							/>
						</Grid>
					</Grid>
					<div className={classes.wrapper}>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							disabled={!isLoading ? !alias : !isLoading}
							className={classes.submit}
							onClick={(e) => handleSave(e, alias, selected)}
						>
							Confirm save
						</Button>
						{isLoading && (
							<CircularProgress size={24} className={classes.buttonProgress} />
						)}
					</div>
				</div>
			);
		} else {
			return (
				<>
					<List className={classes.listOfItems}>
						{mySearchHistory.map((entry, index) => {
							const labelId = `checkbox-list-label-${index}`;
							const value = JSON.parse(entry.Result);

							return (
								<>
									<ListItem
										key={value}
										role={undefined}
										dense
										button
										selected={selected === index}
										onClick={(event) => {
											handleToggle(event, entry, index);
										}}
										className={classes.item}
									>
										<ListItemText
											id={labelId}
											primary={
												<Grid container className={classes.searchListText}>
													<Grid item xs={7}>
														<Typography
															variant="subtitle1"
															component="h5"
														>
															{handleDisplay(value, null)}
														</Typography>
													</Grid>
													<Grid item xs={3}>
														<Typography
															variant="subtitle2"
															component="h6"
														>
															{value['searchHistory'] &&
																displayHumanReadableDate(
																	value['searchHistory']
																)}
														</Typography>
													</Grid>
												</Grid>
											}
										></ListItemText>
										<ListItemSecondaryAction>
											<IconButton
												edge="end"
												aria-label="view"
												onClick={(e) => handleClick(e, value)}
											>
												<VisibilityIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								</>
							);
						})}
					</List>
					<div className={classes.wrapper}>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							disabled={!isLoading ? selected === -1 : !isLoading}
							className={classes.submit}
							onClick={(e) => handleEdit(e, alias, selected)}
						>
							Save selected result
						</Button>
						{isLoading && (
							<CircularProgress size={24} className={classes.buttonProgress} />
						)}
					</div>
				</>
			);
		}
	} else if (selectOption === 'bookmark' && bookmarks && bookmarks.length > 0) {
		if (editMode) {
			return (
				<div className={classes.root}>
					<Grid container className={classes.aliasText}>
						<Grid item xs={12}>
							<Typography variant="h5">Rename this search query</Typography>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="aliasText"
								type="text"
								fullWidth
								variant="outlined"
								placeholder="50 characters allowed."
								inputProps={{
									maxLength: 50
								}}
								onChange={(e) => handleAliasChange(e)}
							/>
						</Grid>
					</Grid>
					<div className={classes.wrapper}>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							disabled={!isLoading ? !alias : !isLoading}
							className={classes.submit}
							onClick={(e) => handleSave(e, alias, selected)}
						>
							Confirm save
						</Button>
						{isLoading && (
							<CircularProgress size={24} className={classes.buttonProgress} />
						)}
					</div>
				</div>
			);
		} else {
			return (
				<List className={classes.listOfItems}>
					{bookmarks.map((entry, index) => {
						const labelId = `checkbox-list-label-${index}`;
						const value = JSON.parse(entry.Result);
						const alias = 'Alias' in entry ? entry.Alias : '';
						const selected = isPicked(entry);

						return (
							<>
								<ListItem
									key={value}
									role={undefined}
									dense
									button
									onClick={(event) => {
										handleCheckItem(event, entry);
									}}
									className={clsx(classes.item, selected && classes.checkedItem)}
								>
									<ListItemIcon>
										<Checkbox
											edge="start"
											checked={selected}
											tabIndex={-1}
											disableRipple
											inputProps={{ 'aria-labelledby': labelId }}
										/>
									</ListItemIcon>
									<ListItemText
										id={labelId}
										primary={
											<Grid container className={classes.searchListText}>
												<Grid item xs={7}>
													<Typography variant="subtitle1" component="h5">
														{handleDisplay(value, alias)}
													</Typography>
												</Grid>
												<Grid item xs={3}>
													<Typography variant="subtitle2" component="h6">
														{value['searchHistory'] &&
															displayHumanReadableDate(
																value['searchHistory']
															)}
													</Typography>
												</Grid>
											</Grid>
										}
									></ListItemText>
									<ListItemSecondaryAction>
										<IconButton
											edge="end"
											aria-label="view"
											onClick={(e) => handleClick(e, value)}
										>
											<VisibilityIcon />
										</IconButton>
										<IconButton
											edge="end"
											aria-label="view"
											onClick={(e) => handleEdit(e, value, index)}
										>
											<EditIcon />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							</>
						);
					})}
				</List>
			);
		}
	}
	return (
		<Typography className={classes.spinner} variant="h4">
			You have 0 results to show.
		</Typography>
	);
}

export default function SearchHistoryDialog({
	mySearchHistory,
	handleSearchHistory,
	saveSearchHistory,
	saveBookmarks,
	bookmarks,
	selectOption,
	notificationState,
	notificationMessage,
	setSubmitSuccessStatus,
	open,
	setOpen
}) {
	const classes = useStyles();
	const [saveChecked, setSavedChecked] = useState([]);
	const [bookmarkChecked, setBookmarkChecked] = useState([]);
	const [loadingButton, setLoadingButton] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [editMode, openEditMode] = useState(false);

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

	const handleClose = () => {
		setOpen(false);
		openEditMode(false);
		setSavedChecked([]);
		setBookmarkChecked([]);
	};

	const handleEditCancel = () => {
		openEditMode(false);
	};

	const handleHistoryDelete = (e) => {
		e.preventDefault();
		setIsLoading(true);
		var newArr = mySearchHistory.filter(
			(item) => !saveChecked.some((val) => val.Id === item.Id)
		);
		var indexToDelete = saveChecked.map((item) => item.Id);
		deleteSearchHistoryByIndex({ index: indexToDelete })
			.then((res) => {
				if (res.data.success) {
					setSubmitSuccessStatus('success');
					notificationState(true);
					notificationMessage('Successfully removed selected items.');
					saveSearchHistory(newArr);
					setSavedChecked([]);
					setIsLoading(false);
				}
			})
			.catch((err) => {
				console.log(err.message);
				setIsLoading(false);
			});
	};

	const handleBookmarkDelete = (e) => {
		e.preventDefault();
		setIsLoading(true);
		var newArr = bookmarks.filter((item) => !bookmarkChecked.some((val) => val.Id === item.Id));
		console.log(newArr);
		var indexToDelete = bookmarkChecked.map((item) => item.Id);
		deleteMyBookmarksByIndex({ index: indexToDelete })
			.then((res) => {
				if (res.data.success) {
					setSubmitSuccessStatus('success');
					notificationState(true);
					notificationMessage('Successfully removed selected items.');
					saveBookmarks(newArr);
					setBookmarkChecked([]);
					setIsLoading(false);
				}
			})
			.catch((err) => {
				console.log(err.message);
				setIsLoading(false);
			});
	};

	const handleBookmarkSave = (e, alias, index) => {
		console.log(index);
		e.preventDefault();
		setLoadingButton(true);
		let query = mySearchHistory[index];
		query['Alias'] = alias;
		saveToMyBookmarks({ history: query })
			.then((res) => {
				if (res.data.success) {
					const data = res.data.history;
					setSubmitSuccessStatus('success');
					notificationState(true);
					notificationMessage("We've saved your selected filter.");
					var arr = [...data, ...bookmarks];
					saveBookmarks(arr);
					setIsLoading(false);
					setLoadingButton(false);
					setSavedChecked([]);
					handleClose();
				}
			})
			.catch((err) => {
				console.log(err.message);
				if (err.response.status === 404) {
					notificationState(true);
					notificationMessage('Your input contained a forbidden word. Please try again.');
				}
				setIsLoading(false);
				setLoadingButton(false);
			});
	};

	const handleBookmarkUpdate = (e, alias, index) => {
		e.preventDefault();
		setLoadingButton(true);
		let query = bookmarks[index];
		query['Alias'] = alias;
		updateMyBookmarks({ history: query })
			.then((res) => {
				if (res.data.success) {
					setSubmitSuccessStatus('success');
					notificationState(true);
					notificationMessage("We've updated your selected filter.");
					bookmarks[index] = query;
					setIsLoading(false);
					setLoadingButton(false);
					setBookmarkChecked([]);
					setOpen(false);
					openEditMode(false);
				}
			})
			.catch((err) => {
				console.log(err);
				if (err.response.status === 404) {
					notificationState(true);
					notificationMessage('Your input contained a forbidden word. Please try again.');
				}
				setIsLoading(false);
				setLoadingButton(false);
			});
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="search"
			scroll="paper"
			fullScreen={fullScreen}
		>
			<DialogTitle
				onClose={handleClose}
				selectOption={selectOption}
				handleHistoryDelete={handleHistoryDelete}
				handleBookmarkDelete={handleBookmarkDelete}
				saveChecked={saveChecked}
				bookmarkChecked={bookmarkChecked}
				handleEditCancel={handleEditCancel}
				editMode={editMode}
			>
				{selectOption}
			</DialogTitle>
			<DialogContent dividers className={classes.content}>
				{isLoading ? (
					<div item xs={12} className={classes.spinner}>
						<CircularProgress />
					</div>
				) : selectOption === 'history' ? (
					<SearchHistoryList
						checked={saveChecked}
						setChecked={setSavedChecked}
						handleSearchHistory={handleSearchHistory}
						mySearchHistory={mySearchHistory}
						selectOption={selectOption}
						setOpen={setOpen}
						setEdit={openEditMode}
						editMode={editMode}
						handleSave={handleBookmarkSave}
						saveBookmarks={saveBookmarks}
						bookmarks={bookmarks}
						isLoading={loadingButton}
					/>
				) : (
					<SearchHistoryList
						checked={bookmarkChecked}
						setChecked={setBookmarkChecked}
						handleSearchHistory={handleSearchHistory}
						mySearchHistory={mySearchHistory}
						selectOption={selectOption}
						setOpen={setOpen}
						setEdit={openEditMode}
						editMode={editMode}
						handleSave={handleBookmarkUpdate}
						saveBookmarks={saveBookmarks}
						bookmarks={bookmarks}
					/>
				)}
			</DialogContent>
		</Dialog>
	);
}
