import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';

import { ErrorMessage } from '@hookform/error-message';
import { useForm, Controller } from 'react-hook-form';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';

import { updatePassword } from '../../../api/serverRequests';
import { Title } from '../..';

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100vh'
	},
	paper: {
		padding: theme.spacing(15, 10),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		height: '100%',
		backgroundColor: theme.palette.grey[300]
	},
	formControl: {
		marginTop: theme.spacing(1),
		width: '100%'
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	}
}));

export default function UserInfo({ userInfo, modifyUser, changePassword }) {
	const methods = useForm();
	const { handleSubmit, control, errors, getValues, reset } = methods;
	const [errMessage, setErrMessage] = useState('');
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [limited, setLimited] = useState(false);
	const classes = useStyles();

	// // submit status updated after user has clicked save in edit modal
	const [submitSuccessStatus, setSubmitSuccessStatus] = useState(''); // keeps track of status when data is posted

	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message

	const onSubmit = async (formData) => {
		updatePassword(formData)
			.then((res) => {
				if (res.data.success) {
					setSubmitSuccessStatus('success');
					setNotificationState(true);
					setNotificationMessage('Password successfully changed!');
				} else {
					setErrMessage(res.data.message);
					if (res.data.limit_exceeded) {
						setLimited(true);
					}
				}
			})
			.catch((err) => {
				setErrMessage(
					"You've reached the attempt limit, or there was a server error. Please try again later."
				);
				console.log(err);
			});
	};

	const handleClickShowConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	const handleClickShowNewPassword = () => {
		setShowNewPassword(!showNewPassword);
	};

	//comparing equality of two values
	const sameAsNew = (field, getValues) => (value) => {
		const compareTo = getValues(field);
		return compareTo === value;
	};

	const sameAsOld = (field, getValues) => (value) => {
		const compareTo = getValues(field);
		return compareTo !== value;
	};

	return (
		<div>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={notificationState}
				autoHideDuration={6000}
				onClose={() => setNotificationState(false)}
			>
				<Alert
					onClose={() => setNotificationState(false)}
					severity={submitSuccessStatus === 'success' ? 'success' : 'error'}
					variant="filled"
				>
					{notificationMessage}
				</Alert>
			</Snackbar>
			<form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={2}>
						<Title>Name</Title>
					</Grid>
					<Grid item xs={12} sm={10}>
						<Typography component="h1" variant="h5">
							{userInfo['user']['name']}
						</Typography>
						<Divider />
					</Grid>
					<Divider />
					<Grid item xs={12} sm={2}>
						<Title>Organization</Title>
					</Grid>
					<Grid item xs={12} sm={10}>
						<Typography component="h1" variant="h5">
							{userInfo['user']['organization']}
						</Typography>
						<Divider />
					</Grid>
					<Grid item xs={12} sm={2}>
						<Title>Email</Title>
					</Grid>
					<Grid item xs={12} sm={10}>
						<Typography component="h1" variant="h5">
							{userInfo['user']['email']}
						</Typography>
						<Divider />
					</Grid>
					{changePassword && (
						<>
							<Grid item xs={12}>
								<Controller
									name="oldPassword"
									defaultValue=""
									control={control}
									render={(props) => (
										<FormControl
											required
											variant="outlined"
											className={classes.formControl}
										>
											<InputLabel htmlFor="password">
												Current Password
											</InputLabel>
											<OutlinedInput
												name="oldPassword"
												id="OldPassword"
												label="oldPassword"
												type="password"
												value={props.value}
												onChange={props.onChange}
												inputRef={props.ref}
											/>
										</FormControl>
									)}
									rules={{
										required: 'Password is required',
										minLength: {
											value: 8,
											message: 'Minimum length of password is 8 characters!'
										},
										pattern: {
											value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.,)(:";'\+_=]).{8,}$/,
											message:
												"A password must contain at least eight characters, including at least one number, both lower and uppercase letters, and special characters of '#?!@$%^&*-'"
										}
									}}
								/>
								<ErrorMessage
									errors={errors}
									name="oldPassword"
									render={({ message }) => (
										<p className="text-danger mx-2">{message}</p>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="newPassword"
									defaultValue=""
									control={control}
									render={(props) => (
										<FormControl
											required
											variant="outlined"
											className={classes.formControl}
										>
											<InputLabel htmlFor="password">New Password</InputLabel>
											<OutlinedInput
												name="newPassword"
												id="NewPassword"
												label="NewPassword"
												type={showNewPassword ? 'text' : 'password'}
												value={props.value}
												onChange={props.onChange}
												inputRef={props.ref}
												/* 												endAdornment={
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={handleClickShowNewPassword}
															edge="end"
														>
															{showNewPassword ? (
																<Visibility />
															) : (
																<VisibilityOff />
															)}
														</IconButton>
													</InputAdornment>
												} */
											/>
										</FormControl>
									)}
									rules={{
										required: 'Password is required',
										minLength: {
											value: 8,
											message: 'Minimum length of password is 8 characters!'
										},
										pattern: {
											value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.,]).{8,}$/,
											message:
												"Your new password must contain at least eight characters, including at least one number, both lower and uppercase letters, and special characters of '#?!@$%^&*-'"
										}
									}}
								/>
								<ErrorMessage
									errors={errors}
									name="newPassword"
									render={({ message }) => (
										<p className="text-danger mx-2">{message}</p>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="confirmPassword"
									defaultValue=""
									control={control}
									render={(props) => (
										<FormControl
											required
											variant="outlined"
											className={classes.formControl}
										>
											<InputLabel htmlFor="confirmPassword">
												Confirm Password
											</InputLabel>
											<OutlinedInput
												name="confirmPassword"
												id="confirmPassword"
												label="Confirm Password"
												type={showConfirmPassword ? 'text' : 'password'}
												value={props.value}
												onChange={props.onChange}
												inputRef={props.ref}
												/* 												endAdornment={
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={handleClickShowConfirmPassword}
															edge="end"
														>
															{showConfirmPassword ? (
																<Visibility />
															) : (
																<VisibilityOff />
															)}
														</IconButton>
													</InputAdornment>
												} */
											/>
										</FormControl>
									)}
									rules={{
										required: 'Confirm password is required',
										minLength: {
											value: 8,
											message: 'Minimum length of password is 8 characters!'
										},
										validate: {
											sameNew: sameAsNew('newPassword', getValues),
											sameOld: sameAsOld('oldPassword', getValues)
										}
									}}
								/>
								<ErrorMessage
									errors={errors}
									name="confirmPassword"
									render={({ message }) => {
										return errors.confirmPassword?.type === 'sameNew' ? (
											<p className="text-danger mx-2">
												Your new password doesn't match.
											</p>
										) : errors.confirmPassword?.type === 'sameOld' ? (
											<p className="text-danger mx-2">
												Your new password is the same as the current one.
											</p>
										) : (
											<p className="text-danger mx-2">{message}</p>
										);
									}}
								/>
								{errMessage && <p className="text-danger my-0">{errMessage}</p>}
							</Grid>
							<Button
								disabled={limited ? !limited : limited}
								type="submit"
								fullWidth
								variant="contained"
								color="secondary"
								className={classes.submit}
							>
								Submit Password Change
							</Button>
						</>
					)}
				</Grid>
			</form>
		</div>
	);
}
