// dev credentials
const firebaseConfigDev = {
	apiKey: 'AIzaSyAezyqE1rICIUgvkp9HlC2vJLFYj-EyzoQ',
	authDomain: 'dev-realsaige.firebaseapp.com',
	projectId: 'dev-realsaige',
	storageBucket: 'dev-realsaige.appspot.com',
	messagingSenderId: '199719617363',
	appId: '1:199719617363:web:1a337dcd8d76a6fbfd57a6',
	measurementId: 'G-PLWP6XJ2ZP'
};

const devVapidKey =
	'BAz1Gyd_3rXXLxdq99oKQEJ2B-_PJIaLEs7lpCxQd3qx_2lsLf-PTezMeOpU7XO6FUGyYbiuP9MeVthLVCJLWro';

// prod credentials
const firebaseConfigProd = {
	apiKey: 'AIzaSyB04c4Ovi8zti7yc0o7SBeBBDI8uvHwiRI',
	authDomain: 'prod-realsaige.firebaseapp.com',
	projectId: 'prod-realsaige',
	storageBucket: 'prod-realsaige.appspot.com',
	messagingSenderId: '731484000077',
	appId: '1:731484000077:web:dd07ccd5a5b3403b943104',
	measurementId: 'G-EYG7KEY0RE'
};

const prodVapidKey =
	'BFiZQeUfslRqYwIMVzGlMlUkl_CENvaHtWSmGubEQobMJ15bvQ472uHP2-xysJWsauzCjxx9U39HWFU6uUrHmus';

export const setFirebaseEnvironmentVariables = () => {
	const url = window.location.href;

	if (url.includes('app.realsaige')) {
		return { firebaseConfig: firebaseConfigProd, vapidKey: prodVapidKey };
	} else {
		console.log('FIREBASE === DEV MODE ~ this message should only appear in development!');
		return { firebaseConfig: firebaseConfigDev, vapidKey: devVapidKey };
	}
};

// compose the swURL (service worker url) to send the credentials so it can initialize firebase
const { firebaseConfig } = setFirebaseEnvironmentVariables(); // get the config vars
const param = new URLSearchParams(firebaseConfig).toString(); // create url param
const swUrl = `/firebase-messaging-sw.js?${param}`; // compose url

// register firebase service worker and send the appropriate config vars
if ('serviceWorker' in navigator) {
	window.addEventListener('load', () => {
		navigator.serviceWorker
			.register(swUrl)
			.then((registration) => {
				console.log('Firebase Service Worker Registered:', registration);
			})
			.catch((error) => {
				console.error('Firebase service Worker registration failed:', error);
			});
	});
}
