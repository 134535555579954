import React from 'react';

import { Controller } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

function CusSelectFieldManual({
	itemGrid,
	name,
	label,
	options,
	control,
	fieldStyle,
	multiple,
	placeholder,
	defaultValue,
	type
}) {
	return (
		<Grid item xs={12} sm={itemGrid}>
			<Controller
				name={name}
				control={control}
				defaultValue={defaultValue}
				color="primary"
				render={({ onChange, value }) => (
					<TextField
						defaultValue={defaultValue}
						className={fieldStyle}
						type={type}
						placeholder={placeholder}
						onChange={onChange}
						value={value}
						label={label}
						variant="standard"
					/>
				)}
			/>
		</Grid>
	);
}

export default React.memo(CusSelectFieldManual);
