import { ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';

export default function ListLink({ navItem, selected }) {
	return (
		<ListItem button component={Link} to={navItem.link} selected={selected}>
			<Tooltip title={navItem.label}>
				<ListItemIcon>
					{!!navItem.badge ? (
						<Badge badgeContent={navItem.badge}>
							<navItem.iconComponent />
						</Badge>
					) : (
						<navItem.iconComponent />
					)}
				</ListItemIcon>
			</Tooltip>
			<ListItemText primary={navItem.label} />
		</ListItem>
	);
}
