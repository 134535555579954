import { useContext } from 'react';

import { UserContext, DispatchUserContext } from '../context/Provider';
import { WindowDimensionsCtx } from '../context/Provider';

export const useUserState = () => [useContext(UserContext), useContext(DispatchUserContext)];

//For responsiveness
export const useWindowDimensions = () => {
	return useContext(WindowDimensionsCtx);
};
