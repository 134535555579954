import React, { createContext, useState, useEffect, useReducer } from 'react';
import { createTheme } from '@material-ui/core/styles';
import { saveUserLocation, sessionStorageParser } from '../helper/parser';

const defaultUserState = sessionStorageParser();
//defaultUserState.isLoggedIn = true; //skip login detection first
export const UserContext = createContext(defaultUserState);
export const DispatchUserContext = createContext(undefined);

export const UserStateProvider = ({ children }) => {
	const [userState, dispatchUser] = useReducer((userState, newValue) => {
		sessionStorageParser(newValue);

		return { ...userState, ...newValue };
	}, defaultUserState);

	return (
		<UserContext.Provider value={userState}>
			<DispatchUserContext.Provider value={dispatchUser}>
				{children}
			</DispatchUserContext.Provider>
		</UserContext.Provider>
	);
};

export const WindowDimensionsCtx = createContext(null);

const windowDims = () => ({
	height: window.innerHeight,
	width: window.innerWidth
});

export const WindowDimensionsProvider = ({ children }) => {
	const [dimensions, setDimensions] = useState(windowDims());
	useEffect(() => {
		const handleResize = () => {
			setDimensions(windowDims());
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	return (
		<WindowDimensionsCtx.Provider value={dimensions}>{children}</WindowDimensionsCtx.Provider>
	);
};

export const theme = createTheme({
	breakpoint: {
		/* These breakpoints are mui's default */
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1280,
			xl: 1920
		}
	},
	palette: {
		primary: {
			main: '#004F2B'
		},
		grey: {
			KN_grey: '#545454',
			KN_light_grey: '#B0B0B0',
			KN_back_ground: '#F8F8F8',
			KN_back_ground_soft: 'rgba(248, 248, 248, 0.45)'
		},
		red: {
			KN_accent: '#FF0000',
			KN_accent20: '#rgba(255, 223, 223, 0.2)'
		},
		green: {
			KN_mint: '#FF9900',
			KN_success20: 'rgba(227, 250, 224, 0.2)',
			KN_dark_mint: '#004F2B',
			KN_mint_10: 'rgba(72, 193, 198, 0.1)'
		},
		yellow: {
			KN_middle20: '#rgba(255, 242, 108, 0.2)'
		},
		black: {
			main: '#000'
		},
		white: {
			main: '#fff',
			secondary: '#FEFEFE'
		}
	},
	typography: {
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 700,
		h2: {
			color: '#545454',
			fontFamily: 'Helvetica, Roboto, Arial, sans-serif',
			fontWeight: 700,
			fontSize: 'clamp(1.4rem, 2.1vw + 0.7rem, 3.3rem)',
			lineHeight: '120%'
		},
		h3: {
			color: '#004F2B',
			fontFamily: 'Helvetica, Roboto, Arial, sans-serif',
			fontSize: '26px',
			fontWeight: 400
		},
		h4: {
			color: '#000',
			fontFamily: 'Montserrat, Roboto, Arial, sans-serif',
			fontWeight: 700,
			fontSize: 'clamp(1rem, 1vw + 0.3rem, 1.4rem)'
		},
		h5: {
			color: '#000',
			fontFamily: 'Roboto, Arial, sans-serif',
			fontWeight: 700,
			fontSize: 'clamp(1rem, 0.9vw + 0.4rem, 1.2rem)'
		},
		h6: {
			color: '#545454',
			fontFamily: 'Helvetica, Roboto, Arial, sans-serif',
			fontWeight: 400,
			fontSize: 'clamp(1rem, 0.9vw + 0.3rem, 1.2rem)'
		},
		subtitle1: {
			color: '#545454',
			fontSize: 'clamp(0.9rem, 0.8vw + 0.3rem, 1.1rem)'
		},
		subtitle2: {
			fontWeight: 400,
			color: '#B0B0B0'
		}
	}
});
