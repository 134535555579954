import React, { useState, useEffect } from 'react';

import { Grid, Typography, makeStyles, CircularProgress, Snackbar } from '@material-ui/core';

import { getAssignedTasksCount } from '../../../../api/serverRequests';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
	tasks: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column'
	},
	container: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column'
		}
	},
	spinner: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }
}));

export default function TotalAssignedTasks(props) {
	const classes = useStyles();
	const { curAgent, curOrg, startDate, endDate } = props;
	const [count, setCount] = useState(null);
	const [isLoading, setLoading] = useState(true);

	// // submit status updated after user has clicked save in edit modal
	const [submitSuccessStatus, setSubmitSuccessStatus] = useState(''); // keeps track of status when data is posted

	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message

	useEffect(() => {
		setLoading(true);
		getAssignedTasksCount(curAgent, curOrg, startDate, endDate)
			.then((res) => {
				if (res.data.success) {
					setCount(res.data.total[0]);
					setLoading(false);
				}
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				setNotificationState(true);
				setNotificationMessage('Error loading tasks');
				console.log(err.message);
				setLoading(false);
			});
	}, [curAgent, startDate, endDate]);

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={notificationState}
				autoHideDuration={6000}
				onClose={() => setNotificationState(false)}
			>
				<Alert
					onClose={() => setNotificationState(false)}
					severity={submitSuccessStatus === 'success' ? 'success' : 'error'}
					variant="filled"
				>
					{notificationMessage}
				</Alert>
			</Snackbar>
			<div className={classes.spinner}>
				{isLoading ? (
					<div className={classes.spinner}>
						<CircularProgress />
					</div>
				) : (
					<Grid container className={classes.container}>
						{/* TODO: Add number of tasks for the current user to display */}
						<Grid item xs={12} className={classes.tasks}>
							{count > 0 && (
								<Grid>
									<Typography variant="h2">{count}</Typography>
								</Grid>
							)}
							<Grid>
								<Typography variant="h4">
									{count > 0
										? count >= 1
											? 'assigned task'
											: 'assigned tasks'
										: 'You have 0 assigned tasks.'}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				)}
			</div>
		</>
	);
}
