import React, { useState, useRef, useEffect } from 'react';

import {
	Typography,
	TableRow,
	Chip,
	Grid,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	closeButton: {
		color: theme.palette.primary.main
	}
}));

export default function ViewMoreModal({
	renderComponents,
	itemsToRender,
	title,
	viewMore,
	setViewMore
}) {
	const classes = useStyles();

	// for scrollable dialog modal
	const [scroll, setScroll] = useState('paper');

	const handleClickOpen = (scrollType) => () => {
		setViewMore(true);
		setScroll(scrollType);
	};
	const handleClose = () => {
		setViewMore(false);
	};

	const descriptionElementRef = useRef(null);
	useEffect(() => {
		if (viewMore) {
			const { current: descriptionElement } = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
	}, [viewMore]);

	return (
		<>
			<Dialog
				fullWidth={true}
				maxWidth={'xs'}
				open={handleClickOpen}
				onClose={handleClose}
				scroll={scroll}
				aria-labelledby={`scroll-all-${title}`}
				aria-describedby={`scroll-all-${title}`}
			>
				<DialogTitle id={`scroll-all-${title}-title`}>
					<Typography variant="h5" component="h4">
						{`View All ${title}`}
					</Typography>
				</DialogTitle>
				<DialogContent dividers={scroll === 'paper'}>
					<DialogContentText
						id={`all-${title}`}
						ref={descriptionElementRef}
						tabIndex={-1}
					>
						<Typography variant="subtitle2" component="h6">
							{`${title}(${
								typeof itemsToRender === 'object'
									? Object.keys(itemsToRender).length
									: itemsToRender.length
							})`}
						</Typography>
						{renderComponents(itemsToRender)}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} className={classes.closeButton}>
						CLOSE
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
