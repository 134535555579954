import React from 'react';

import { Chip, Badge } from '@material-ui/core';

export default function Tag({ tag, count, handleClick, isSelected }) {
	return (
		<React.Fragment>
			<Badge badgeContent={count < 99 ? count : '99+'} color="secondary">
				<Chip
					label={tag}
					clickable
					onClick={handleClick}
					color={isSelected ? 'primary' : 'default'}
				/>
			</Badge>
		</React.Fragment>
	);
}
