import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import { putUserRoles, getOrganizationRoles } from '../../../../../api/serverRequests';

const useStyles = makeStyles((theme) => ({
	gridContainer: {
		display: 'flex',
		padding: '10% 10% 10% 10%',
		alignItems: 'center'
	},
	gridItem: {
		alignItems: 'center'
	},
	inputField: {
		width: '45%',
		padding: '9px, 0px, 16px, 16px',
		gap: '10px',
		color: 'gray',
		fontSize: '15px',
		outline: 'None'
	},
	header: {
		fontWeight: '600',
		fontSize: '18px',
		color: '#000000'
	},
	subheader: {
		fontWeight: '400',
		color: '#000000',
		fontSize: '20px',
		marginBottom: '20px'
	},
	checklist: {
		display: 'flex',
		flexDirection: 'column',
		spaceBetween: '14px'
	},
	selectAllContainer: {
		padding: '2% 6% 2% 6%',
		float: 'right',
		display: 'inline-block',
		boxShadow: '0px 1px 16px rgba(0, 0, 0, 0.1)',
		borderRadius: '8px'
	},
	box: {
		marginRight: '5px'
	},
	submit: {
		margin: theme.spacing(3, 2, 2),
		width: '13%'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	}
}));

export default function UserRolesForm({ setIsLoadingUserAccessTab, setFormOpen }) {
	const [isLoading, setIsLoading] = useState(true);
	const [errMsg, setErrMsg] = useState('');
	const classes = useStyles();
	const [nameInput, setNameInput] = useState('');
	const [emailInput, setEmailInput] = useState('');
	const [rolesChecklist, setRolesChecklist] = useState({});
	const [updateCheckboxes, setUpdateCheckboxes] = useState(false);
	const organizationRoles = localStorage.getItem('orgRolesList')
		? JSON.parse(localStorage.getItem('orgRolesList'))
		: {};

	const setCheckBoxes = (userRoles) => {
		const checklist = {};
		for (let i = 0; i < organizationRoles.length; i++) {
			const id = organizationRoles[i].Id;
			const box = {
				roleId: id,
				label: organizationRoles[i].Name,
				checked: false
			};

			if (id in userRoles) {
				box['checked'] = true;
			}
			checklist[id] = box;
		}
		setRolesChecklist(checklist);
	};

	const buildFormData = () => {
		const roleIds = [];
		const checkedRoles = Object.values(rolesChecklist).filter(
			(checkbox) => checkbox.checked === true
		);
		for (let role of checkedRoles) {
			roleIds.push(role.roleId);
		}

		const formData = {
			role_ids: roleIds
		};
		return formData;
	};

	const handleSubmit = () => {
		if (!emailInput) {
			setErrMsg('Email is required');
		} else {
			const formData = buildFormData();
			putUserRoles(emailInput, formData)
				.then((res) => {
					if (res.data.success) {
						localStorage.removeItem('orgUserList');
						localStorage.removeItem('userListExpiryTime');
						setIsLoadingUserAccessTab(true);
						setFormOpen(false);
					}
				})
				.catch((err) => {
					setErrMsg(err.message);
					console.log(err.message);
				});
		}
	};

	const handleCheck = (e) => {
		const roleId = e.target.value;
		const updatedRolesChecklist = rolesChecklist;
		updatedRolesChecklist[roleId]['checked'] = !updatedRolesChecklist[roleId]['checked'];
		setRolesChecklist(updatedRolesChecklist);
		setUpdateCheckboxes(!updateCheckboxes);
	};

	const handleNameInput = (e) => {
		setNameInput(e.target.value);
	};

	const handleEmailInput = (e) => {
		setEmailInput(e.target.value);
	};

	const handleSelectAll = (e) => {
		const updatedRolesChecklist = rolesChecklist;
		if (e.target.checked) {
			for (const key of Object.keys(updatedRolesChecklist)) {
				updatedRolesChecklist[key]['checked'] = true;
			}
		} else {
			for (const key of Object.keys(updatedRolesChecklist)) {
				updatedRolesChecklist[key]['checked'] = false;
			}
		}
		setRolesChecklist(updatedRolesChecklist);
		setUpdateCheckboxes(!updateCheckboxes);
	};

	useEffect(() => {
		if (isLoading) {
			const form = JSON.parse(localStorage.getItem('userRolesForm'));
			if (form) {
				setNameInput(form['name']);
				setEmailInput(form['email']);
				if (form['roles']) {
					setCheckBoxes(form['roles']);
					setIsLoading(false);
					return;
				}
			}
			setCheckBoxes({});
			setIsLoading(false);
		}
	}, [localStorage.getItem('userRolesForm')]);

	return isLoading ? (
		<CircularProgress size={50} color="inherit" />
	) : (
		<React.Fragment>
			<Grid
				container
				className={classes.gridContainer}
				justifyContent="center"
				alignItems="center"
				spacing={4}
			>
				<Grid item xs={12}>
					<TextField
						fullWidth
						id="outlined-basic"
						placeholder="Name"
						value={nameInput}
						onChange={handleNameInput}
						color="primary"
						size="small"
						variant="outlined"
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						fullWidth
						id="outlined-basic"
						placeholder="Email"
						value={emailInput}
						onChange={handleEmailInput}
						color="primary"
						size="small"
						variant="outlined"
					/>
				</Grid>
				<Grid item xs={6}>
					<div className={classes.header}>Roles</div>
				</Grid>
				<Grid item xs={6}>
					<div className={classes.selectAllContainer}>
						<input className={classes.box} type="checkbox" onChange={handleSelectAll} />
						<span className={'selectAll'}>{'Select All'}</span>
					</div>
				</Grid>
				<Grid item xs={12}>
					<Container>
						<Grid container spacing={2}>
							<Grid className={classes.gridItem} item xs={12}>
								<Grid container spacing={2}>
									{Object.values(rolesChecklist).map((item, index) => (
										<Grid item xs={12} key={item.roleId}>
											<input
												value={item.roleId}
												className={classes.box}
												type="checkbox"
												checked={item.checked}
												onChange={handleCheck}
											/>
											<span>{item.label}</span>
										</Grid>
									))}
								</Grid>
							</Grid>
						</Grid>
					</Container>
				</Grid>
				<Button
					type="button"
					variant="contained"
					color="primary"
					className={classes.submit}
					onClick={handleSubmit}
				>
					Save
				</Button>
			</Grid>
		</React.Fragment>
	);
}
