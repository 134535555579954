import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { IconButton, Grid, Menu, MenuItem, ListItemIcon, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import HistoryIcon from '@material-ui/icons/History';
import loadable from '@loadable/component';

const SearchHistoryDialog = loadable(() => import('../..'), {
	resolveComponent: (components) => components.SearchHistoryDialog
});

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}
}));

export default function SearchHistory({
	mySearchHistory,
	handleSearchHistory,
	saveSearchHistory,
	saveBookmarks,
	bookmarks,
	notificationState,
	notificationMessage,
	setSubmitSuccessStatus
}) {
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState(false);
	const [option, setOption] = useState('');

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleOpenBookmark = () => {
		setOption('bookmark');
		setOpen(true);
		setAnchorEl(null);
	};

	const handleOpenSearchHistory = () => {
		setOption('history');
		setOpen(true);
		setAnchorEl(null);
	};

	return (
		<React.Fragment>
			<IconButton
				aria-label="more"
				aria-controls="long-menu"
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreVertIcon />
			</IconButton>

			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem onClick={handleOpenBookmark}>
					<ListItemIcon>
						<BookmarkBorderOutlinedIcon fontSize="small" />
					</ListItemIcon>
					<Typography variant="inherit">My Saved Searches</Typography>
				</MenuItem>
				<MenuItem onClick={handleOpenSearchHistory}>
					<ListItemIcon>
						<HistoryIcon fontSize="small" />
					</ListItemIcon>
					<Typography variant="inherit">My Search History</Typography>
				</MenuItem>
			</Menu>
			<SearchHistoryDialog
				mySearchHistory={mySearchHistory}
				handleSearchHistory={handleSearchHistory}
				saveSearchHistory={saveSearchHistory}
				saveBookmarks={saveBookmarks}
				bookmarks={bookmarks}
				selectOption={option}
				open={open}
				setOpen={setOpen}
				notificationState={notificationState}
				notificationMessage={notificationMessage}
				setSubmitSuccessStatus={setSubmitSuccessStatus}
			/>
		</React.Fragment>
	);
}
