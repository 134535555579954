import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Checkbox, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { useUserState } from '../../../../../../../hook/customerHook';

const useStyles = makeStyles((theme) => ({
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
		'@media print': {
			display: 'none'
		}
	},
	spinner: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%'
	},
	formControl: {
		marginTop: theme.spacing(1),
		width: '100%'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	}
}));

export default function TagForm({ formSubmitObject, addDataToFormObject, tags, tagsOptions }) {
	// NOTE tags prop represents the tags that are already assigned to the property
	const [userState] = useUserState();
	const [assignedTags, setAssignedTags] = useState([]);
	const [tagsToRemove, setTagsToRemove] = useState([]);

	const [selectedTags, setSelectedTags] = useState([]);
	const [tagSelectionOptions, setTagSelectionOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const classes = useStyles();

	useEffect(() => {
		// to properly get and format tag options for the autocomplete field
		const mapTagToOptions = (tags) => {
			return tags.map((tag) => {
				return { label: tag.Tag, value: [tag.Id] };
			});
		};

		// check if we have the options for the tags
		if (tagsOptions.length === 0) {
			setIsLoading(true);
		} else {
			setIsLoading(false);
			setTagSelectionOptions(mapTagToOptions(tagsOptions)); // change format of tags to {'label': 'string', 'value':[1]}
		}
	}, [tagsOptions]);

	useEffect(() => {
		// we need to filter the tags for tags that the user has assigned
		// otherwise user's will be able to remove tags that others have assigned
		// also changed the tag format to {'label': 'string', 'value':[1]} bc it makes things easier
		const filterUserAssignedTags = (tags) => {
			return tags
				.filter((tag) => tag.UserName === userState.user.name) // filter tags by username, so that only tags the specific user has already assigned show as checked
				.map((tag) => {
					return { label: tag.Tag, value: [tag.TagId] };
				});
		};

		setAssignedTags(filterUserAssignedTags(tags));
	}, [tags, userState.user.name]);

	useEffect(() => {
		// setting the default tags to the autocomplete component
		// NOTE -> tags that are already assigned to a property by a user are not added to the formSubmitObject, UNTIL THE USER TOUCHES ANY TAG OPTION IN THE AUTOCOMPLETE
		// this is done intentionally to speed up operations and avoid redundant tag insertion if the user hasn't modified the TagForm.

		if (assignedTags.length > 0) {
			// we need to match the assignedTags with the tagSelectionOptions
			// this is to ensure referance equality for the mui autocomplete
			// https://mui.com/material-ui/api/autocomplete/ <- scroll down to value prop

			const defaultTags = assignedTags.map((assignedTag) => {
				const matchingOption = tagSelectionOptions.find((option) =>
					isOptionEqualToValue(option, assignedTag.value)
				);

				// this should never return null there should always be a matching option
				// unless someone removes one of the tag options from the database
				return matchingOption || null;
			});
			setSelectedTags(defaultTags);
		}
	}, [assignedTags, tagSelectionOptions]);

	useEffect(() => {
		// this useEffect keeps track of previously assigned tags being removed/unchecked

		const findTagsToRemoveFromProperty = (formSubmitObjectTags, assignedTags) => {
			if (formSubmitObjectTags && assignedTags) {
				const formTagIds = new Set(formSubmitObjectTags.map((tag) => tag.value[0]));
				const assignedTagIds = new Set(assignedTags.map((tag) => tag.value[0]));

				const differentTagIds = [...assignedTagIds].filter((id) => !formTagIds.has(id));

				const tagsToRemoveFromProperty = assignedTags.filter((tag) =>
					differentTagIds.includes(tag.value[0])
				);

				setTagsToRemove(tagsToRemoveFromProperty);
			}
		};

		findTagsToRemoveFromProperty(formSubmitObject.Tags, assignedTags);
	}, [assignedTags, formSubmitObject.Tags]);

	useEffect(() => {
		// this useEffect just updates the formObject

		// instead of using a separate API to remove tags (which we have), it's better to include the tag removal in the form object.
		// by doing this, if the request to save changes fails, the user will be aware that ALL the changes failed to save.
		// it would be a poor user experience if only the tag removal or other changes went through, while the rest failed.
		addDataToFormObject('TagsToRemove', tagsToRemove);
	}, [tagsToRemove]);

	const isOptionEqualToValue = (option, value) => {
		// compare option object reference with value reference
		return JSON.stringify(option.value) === JSON.stringify(value);
	};

	return isLoading ? (
		<div className={classes.spinner}>
			<CircularProgress />
		</div>
	) : (
		<React.Fragment>
			<form className={classes.form}>
				<Autocomplete
					multiple
					limitTags={2}
					id="edit-modal-select-tags"
					options={tagSelectionOptions}
					value={selectedTags}
					isOptionEqualToValue={isOptionEqualToValue}
					disableCloseOnSelect
					getOptionLabel={(option) => (option ? option.label : '')}
					renderOption={(option, { selected }) => (
						<React.Fragment>
							<Checkbox
								icon={<CheckBoxOutlineBlankIcon fontSize="medium" />}
								checkedIcon={<CheckBoxIcon color="primary" fontSize="medium" />}
								style={{ marginRight: 8 }}
								checked={selected}
							/>
							{option.label}
						</React.Fragment>
					)}
					renderInput={(params) => (
						<TextField {...params} label="Add Tags" placeholder="" variant="outlined" />
					)}
					onChange={(e, value) => {
						setSelectedTags(value);
						addDataToFormObject('Tags', value);
					}}
				/>
			</form>
		</React.Fragment>
	);
}
