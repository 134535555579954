import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

export default function ErrorMsg({ text }) {
	const useStyles = makeStyles((theme) => ({
		textErr: {
			color: 'red'
		}
	}));

	const classes = useStyles();

	return (
		<Typography variant="h6" component="h6" className={classes.textErr}>
			{text}
		</Typography>
	);
}
