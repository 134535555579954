import React, { useEffect, useState } from 'react';
import { Grid, Paper, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import { ListingTag, TagForm, ErrorMsg, Title } from '../..';
import { useUserState } from '../../../hook/customerHook';
import { getHomeTagsByOrganization, deleteTagFromHome } from '../../../api/serverRequests';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '1.5rem 2.5rem'
	},
	tagList: {
		order: 2,
		[theme.breakpoints.up('sm')]: {
			order: 1
		}
	},
	tagForm: {
		order: 1,
		[theme.breakpoints.up('sm')]: {
			order: 2
		}
	}
}));

export default function TagSection({ homeId, mlnum, isEditModal, addDataToFormObject }) {
	const [tags, setTags] = useState(null);
	const [errMsg, setErrMsg] = useState('');
	const [, dispatchUser] = useUserState();
	const classes = useStyles();

	// // submit status updated after user has clicked save in edit modal
	const [submitSuccessStatus, setSubmitSuccessStatus] = useState(''); // keeps track of status when data is posted

	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message

	const fetchData = async () => {
		getHomeTagsByOrganization(homeId)
			.then((res) => {
				if (res.status === 200) {
					setTags(res.data.tags);
					setErrMsg('');
				}
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue'
					);
					dispatchUser({
						isLoggedIn: false
					});
					return;
				}
				setErrMsg('Error while loading tags!');
				console.log(err.message);
			});
	};

	const handleRefresh = () => {
		fetchData();
	};

	const handleDelete = (homeId, tagId) => {
		deleteTagFromHome({ homeId: homeId, tagId: tagId })
			.then((res) => {
				if (res.data.success) {
					setSubmitSuccessStatus('success');
					setNotificationState(true);
					setNotificationMessage('Selected tag is deleted!');
				}
				fetchData();
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue'
					);
					dispatchUser({
						isLoggedIn: false
					});
					return;
				}
				setErrMsg(err.message);
				console.log(err.message);
			});
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={notificationState}
				autoHideDuration={6000}
				onClose={() => setNotificationState(false)}
			>
				<Alert
					onClose={() => setNotificationState(false)}
					severity={submitSuccessStatus === 'success' ? 'success' : 'error'}
					variant="filled"
				>
					{notificationMessage}
				</Alert>
			</Snackbar>
			<Grid container>
				<Grid item xs={12} sm={9} md={10} xl={11} className={classes.tagList}>
					{errMsg && <ErrorMsg text={errMsg} />}
					{tags &&
						tags.map((tag) => (
							<ListingTag
								key={`${tag.Tag}_${tag.UserName}`}
								tag={tag.Tag}
								username={tag.UserName}
								handleDelete={
									tag.Editable ? () => handleDelete(homeId, tag.TagId) : null
								}
							/>
						))}
				</Grid>
				<Grid item xs={12} className={classes.tagForm}>
					<TagForm
						homeId={homeId}
						mlnum={mlnum}
						handleRefresh={handleRefresh}
						isEditModal={isEditModal}
						addDataToFormObject={addDataToFormObject}
					/>
				</Grid>
			</Grid>
		</>
	);
}
