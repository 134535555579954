import {
	Avatar,
	Grid,
	Typography,
	Box,
	makeStyles,
	Divider,
	Button,
	Menu,
	MenuItem,
	ListItemIcon,
	ListItemText,
	MenuList,
	Paper
} from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { blue, grey } from '@material-ui/core/colors';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CheckIcon from '@material-ui/icons/Check';
import { markNotificationAsRead } from '../../../../../api/serverRequests';
import { useUserState } from '../../../../../hook/customerHook';

const useStyles = makeStyles((theme) => ({
	largeAvatar: {
		width: theme.spacing(6.5),
		height: theme.spacing(6.5),
		marginLeft: 1,
		color: theme.palette.black.main,
		backgroundColor: grey[400],
		fontSize: 26
	},
	leftMostContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	notificationTitle: {
		color: theme.palette.primary.main,
		fontSize: 15,
		fontWeight: 600
	},
	notificationMessage: {
		color: theme.palette.black.main,
		fontSize: 15
	},
	notificationTime: {
		color: theme.palette.black.main,
		fontSize: 10,
		opacity: 0.5
	},
	rightMostContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between'
	},
	viewMoreText: {
		cursor: 'pointer',
		color: theme.palette.primary.main,
		textDecoration: 'underline',
		fontWeight: 600,
		fontSize: 14
	}
}));

export default function Notification({ notifications, setNotifications, onlyUnread }) {
	const classes = useStyles();
	const history = useHistory();
	const [dispatchUser] = useUserState();
	const [anchorElArr, setAnchorElArr] = useState([]);

	// const open = Boolean(anchorEl);

	const handleMoreButtonClick = (event, index) => {
		const newAnchorElArr = [...anchorElArr];
		newAnchorElArr[index] = event.currentTarget;
		setAnchorElArr(newAnchorElArr);
	};

	const handleClose = (index) => {
		const newAnchorElArr = [...anchorElArr];
		newAnchorElArr[index] = null;
		setAnchorElArr(newAnchorElArr);
	};

	const updateNotificationToUnread = (notificationId) => {
		// we should not re fetch everytime the user marks a notification as read
		// it can be frusterating to constantly wait for the loading time
		const updatedData = notifications.map((item) =>
			item.notificationId === notificationId ? { ...item, readStatus: 1 } : item
		);
		return updatedData;
	};

	const handleMarkAsRead = (index, notificationId) => {
		setNotifications(updateNotificationToUnread(notificationId));

		markNotificationAsRead(notificationId)
			.then((res) => {
				if (res.status === 200) {
					// do nothing
				}
			})
			.catch((err) => {
				if (err.status === 401) {
					dispatchUser({
						isLoggedIn: false
					});
					return;
				} else {
					console.error(err.message);
				}
			});
		handleClose(index);
	};

	const renderNotifications = (notifications, onlyUnread) => {
		return notifications.map((notification, index) => {
			if (!onlyUnread || (onlyUnread && notification.readStatus === 0)) {
				return (
					<React.Fragment key={index}>
						<Grid container spacing={0} style={{ marginTop: 10 }}>
							<Grid item xs={2}>
								<Box className={classes.leftMostContainer}>
									<FiberManualRecordIcon
										style={{
											fontSize: 14,
											color: notification.readStatus ? grey[400] : '#004F2B'
										}}
									/>
									<Avatar className={classes.largeAvatar} color="primary">
										{notification.initial}
									</Avatar>
								</Box>
							</Grid>
							<Grid item xs={9} style={{ paddingLeft: 4 }}>
								<Typography
									component="p"
									variant="p"
									className={classes.notificationTitle}
								>
									{notification.title}
								</Typography>
								<Typography
									component="p"
									variant="p"
									className={classes.notificationMessage}
								>
									{notification.message.noHighlight}{' '}
									<span style={{ fontWeight: 600 }}>
										{notification.message.highlight}{' '}
									</span>
									{notification.message.noHighlight2}
								</Typography>
								<Typography
									component="p"
									variant="p"
									className={classes.notificationTime}
								>
									{notification.time}
								</Typography>
							</Grid>
							<Grid item xs={1} className={classes.rightMostContainer}>
								<MoreHorizIcon
									fontSize="large"
									style={{ color: grey[400], cursor: 'pointer' }}
									onClick={(event) => handleMoreButtonClick(event, index)}
								/>
								<Menu
									style={{
										marginTop: 30,
										right: 30
									}}
									id={notification.notificationId}
									anchorEl={anchorElArr[index]}
									open={Boolean(anchorElArr[index])}
									onClose={() => handleClose(index)}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'right'
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'right'
									}}
								>
									<MenuItem
										onClick={() =>
											handleMarkAsRead(index, notification.notificationId)
										}
									>
										<CheckIcon fontSize="medium" style={{ marginRight: 3 }} />
										<Typography
											component="p"
											varaint="p"
											style={{ fontSize: 14 }}
										>
											Mark as Read
										</Typography>
									</MenuItem>
								</Menu>
								<Typography
									component="p"
									variant="p"
									className={classes.viewMoreText}
									onClick={() => history.push(notification.propertyUrl)}
								>
									View
								</Typography>
							</Grid>
						</Grid>
						<Divider style={{ marginTop: 10 }} />
					</React.Fragment>
				);
			} else {
				return null;
			}
		});
	};

	return renderNotifications(notifications, onlyUnread);
}
