import React, { useEffect, useState } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { Button, Select, MenuItem, CardActions } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import blue from '@material-ui/core/colors/blue';
import { makeStyles } from '@material-ui/core/styles';
import { useUserState } from '../../../hook/customerHook';
import { Controller, useForm } from 'react-hook-form';
import { getAllUsersAssignedToTask, getStatusByOrganization } from '../../../api/serverRequests';
import ErrorMsg from '../ErrorMsg';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	button: {
		flex: 1,
		color: blue[900],
		margin: 10,
		justifyContent: 'center',
		alignSelf: 'center'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},
	actions: {
		padding: theme.spacing(2, 4),
		backgroundColor: '#f8f8f8',
		borderTop: '1px solid rgba(0, 0, 0, 0.12)'
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		display: 'flex',
		flexFlow: 'column nowrap',
		'& .MuiList-root': {
			overflowX: 'auto',
			scrollbarWidth: 'none',
			gap: '0.2rem'
		},
		'& .MuiList-root::-webkit-scrollbar': {
			display: 'none'
		},
		'& .MuiListItem-root': {
			padding: theme.spacing(0, 1, 0, 0),
			width: 'auto'
		},
		'& .MuiChip-root': {
			padding: theme.spacing(2, 1),
			transition: 'none'
			// margin: theme.spacing(0, 1, 1, 0)
		},
		'& .MuiChip-clickable:active': {
			boxShadow: 'none'
		}
	},
	cardContent: {
		padding: theme.spacing(4),
		paddingTop: theme.spacing(2),
		paddingBottom: '0 !important',
		overflowY: 'auto',
		overflowX: 'hidden'
	}
}));

export default function UserFiltersForm({
	refreshListings,
	restoredFilters,
	editAssignedTaskVisible,
	assignMyself,
	handleClose,
	setHasArea
}) {
	const classes = useStyles();
	const [userState, dispatchUser] = useUserState();

	let defaultValuesEmpty = {
		assignedTask: 'any',
		taskStatus: 'any'
	};

	const defaultValues = Object.assign(
		{},
		defaultValuesEmpty,
		restoredFilters,
		userState.userFilters
	);

	const { handleSubmit, control, reset, watch, setValue } = useForm({
		defaultValues
	});

	function getStoredAssigned() {
		return JSON.parse(sessionStorage.getItem('assignedTaskOptions')) || null;
	}

	function getStoredTaskStatus() {
		return JSON.parse(sessionStorage.getItem('taskStatusOptions')) || null;
	}

	const [assignedUsers, setAssignedUsers] = useState(
		getStoredAssigned() || [
			{
				label: 'Loading...',
				value: 'any'
			}
		]
	);

	const [taskStatus, setTaskStatus] = useState(
		getStoredTaskStatus() || [
			{
				label: 'Loading...',
				value: 'any'
			}
		]
	);
	const [errorMsg] = useState('');

	useEffect(() => {
		if (sessionStorage.getItem('assignedTaskOptions')) return;
		let options = [
			{
				label: 'Any',
				value: 'any'
			},
			{
				label: 'Unassigned',
				value: 'unassigned'
			}
		];
		if (assignMyself) {
			options.push({
				label: userState['user'].name,
				value: userState['user'].user_id
			});
			setAssignedUsers(options);
			sessionStorage.setItem('assignedTaskOptions', JSON.stringify(options));
		} else {
			getAllUsersAssignedToTask()
				.then((res) => {
					if (res.status === 200) {
						const data = res.data.users.map((item, id) => {
							return { label: item['UserName'], value: item['UserId'] };
						});
						setAssignedUsers(options.concat(data));
						sessionStorage.setItem(
							'assignedTaskOptions',
							JSON.stringify(options.concat(data))
						);
					}
				})
				.catch((err) => {
					console.log(err.message);
				});
		}
	}, []);

	useEffect(() => {
		if (sessionStorage.getItem('taskStatusOptions')) return;
		getStatusByOrganization()
			.then((res) => {
				if (res.status === 200) {
					let dummy = [
						{
							label: 'Any',
							value: 'any'
						}
					];
					const data = res.data.statusArr.map((item, id) => {
						return { label: item['Status'], value: item['Id'] };
					});
					setTaskStatus(dummy.concat(data));
					sessionStorage.setItem('taskStatusOptions', JSON.stringify(dummy.concat(data)));
				}
			})
			.catch((err) => {
				console.log(err.message);
			});
	}, []);

	const handleSearchSubmit = async (formData) => {
		let filtersPersisted = {};
		for (const key of Object.keys(formData)) {
			if (formData[key] && formData[key] !== 'any') {
				filtersPersisted[key] = formData[key];
				if (restoredFilters) restoredFilters[key] = formData[key];
			}
		}
		dispatchUser({ userFilters: filtersPersisted });
		handleClose();
		refreshListings(filtersPersisted);
	};

	const handleReset = () => {
		reset(defaultValuesEmpty);
		if (restoredFilters && 'userFilters' in restoredFilters) {
			delete restoredFilters['userFilters'];
		}
		dispatchUser({ userFilters: null });
		handleClose();
	};
	return (
		<React.Fragment>
			<form
				className={classes.form}
				id="userFilters"
				onSubmit={handleSubmit(handleSearchSubmit)}
			>
				<CardContent className={classes.cardContent}>
					{errorMsg && <ErrorMsg text={errorMsg} />}
					<Grid container justifyContent="start" alignItems="center" spacing={2}>
						<Grid container style={{ gap: '0.7rem' }} item xs={12} direction="column">
							<Grid
								item
								container
								style={{
									gap: '0.7rem',
									marginTop: '1rem',
									paddingBottom: '1.5rem',
									marginBottom: '0.5rem',
									borderBottom: '1px solid lightgray'
								}}
							>
								{(editAssignedTaskVisible || assignMyself) && (
									<Grid item container xs={12} style={{ alignItems: 'center' }}>
										<Grid item xs={6}>
											<Typography>Assigned to</Typography>
										</Grid>
										<Grid item xs={6}>
											<Controller
												name={'assignedTask'}
												control={control}
												color="primary"
												render={({ onChange, value }) => {
													return (
														<Select
															id="assignedTask"
															fullWidth
															value={value}
															onChange={(e) => {
																onChange(e.target.value);
															}}
															style={{
																color:
																	value === 'any'
																		? 'gray'
																		: 'black'
															}}
														>
															{assignedUsers &&
																assignedUsers.map((option) => {
																	if (option.value !== 'any') {
																		return (
																			<MenuItem
																				value={option.value}
																			>
																				{option.label}
																			</MenuItem>
																		);
																	} else {
																		return (
																			<MenuItem
																				value="any"
																				style={{
																					color: 'gray'
																				}}
																			>
																				{option.label}
																			</MenuItem>
																		);
																	}
																})}
														</Select>
													);
												}}
											></Controller>
										</Grid>
									</Grid>
								)}
								<Grid item container xs={12} style={{ alignItems: 'center' }}>
									<Grid item xs={6}>
										<Typography>Has Task Status</Typography>
									</Grid>
									<Grid item xs={6}>
										<Controller
											name={'taskStatus'}
											control={control}
											color="primary"
											render={({ onChange, value }) => {
												return (
													<Select
														fullWidth
														value={value}
														onChange={(value) => {
															onChange(value);
														}}
														style={{
															color:
																value === 'any' ? 'gray' : 'black'
														}}
													>
														{taskStatus &&
															taskStatus.map((option) => {
																if (option.value !== 'any') {
																	return (
																		<MenuItem
																			value={option.value}
																		>
																			{option.label}
																		</MenuItem>
																	);
																} else {
																	return (
																		<MenuItem
																			value="any"
																			style={{
																				color: 'gray'
																			}}
																		>
																			{option.label}
																		</MenuItem>
																	);
																}
															})}
													</Select>
												);
											}}
										></Controller>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</CardContent>
				<CardActions className={classes.actions}>
					<Grid item container alignItems="center">
						<Grid item xs={6}>
							<Button
								onClick={() => {
									handleReset();
								}}
							>
								Clear All
							</Button>
						</Grid>
						<Grid item xs={6}>
							<Button
								// type="submit"
								onClick={handleSubmit(handleSearchSubmit)}
								id="advFilters"
								fullWidth
								size="large"
								variant="contained"
								color="primary"
								className={classes.submit}
							>
								Apply
							</Button>
						</Grid>
					</Grid>
				</CardActions>
			</form>
		</React.Fragment>
	);
}
