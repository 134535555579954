import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useHistory } from 'react-router-dom';
import {
	Grid,
	Paper,
	TextField,
	Button,
	Typography,
	Box,
	Container,
	AppBar,
	Toolbar
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { sendDataToBackend, fetchUserDetails } from '../../../api/serverRequests';
import DefaultImage from '../../../assets/images/lead_logos/default.png';
import BackgroundImage from '../../../assets/images/background.jpg';

const useStyles = makeStyles((theme) => ({
	root: {
		overflowX: 'hidden',
		width: '100%',
		background: `url(${BackgroundImage}) no-repeat center center fixed`,
		backgroundSize: 'cover',
		color: '#fff'
	},
	container: {
		padding: theme.spacing(4),
		margin: '0 auto',
		maxWidth: '1000px',
		backgroundColor: 'rgba(255, 255, 255, 0.9)',
		borderRadius: '15px'
	},
	paper: {
		padding: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%'
	},
	heroImage: {
		width: '100%',
		height: 'auto',
		marginBottom: theme.spacing(4),
		borderRadius: '10px'
	},
	textField: {
		width: '100%',
		marginBottom: theme.spacing(2),
		borderRadius: '10px',
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#004F2B'
			},
			'&:hover fieldset': {
				borderColor: '#00391E'
			},
			'&.Mui-focused fieldset': {
				borderColor: '#004F2B'
			}
		}
	},
	formWrapper: {
		marginTop: theme.spacing(2),
		width: '100%'
	},
	title: {
		fontWeight: 'bold',
		marginBottom: theme.spacing(2),
		textAlign: 'center',
		color: '#333'
	},
	sectionTitle: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
		color: '#555'
	},
	submit: {
		marginTop: theme.spacing(3),
		backgroundColor: '#004F2B',
		width: '200px',
		height: '50px',
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto',
		color: '#FFFFFF',
		borderRadius: '25px',
		boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
		transition: 'background-color 0.3s, transform 0.3s',
		'&:hover': {
			backgroundColor: '#00391E',
			transform: 'scale(1.05)'
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			height: 'auto'
		}
	},
	appBar: {
		backgroundColor: 'transparent',
		boxShadow: 'none'
	},
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
		color: '#fff'
	},
	addressField: {
		border: '2px solid #004F2B',
		borderRadius: '15px',
		padding: '10px',
		fontSize: '1.2rem',
		color: '#333',
		textAlign: 'center',
		marginBottom: theme.spacing(3),
		backgroundColor: '#f9f9f9'
	},
	inviteText: {
		color: '#555',
		textAlign: 'center',
		fontSize: '1.1rem',
		marginBottom: theme.spacing(3)
	}
}));

export default function LeadCapture() {
	const history = useHistory();
	const classes = useStyles();
	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors }
	} = useForm();
	const [orgLogo, setOrgLogo] = useState(null);
	const [address, setAddress] = useState('');
	const [city, setCity] = useState('');
	const [userId, setUserId] = useState('');
	const [homeId, setHomeId] = useState('');
	const [orgId, setOrgId] = useState('');
	const [submissionSuccess, setSubmissionSuccess] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const validateName = (value) => {
		const nameRegex = /^[a-zA-Z\s'-]+$/;
		return nameRegex.test(value) || 'Invalid name format';
	};

	const validatePhoneNumber = (value) => {
		const phoneRegex = /^(?:\(\d{3}\)\s?|^\d{3}[-\s]?)\d{3}[-\s]?\d{4}$/;
		return phoneRegex.test(value) || 'Invalid phone number format';
	};

	const validateEmail = (value) => {
		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return emailRegex.test(value) || 'Invalid email address format';
	};

	const onSubmit = (data) => {
		console.log('Form Data Submitted:', data);

		const fullData = {
			City: city,
			Address: address,
			UserId: userId,
			OrganizationId: orgId,
			HomeId: homeId,
			Data: { Name: data.name, Phone: data.phoneNumber, Email: data.emailAddress }
		};

		sendDataToBackend(fullData)
			.then((response) => {
				console.log('Data sent successfully:', response);
				setSubmissionSuccess(true);
				setErrorMessage('');

				const params = new URLSearchParams({
					userid: userId,
					orgId,
					address,
					city,
					homeId
				});

				history.push(`/propertyreport?${params.toString()}`);
			})
			.catch((error) => {
				console.error('Error sending data:', error);
				setErrorMessage('Error submitting form. Please try again.');
				setSubmissionSuccess(false);
			});
	};

	const location = useLocation();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const address = queryParams.get('address');
		const city = queryParams.get('city');
		const userId = queryParams.get('userid');
		const homeId = queryParams.get('homeId');
		const orgId = queryParams.get('orgId');

		if (address) setAddress(address);
		if (city) setCity(city);
		if (userId) setUserId(userId);
		if (homeId) setHomeId(homeId);
		if (orgId) setOrgId(orgId);

		if (orgId || userId) {
			fetchUserDetails(orgId, userId)
				.then((res) => {
					if (res.data.success) {
						const userDetails = res.data.user_details;
						const logoPath = require('../../../assets/images/lead_logos/' +
							userDetails.logo);
						setOrgLogo(logoPath);
					}
				})
				.catch((err) => {
					console.error('Error fetching user details:', err);
				});
		}

		if (city && address) {
			setValue('city', city);
			setValue('address', address);
			sendDataToBackend({
				City: city,
				Address: address,
				UserId: userId,
				OrganizationId: orgId,
				HomeId: homeId
			})
				.then((data) => console.log(data))
				.catch((error) => console.error('Error:', error));
		}
	}, [location, setValue]);

	return (
		<Box className={classes.root}>
			<AppBar position="static" className={classes.appBar}>
				<Toolbar className={classes.toolbar}></Toolbar>
			</AppBar>
			<Container maxWidth="md">
				<img
					src={orgLogo ? orgLogo : DefaultImage}
					alt="Hero"
					className={classes.heroImage}
				/>
				<Grid container justifyContent="center" className={classes.container}>
					<Paper className={classes.paper} elevation={3}>
						<Typography variant="h5" component="h2" className={classes.title}>
							Complete Home Report for
						</Typography>
						{address && (
							<Typography
								variant="h4"
								component="h2"
								className={classes.addressField}
							>
								{address}, {city}
							</Typography>
						)}

						{/* Display Success Message */}
						{submissionSuccess && (
							<Typography
								variant="h6"
								style={{ color: 'green', textAlign: 'center', marginTop: '20px' }}
							>
								Form submitted successfully!
							</Typography>
						)}

						{/* Display Error Message */}
						{errorMessage && (
							<Typography
								variant="h6"
								style={{ color: 'red', textAlign: 'center', marginTop: '20px' }}
							>
								{errorMessage}
							</Typography>
						)}

						{/* Conditionally Render Form */}
						{!submissionSuccess && (
							<div className={classes.formWrapper}>
								<Typography variant="h6" className={classes.inviteText}>
									Enter your details below to view your personalized report
									instantly:
								</Typography>
								<form onSubmit={handleSubmit(onSubmit)}>
									<Grid container spacing={2}>
										{/* Address Details */}
										{!address && (
											<Grid item xs={12}>
												<Typography
													variant="h6"
													className={classes.sectionTitle}
												>
													Address Details:
												</Typography>
											</Grid>
										)}
										{!address && (
											<Grid item xs={12} sm={6}>
												<Controller
													name="address"
													control={control}
													defaultValue=""
													render={({ onChange, value }) => (
														<TextField
															id="addressValue"
															className={classes.textField}
															label="Street Address"
															required
															value={value}
															variant="outlined"
															onChange={(e) =>
																onChange(e.target.value)
															}
														/>
													)}
												/>
											</Grid>
										)}
										{!city ? (
											<Grid item xs={12} sm={6}>
												<Controller
													name="city"
													control={control}
													defaultValue=""
													render={({ onChange, value }) => (
														<TextField
															id="cityInformation"
															className={classes.textField}
															label="City"
															required
															value={value}
															variant="outlined"
															onChange={(e) =>
																onChange(e.target.value)
															}
														/>
													)}
												/>
											</Grid>
										) : (
											''
										)}
										{/* Contact Details */}
										<Grid item xs={12}>
											<Typography
												variant="h6"
												className={classes.sectionTitle}
											></Typography>
										</Grid>
										<Grid item xs={12} sm={6}>
											<Controller
												name="name"
												control={control}
												defaultValue=""
												rules={{ validate: validateName }}
												render={({ onChange, value }) => (
													<TextField
														id="name"
														className={classes.textField}
														label="Name"
														required
														error={!!errors.name}
														helperText={
															errors.name ? errors.name.message : ''
														}
														value={value}
														variant="outlined"
														onChange={(e) => onChange(e.target.value)}
													/>
												)}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<Controller
												name="emailAddress"
												control={control}
												defaultValue=""
												rules={{ validate: validateEmail }}
												render={({ onChange, value }) => (
													<TextField
														id="emailAddress"
														className={classes.textField}
														label="Email Address"
														error={!!errors.emailAddress}
														required
														helperText={
															errors.emailAddress
																? errors.emailAddress.message
																: ''
														}
														value={value}
														variant="outlined"
														onChange={(e) => onChange(e.target.value)}
													/>
												)}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<Controller
												name="phoneNumber"
												control={control}
												defaultValue=""
												rules={{
													validate: (value) =>
														value === '' || validatePhoneNumber(value)
												}}
												render={({ onChange, value }) => (
													<TextField
														id="phoneNumber"
														className={classes.textField}
														label="Phone Number"
														error={!!errors.phoneNumber}
														helperText={
															errors.phoneNumber
																? errors.phoneNumber.message
																: ''
														}
														value={value}
														variant="outlined"
														onChange={(e) => onChange(e.target.value)}
													/>
												)}
											/>
										</Grid>
										{/* Submit Button */}
										<Grid item xs={12}>
											<Button
												type="submit"
												variant="contained"
												color="primary"
												className={classes.submit}
											>
												View Report
											</Button>
										</Grid>
									</Grid>
								</form>
							</div>
						)}
					</Paper>
				</Grid>
			</Container>
		</Box>
	);
}
