import React, { useState } from 'react';

import { Paper, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(2),
		overflow: 'auto',
		height: '100%',
		width: '100%'
	}
}));

export default function StatusPaper({ children, ...props }) {
	const classes = useStyles();
	return <Paper className={classes.paper}>{children}</Paper>;
}
