import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
	box: {
		padding: theme.spacing(2),
		display: 'flex',
		flexWrap: 'wrap',
		gap: theme.spacing(1),
		overflowY: 'auto',
		overflowX: 'hidden'
	},
	chip: {
		margin: theme.spacing(0.5),
		transition: 'all 0.3s ease'
	},
	spinner: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%'
	}
}));

const TagBox = ({ viewPreviousSearch, bookmarks }) => {
	const classes = useStyles();

	useEffect(() => {
		console.log('Bookmarks updated:', bookmarks);
	}, [bookmarks]);

	return (
		<div className={classes.box}>
			{bookmarks &&
				bookmarks.map((bookmark) => (
					<Chip
						key={bookmark.Id}
						label={bookmark.Alias || 'Unnamed Bookmark'}
						className={classes.chip}
						onClick={() => viewPreviousSearch(JSON.parse(bookmark.Result))}
					/>
				))}
		</div>
	);
};

export default TagBox;
