import React, { useCallback, useState } from 'react';
import { Paper, CircularProgress, Snackbar, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Lists, Title, TagDropdown, BaseLayout, Loading, StatusPaper, TopBar } from '../..';
import { useUserState } from '../../../hook/customerHook';
import { PERMS } from '../../../helper/perms';
import {
	checkUserHasPermissionId,
	saveUserDashboardLayout,
	getUserDashboardLayout
} from '../../../api/serverRequests';
import { useEffect } from 'react';
import { Responsive as ResponsiveGridLayout } from 'react-grid-layout';
import { withSize } from 'react-sizeme';
import loadable from '@loadable/component';
import moment from 'moment';

const TotalAssignedTasks = loadable(() => import('../../'), {
	resolveComponent: (components) => components.TotalAssignedTasks
});

const MonthlyAgentRank = loadable(() => import('../../'), {
	resolveComponent: (components) => components.MonthlyAgentRank
});

const KnockingPieChart = loadable(() => import('../../'), {
	resolveComponent: (components) => components.KnockingPieChart
});

const FollowUpOverview = loadable(() => import('../../'), {
	resolveComponent: (components) => components.FollowUpOverview
});

const NewTasks = loadable(() => import('../../'), {
	resolveComponent: (components) => components.NewTasks
});

const useStyles = makeStyles((theme) => ({
	root: { display: 'flex', flexGrow: 1 },
	paper: {
		padding: theme.spacing(3),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column'
	},
	fixedHeight: {
		height: 120
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	rankings: {
		display: 'flex',
		flexDirection: 'row',
		margin: 0
	},
	responsive: {
		width: '100%',
		height: '100%'
	},
	spinner: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }
}));

export default withSize({ refreshMode: 'debounce', refreshRate: 60 })(Dashboardv2);

const orgLayouts = [
	{ i: 'a', x: 0, y: 0, w: 3, h: 4 },
	{ i: 'b', x: 3, y: 0, w: 5, h: 5 },
	{ i: 'c', x: 0, y: 3, w: 3, h: 5 },
	{ i: 'd', x: 3, y: 4, w: 5, h: 4 },
	{ i: 'e', x: 8, y: 0, w: 3, h: 9 },
	{ i: 'f', x: 0, y: 6, w: 10, h: 6 }
];
function Dashboardv2({ size: { width } }) {
	const classes = useStyles();
	const [listings, setListings] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [userState, dispatchUser] = useUserState();
	const [layouts, setLayouts] = useState(null);
	const [agentDashboard, setAgentDashboard] = useState(
		userState.user
			? sessionStorage.getItem('agentTags')
				? JSON.parse(sessionStorage.getItem('agentTags'))['agent']['value']
				: userState.user.user_id
			: ''
	);
	const [agentOrg, setAgentOrg] = useState(userState.user ? userState.user.organizationId : -1);
	const [edit, toggleEdit] = useState(false);
	const [startDate, setStartDate] = useState(
		userState.timeFilter ? userState.timeFilter.timeFrameStart : ''
	);
	const [endDate, setEndDate] = useState(
		userState.timeFilter ? userState.timeFilter.timeFrameEnd : moment().toISOString()
	);

	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message

	// // submit status updated after user has clicked save in edit modal
	const [statusClass, setStatusClass] = useState(''); // keeps track of status when data is posted

	useEffect(() => {
		localStorage.setItem('userState', JSON.stringify(userState));
		localStorage.setItem('isLoggedIn', true);

		if (userState.user.layout) {
			setLayouts(JSON.parse(userState.user.layout));

			return;
		}
		getUserDashboardLayout(userState.user.user_id)
			.then((res) => {
				if (res.status === 200) {
					const userPref = JSON.parse(res.data.layout);
					const shownWidgets = JSON.parse(res.data.widgets);
					setLayouts(userPref ? userPref : orgLayouts);
					setItems(widgets.filter((obj) => shownWidgets.includes(obj.widget)));
					dispatchUser({
						layout: userPref ? userPref : orgLayouts,
						widgets: shownWidgets
					});
				}
				setIsLoading(false);
			})
			.catch((err) => {
				if (err.response.status === 401) {
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue.'
					);
					dispatchUser({
						isLoggedIn: false
					});
					<Redirect to="/login" />;
					return;
				} else {
					setLayouts(orgLayouts);
					dispatchUser({ layout: orgLayouts });
					setIsLoading(false);
				}
			});
	}, []);

	useEffect(() => {
		let unmounted = false;
		return () => {
			unmounted = true;
		};
	}, []);

	const handleLoading = (loading) => {
		setIsLoading(loading);
	};

	const saveLayout = () => {
		const shownWidgets = JSON.stringify(items.map((i) => i.widget));
		dispatchUser({ layout: layouts, widgets: shownWidgets });
		saveUserDashboardLayout(userState.user.user_id, JSON.stringify(layouts), shownWidgets)
			.then((res) => {
				if (res.status === 200) {
					setNotificationMessage('Your layout has been saved!');
					setNotificationState(true);
					setStatusClass('success');
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleDropdownChange = (event) => {
		setAgentDashboard(event.target.value);
	};

	const editOptions = () => {
		toggleEdit(!edit);
		setNotificationMessage(`Editing has been turned ${!edit ? 'on.' : 'off.'}`);
		setNotificationState(true);
		setStatusClass('info');
	};

	const handleLayoutRender = (item) => {
		let widget;
		if (layouts) widget = layouts.find((g) => g.i === item.widget);
		else {
			widget = orgLayouts.find((g) => g.i === item.widget);
		}
		return widget;
	};

	const widgets = [
		{
			widget: 'a',
			name: '# of Assigned Tasks',
			component: (
				<TotalAssignedTasks
					curAgent={agentDashboard}
					curOrg={agentOrg}
					startDate={startDate}
					endDate={endDate}
				/>
			)
		},
		{
			widget: 'b',
			name: 'User Rankings',
			component: (
				<MonthlyAgentRank curOrg={agentOrg} startDate={startDate} endDate={endDate} />
			)
		},
		{
			widget: 'c',
			name: 'Follow Up Overview',
			component: (
				<FollowUpOverview
					curAgent={agentDashboard}
					curOrg={agentOrg}
					startDate={startDate}
					endDate={endDate}
				/>
			)
		},
		{
			widget: 'd',
			name: 'Task Status Pie Chart',
			component: (
				<KnockingPieChart
					curAgent={agentDashboard}
					curOrg={agentOrg}
					startDate={startDate}
					endDate={endDate}
				/>
			)
		},
		{
			widget: 'e',
			name: 'Newly Assigned Tasks',
			component: (
				<NewTasks
					curAgent={agentDashboard}
					curOrg={agentOrg}
					startDate={startDate}
					endDate={endDate}
				/>
			)
		},
		{
			widget: 'f',
			name: 'Dashboard Tag',
			component: (
				<DashboardTag
					listings={listings}
					handleLoading={handleLoading}
					setListings={setListings}
					agentValue={agentDashboard}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
				/>
			)
		}
	];

	const onRemoveItem = (widget) => {
		setItems(items.filter((i) => i.widget !== widget));
	};
	const onAddItem = (itemId) => {
		setItems([...items, widgets.find((i) => i.widget === itemId)]);
	};

	const onMoveStop = (newLayout) => {
		setLayouts(newLayout);
	};

	const [items, setItems] = useState(
		sessionStorage.getItem('widgets')
			? widgets.filter((obj) => sessionStorage.getItem('widgets').includes(obj.widget))
			: widgets
	);

	useEffect(() => {
		setItems(widgets.filter((obj) => items.some((i) => obj.widget === i.widget)));
	}, [agentDashboard, listings, startDate, endDate]);

	return (
		<BaseLayout>
			{/* TODO: refactor snackbar into reusable component */}
			{layouts ? (
				<>
					<Snackbar
						anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
						open={notificationState}
						autoHideDuration={4500}
						onClose={() => setNotificationState(false)}
					>
						<Alert
							onClose={() => setNotificationState(false)}
							severity={statusClass}
							variant="filled"
						>
							{notificationMessage}
						</Alert>
					</Snackbar>
					<TopBar
						toggleEdit={editOptions}
						setAgentValue={setAgentDashboard}
						agentValue={agentDashboard}
						items={items}
						loggedInUser={userState.user.user_id}
						originalItems={widgets}
						onAddItem={onAddItem}
						onRemoveItem={onRemoveItem}
						edit={edit}
						onLayoutSave={saveLayout}
						handleDropdownChange={handleDropdownChange}
						handleLoading={handleLoading}
						startDate={startDate}
						endDate={endDate}
						setStartDate={setStartDate}
						setEndDate={setEndDate}
					/>
					<ResponsiveGridLayout
						className="layout"
						layouts={layouts}
						breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
						cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
						rowHeight={60}
						isDraggable={edit}
						isResizable={edit}
						onDragStop={(newLayout) => onMoveStop(newLayout)}
						onResizeStop={(newLayout) => onMoveStop(newLayout)}
						width={width / 1.15}
					>
						{items.map((item, id) => {
							return (
								<div key={item.widget} data-grid={handleLayoutRender(item)}>
									{item.widget === 'f' || item.widget === 'g' ? (
										<React.Fragment key={item.name}>
											{item.component}
										</React.Fragment>
									) : (
										<StatusPaper key={item.name}>{item.component}</StatusPaper>
									)}
								</div>
							);
						})}
					</ResponsiveGridLayout>
				</>
			) : (
				<div item xs={12} className={classes.spinner}>
					<CircularProgress />
				</div>
			)}
		</BaseLayout>
	);
}

function DashboardTag({ handleLoading, setListings, agentValue, listings, isLoading }) {
	const classes = useStyles();
	const [viewTagsPermission, setViewTagsPermission] = useState(false);
	const [userState, dispatchUser] = useUserState();
	const [isListLoading, setListLoading] = useState(true);

	// permission ids
	const VIEW_OTHER_TAGS_PERM_ID = PERMS.VIEW_OTHER_USERS_DASHBOARD;

	useEffect(() => {
		const checkTagViewingStatus = async () => {
			if (userState.user) {
				checkUserHasPermissionId(VIEW_OTHER_TAGS_PERM_ID, userState.user.user_id)
					.then((res) => {
						if (res.data.success) {
							setViewTagsPermission(true);
						}
					})
					.catch((err) => {
						if (err.response && err.response.status === 401) {
							dispatchUser({
								isLoggedIn: false
							});
						}
						console.error(err.message);
					});
			}
		};

		if (isLoading) {
			checkTagViewingStatus();
		}
	}, []);

	const titles = [
		{ label: 'Image', numeric: false, id: 'Pics' },
		{ label: 'Address', numeric: false, id: 'Address' },
		{ label: 'Area', numeric: false, id: 'Area' },
		{ label: 'SubArea', numeric: false, id: 'S/A' },
		{ label: 'Price', numeric: true, id: 'Price' },
		{ label: 'Assessment', numeric: true, id: 'assessment' },
		{ label: 'Total Bedrooms', numeric: true, id: 'Tot BR' },
		{ label: 'Total Bathrooms', numeric: true, id: 'Tot Baths' },
		{ label: 'Kitchens', numeric: true, id: '#Kitchens' },
		{ label: 'Total Floor Area', numeric: true, id: 'TotFlArea' },
		{ label: 'Year Built', numeric: true, id: 'Yr Blt' },
		{ label: 'Frontage (Feet)', numeric: true, id: 'Frontage - Feet' },
		{ label: 'Lot Size', numeric: true, id: 'Lot Sz (Sq.Ft.)' }
		//{label: 'Direction',numeric: false, id:''},
	];

	const cells = [
		'Address',
		'Area',
		'SA',
		'Price',
		'assessment',
		'TotBR',
		'TotBaths',
		'NumKitchens',
		'TotFlArea',
		'YrBlt',
		'FrontageFeet',
		'LotSzSqFt'
	];

	return (
		<>
			<Paper className={classes.paper}>
				<Title>Tags</Title>
				<TagDropdown
					handleLoading={handleLoading}
					handleListings={setListings}
					canViewOtherAgentTags={viewTagsPermission}
					agentValue={agentValue}
					setIsLoading={setListLoading}
				/>
				{isListLoading ? (
					<div className={classes.spinner}>
						<CircularProgress />
					</div>
				) : listings && listings.length > 0 ? (
					<Lists
						isSearch={false}
						listHeads={titles}
						listCells={cells}
						listings={listings}
						title={`Listing with tags`}
						openInNewTab={true}
					/>
				) : (
					<Title>No listings found</Title>
				)}
			</Paper>
		</>
	);
}
