import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Tabs, Tab, CircularProgress } from '@material-ui/core';
import UsersList from '../../UserAccessComps/UsersList';
import RolesList from '../../UserAccessComps/RolesList';
import {
	getPermissionsPreset,
	getOrganizationRoles,
	checkUserHasPermissionId
} from '../../../../api/serverRequests';
import { NotAuthorized } from '../../..';
import { useUserState } from '../../../../hook/customerHook';

const useStyles = makeStyles((theme) => ({
	tab: {
		background: 'white',
		color: 'grey',
		'&.Mui-selected': {
			background: '#c7d4d9',
			color: '#83979e'
		}
	}
}));

const SELECTED_TAB = {
	USERS: 1,
	ROLES: 2
};

const UserAccessTab = ({ userAccessPageVisible }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [curTabValue, setCurTabValue] = useState(SELECTED_TAB.USERS);
	const [userState, dispatchUser] = useUserState();

	const classes = useStyles();

	const handleTabChange = () => {
		if (curTabValue === SELECTED_TAB.USERS) {
			setCurTabValue(SELECTED_TAB.ROLES);
		} else {
			setCurTabValue(SELECTED_TAB.USERS);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const permissionsRes = await getPermissionsPreset();
				if (permissionsRes.data.success) {
					const permissions = permissionsRes.data.permissions;

					console.log('first', permissions);
					localStorage.setItem('PERMISSIONS', JSON.stringify(permissions));
				}

				const rolesRes = await getOrganizationRoles();
				if (rolesRes.data.success) {
					const rolesData = rolesRes.data.roles;
					localStorage.setItem('orgRolesList', JSON.stringify(rolesData));
				}
				setIsLoading(false);
			} catch (e) {
				setIsLoading(false);
				console.log(e);
			}
		};

		if (isLoading && userAccessPageVisible) {
			fetchData();
		}
	}, []);

	return isLoading ? (
		<>
			<CircularProgress size={50} color="inherit" />
		</>
	) : userAccessPageVisible ? (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper>
						<Tabs
							value={curTabValue}
							onChange={handleTabChange}
							indicatorColor="primary"
							aria-label="user-roles-tabs"
						>
							<Tab className={classes.tab} label="Users" value={SELECTED_TAB.USERS} />
							<Tab className={classes.tab} label="Roles" value={SELECTED_TAB.ROLES} />
						</Tabs>
						{curTabValue === SELECTED_TAB.USERS ? (
							<UsersList tabValue={SELECTED_TAB.USERS} index={0}></UsersList>
						) : (
							<RolesList tabValue={SELECTED_TAB.ROLES} index={1}></RolesList>
						)}
					</Paper>
				</Grid>
			</Grid>
		</>
	) : (
		<NotAuthorized />
	);
};

export default UserAccessTab;
