import { useState, useEffect, useRef } from 'react';
import Alert from '@material-ui/lab/Alert';

import axios from 'axios';
// router //
import { Prompt } from 'react-router-dom';

// comps //
import { ErrorMsg } from '../../..';
import { Message } from '../../..';

// mui //
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import CancelIcon from '@material-ui/icons/Cancel';
import ProgressBar from '../ProgressBar';
import { CircularProgress, Snackbar } from '@material-ui/core';

// api //
import { uploadRegionFile } from '../../../../api/serverRequests';
import { useUserState } from '../../../../hook/customerHook';

const useStyles = makeStyles({
	fileUploader: {
		marginBottom: '1.5em',
		marginTop: '0.7em'
	},
	uploadActions: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '23em'
	},
	chooseBtn: {
		fontWeight: 'bold',
		border: '1px solid black'
	},
	uploadBtn: {
		fontWeight: 'bold',
		color: 'white',
		backgroundColor: '#808080'
	},
	uploadWrapper: {
		display: 'flex',
		alignItems: 'end',
		justifyContent: 'space-between',
		maxWidth: '305px'
	},
	uploadFileWrapper: {
		display: 'flex',
		alignItems: 'end',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		gap: '5px'
	},
	cancelBtn: {
		padding: 0
	},
	progress: { display: 'flex', flexDirection: 'column', marginTop: '20px', marginBottom: '20px' },
	progressBar: {
		display: 'flex',
		flexDirection: 'column'
	}
});

export default function FileUploadRegion({ setClusters, setFilePath }) {
	const [file, setFile] = useState(null);
	const [errorMsg, setErrorMsg] = useState('');
	const [progress, setProgress] = useState(0);
	const [uploadText, setUploadText] = useState('');
	const [isUploading, setIsUploading] = useState(false);
	const [userState, dispatchUser] = useUserState();
	const source = useRef(null);
	const classes = useStyles();
	// // submit status updated after user has clicked save in edit modal
	const [submitSuccessStatus, setSubmitSuccessStatus] = useState(''); // keeps track of status when data is posted

	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message

	const handleChange = (event) => {
		setProgress(0);
		setErrorMsg('');
		if (event.target.files[0] != null) {
			setUploadText('Upload?');
			setFile(event.target.files[0]);
			event.target.value = null;
		}
	};

	const handleCancel = (event) => {
		event.preventDefault();
		source.current.cancel('user cancelled');
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setErrorMsg('');
		setIsUploading(false);
		if (!file) return setErrorMsg('Please select a file to upload.');
		if (file.type !== 'text/csv') {
			return setErrorMsg('Please upload a .csv file.');
		}

		const csvFile = new FormData();
		csvFile.append('csv', file);
		setUploadText('Uploading...');
		setIsUploading(true);
		source.current = axios.CancelToken.source();
		uploadRegionFile(csvFile, source.current.token, setProgress)
			.then((res) => {
				// console.log(res);
				if (res.status === 200) {
					setProgress(100);
					setUploadText('Upload complete!');
					setIsUploading(false);
				}
			})
			.catch((err) => {
				console.log(err);
				if (axios.isCancel(err)) {
					setUploadText('Upload cancelled.');
					setIsUploading(false);
					setFile(null);
				} else if (err.response.status === 400) {
					setProgress(0);
					setErrorMsg(err.response.data.error_message);
					setUploadText('Upload failed!');
					setIsUploading(false);
					setFile(null);
				} else if (err.response.status === 401) {
					setSubmitSuccessStatus('error');
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue'
					);
					dispatchUser({
						isLoggedIn: false
					});
				} else if (err.response.status === 500) {
					setProgress(0);
					setErrorMsg('There was an issue with the server, please contact an admin.');
					setUploadText('Upload failed!');
					setIsUploading(false);
					setFile(null);
				} else if (err.response.status === 504) {
					setProgress(0);
					setErrorMsg('Response timed out. Please try a smaller file');
					setUploadText('Upload failed!');
					setIsUploading(false);
					setFile(null);
				} else {
					setProgress(0);
					setErrorMsg('Something went wrong. Try again.');
					setUploadText('Upload failed!');
					setIsUploading(false);
					setFile(null);
				}
			});
	};

	function truncate(str) {
		return str.length > 20 ? str.substring(0, 20) + '...' : str;
	}

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={notificationState}
				autoHideDuration={6000}
				onClose={() => setNotificationState(false)}
			>
				<Alert
					onClose={() => setNotificationState(false)}
					severity={submitSuccessStatus === 'success' ? 'success' : 'error'}
					variant="filled"
				>
					{notificationMessage}
				</Alert>
			</Snackbar>
			<div className={classes.fileUploader}>
				<Prompt
					when={isUploading}
					message={(location, action) => {
						console.log(action);
						if (action) {
							return 'There is a operation in process, are you sure you want to leave?';
						}
						console.log(location);
					}}
				/>
				<form onSubmit={handleSubmit}>
					<div className={classes.progressBar}>
						<div className={classes.uploadWrapper}>
							<div className={classes.uploadFileWrapper}>
								{uploadText && <Message text={uploadText} />}
								{file ? truncate(file.name) : null}
							</div>
							{isUploading && (
								<IconButton
									aria-label="cancel"
									onClick={handleCancel}
									className={classes.cancelBtn}
								>
									<CancelIcon />
								</IconButton>
							)}
						</div>
						{isUploading && (
							<div className={classes.progress}>
								<CircularProgress />
							</div>
						)}
					</div>
					{errorMsg && <ErrorMsg text={errorMsg} />}
					<div className={classes.uploadActions}>
						<Button variant="outlined" component="label" className={classes.chooseBtn}>
							Choose Files
							<input type="file" hidden onChange={handleChange} />
						</Button>
						<Button
							variant="contained"
							color="default"
							className={classes.uploadBtn}
							endIcon={<PresentToAllIcon />}
							type="submit"
						>
							Upload
						</Button>
					</div>
				</form>
			</div>
		</>
	);
}
