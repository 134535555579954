import React, { useState, useEffect } from 'react';
import {
	Typography,
	IconButton,
	Table,
	TableRow,
	CircularProgress,
	Box,
	Button,
	Modal
} from '@material-ui/core';
import MuiTableCell from '@material-ui/core/TableCell';
import PhoneIcon from '@material-ui/icons/Phone';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { makeStyles, withStyles } from '@material-ui/styles';
import { Edit } from '@material-ui/icons';
import DoneIcon from '@material-ui/icons/Done';
import VoicemailIcon from '@material-ui/icons/Voicemail';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import HelpIcon from '@material-ui/icons/Help';
import MicOffIcon from '@material-ui/icons/MicOff';
import Tooltip from '@material-ui/core/Tooltip';
import EditPopUp from './EditPopUp';
import RenderTags from './RenderTags';
import RenderComments from './RenderComments';
import moment from 'moment';

//Dynamically load components on request for faster page rendering
import loadable from '@loadable/component';

import { useUserState } from '../../../../hook/customerHook';
import {
	getStatusByOrganization,
	getTagsByOrganization,
	getActiveAgents,
	postTaskOwner,
	getUserActivitiesSingleListing
} from '../../../../api/serverRequests';
import AuditTable from '../../AuditTable';

const CallRecordings = loadable(() => import('../../../'), {
	resolveComponent: (components) => components.CallRecordingTable
});

const useStyles = makeStyles((theme) => ({
	root: {
		[theme.breakpoints.down('sm')]: {
			padding: 10
		}
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		margin: 10
	},
	statusTable: {
		margin: '1rem 2rem 1rem 0',
		// width: '85vw',

		'@media (max-width: 700px)': {
			// width: '80vw'
		}
	},
	columnSpacing: {
		width: 'clamp(1rem, 30vw, 15rem)',
		'@media (max-width: 450px)': {
			width: '9rem'
		}
	},
	spinner: {
		marginTop: 20,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%'
	},
	tagsTitle: {
		display: 'inline-block'
	},
	chip: {
		display: 'inline-block',
		margin: 3
	},
	editHistory: {
		marginTop: 30,
		display: 'flex'
	},
	viewEditHistory: {
		textDecoration: 'underline',
		marginRight: 20,

		color: theme.palette.primary.main,
		fontWeight: 700,
		'&:hover': {
			cursor: 'pointer'
		}
	},
	PhoneNumberContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	PhoneIcon: {
		padding: 7,
		marginLeft: 5
	},
	iconDisplay: {
		display: 'flex',
		alignItems: 'center'
	},
	historyModal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		boxShadow: 24,
		p: 4,
		background: 'white'
	}
}));

const TableCell = withStyles({
	root: {
		borderBottom: 'none',
		padding: '0.5rem 0'
	}
})(MuiTableCell);

export default function CustomTab({
	listing,
	primaryInfo,
	secondaryInfo,
	assignedInfo,
	shouldRefetch,
	setShouldRefetch,
	isLoadingPrimary,
	isLoadingSecondary,
	submitSuccessStatus,
	setSubmitSuccessStatus,
	setNotificationState,
	setNotificationMessage,
	knockingStatusVisible,
	viewCommentsVisible,
	tagsVisible,
	editAssignedTaskVisible,
	viewCallRecordingsVisible,
	addCommentsVisible,
	permissionChecked,
	resetPermissions,
	assignMyself,
	viewEditHistoryVisible,
	viewOwnerName,
	viewOwnerPhone
}) {
	const classes = useStyles();
	const [userState, dispatchUser] = useUserState();

	const [editPopUp, setEditPopUp] = useState(false);
	const propertyListingInfo = listing;

	// setting data
	const [knockingStatus, setKnockingStatus] = useState('');
	const [followUpSchedule, setFollowUpSchedule] = useState('');
	const [ownerName, setOwnerName] = useState('');
	const [ownerPhoneNumber, setOwnerPhoneNumber] = useState('');
	const [tags, setTags] = useState([]);
	const [comments, setComments] = useState([]);

	// selectable options that are needed within edit modal
	const [knockingStatusOptions, setKnockingStatusOptions] = useState([]);
	const [tagsOptions, setTagsOptions] = useState([]);

	// // selectable options for assigning a task to agent
	const [selectAgentOptions, setSelectAgentOptions] = useState([]);
	const [owner, setOwner] = useState(null);

	const [userActivities, setUserActivities] = useState([]);
	const [openHistoryModal, setopenHistoryModal] = useState(false);
	const handleopenHistoryModal = () => setopenHistoryModal(true);
	const handleCloseHistoryModal = () => setopenHistoryModal(false);

	const [agentsOptsAuditTable, setAgentOptsAuditTable] = useState([]);
	const [curAgentAuditTable, setCurAgentAuditTable] = useState(null);
	const [curAgentNameAuditTable, setCurAgentNameAuditTable] = useState(null);
	const [auditTableLoading, setAuditTableLoading] = useState(true);

	useEffect(() => {
		// with every selectable option for knocking status (called, not interested, etc...) there is an associated id in the DB
		// we must fetch this and assign it as the 'value' to that label
		const fetchOptionsIdValue = async (mapStatusToOptions) => {
			const response = await getStatusByOrganization();
			try {
				if (response.data.success) {
					const options = mapStatusToOptions(response.data.statusArr);
					setKnockingStatusOptions(options);
				}
			} catch (err) {
				if (response.status === 401) {
					return dispatchUser({ isLoggedIn: false });
				}
				console.log(err.message);
			}
		};

		const mapStatusToOptions = (statusArr) => {
			// this function formats the possible options to an array holding objects of the form {label: string, value: int}
			return statusArr.map((status) => {
				return { label: status.Status, value: status.Id };
			});
		};

		fetchOptionsIdValue(mapStatusToOptions);
	}, []);

	useEffect(() => {
		handleAudit();
	}, [shouldRefetch, auditTableLoading]);

	useEffect(() => {
		const fetchAgentOptionsIdValue = async (mapAgentInfoToOptions) => {
			const agentsRes = await getActiveAgents();
			try {
				if (agentsRes.data.success) {
					if (assignMyself) {
						let options = [];
						options.push({
							label: userState['user'].name,
							value: userState['user'].user_id
						});
						options.push({ label: 'Unassign', value: '-1' });
						setSelectAgentOptions(options);
					} else {
						let options = mapAgentInfoToOptions(agentsRes.data.agents);
						options.push({ label: 'Unassign', value: '-1' });
						setSelectAgentOptions(options);
						console.log('what is the agent options?', selectAgentOptions);
					}
					setAgentOptsAuditTable(agentsRes.data.agents);
				}
			} catch (err) {
				if (agentsRes.status === 401) {
					return dispatchUser({ isLoggedIn: false });
				}
				console.log(err.message);
			}
		};

		const mapAgentInfoToOptions = (agentArr) => {
			// this function formats the possible options to an array holding objects of the form {name: string, uuid: string}
			return agentArr.map((agent) => {
				return { label: agent.name, value: agent.uuid };
			});
		};

		fetchAgentOptionsIdValue(mapAgentInfoToOptions);
	}, [permissionChecked, assignMyself]);

	const handleSubmit = () => {
		const formData = {
			owner: owner,
			homeId: propertyListingInfo.HomeId
		};
		postTaskOwner(formData)
			.then((res) => {
				if (res.data.success) {
					console.log('status updated');
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		// we need to fetch the tag options

		let unmounted = false;
		const fetchData = async () => {
			getTagsByOrganization()
				.then((res) => {
					if (res.status === 200) {
						setTagsOptions(res.data.tags);
					}
				})
				.catch((err) => {
					if (err.response.status === 401) {
						dispatchUser({
							isLoggedIn: false
						});
						return;
					}
					console.log(err.message);
				});
		};
		fetchData();
		return () => {
			unmounted = true;
		};
	}, []);

	// unpack data safely
	useEffect(() => {
		// NOTE, the owner info is passed through the listing so
		// we do not fetch it in fetchData()setOwner
		const {
			KnockingStatus = '',
			FollowUpSchedule = '',
			OwnerName = '',
			OwnerPhoneNumber = ''
		} = primaryInfo || {};

		const { Tags = [], Comments = [] } = secondaryInfo || {};

		const { AssignedAgent = '' } = assignedInfo || {};

		setKnockingStatus(KnockingStatus);
		setFollowUpSchedule(FollowUpSchedule);
		if (viewOwnerName) setOwnerName(OwnerName);
		if (viewOwnerPhone) setOwnerPhoneNumber(OwnerPhoneNumber);
		setTags(Tags);
		setComments(Comments);
		setOwner(AssignedAgent);
	}, [primaryInfo, secondaryInfo, assignedInfo]);

	const handlePopUpClick = () => {
		// handle closing edit modal
		setEditPopUp(!editPopUp);
	};

	const handleCaller = (num) => {
		const phone = num.replace(/-+/g, '');
		window.open(
			'/user/callcenter/' + phone + '/' + listing.HomeId,
			'_blank',
			'toolbar=0, status=0, width=450, height=650'
		);
	};

	const handleAudit = () => {
		getUserActivitiesSingleListing(listing.HomeId, 35, null, curAgentAuditTable)
			.then((res) => {
				if (res.data.success) {
					setUserActivities(res.data.userActivities);
					setAuditTableLoading(false);
				}
			})
			.catch((err) => {
				console.log(err.message);
			});
	};

	const titles = [
		{ label: 'User Name', numeric: false, id: 'username', align: 'center' },
		{ label: 'Action', numeric: false, id: 'action', align: 'left' },
		{ label: 'Detail', numeric: false, id: 'detail', align: 'left' },
		{ label: 'Response time', numeric: false, id: 'response', align: 'left' },
		{ label: 'Date & Time', numeric: false, id: 'datetime', align: 'left' }
	];

	const cells = ['User Name', 'Action', 'Detail', 'Response time', 'Date & Time'];

	return isLoadingPrimary ? (
		<div className={classes.spinner}>
			<CircularProgress />
		</div>
	) : (
		<Box className={classes.test}>
			<Typography variant="h4" component="h4">
				Custom Info
				<IconButton
					onClick={handlePopUpClick}
					aria-label="edit"
					size="medium"
					color="primary"
				>
					<Edit fontSize="inherit" />
				</IconButton>
				<EditPopUp
					listing={propertyListingInfo}
					handlePopUpClick={handlePopUpClick}
					editPopUp={editPopUp}
					primaryInfo={primaryInfo}
					assignedInfo={assignedInfo}
					shouldRefetch={shouldRefetch}
					setShouldRefetch={setShouldRefetch}
					submitSuccessStatus={submitSuccessStatus}
					setSubmitSuccessStatus={setSubmitSuccessStatus}
					setNotificationState={setNotificationState}
					setNotificationMessage={setNotificationMessage}
					knockingStatusOptions={knockingStatusOptions}
					tags={tags}
					tagsOptions={tagsOptions}
					selectAgentOptions={selectAgentOptions}
					knockingStatusVisible={knockingStatusVisible}
					viewCommentsVisible={viewCommentsVisible}
					tagsVisible={tagsVisible}
					editAssignedTaskVisible={editAssignedTaskVisible}
					addCommentsVisible={addCommentsVisible}
					owner={owner}
					setOwner={setOwner}
					resetPermissions={resetPermissions}
					assignMyself={assignMyself}
					viewOwnerName={viewOwnerName}
					viewOwnerPhone={viewOwnerPhone}
				/>
			</Typography>
			<Table className={classes.statusTable}>
				{/* DISPLAYING KNOCKING STATUS */}
				{knockingStatusVisible && (
					<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						<TableCell
							variant="h5"
							component="th"
							scope="row"
							align="left"
							className={classes.columnSpacing}
						>
							<Typography variant="subtitle1" component="h5">
								Knocking Status
							</Typography>
						</TableCell>
						<TableCell align="left">
							<Typography variant="subtitle1" component="h5">
								{knockingStatus !== '' ? knockingStatus : '-'}
							</Typography>
						</TableCell>
					</TableRow>
				)}
				{/* DISPLAYING ASSIGN TASK */}
				<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
					<TableCell component="th" scope="row">
						<Typography variant="subtitle1" component="h5">
							Assigned Agent
						</Typography>
					</TableCell>
					<TableCell align="left">
						<Typography variant="subtitle1" component="h5">
							{assignedInfo.name ? assignedInfo.name : '-'}
						</Typography>
					</TableCell>
				</TableRow>
				{/* DISPLAYING FOLLOW UP TIME */}
				{knockingStatusVisible && (
					<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						<TableCell component="th" scope="row">
							<Typography variant="subtitle1" component="h5">
								Follow Up
							</Typography>
						</TableCell>
						<TableCell align="left">
							<Typography variant="subtitle1" component="h5">
								{followUpSchedule !== ''
									? moment.unix(followUpSchedule).format('MMMM Do YYYY, h:mm a')
									: '-'}
							</Typography>
						</TableCell>
					</TableRow>
				)}
				{/* Owner Name */}
				{viewOwnerName && (
					<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						<TableCell component="th" scope="row">
							<Typography variant="subtitle1" component="h5">
								Owner Name
							</Typography>
						</TableCell>
						<TableCell align="left">
							<Typography variant="subtitle1" component="h5">
								{ownerName ? ownerName : '-'}
							</Typography>
						</TableCell>
					</TableRow>
				)}
				{/* Owner Phonenumber */}
				<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
					{viewOwnerPhone && (
						<TableCell component="th" scope="row">
							<Typography
								variant="subtitle1"
								component="h5"
								className={classes.iconDisplay}
							>
								Owner Phone No.{' '}
								{listing.VerifyStatus === 'HUMAN' && (
									<Tooltip title="Human" aria-label="Human">
										<DoneIcon />
									</Tooltip>
								)}
								{listing.VerifyStatus === 'UNKNOWN' && (
									<Tooltip title="Unknown" aria-label="Unknown">
										<HelpIcon />
									</Tooltip>
								)}
								{listing.VerifyStatus === 'NODETECTION' && (
									<Tooltip title="No Detection" aria-label="No Detection">
										<NotInterestedIcon />
									</Tooltip>
								)}
								{listing.VerifyStatus === 'VOICEMAILBEEP' && (
									<Tooltip title="Voicemail Beep" aria-label="Voicemail Beep">
										<VoicemailIcon />
									</Tooltip>
								)}
								{listing.VerifyStatus === 'VOICEMAILNOBEEP' && (
									<Tooltip
										title="Voicemail No Beep"
										aria-label="Voicemail No Beep"
									>
										<MicOffIcon />
									</Tooltip>
								)}
							</Typography>
						</TableCell>
					)}
					<TableCell align="left" className={classes.PhoneNumberContainer}>
						{
							<Typography variant="subtitle1" component="h5">
								{ownerPhoneNumber ? ownerPhoneNumber : '-'}
							</Typography>
						}
						{ownerPhoneNumber && (
							<div style={{ display: 'flex', alignItems: 'center', gap: '17px' }}>
								{viewCallRecordingsVisible && (
									<Button
										variant="contained"
										color="primary"
										size="small"
										className={classes.button}
										startIcon={<PhoneIcon />}
										endIcon={<OpenInNewIcon />}
										onClick={() => handleCaller(ownerPhoneNumber)}
									>
										Call
									</Button>
								)}
							</div>
						)}
					</TableCell>
				</TableRow>

				{isLoadingSecondary ? (
					<div className={classes.spinner}>
						<CircularProgress />
					</div>
				) : (
					<>
						{/* DISPLAYING TAGS */}
						{tagsVisible && (
							<RenderTags
								homeId={propertyListingInfo.HomeId}
								tags={tags}
								setTags={setTags}
							/>
						)}
						{/* DISPLAYING COMMENTS */}
						{viewCommentsVisible && (
							<RenderComments comments={comments} setComments={setComments} />
						)}
					</>
				)}
				{/* DISPLAYING OWNER INFO */}
			</Table>

			{viewCallRecordingsVisible && <CallRecordings homeId={listing.HomeId} />}
			{viewEditHistoryVisible && (
				<Box className={classes.editHistory}>
					<Typography
						variant="subtitle2"
						component="h6"
						className={classes.viewEditHistory}
						onClick={handleopenHistoryModal}
					>
						View Edit History
					</Typography>
				</Box>
			)}
			<Modal
				open={openHistoryModal}
				onClose={handleCloseHistoryModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box className={classes.historyModal}>
					<AuditTable
						listHeads={titles}
						listCells={cells}
						listings={userActivities}
						setListings={setUserActivities}
						title={`Audit`}
						homeId={listing.HomeId}
						agents={agentsOptsAuditTable}
						curAgent={curAgentAuditTable}
						setCurAgent={setCurAgentAuditTable}
						setCurAgentName={setCurAgentNameAuditTable}
						boardLoading={auditTableLoading}
						setBoardLoading={setAuditTableLoading}
					/>
				</Box>
			</Modal>
		</Box>
	);
}
