import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	Container,
	Button,
	CircularProgress,
	Typography,
	TablePagination,
	TableContainer,
	Table,
	TableRow,
	TableCell,
	TableBody,
	Modal
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { getOrganizationUsers } from '../../../../api/serverRequests';
import UserRolesForm from './UserRolesForm';

const CACHE_EXPIRY_MIN = 20;

const useStyles = makeStyles((theme) => ({
	roleTag: {
		background: 'rgba(0, 0, 0, 0.08)',
		borderRadius: '16px',
		zIndex: '1',
		alignItems: 'center',
		padding: '3.5px 5.5px',
		margin: '3.5px'
	},
	tableContainer: {
		marginTop: '20px'
	},
	header: {
		marginLeft: '10px',
		marginTop: '20px',
		fontSize: '20px',
		fontWeight: '400'
	},
	modalContainer: {
		width: '80%',
		height: '80%',
		backgroundColor: '#ffffff',
		border: '2px solid #000',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '8%'
	},
	btn: {
		float: 'right',
		marginLeft: '12px'
	}
}));

export default function UsersList(tabValue, index) {
	const [isLoading, setIsLoading] = useState(true);
	const [errMsg, setErrMsg] = useState('');
	const classes = useStyles();

	const [formOpen, setFormOpen] = useState(false);
	const [page, setPage] = useState(0);
	const [usersCount, setUsersCount] = useState(10);
	const [rowsPerPage] = useState(10);
	const [users, setUsers] = useState({});

	const handleChangePage = (e, newPage) => {
		setPage(newPage);
	};

	const cacheUserList = (list) => {
		localStorage.setItem('orgUserList', JSON.stringify(list));
		const expiryTime = new Date();
		expiryTime.setMinutes(expiryTime.getMinutes() + CACHE_EXPIRY_MIN);
		localStorage.setItem('userListExpiryTime', expiryTime.toJSON());
	};

	const handleEditBtnClick = (user) => {
		const form = {
			name: user.name,
			email: user.email,
			roles: user.roles
		};
		localStorage.setItem('userRolesForm', JSON.stringify(form));
		setFormOpen(true);
	};

	const handleCloseForm = (user) => {
		localStorage.removeItem('userRolesForm');
		setFormOpen(false);
	};

	const handleDeleteBtnClick = (e) => {
		// todo
	};

	const handleNewUserBtn = (e) => {
		localStorage.setItem('userRolesForm', JSON.stringify({}));
	};

	const handleRefreshBtn = () => {
		if (localStorage.getItem('orgUserList')) {
			localStorage.removeItem('orgUserList');
			localStorage.removeItem('userRolesForm');
			localStorage.removeItem('userListExpiryTime');
			setIsLoading(true);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await getOrganizationUsers();
				if (res.data.success) {
					const usersData = res.data.users;
					cacheUserList(usersData);
					setUsers(usersData);
					setUsersCount(Object.keys(usersData).length);
				}

				setIsLoading(false);
			} catch (e) {
				console.log(e);
			}
		};

		if (isLoading) {
			if (localStorage.getItem('orgUserList')) {
				const expiryTime = new Date(localStorage.getItem('userListExpiryTime'));
				const currentTime = new Date();
				if (currentTime < expiryTime) {
					const usersData = JSON.parse(localStorage.getItem('orgUserList'));
					setUsers(usersData);
					setUsersCount(Object.keys(usersData).length);
					setIsLoading(false);
				} else {
					fetchData();
				}
			} else {
				fetchData();
			}
		}
	});

	useEffect(() => {
		if (!localStorage.getItem('orgUserList')) {
			setFormOpen(false);
			setIsLoading(true);
		}
	}, [localStorage.getItem('orgUserList')]);

	return isLoading ? (
		<CircularProgress size={50} color="inherit" />
	) : (
		<React.Fragment>
			<div role="tabpanel" id={index}>
				<Modal open={formOpen} onClose={handleCloseForm}>
					<Container className={classes.modalContainer}>
						<UserRolesForm
							setIsLoadingUserAccessTab={setIsLoading}
							setFormOpen={setFormOpen}
						/>
					</Container>
				</Modal>
				<Container>
					<Container style={{ flexDirection: 'row' }}>
						<Button
							variant="contained"
							color="secondary"
							className={classes.btn}
							onClick={handleRefreshBtn}
						>
							Refresh
						</Button>
						<Button
							variant="contained"
							color="primary"
							className={classes.btn}
							onClick={handleNewUserBtn}
						>
							New User
						</Button>
					</Container>
					<Typography className={classes.header}>
						Users in your organization ({usersCount})
					</Typography>
					<TableContainer className={classes.tableContainer}>
						<Table sx={{ minWidth: 650 }}>
							<TableBody>
								{Object.values(users)
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((user) => (
										<TableRow key={user.email}>
											<TableCell
												style={{ color: '#192591' }}
												component="th"
												scope="row"
											>
												{user.name}
											</TableCell>
											<TableCell component="th" scope="row">
												{Object.values(user.roles).map((role) => (
													<span className={classes.roleTag}>{role}</span>
												))}
											</TableCell>
											<TableCell component="th" scope="row" float="right">
												<IconButton
													style={{ float: 'right' }}
													onClick={() => handleEditBtnClick(user)}
												>
													<EditIcon></EditIcon>
												</IconButton>
											</TableCell>
											<TableCell component="th" scope="row" float="right">
												<IconButton
													disabled
													style={{ float: 'right' }}
													onClick={() => handleDeleteBtnClick(user)}
												>
													<DeleteIcon></DeleteIcon>
												</IconButton>
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						component="div"
						count={usersCount}
						page={page}
						onPageChange={handleChangePage}
						rowsPerPage={rowsPerPage}
						rowsPerPageOptions={[10]}
					/>
				</Container>
			</div>
		</React.Fragment>
	);
}
