import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { makeStyles } from '@material-ui/core/styles';

import { Title } from '../../';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(3),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column'
	},
	fixedHeight: {
		height: 120
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	}
}));

export default function NotFoundError({ listing }) {
	const classes = useStyles();
	return (
		<Grid container>
			<Grid item xs={12}>
				<Paper className={classes.paper}>
					{listing ? (
						<Title>Listing {listing} not found.</Title>
					) : (
						<Title>We could not find a listing with that ID.</Title>
					)}
				</Paper>
			</Grid>
		</Grid>
	);
}
