import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	progress: {
		width: FullWidthConstraint('full'),
		marginTop: '20px',
		marginBottom: '10px',
		float: 'left'
	},
	progressBar: {
		display: 'flex',
		alignItems: 'center'
	},
	progressBarLabel: {
		minWidth: 35
	}
});

function FullWidthConstraint(type) {
	return type === 'full' ? '100%' : '23em';
}
function LinearProgressWithLabel(props) {
	const classes = useStyles();
	return (
		<Box className={classes.progressBar}>
			<Box width="100%" mr={1}>
				<LinearProgress variant="determinate" {...props} />
			</Box>
			<Box className={classes.progressBarLabel}>
				<Typography variant="body2" color="textSecondary">{`${Math.round(
					props.value
				)}%`}</Typography>
			</Box>
		</Box>
	);
}

LinearProgressWithLabel.propTypes = {
	value: PropTypes.number.isRequired
};

export default function ProgressBar(props) {
	// const [progress, setProgress] = useState(0);
	const classes = useStyles();

	// useEffect(() => {
	//   const timer = setInterval(
	//     () =>
	//       setProgress(prevProgress =>
	//         prevProgress >= 100 ? 0 : prevProgress + 10
	//       ),
	//     800
	//   );
	//   return () => clearInterval(timer);
	// }, []);

	return (
		<div className={classes.progress}>
			<LinearProgressWithLabel value={props.value} setProgress={props.setProgress} />
		</div>
	);
}

// import {makeStyles} from '@material-ui/core/styles';
// import CircularProgress from '@material-ui/core/CircularProgress';

// const useStyles = makeStyles(theme => ({
//   root: {
//     display: 'flex',
//     '& > * + *': {
//       marginLeft: theme.spacing(2),
//     },
//   },
// }));

// export default function ProgressBar() {
//   const classes = useStyles();

//   return (
//     <div className={classes.root}>
//       <CircularProgress />
//     </div>
//   );
// }
