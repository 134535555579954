import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2, 0, 2)
	}
}));

export default function SubmitButton({ label, ...props }) {
	const classes = useStyles();
	return (
		<Button
			className={classes.root}
			type="submit"
			variant="contained"
			color="primary"
			{...props}
		>
			{label}
		</Button>
	);
}

SubmitButton.defaultProps = {
	label: 'Submit'
};
