import React from 'react';
import Picker from 'emoji-picker-react';
import IconButton from '@material-ui/core/IconButton';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';

export default function EmojiPicker({ isShow, disabled, handleEmojiSelected, toggleEmojiPicker }) {
	const onEmojiClick = (event, emojiObject) => {
		handleEmojiSelected(emojiObject);
		toggleEmojiPicker(false);
	};

	return (
		<div className="EmojiPicker">
			<IconButton
				color="secondary"
				aria-label="add an emoji"
				disabled={disabled}
				className="EmojiPicker__Btn"
				onClick={toggleEmojiPicker}
			>
				<InsertEmoticonIcon alt="Add Emoji" color="primary" fontSize="large" />
			</IconButton>
			{isShow && (
				<div className="EmojiPicker__Wrapper">
					<Picker onEmojiClick={onEmojiClick} />
				</div>
			)}
		</div>
	);
}
