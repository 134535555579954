export const priceOptions = [
	// {label:"any" , value:""},
	{ label: '$200,000', value: '200000' },
	{ label: '$250,000', value: '250000' },
	{ label: '$300,000', value: '300000' },
	{ label: '$350,000', value: '350000' },
	{ label: '$400,000', value: '400000' },
	{ label: '$450,000', value: '450000' },
	{ label: '$500,000', value: '500000' },
	{ label: '$550,000', value: '550000' },
	{ label: '$600,000', value: '600000' },
	{ label: '$650,000', value: '650000' },
	{ label: '$700,000', value: '700000' },
	{ label: '$750,000', value: '750000' },
	{ label: '$800,000', value: '800000' },
	{ label: '$850,000', value: '850000' },
	{ label: '$900,000', value: '900000' },
	{ label: '$950,000', value: '950000' },
	{ label: '$1M', value: '1000000' },
	{ label: '$1.5M', value: '1500000' },
	{ label: '$2M', value: '2000000' },
	{ label: '$2.5M', value: '2500000' },
	{ label: '$3M', value: '3000000' },
	{ label: '$3.5M', value: '3500000' },
	{ label: '$4M', value: '4000000' },
	{ label: '$4.5M', value: '4500000' },
	{ label: '$5M', value: '5000000' },
	{ label: '$6M', value: '6000000' },
	{ label: '$7M', value: '7000000' },
	{ label: '$8M', value: '8000000' },
	{ label: '$9M', value: '9000000' },
	{ label: '$10M', value: '10000000' }
	// {label:'$10M+', value:10000000},
];

export const numOptions = [
	// {label:'any' , value:""},
	{ label: '1+', value: '1+' },
	{ label: '2+', value: '2+' },
	{ label: '3+', value: '3+' },
	{ label: '4+', value: '4+' },
	{ label: '5+', value: '5+' }
];

export const areaOptions = [
	// {label:'any' , value:""},
	{ label: 'North Vancouver', value: 'North Vancouver' },
	{ label: 'West Vancouver', value: 'West Vancouver' },
	{ label: 'Burnaby', value: 'Burnaby' }
];

export const sinceLastXDaysOptions = [
	{ label: '1 Day', value: '1' },
	{ label: '3 Days', value: '3' },
	{ label: '7 Days', value: '7' },
	{ label: '14 Days', value: '14' },
	{ label: '14+ Days', value: '14+' }
];

export const subOptions = {
	'West Vancouver': [
		{ label: 'VWVBP', value: ['VWVBP'] },
		{ label: 'VWVWC', value: ['VWVWC'] },
		{ label: 'VWVLB', value: ['VWVLB'] },
		{ label: 'VWVGM', value: ['VWVGM'] },
		{ label: 'VWVCD', value: ['VWVCD'] },
		{ label: 'VWVBR', value: ['VWVBR'] },
		{ label: 'VWVHB', value: ['VWVHB'] },
		{ label: 'VWVCA', value: ['VWVCA'] },
		{ label: 'VWVPR', value: ['VWVPR'] },
		{ label: 'VWVSH', value: ['VWVSH'] },
		{ label: 'VWVHS', value: ['VWVHS'] },
		{ label: 'VWVEH', value: ['VWVEH'] },
		{ label: 'VWVQU', value: ['VWVQU'] },
		{ label: 'VWVDU', value: ['VWVDU'] },
		{ label: 'VWVCW', value: ['VWVCW'] },
		{ label: 'VWVWM', value: ['VWVWM'] },
		{ label: 'VWVFC', value: ['VWVFC'] },
		{ label: 'VWVCE', value: ['VWVCE'] },
		{ label: 'VWVGL', value: ['VWVGL'] },
		{ label: 'VWVWB', value: ['VWVWB'] },
		{ label: 'VWVCY', value: ['VWVCY'] },
		{ label: 'VWVAM', value: ['VWVAM'] },
		{ label: 'VWVSC', value: ['VWVSC'] },
		{ label: 'VWVAL', value: ['VWVAL'] },
		{ label: 'VWVWH', value: ['VWVWH'] },
		{ label: 'VWVUC', value: ['VWVUC'] },
		{ label: 'VWVER', value: ['VWVER'] },
		{ label: 'VWVCP', value: ['VWVCP'] },
		{ label: 'VWVOC', value: ['VWVOC'] },
		{ label: 'VWVPV', value: ['VWVPV'] },
		{ label: 'VWVRR', value: ['VWVRR'] },
		{ label: 'VWVCB', value: ['VWVCB'] },
		{ label: 'VWVWE', value: ['VWVWE'] },
		{ label: 'VWVPA', value: ['VWVPA'] },
		{ label: 'VWVDR', value: ['VWVDR'] }
	],
	'North Vancouver': [
		{ label: 'VNVUL', value: ['VNVUL'] },
		{ label: 'VNVSY', value: ['VNVSY'] },
		{ label: 'VNVIA', value: ['VNVIA'] },
		{ label: 'VNVNG', value: ['VNVNG'] },
		{ label: 'VNVDC', value: ['VNVDC'] },
		{ label: 'VNVPN', value: ['VNVPN'] },
		{ label: 'VNVLV', value: ['VNVLV'] },
		{ label: 'VNVCA', value: ['VNVCA'] },
		{ label: 'VNVCL', value: ['VNVCL'] },
		{ label: 'VNVMC', value: ['VNVMC'] },
		{ label: 'VNVLL', value: ['VNVLL'] },
		{ label: 'VNVCV', value: ['VNVCV'] },
		{ label: 'VNVPH', value: ['VNVPH'] },
		{ label: 'VNVRP', value: ['VNVRP'] },
		{ label: 'VNVGW', value: ['VNVGW'] },
		{ label: 'VNVBD', value: ['VNVBD'] },
		{ label: 'VNVIR', value: ['VNVIR'] },
		{ label: 'VNVWP', value: ['VNVWP'] },
		{ label: 'VNVCY', value: ['VNVCY'] },
		{ label: 'VNVNL', value: ['VNVNL'] },
		{ label: 'VNVTE', value: ['VNVTE'] },
		{ label: 'VNVDO', value: ['VNVDO'] },
		{ label: 'VNVED', value: ['VNVED'] },
		{ label: 'VNVDE', value: ['VNVDE'] },
		{ label: 'VNVWL', value: ['VNVWL'] },
		{ label: 'VNVFH', value: ['VNVFH'] },
		{ label: 'VNVPP', value: ['VNVPP'] },
		{ label: 'VNVWS', value: ['VNVWS'] },
		{ label: 'VNVLY', value: ['VNVLY'] },
		{ label: 'VNVUD', value: ['VNVUD'] },
		{ label: 'VNVBL', value: ['VNVBL'] },
		{ label: 'VNVWT', value: ['VNVWT'] },
		{ label: 'VNVBR', value: ['VNVBR'] },
		{ label: 'VNVQU', value: ['VNVQU'] }
	],
	Burnaby: [{ label: 'None', value: ['None'] }]
};

export const frontageOptions = [
	// {label:'any' , value:""},
	{ label: '20F+', value: '20' },
	{ label: '30F+', value: '30' },
	{ label: '40F+', value: '40' },
	{ label: '50F+', value: '50' },
	{ label: '75F+', value: '75' },
	{ label: '100+', value: '100' },
	{ label: '150+', value: '150' },
	{ label: '200+', value: '200' }
];

export const sizeOptions = [
	// {label:'any' , value:""},
	{ label: '500', value: '500' },
	{ label: '750', value: '750' },
	{ label: '1,000', value: '1000' },
	{ label: '1,250', value: '1250' },
	{ label: '1,500', value: '1500' },
	{ label: '1,750', value: '1750' },
	{ label: '2,000', value: '2000' },
	{ label: '2,250', value: '2250' },
	{ label: '2,500', value: '2500' },
	{ label: '2,750', value: '2750' },
	{ label: '3,000', value: '3000' },
	{ label: '3,250', value: '3250' },
	{ label: '3,500', value: '3500' },
	{ label: '4,000', value: '4000' },
	{ label: '5,000', value: '5000' },
	{ label: '6,000', value: '6000' },
	{ label: '7,000', value: '7000' },
	{ label: '8,000', value: '8000' },
	{ label: '9,000', value: '9000' },
	{ label: '10,000', value: '10000' }
];

export const yearOptions = [
	// {label:'any' , value:""},
	{ label: '2021', value: '2021' },
	{ label: '2020', value: '2020' },
	{ label: '2019', value: '2019' },
	{ label: '2018', value: '2018' },
	{ label: '2017', value: '2017' },
	{ label: '2016', value: '2016' },
	{ label: '2015', value: '2015' },
	{ label: '2014', value: '2014' },
	{ label: '2013', value: '2013' },
	{ label: '2012', value: '2012' },
	{ label: '2011', value: '2011' },
	{ label: '2010', value: '2010' },
	{ label: '2005', value: '2005' },
	{ label: '2000', value: '2000' },
	{ label: '1995', value: '1995' },
	{ label: '1990', value: '1990' },
	{ label: '1980', value: '1980' },
	{ label: '1970', value: '1970' },
	{ label: '1960', value: '1960' },
	{ label: '1950', value: '1950' }
];

export const percentOptions = [
	{ label: '-50%', value: '-50%' },
	{ label: '-45%', value: '-45%' },
	{ label: '-40%', value: '-40%' },
	{ label: '-35%', value: '-35%' },
	{ label: '-30%', value: '-30%' },
	{ label: '-25%', value: '-25%' },
	{ label: '-20%', value: '-20%' },
	{ label: '-15%', value: '-15%' },
	{ label: '-10%', value: '-10%' },
	{ label: '-5%', value: '-5%' },
	{ label: '0%', value: '0%' },
	{ label: '5%', value: '5%' },
	{ label: '10%', value: '10%' },
	{ label: '15%', value: '15%' },
	{ label: '20%', value: '20%' },
	{ label: '25%', value: '25%' },
	{ label: '30%', value: '30%' },
	{ label: '35%', value: '35%' },
	{ label: '40%', value: '40%' },
	{ label: '45%', value: '45%' },
	{ label: '50%', value: '50%' }
];

export const highwayOptions = [
	// {label:'any' , value:""},
	{ label: '100m+', value: '100' },
	{ label: '200m+', value: '200' },
	{ label: '300m+', value: '300' },
	{ label: '400m+', value: '400' },
	{ label: '500m+', value: '500' },
	{ label: '750m+', value: '750' },
	{ label: '1km+', value: '1000' }
];

export const searchTypeOptions = [
	{ label: 'Price Drop', value: 'price_dropped' },
	{ label: 'Below Last Sold', value: 'active' },
	{ label: 'Below Assessment', value: 'below_assessment' },
	{ label: 'Below Estimation [beta]', value: 'below_estimate' },
	{ label: 'Flip Tax [Comming soon]', value: 'flip tax' }
];

export const slopeOptions = [
	// {label:'any' , value:""},
	{ label: 'Flat', value: true },
	{ label: 'Slope', value: false }
];

export const directionOptions = [
	// {label:'any' , value:""},
	{ label: 'North-South', value: 'NS' },
	{ label: 'West-East', value: 'WE' }
];
