import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, CircularProgress, Snackbar } from '@material-ui/core';
import { useUserState } from '../../../hook/customerHook';
import { CusSelectField, ErrorMsg } from '../../';
import { getStatusByOrganization, postStatusToHome } from '../../../api/serverRequests';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	formControl: {
		marginTop: theme.spacing(1),
		width: '100%'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	}
}));

export default function StatusForm({ homeId, handleRefresh }) {
	const [statusOptions, setStatusOptions] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [errMsg, setErrMsg] = useState('');
	const classes = useStyles();
	const { handleSubmit, control, reset } = useForm();
	const [, dispatchUser] = useUserState();
	// // submit status updated after user has clicked save in edit modal
	const [submitSuccessStatus, setSubmitSuccessStatus] = useState(''); // keeps track of status when data is posted

	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message

	const handlePostStatus = (formData) => {
		if (!formData.status) {
			setErrMsg('Status is required');
			return;
		}
		postStatusToHome({ homeId: homeId, statusId: parseInt(formData.status.value) })
			.then((res) => {
				if (res.data.success) {
					setErrMsg('');
					setSubmitSuccessStatus('success');
					setNotificationState(true);
					setNotificationMessage('Status have been posted successfully');
					handleRefresh();
					reset({ status: '' });
				}
			})
			.catch((err) => {
				setErrMsg(err.message);
				console.log(err.message);
			});
	};

	const mapStatusToOptions = (statusArr) => {
		return statusArr.map((status) => {
			return { label: status.Status, value: status.Id };
		});
	};

	useEffect(() => {
		let unmounted = false;
		const fetchData = async () => {
			getStatusByOrganization()
				.then((res) => {
					setErrMsg('');
					if (res.data.success) {
						const options = mapStatusToOptions(res.data.statusArr);
						setStatusOptions(options);
					}
					if (!unmounted) {
						setIsLoading(false);
					}
				})
				.catch((err) => {
					if (err.response.status === 401) {
						dispatchUser({
							isLoggedIn: false
						});
						return;
					} else if (!unmounted) {
						setIsLoading(false);
					}
					setErrMsg(err.message);
					console.log(err.message);
				});
		};
		fetchData();
		return () => {
			unmounted = true;
		};
	}, []);

	return isLoading ? (
		<CircularProgress size={50} color="inherit" />
	) : (
		<React.Fragment>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={notificationState}
				autoHideDuration={6000}
				onClose={() => setNotificationState(false)}
			>
				<Alert
					onClose={() => setNotificationState(false)}
					severity={submitSuccessStatus === 'success' ? 'success' : 'error'}
					variant="filled"
				>
					{notificationMessage}
				</Alert>
			</Snackbar>
			<form className={classes.form} onSubmit={handleSubmit(handlePostStatus)}>
				<Grid container justifyContent="center" alignItems="center" spacing={2}>
					<CusSelectField
						defaultValue=""
						itemGrid={6}
						name="status"
						label="Status"
						options={statusOptions}
						control={control}
						fieldStyle={classes.formControl}
					/>
					{errMsg && <ErrorMsg text={errMsg} />}
					<Button
						type="submit"
						variant="contained"
						color="primary"
						className={classes.submit}
					>
						Submit
					</Button>
				</Grid>
			</form>
		</React.Fragment>
	);
}
