import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around',
		width: '100%',
		height: '100%'
	},
	setupContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: 150
	},
	subtext: {
		textAlign: 'center',
		marginTop: 5,
		fontSize: 16,
		opacity: 0.5,
		fontWeight: 700
	}
}));

export default function InitialSetup({ setNotificationSetupButton }) {
	const classes = useStyles();
	return (
		<Box className={classes.root}>
			<Box className={classes.setupContainer}>
				<Box>
					<Typography
						component="h2"
						variant="h2"
						style={{ textAlign: 'center', fontSize: 26 }}
					>
						Set Up Notifications Now!
					</Typography>
					<Typography component="p" variant="subtitle1" className={classes.subtext}>
						get alerts, reminders, and the latest updates!
					</Typography>
				</Box>
				<Button
					variant="contained"
					color="primary"
					onClick={() => setNotificationSetupButton(true)}
				>
					Setup
				</Button>
			</Box>
		</Box>
	);
}
