import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Controller } from 'react-hook-form';

import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';

import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1)
	},
	textInput: {
		'& label.Mui-focused': {
			color: '#004F2B',
			background: 'rgba(72, 193, 198, 0.06)'
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#004F2B'
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#004F2B'
			},
			'&:hover fieldset': {
				borderColor: '#004F2B'
			},
			'&.Mui-focused fieldset': {
				borderColor: '#004F2B'
			}
		}
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		background: '#B6B6B6',
		'&:hover': {
			backgroundColor: '#004F2B',
			boxShadow: 'none'
		}
	}
}));

export default function SingleInputForm({
	title,
	handleSubmit,
	errors,
	onConfirmSubmit,
	control,
	...props
}) {
	const classes = useStyles();
	return (
		<>
			<Typography component="h1" variant="h5">
				{title}
			</Typography>
			<form className={classes.form} onSubmit={handleSubmit(onConfirmSubmit)}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Controller
							name="code"
							control={control}
							render={(props) => (
								<TextField
									className={classes.textInput}
									name="code"
									variant="outlined"
									fullWidth
									id="code"
									label="Code"
									autoFocus
									value={props.value || ''}
									onChange={props.onChange}
									inputRef={props.ref}
								/>
							)}
							rules={{
								required: 'Code is required',
								pattern: {
									value: /^\d{6}$/i,
									message: 'Invalid code!'
								}
							}}
						/>
						<ErrorMessage
							errors={errors}
							name="code"
							render={({ message }) => <p className="text-danger mx-2">{message}</p>}
						/>
					</Grid>
				</Grid>
				<Button
					type="submit"
					fullWidth
					variant="contained"
					color="primary"
					className={classes.submit}
				>
					Confirm
				</Button>
			</form>
		</>
	);
}
