import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

export default function Message({ text }) {
	const useStyles = makeStyles((theme) => ({
		textBold: {
			fontWeight: 'bold'
		}
	}));

	const classes = useStyles();

	return (
		<Typography variant="body1" component="p" className={classes.textBold}>
			{text}
		</Typography>
	);
}
