import React, { useEffect, useState } from 'react';
import { Grid, Divider, Typography, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { StatusForm, ErrorMsg } from '../..';
import { useUserState } from '../../../hook/customerHook';
import { getHomeStatusByOrganization } from '../../../api/serverRequests';

export default function StatusSection({ homeId }) {
	const [status, setStatus] = useState('');
	const [errMsg, setErrMsg] = useState('');
	const [, dispatchUser] = useUserState();

	// // submit status updated after user has clicked save in edit modal
	const [submitSuccessStatus, setSubmitSuccessStatus] = useState(''); // keeps track of status when data is posted

	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message

	const fetchData = async () => {
		getHomeStatusByOrganization(homeId)
			.then((res) => {
				if (res.status === 200) {
					setStatus(res.data.status ? res.data.status.Status : '');
					setErrMsg('');
				}
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue'
					);
					dispatchUser({
						isLoggedIn: false
					});
					return;
				}
				setErrMsg('Error while loading tags!');
				console.log(err.message);
			});
	};

	const handleRefresh = () => {
		fetchData();
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={notificationState}
				autoHideDuration={6000}
				onClose={() => setNotificationState(false)}
			>
				<Alert
					onClose={() => setNotificationState(false)}
					severity={submitSuccessStatus === 'success' ? 'success' : 'error'}
					variant="filled"
				>
					{notificationMessage}
				</Alert>
			</Snackbar>
			<Grid container item xs={12}>
				<Grid item xs={12} sm={6}>
					{errMsg && <ErrorMsg text={errMsg} />}
					<Typography>Status: {status}</Typography>
				</Grid>
				<Divider />
				<Grid item xs={12} sm={6}>
					<StatusForm homeId={homeId} handleRefresh={handleRefresh} />
				</Grid>
			</Grid>
		</>
	);
}
